import React, { Component } from 'react'
import { Menu, Tab, Container, Grid, Header, Icon, Image, Label, Responsive, Segment } from "semantic-ui-react";
import Income from "./Income";
import Setting from "./Setting";
import Invite from "./Invite";
import WalletHeader from "../wallet/WalletHeader";
import Wallet from "../wallet/Wallet";
import UserService from '../../../service/UserService'
import config from '../../../config/config'
import '../../../assets/css/profileCustom.css'

const asyncFunc = (event) => {
    return new Promise(resolve => {
        event();
        resolve();
    });
};

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameHot: 2,
            isLoadingPane: false
        };
    }

    async componentDidMount() {
        await asyncFunc(() => { });
        this.resizeGame();
    }

    async UNSAFE_componentWillMount() {
        await UserService.fetchMe()
    }

    resizeGame = () => {
        let gameHot = 2;
        let innerWidth = window.innerWidth;
        if (innerWidth > 1080) {
            gameHot = 4;
        } else if (innerWidth > 620) {
            gameHot = 2;
        } else {
            gameHot = 1;
        }
        this.setState((prevState) => ({ gameHot: gameHot }))
    };

    handelChangeLoadingPane = (status) => {
        this.setState({ isLoadingPane: status })
    }

    panes = [
        {
            menuItem: { key: 'Setting', content: <div style={{ display: 'flex', alignItems: 'center' }}>ตัวฉัน</div> },
            render: () => <Tab.Pane attached={false} loading={this.state.isLoadingPane} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0,marginTop:-10 }}><Setting /></Tab.Pane>,
        },
        // {
        //     menuItem: { key: 'Wallet', content: <div style={{ display: 'flex', alignItems: 'center' }}><img src={require('../../../assets/images/wallet_profile_icon.png')} style={{ height: 16, marginRight: 5 }} /> กระเป๋า</div> },
        //     render: () => <Tab.Pane attached={false} loading={this.state.isLoadingPane} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0 }}><Wallet defaultActiveIndex={this.props.match.params?.id === 'af' ? 1 : 0} /></Tab.Pane>,
        // },
        {
            menuItem: { key: 'Invite', content: <div style={{ display: 'flex', alignItems: 'center' }}>แนะนำเพื่อน</div> },
            render: () => <Tab.Pane attached={false} loading={this.state.isLoadingPane} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0 }}><Invite handelChangeLoadingPane={this.handelChangeLoadingPane} /></Tab.Pane>,
        },
        // {
        //     menuItem: { key: 'Income', icon: 'money bill alternate outline', content: 'รายได้' },
        //     render: () => <Tab.Pane attached={false} loading={this.state.isLoadingPane} style={{boxShadow:'unset',border: 0,backgroundColor: 'unset',padding: 0}}><Income handelChangeLoadingPane={this.handelChangeLoadingPane} /></Tab.Pane>,
        // },
    ]

    render() {
        const { match: { params } } = this.props;
        return (
            <Container className='profileHeader'>
                <Tab
                    menu={{ pointing: true }}
                    panes={(config.enableAF == true || config.enableAF == 'true') ? this.panes : [this.panes[0], this.panes[1]]}
                    defaultActiveIndex={params ? (params?.id <= 1 ? params.id : '0') : '0'}
                // defaultActiveIndex={params ? (params.id == 'af' ? 1 : (params?.id <= 2 ? params.id : '0')) : '0'} 
                />
            </Container>
        );
    }
}


export default Profile