import React, { useEffect, useState } from 'react';
import { Input, Card, Container, Tab, Header, Icon, Image, Label, Responsive, Segment, Modal, CardContent } from "semantic-ui-react";
import GameService from '../../../service/GameService';
import Authen from '../../../service/Authen';
import Config from '../../../config/config';
import AlertClosed from './AlertClosed';

const list_game = [
    // 
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-001",
        "GameName": "Fishin Reels"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-002",
        "GameName": "Gates of Olympus"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-003",
        "GameName": "Hot to Burn Hold and Spin"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-004",
        "GameName": "Joker King"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-005",
        "GameName": "The Hand of Midas"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-006",
        "GameName": "Eye of the Storm"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-007",
        "GameName": "Dragon Kingdom Eyes of Fire"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-008",
        "GameName": "Madame Destiny Megaways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-009",
        "GameName": "Congo Cash"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-010",
        "GameName": "Emerald King Rainbow Road"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-011",
        "GameName": "Mysterious Egypt"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-012",
        "GameName": "Golden Ox"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-013",
        "GameName": "Voodoo Magic"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-014",
        "GameName": "Big Bass Bonanza"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-015",
        "GameName": "Bonanza Gold"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-016",
        "GameName": "John Hunter and the Mayan Gods"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-017",
        "GameName": "Spartan King"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-018",
        "GameName": "Christmas Carol Megaways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-019",
        "GameName": "Cowboys Gold"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-020",
        "GameName": "Pirate Gold Deluxe"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-021",
        "GameName": "Emerald King"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-022",
        "GameName": "The Dragon Tiger"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-023",
        "GameName": "Book of Kingdoms"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-024",
        "GameName": "Return of the Dead"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-025",
        "GameName": "Gems Bonanza"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-026",
        "GameName": "Wild Walker"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-027",
        "GameName": "Rise of Samurai"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-028",
        "GameName": "5 Lions Dance"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-029",
        "GameName": "Star Bounty"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-030",
        "GameName": "Great Rhino Deluxe"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-031",
        "GameName": "Wild Wild Riches"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-032",
        "GameName": "Ultra Hold and Spin"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-033",
        "GameName": "Curse of the Werewolf Megaways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-034",
        "GameName": "Jungle Gorilla"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-035",
        "GameName": "Aztec Gems Deluxe"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-036",
        "GameName": "The Dog House Megaways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-037",
        "GameName": "The Tiger Warrior"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-038",
        "GameName": "Street Racer"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-039",
        "GameName": "Fu Fu Fu"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-040",
        "GameName": "Drago-Jewels of Fortune"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-041",
        "GameName": "Bronco Spirit"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-042",
        "GameName": "Pyramid King"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-043",
        "GameName": "Ultra Burn"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-044",
        "GameName": "Money Money Money"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-045",
        "GameName": "Starz Megaways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-046",
        "GameName": "Fruit Party"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-047",
        "GameName": "John Hunter and the Book of Tut"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-048",
        "GameName": "Three Star Fortune"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-049",
        "GameName": "Great Rhino Megaways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-050",
        "GameName": "Hot to Burn"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-051",
        "GameName": "Dance Party"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-052",
        "GameName": "Wild West Gold"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-053",
        "GameName": "Lucky Dragon Ball"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-054",
        "GameName": "Fruit Rainbow"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-055",
        "GameName": "The Wild Machine"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-056",
        "GameName": "Aztec Bonanza"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-057",
        "GameName": "Mysterious"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-058",
        "GameName": "Golden Beauty"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-059",
        "GameName": "Master Joker"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-060",
        "GameName": "Super 7s"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-061",
        "GameName": "Release the Kraken"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-062",
        "GameName": "Buffalo King"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-063",
        "GameName": "Magic Journey"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-064",
        "GameName": "Money Mouse"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-067",
        "GameName": "Sweet Bonanza Xmas"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-069",
        "GameName": "Fire Strike"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-072",
        "GameName": "John Hunter and the Tomb of the Scarab Queen"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-073",
        "GameName": "Tree of Riches"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-080",
        "GameName": "5 Lions Gold"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-082",
        "GameName": "Sweet Bonanza"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-085",
        "GameName": "The Dog House"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-089",
        "GameName": "Golden Pig"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-093",
        "GameName": "Mustang Gold"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-096",
        "GameName": "Vegas Magic"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-104",
        "GameName": "5 Lions"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-106",
        "GameName": "Great Rhino"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-107",
        "GameName": "Joker's Jewels"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-109",
        "GameName": "Fire 88"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-111",
        "GameName": "Aztec Gems"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-118",
        "GameName": "Diamond Strike"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-121",
        "GameName": "Caishen's Gold"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-126",
        "GameName": "3 Kingdoms - Battle of Red Cliffs"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-127",
        "GameName": "Wolf Gold"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-131",
        "GameName": "Dragon Kingdom"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-135",
        "GameName": "Journey to the West"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-163",
        "GameName": "7 Monkeys"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-164",
        "GameName": "Triple Tigers"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-165",
        "GameName": "Monkey Madness"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-167",
        "GameName": "888 Dragons"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-169",
        "GameName": "888 Gold"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-171",
        "GameName": "Temujin Treasures"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-172",
        "GameName": "Juicy Fruits"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-173",
        "GameName": "Wild Booster"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-174",
        "GameName": "Power of Thor Megaways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-175",
        "GameName": "Hot Fiesta"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-176",
        "GameName": "Buffalo King Megaways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-177",
        "GameName": "Floating Dragon"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-178",
        "GameName": "Panda Fortune 2"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-179",
        "GameName": "The Amazing Money Machine"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-180",
        "GameName": "Aztec King"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-181",
        "GameName": "The Magic Cauldron - Enchanted Brew"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-182",
        "GameName": "Cash Elevator"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-183",
        "GameName": "5 Lions Megaways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-184",
        "GameName": "Dragon Hot Hold and Spin"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-185",
        "GameName": "Yum Yum Powerways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-186",
        "GameName": "Rise of Giza PowerNudge"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-187",
        "GameName": "Pyramid Bonanza"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-188",
        "GameName": "Raging Bull"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-189",
        "GameName": "Starlight Princess"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-190",
        "GameName": "Bigger Bass Bonanza"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-191",
        "GameName": "Heart of Rio"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-192",
        "GameName": "Lucky Lightning"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-193",
        "GameName": "Empty the Bank"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-194",
        "GameName": "Fruit Party 2"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-195",
        "GameName": "Phoenix Forge"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-196",
        "GameName": "Chicken Drop"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-197",
        "GameName": "Lucky Grace And Charm"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-198",
        "GameName": "Aztec King Megaways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-199",
        "GameName": "Chilli Heat Megaways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-200",
        "GameName": "Treasure Wild"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-201",
        "GameName": "Cash Bonanza"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-202",
        "GameName": "Piggy Bank Bills"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-203",
        "GameName": "Day of Dead"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-204",
        "GameName": "Candy Village"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-205",
        "GameName": "Mystic Chief"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-206",
        "GameName": "The Tweety House"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-207",
        "GameName": "Star Pirates Code"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-208",
        "GameName": "John Hunter and the Quest for Bermuda Riches"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-209",
        "GameName": "Big Juan"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-210",
        "GameName": "Santa's Wonderland"
    },
    {
        "GameType": "SLOT",
        "GameCode": "PP-SLOT-211",
        "GameName": "Christmas Big Bass Bonanza"
    },
]
const PPSlotGame = () => {
    const [loading, setLoading] = useState(false)
    const [listGames, setListGames] = useState(list_game)
    const [listGamesFiltter, setListGamesFiltter] = useState(list_game)
    const [gameAmount, setGameAmount] = useState(3);
    const [searchValue, setSearchValue] = useState('')
    const [alertClose, setAlertClose] = useState(false)

    useEffect(() => {
        resizeGame()
    }, [])

    const resizeGame = () => {
        let gameAmount = 4;
        let innerWidth = window.innerWidth;
        if (innerWidth > 620) {
            gameAmount = 4;
        } else {
            gameAmount = 2;
        }
        setGameAmount(gameAmount)
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value)
        const _listGames = listGames
        let result = _listGames.filter(gameItem => gameItem.GameName.toLowerCase().includes(e.target.value.toLowerCase()));
        setListGamesFiltter(result)
    }

    const handlePlayPP = (GameCode, GameType) => {
        if (Config.ENABLE_PP_SLOT == true) {
            GameService.playSlotPP(GameCode, GameType).then((res) => {
                if (res.data.status == "0000") {
                    window.location.href = res.data.url
                } else {
                    setAlertClose(true)
                }
            }).catch(() => {
                setAlertClose(true)
            })
        }
    }

    return (
        <Container style={{ margin: 0, paddingBottom: 10, marginTop: 35 }}>
            <Segment loading={loading} style={{ boxShadow: 'unset', border: 0, marginBottom: 18, background: 'transparent' }}>

                <div className="search-container">
                    <input placeholder="ค้นหา" onChange={handleSearchChange} value={searchValue} />
                    <Icon name="search" />
                </div>

                <Responsive onUpdate={resizeGame}>
                    <Card.Group itemsPerRow={gameAmount} className="CardGroup-custom home-card" >
                        {
                            listGamesFiltter?.map((item, index) => {
                                return (
                                    <a
                                        className="ui card pg_slot_games_styles"
                                        target="_blank"
                                        key={index}
                                        style={{ borderRadius: 0 }}
                                        onClick={() => { handlePlayPP(item.GameCode, item.GameType) }}
                                    >
                                        <div
                                            style={{ backgroundImage: `url(${require(`../../../assets/images/home/pp/${item.GameName.replaceAll(' ', '').replaceAll("'", '')}.png`)})` }}
                                        >
                                        </div>
                                        <center style={{
                                            marginTop: -7,
                                            marginBottom: -2,
                                            overflow: 'hidden',
                                            height: 30,
                                            backgroundImage: `url(${require('../../../assets/images/home/name_game_slot.png')})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            color: '#fff',
                                        }}
                                            className="name-slot-game"
                                        >
                                            <span style={{ fontSize: '0.70em' }}>
                                                {item.GameName}
                                            </span>
                                        </center>
                                    </a>
                                )

                            })
                        }
                    </Card.Group>
                </Responsive>
            </Segment>

            <AlertClosed
                open={alertClose}
                onClose={() => setAlertClose(false)}
                name={"Pragmaticplay"}
            />

        </Container >
    )
}

export default PPSlotGame