import React, { useEffect, useState } from 'react';
import { Input, Card, Container, Tab, Header, Icon, Image, Label, Responsive, Segment, Modal, CardContent } from "semantic-ui-react";
import GameService from '../../../service/GameService';
import config from '../../../config/config';
import { isMobile } from 'react-device-detect';
import AlertClosed from './AlertClosed';
import Loading from '../../layout/Loading';

const SpadeAMBSlotgame = () => {
    const [loading, setLoading] = useState(false)
    const [listGames, setListGames] = useState([])
    const [listGamesFilter, setListGamesFilter] = useState([])
    const [gameAmount, setGameAmount] = useState(3);
    const [searchValue, setSearchValue] = useState('')
    const [alertClose, setAlertClose] = useState(false)
    const [loadingGame, setLoadingGame] = useState(false)

    useEffect(() => {
        setLoading(true)
        GameService.getSpadeAmbGame().then((res) => {
            if (res.status) {
                resizeGame()
                setListGames(res.data.data)
                setListGamesFilter(res.data.data)
                setLoading(false)
            }
        })
    }, [])

    const resizeGame = () => {
        let gameAmount = 4;
        let innerWidth = window.innerWidth;
        if (innerWidth > 620) {
            gameAmount = 4;
        } else {
            gameAmount = 2;
        }
        setGameAmount(gameAmount)
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value)
        const _listGames = listGames
        let result = _listGames?.filter(gameItem => gameItem.name.toLowerCase().includes(e.target.value.toLowerCase()));
        setListGamesFilter(result)
    }

    const handlePlaySpadeAMB = (GameCode) => {
        setLoadingGame(true)
        if (config.ENABLE_SPADE_AMB_SLOT == "true") {
            GameService.loginSpadeAmbGame(GameCode, isMobile).then((res) => {
                if (res.data.status == "0000") {
                    if (res?.data?.data?.data?.url) {
                        window.location.href = res?.data?.data?.data?.url
                        setTimeout(() => {
                            setLoadingGame(false)
                        }, 1500)
                    } else {
                        setLoadingGame(false)
                        setAlertClose(true)
                    }
                } else {
                    setLoadingGame(false)
                    setAlertClose(true)
                }
            }).catch(() => {
                setLoadingGame(false)
                setAlertClose(true)
            })
        }
    }

    return (
        <Container style={{ margin: 0, paddingBottom: 10, marginTop: 10 }}>

            <Loading loading={loadingGame} />

            <Segment loading={loading} style={{ boxShadow: 'unset', border: 0, marginBottom: 18, background: 'transparent' }}>

                <div className="search-container" st>
                    <input placeholder="ค้นหา" onChange={handleSearchChange} value={searchValue} />
                    <Icon name="search" />
                </div>

                <Responsive onUpdate={resizeGame}>
                    <Card.Group itemsPerRow={gameAmount} className="CardGroup-custom home-card" >
                        {
                            listGamesFilter?.map((item, index) => {
                                return (
                                    <a
                                        className="ui card pg_slot_games_styles"
                                        target="_blank"
                                        key={index}
                                        style={{ borderRadius: 0 }}
                                        onClick={() => {
                                            handlePlaySpadeAMB(item.code)
                                        }}
                                    >
                                        <div
                                            style={{ backgroundImage: `url(${item?.img})` }}
                                        >
                                        </div>
                                        <center style={{
                                            marginTop: -7,
                                            marginBottom: -2,
                                            overflow: 'hidden',
                                            height: 30,
                                            backgroundImage: `url(${require('../../../assets/images/home/name_game_slot.png')})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            color: '#fff',
                                        }}
                                            className="name-slot-game-pg"
                                        >
                                            <span style={{ fontSize: '0.70em' }}>
                                                {item.name}
                                            </span>
                                        </center>
                                    </a>
                                )

                            })
                        }
                    </Card.Group>
                </Responsive>
            </Segment>

            <AlertClosed
                open={alertClose}
                onClose={() => setAlertClose(false)}
                name={"Spade"}
            />

        </Container >
    )

}

export default SpadeAMBSlotgame