import Config from "../config/config";
import Authen from './Authen'
import apiClient from './axios/apiClient'

export default class HuayService {
    constructor() {
    }

    static async getHuayPlaylist(huayId = null) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'huay/playlist/'+((huayId!=null) ? huayId : ''),
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async getRound(roundId) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'huay/round/'+roundId,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async getRoundAll(huayId=null) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'huay/rounds/'+ huayId || '',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async play(roundID,orderitem,orderName) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain+'huay/play/'+roundID,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
            data: JSON.stringify({
                "orderItem": orderitem,
                "orderName":orderName
            })
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return {status: false ,msg: error.response.data.errors.message, type:error.response.data.errors.type, data:error.response.data.errors.data}
        });        
        return await returnData
    }

    static async getNumberOutOfLimit(roundId) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'huay/outOfLimit/'+roundId,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async cancelOrder(orderId) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain+'huay/cancelOrder',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
            data:{
                orderId: orderId
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }
}