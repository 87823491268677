import React, { Component } from 'react'
import {
    Container,
} from "semantic-ui-react";
import WalletMoney from "./WalletMoney";

class Wallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndexLottery: -1,
            activeIndexCredit: -1,
            statementCredit: [],
            historyTransactionLoading: false,
            ShowLimitStatementCreditList: 10
        };
    }

    render() {

        return (
            <Container className="walletCustom">
                <WalletMoney defaultActiveIndex={this.props.defaultActiveIndex} />
            </Container>
        );
    }
}

export default Wallet;
