import React from "react";
import {
  Grid,
  Table,
  Button,
  Input,
  Icon,
  Segment,
  Message,
  Header,
  Container,
  Image,
  Dropdown,
  Modal,
} from "semantic-ui-react";
import Config from "../../../config/config";
import GameService from "../../../service/GameService";
import { Link, useHistory } from "react-router-dom";

const Slot = () => {
  const history = useHistory();
  const handleClick = (path, is_enable) => {
    if (is_enable == true || is_enable == "true") {
      history.push(path);
    }
  };
  return (
    <Container>
      <Grid>
        {Config.ENABLE_JOKER_SLOT == true && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick("/jokerslotgames", Config.ENABLE_JOKER_SLOT)
              }
            >
              <img
                src={
                  Config.ENABLE_JOKER_SLOT == true
                    ? require("../../../assets/images/home/joker_slot.jpeg")
                    : require("../../../assets/images/home/joker_slot_disable.jpeg")
                }
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>Joker Gaming</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_JOKER_AMB_SLOT == "true" && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick("/jokerambslotgames", Config.ENABLE_JOKER_AMB_SLOT)
              }
            >
              <img
                src={require("../../../assets/images/home/joker_slot.jpeg")}
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>Joker Gaming</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_PG_SLOT == true && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() => handleClick("/pgslotgames", Config.ENABLE_PG_SLOT)}
            >
              <img
                src={
                  Config.ENABLE_PG_SLOT == true
                    ? require("../../../assets/images/home/pg_slot.jpeg")
                    : require("../../../assets/images/home/pg_slot_disable.jpeg")
                }
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>PG Games</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_PG_AMB_SLOT == "true" && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick("/pgambslotgames", Config.ENABLE_PG_AMB_SLOT)
              }
            >
              <img
                src={require("../../../assets/images/home/pg_slot.jpeg")}
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>PG Games</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_KINGMAKER_SLOT == true && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick("/kingmakerslotgames", Config.ENABLE_KINGMAKER_SLOT)
              }
            >
              <img
                src={
                  Config.ENABLE_KINGMAKER_SLOT == true
                    ? require("../../../assets/images/home/king_slot.jpeg")
                    : require("../../../assets/images/home/king_slot_disable.jpeg")
                }
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>Kingmaker</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_KINGMAKER_AMB_SLOT == "true" && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick(
                  "/kingmakerambslotgames",
                  Config.ENABLE_KINGMAKER_AMB_SLOT
                )
              }
            >
              <img
                src={require("../../../assets/images/home/king_slot.jpeg")}
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>Kingmaker</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_AE_SLOT == true && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick("/awsslotgames", Config.ENABLE_AE_SLOT)
              }
            >
              <img
                src={
                  Config.ENABLE_AE_SLOT == true
                    ? require("../../../assets/images/home/ae_slot.jpeg")
                    : require("../../../assets/images/home/ae_slot_disable.jpeg")
                }
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>Ae Gaming</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_PP_SLOT == true && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() => handleClick("/ppslotgames", Config.ENABLE_PP_SLOT)}
            >
              <img
                src={
                  Config.ENABLE_PP_SLOT == true
                    ? require("../../../assets/images/home/pp.jpeg")
                    : require("../../../assets/images/home/pp_disable.jpeg")
                }
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>Pragmaticplay</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_PP_AMB_SLOT == "true" && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick("/ppambslotgames", Config.ENABLE_PP_AMB_SLOT)
              }
            >
              <img
                src={require("../../../assets/images/home/pp.jpeg")}
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>Pragmaticplay</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_SPADE_SLOT == true && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick("/spadeslotgames", Config.ENABLE_SPADE_SLOT)
              }
            >
              <img
                src={
                  Config.ENABLE_SPADE_SLOT == true
                    ? require("../../../assets/images/home/spade.jpeg")
                    : require("../../../assets/images/home/spade_disable.jpeg")
                }
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>SPADE</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_SPADE_AMB_SLOT == "true" && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick("/spadeambslotgames", Config.ENABLE_SPADE_AMB_SLOT)
              }
            >
              <img
                src={require("../../../assets/images/home/spade.jpeg")}
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>SPADE</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_FC_SLOT == true && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() => handleClick("/fcslotgames", Config.ENABLE_FC_SLOT)}
            >
              <img
                src={
                  Config.ENABLE_FC_SLOT == true
                    ? require("../../../assets/images/home/FC.jpeg")
                    : require("../../../assets/images/home/FC_disable.jpeg")
                }
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>FA CHAI</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_SLOT_XO_AMB == "true" && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick("/slotxoslotgames", Config.ENABLE_SLOT_XO_AMB)
              }
            >
              <img
                src={require("../../../assets/images/home/slotxo.jpg")}
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>Slot Xo</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
        {Config.ENABLE_ASK_ME_SLOT_AMB == "true" && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{ paddingTop: 4, paddingBottom: 4 }}
          >
            <Segment
              style={{
                boxShadow: "unset",
                border: 0,
                background: "var(--background-huay-list)",
                borderRadius: 20,
                padding: "10px 5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick("/askmeslotgames", Config.ENABLE_ASK_ME_SLOT_AMB)
              }
            >
              <img
                src={require("../../../assets/images/home/askmeslot.jpg")}
                style={{
                  width: "100%",
                  maxHeight: 130,
                  borderRadius: 20,
                  padding: "0px 5px 5px",
                }}
              />
              <div className="game-name-container" style={{ marginBottom: 0 }}>
                <div>Ask Me Slot</div>
              </div>
            </Segment>
          </Grid.Column>
        )}
      </Grid>
    </Container>
  );
};

export default Slot;
