import React from 'react'

const AlertGame = (props) => {
    return (
        <div style={{
            borderRadius: 5,
            backgroundColor: 'RGB(255,0,0,0.15)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: '#FF0000',
            borderWidth: 1,
            borderStyle: 'solid',
            padding: '10px 3px'
        }}>
            <p style={{ color: '#FF0000', fontSize: 13, textAlign: 'center' }}>*** หากไม่มีการเดิมพันภายใน {parseInt(props.gameTimeOut) / 60000} นาที เกมจะถูกยกเลิกและคืนเงิน ***</p>
        </div>
    )
};

export default AlertGame