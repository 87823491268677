import React, { useEffect, useState } from 'react';
import { Container, Loader, Dimmer } from "semantic-ui-react";
import YiiGiiService from '../../../service/YiiGiiService';
import HuayService from '../../../service/HuayService';
import LotteryHistory from '../lottery/LotteryHistory';

const HuayHistory = () => {
    const [loading, set_loading] = useState(true)
    const [huay, set_huay] = useState([])
    const [YiiGii, set_YiiGii] = useState([])

    useEffect(() => {
        initData()
    }, [])

    const initData = async () => {
        set_loading(true)
        await YiiGiiService.getYiiGii().then((res) => {
            if (res.status) {
                set_YiiGii(res.data)
            }
        })
        await HuayService.getHuayPlaylist().then(res => {
            if (res.status) {
                set_huay(res.data)
            }
        })
        set_loading(false)
    }
    return (
        <>

            <Container className='lotteryHistory' >
                {
                    loading ?
                        <Dimmer active>
                            <Loader />
                        </Dimmer>
                        :
                        <LotteryHistory huay={huay} yiigii={YiiGii} />
                }
            </Container>

        </>
    )
}

export default HuayHistory