import React, { Component } from "react";
import {
  Button,
  Confirm,
  Divider,
  Header,
  Icon,
  Image,
  Input,
  Loader,
  Message,
  Modal,
  Progress,
  Step,
  Grid,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import scb_img from "../../../assets/images/bank/scb.svg";
import bay_img from "../../../assets/images/bank/bay.svg";
import ktb_img from "../../../assets/images/bank/ktb.svg";
import kbnk_img from "../../../assets/images/bank/kbnk.svg";
import tmb_img from "../../../assets/images/bank/tmb.svg";
import bbl_img from "../../../assets/images/bank/bbl.svg";
import WalletService from "../../../service/WalletService";
import UserService from "../../../service/UserService";
import ConfigValueService from "../../../service/ConfigValueService";
import { BankObject } from "../profile/Setting";
import config from "../../../config/config";

class WithdrawCredit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      show: "mini",
      close: true,
      money: 0,
      next: true,
      time: {},
      seconds: 300,
      countDownPercent: 100,
      step: 1,
      loader: false,
      minimumWithdraw: 0,
      ENABLE_WITHDRAW_CREDIT: false,
      openConfirm: false,
      confirmText: {
        title: "",
        body: "",
      },
      config_note: "",
      maximumWithdraw: 0,
      BANK_SYSTEM_DEPOSIT_AUTO_SYSTEM: "",
      WITHDRAW_ALLOW_TIME: true,
    };
    this.timer = 0;
  }

  componentDidMount() {
    if (this.props.openWithdrawCredit) {
      this.setState({ loader: true });
      UserService.getBankConfig().then((res) => {
        if (res.status === "0000") {
          this.setState({
            ENABLE_WITHDRAW_CREDIT: res.data.BANK_SYSTEM_ENABLE_WITHDRAW_CREDIT,
            minimumWithdraw: Number(res.data.BANK_SYSTEM_MINIMUN_WITHDRAW),
            config_note: res.data.BANK_SYSTEM_WITHDRAW_MSG,
            maximumWithdraw: res.data.BANK_SYSTEM_MAXIMUM_WITHDRAW,
            BANK_SYSTEM_DEPOSIT_AUTO_SYSTEM:
              res.data.BANK_SYSTEM_DEPOSIT_AUTO_SYSTEM,
            WITHDRAW_ALLOW_TIME: res.data.WITHDRAW_ALLOW_TIME,
          });
        }
        this.setState({ loader: false });
      });
    }
    this.setState({ time: this.secondsToTime(this.state.seconds) });
  }

  handleChangeMoney = (event) => {
    this.setState({ next: true });
    let value = event.target.value.replace(/,/g, "");
    if (!isNaN(value)) {
      value = Number(value);
      if (value <= (this.state.maximumWithdraw || 49999)) {
        if (value >= this.state.minimumWithdraw) {
          this.setState({ next: false });
        }
        this.setState({ money: value });
      } else {
        this.setState({ next: false });
      }
    }
  };

  async commitWitdraw() {
    if (this.state.BANK_SYSTEM_DEPOSIT_AUTO_SYSTEM == "VIZPAY") {
      UserService.vizpayWithdraw(this.state.money).then((res) => {
        if (res.status) {
          this.setState({
            step: 2,
            loader: false,
            confirmText: {
              title: "ทำรายการสำเร็จ",
              body: "ทำรายการสำเร็จ รอการอนุมัติ",
            },
          });
        } else if (res.msg === "ยอดเงินไม่เพียงพอ") {
          this.setState({
            step: 2,
            loader: false,
            confirmText: { title: "ทำรายการไม่สำเร็จ", body: res.msg },
          });
        } else {
          this.setState({
            step: 2,
            loader: false,
            confirmText: { title: "ทำรายการไม่สำเร็จ", body: res.msg },
          });
        }
        this.handleOpenSuccessWithdrawCredit();
      });
    } else {
      await WalletService.withdraw(Number(this.state.money)).then((res) => {
        if (res.status) {
          this.setState({
            step: 2,
            loader: false,
            confirmText: {
              title: "ทำรายการสำเร็จ",
              body: "ทำรายการสำเร็จ รอการอนุมัติ",
            },
          });
        } else if (res.msg === "ยอดเงินไม่เพียงพอ") {
          this.setState({
            step: 2,
            loader: false,
            confirmText: { title: "ทำรายการไม่สำเร็จ", body: res.msg },
          });
        } else {
          this.setState({
            step: 2,
            loader: false,
            confirmText: {
              title: "ทำรายการไม่สำเร็จ",
              body: "ทำรายการไม่สำเร็จ กรุณาติดต่อเจ้าหน้าที่",
            },
          });
        }
        this.handleOpenSuccessWithdrawCredit();
      });
    }
  }

  handleClosePopup = () => {
    let timeLeftVar = this.secondsToTime(300);
    this.setState({
      money: 0,
      next: true,
      countDownPercent: 100,
      step: 1,
      time: timeLeftVar,
    });
    this.props.handleCloseWithdrawCredit();
  };

  startTimer = () => {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    return {
      h: hours,
      m: minutes,
      s: seconds.toString().length == 1 ? "0" + seconds : seconds,
    };
  };

  countDown = () => {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
      countDownPercent: parseInt(((seconds * 100) / 900).toFixed(0)),
    });
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  };

  handleOpenSuccessWithdrawCredit = () => {
    this.setState({ openConfirm: true });
  };

  handleCloseSuccessWithdrawCredit = () => {
    this.setState({ openConfirm: false });
    this.props.handleCloseWithdrawCredit();
  };

  toTwoDecimal = (num) => {
    let num_new =
      parseInt(Number(Number((Number(num) * 10).toFixed(3)) * 10).toFixed(3)) /
      100.0;
    let _num = num_new.toString().split(".");
    if (_num.length > 1) {
      return (
        _num[0] +
        "." +
        (_num[1].length < 2
          ? `${_num[1].substring(0, 2)}0`
          : _num[1].substring(0, 2))
      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return (_num[0] + ".00").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  render() {
    return (
      <>
        <Confirm
          open={this.state.openConfirm}
          header={this.state.confirmText.title}
          content={this.state.confirmText.body}
          confirmButton="ตกลง"
          cancelButton={false}
          onConfirm={this.handleCloseSuccessWithdrawCredit}
          size={"mini"}
        />
        <Modal
          centered={false}
          open={this.props.openWithdrawCredit}
          closeIcon
          onClose={this.handleClosePopup}
        >
          <Modal.Header>ถอนเครดิต</Modal.Header>
          <Modal.Content
            as={Segment}
            style={{ margin: 0, border: 0 }}
            loading={this.state.loader}
          >
            {console.log(!!this.state.BANK_SYSTEM_WITHDRAW_AMOUNT_TIME)}
            {UserService.getBankAccount() ? (
              this.state.ENABLE_WITHDRAW_CREDIT ? (
                !!this.state.WITHDRAW_ALLOW_TIME ? (
                  // <Modal.Description>
                  //     <Message size={"big"}>
                  //         <Message.Header><Icon name="university" />เครดิตของท่าน</Message.Header>
                  //         <Message.Content>
                  //             <Divider />
                  //             <Header as={"h1"} className="text-center">{this.toTwoDecimal(WalletService.getWalletBalance().credit)} ฿</Header>
                  //         </Message.Content>
                  //     </Message>
                  //     <Header>จำนวนเงินที่ต้องการถอน</Header>
                  //     <Input placeholder='0 บาท' icon='bitcoin' fluid value={this.state.money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onChange={(event) => {
                  //         this.handleChangeMoney(event)
                  //     }} />

                  //     <Message color="yellow">
                  //         <p>* ถอนขั้นต่ำ "ครั้งละ {this.state.minimumWithdraw} บาท" ไม่เกิน "ครั้งละ {this.state.maximumWithdraw.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "49,999"} บาท"</p>
                  //         <p>* ถ้าเครดิตหรือเงินไม่เข้าเกิน 5นาที ติดต่อแอดมิน</p>
                  //         <p>* ถอนเครดิตได้ไม่เกิน 5 ครั้งต่อวัน</p>
                  //         <p>* ช่วงเวลาทำการของระบบถอน (Withdraw) คือ 00:31 - 22:44 น. [ปิดถอน 22:45 - 00:30]</p>
                  //         {
                  //             this.state.config_note &&
                  //             <p>* {this.state.config_note}</p>
                  //         }
                  //     </Message>

                  //     <Button color="green" disabled={this.state.next} onClick={() => {
                  //         this.setState({ seconds: 300, loader: true }, () => this.commitWitdraw());
                  //         this.startTimer();
                  //     }} fluid>ยืนยัน</Button>
                  // </Modal.Description>
                  <Message color="yellow">
                    <center>
                      <Message.Header style={{ fontSize: 18 }}>
                        {"ปิดถอนชั่วคราว หากต้องการถอนกรุณาติดต่อที่"}
                        <br />
                        <a target={"_blank"} href={config.LineURL}>
                          {`${config.LineID}`}
                        </a>
                      </Message.Header>
                    </center>
                  </Message>
                ) : (
                  <Message color="yellow">
                    <center>
                      <Message.Header>
                        {"ปิดถอนช่วงเวลา 22:45 - 00:30 น."}
                      </Message.Header>
                    </center>
                  </Message>
                )
              ) : (
                <Message color="yellow">
                  <center>
                    <Message.Header>
                      ระบบปิด ทำรายการถอนเคดิต ชั่วคราว
                    </Message.Header>
                  </center>
                </Message>
              )
            ) : (
              <>
                <center>
                  <p style={{ color: "blue", marginBottom: 20 }}>
                    กรุณาเพิ่มบัญชีธนาคารของคุณก่อนถอนเงิน
                  </p>
                </center>
                {/* <Link to='/profile' onClick={this.handleClosePopup}>คลิกที่นี้เพื่อเพิ่มบัญชี</Link> */}
                <BankObject.SettingBankAccount
                  handelSaved={() => this.componentDidMount()}
                />
              </>
              // <Modal.Description style={{ textAlign: 'center' }}>
              //     <p style={{ color: "red", marginBottom: 20 }}>กรุณาเพิ่มบัญชีธนาคารของคุณก่อนถอนเงิน</p>
              //     {/* <a href='/profile' onClick={this.handleClosePopup}><p>คลิกที่นี้เพื่อเพิ่มบัญชี</p></a> */}
              //     <BankObject.SettingBankAccount handelSaved={() => this.componentDidMount()} />
              //     {/* <SettingBankAccount handelSaved={()=>this.componentDidMount()} /> */}
              // </Modal.Description>
            )}
            {/* <Segment className='customeSegment' style={{ background: "#EEE" }}>
                                    <Grid>
                                        <Grid.Column computer='4' tablet='5' mobile='5'>
                                            <Image style={{ 'backgroundColor': 'rgb(254, 196, 59)' }} rounded floated='left' src={BankObject.getBankData().image.src} size='tiny' />
                                        </Grid.Column>
                                        <Grid.Column computer='12' tablet='11' mobile='11'>
                                            <div>
                                                <div style={{ 'fontWeight': 'bold' }}>{BankObject.getBankData().text}</div>
                                                <div>{UserService.getBankAccount().accountNumber}</div>
                                                <div style={{ marginTop: 10 }}>{UserService.getBankAccount().accountName}</div>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Segment> */}
            {/* <Modal.Description>
                       <Header as="h1" color={"green"}
                                className="text-center">{this.state.time.m}:{this.state.time.s}</Header>
                       <Header as="h5" className="text-center">รอยืนยันจากระบบตรวจสอบอัตโนมัติ</Header>
                        <Button color={"green"} fluid onClick={this.handleClosePopup}>เสร็จสิ้น</Button>
                    </Modal.Description> */}
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.handleClosePopup}>
              ยกเลิก
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default WithdrawCredit;
