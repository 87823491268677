import React, { Component, createRef, useState, useEffect } from 'react'
import { Button, Container, Grid, Header, Table, List, Modal, Responsive, Segment, Icon, Divider, Tab, Input, Card, Image } from "semantic-ui-react";
import OrderPayment from "../wallet/OrderPayment";
import { Link } from "react-router-dom";
import { ToastContainer, Slide, toast } from 'react-toastify';
import HuayService from '../../../service/HuayService';
import 'react-toastify/dist/ReactToastify.css';
import BookService from '../../../service/BookService';
import HuayHistory from './HuayHistory';

const asyncFunc = (event) => {
    return new Promise(resolve => {
        event();
        resolve();
    });
};

class LotteryPlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assistRandomBox: 3,
            assistRandomActive: [
                {
                    type: 1,
                    name: "เลขคี่",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                },
                {
                    type: 2,
                    name: "เลขคู่",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                },
                {
                    type: 3,
                    name: "เลขเบิ้ล",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                },
                {
                    type: 4,
                    name: "เลขตอง",
                    active: false,
                    color: "grey",
                    typeLottery: [0],
                },
                {
                    type: 5,
                    name: "เลขสูง",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                },
                {
                    type: 6,
                    name: "เลขต่ำ",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                },
                {
                    type: 7,
                    name: "19 ประตู",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                    mode_active: 1
                },
                {
                    type: 8,
                    name: "กลับเลข",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                    mode_active: 2
                },
                {
                    type: 9,
                    name: "รูดหน้า",
                    active: true,
                    color: "grey",
                    mode_active: 3
                },
                {
                    type: 10,
                    name: "รูดหลัง",
                    active: true,
                    color: "grey",
                    mode_active: 4
                },
                {
                    type: 11,
                    name: "พี่น้อง",
                    active: true,
                    color: "grey",
                },
                {
                    type: 12,
                    name: "น้องพี่",
                    active: true,
                    color: "grey",
                },
            ],
            mode_active: 0, // shooting mode 0 = normal , 1 = 19Goal ,2=revers, 3=รูดหน้า, 3=รูดหลัง
            openConfirm: false,
            openOrderPayment: false,
            inputNumber: 3,
            inputNumberNow: ["-", "-", "-"],
            typeLotteryNow: 1,
            typeLotteryActive: [
                { isActive: false, isAllow: false },
                { isActive: true, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false }
            ],
            books: [],
            typeLotteryDetails: [],
            onLoadingtypeLottery: true,
            inputNumberTemp: '',
            huayDetailName: "",
            favoriteBill: [],
            modalPullBill: false,
            historyLoading: false,
            huayDetail: {},
            oldBill: [],
            modalPullOldBill: false,
            bookDescLoading: true,
            numberOfOrder: 0,
            oldOrderLoading: false,
            ShowLimitOldOrder: 10
        };
    }

    async componentDidMount() {
        this.resizeBox();
        await asyncFunc(() => {
        });
        await this.getLotteryTpye();
        this.handleChangeInput(this.state.typeLotteryDetails[0].length, this.state.typeLotteryDetails[0].type, this.getAssistantAllow(this.state.typeLotteryDetails[0].length))
    }

    async componentWillMount() {
    }

    async getLotteryTpye() {
        this.setState({ onLoadingtypeLottery: true })
        await HuayService.getRound(this.props.match.params.roundId).then((res) => {
            if (res.status) {
                const typeLotteryNew = []
                res.data.lotteryTypes.sort((a, b) => b.length - a.length).map((item, key) => {
                    if (item.isEnabled == 1) {
                        typeLotteryNew.push({
                            type: Number(item.typeId),
                            price: Number(item.multiply),
                            name: item.name,
                            maxPrice: Number(item.maxPrice),
                            minPrice: Number(item.minPrice),
                            length: Number(item.length)
                        })
                    }
                })

                this.setState({
                    typeLotteryDetails: typeLotteryNew,
                    onLoadingtypeLottery: false,
                    huayDetailName: res.data.huayDetail.name,
                    huayDetail: res.data.huayDetail
                })
            }
        })
    }

    commitOrder = (roundId, orderItem, orderName) => HuayService.play(roundId, orderItem, orderName)

    resizeBox = () => {
        let assistRandomBox = 3;
        let innerWidth = window.innerWidth;
        if (innerWidth < 580) {
            assistRandomBox = 3;
        }
        this.setState({ assistRandomBox: assistRandomBox })
    };

    handleOpenOrderPayment = () => {
        let { books } = this.state;
        let result = books.filter(item => item.number[item.number.length - 1] != '-')
        this.setState({ openOrderPayment: true, books: result });
    };

    handleCloseOrderPayment = () => {
        this.setState({ openOrderPayment: false });
    };

    handleOpenNumberShot = () => {
        this.setState({ openNumberShot: true });
    };

    handleCloseNumberShot = () => {
        this.setState({ openNumberShot: false });
    };

    handleChangeInput = (input, type, assistRandom = []) => {
        let { assistRandomActive, mode_active } = this.state;
        assistRandomActive.map(function (item, index) {
            if (assistRandom.indexOf(item.type) > -1) {
                if (item.type == 7 || item.type == 8 || item.type == 9 || item.type == 10) {
                    assistRandomActive[index]['active'] = false;
                    assistRandomActive[index]['color'] = "orange";
                } else {
                    assistRandomActive[index]['active'] = false;
                    assistRandomActive[index]['color'] = "grey";
                }
            } else {
                assistRandomActive[index]['active'] = true;
                assistRandomActive[index]['color'] = "grey";
            }
        });

        let typeLotteryActive = this.state.typeLotteryActive[type].isAllow ? this.state.typeLotteryActive : [
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false }
        ];
        typeLotteryActive[type].isActive = !typeLotteryActive[type].isActive;
        if (!(typeLotteryActive.find(elm => elm.isActive == true))) {
            typeLotteryActive[type].isActive = true
        }

        let inputNumberNow = new Array(input).fill("-");

        this.setState({
            // assistRandomActive: assistRandomActive,
            inputNumber: input,
            typeLotteryActive: typeLotteryActive,
            typeLotteryNow: type,
            inputNumberNow: inputNumberNow,
            mode_active: 0
        });
        this.updateAllowType(type, typeLotteryActive)
    };

    updateAllowType(activeType, typeLotteryActive) {
        const activeTypeObject = this.state.typeLotteryDetails.find(elm => elm.type == activeType)

        if (activeTypeObject) {
            let oldState = typeLotteryActive
            this.state.typeLotteryDetails.filter(elm => elm.length == activeTypeObject.length).map(elm => {
                oldState[elm.type].isAllow = true
            })
            this.setState({ typeLotteryActive: oldState })
        }
    }


    updateDeleteNumber = () => {

    };

    handleDeleteNumber = () => {
        let inputNumberNow = this.state.inputNumberNow;
        let deleteIndex = 0;
        inputNumberNow.map(function (item, index) {
            if (Number.isInteger(item)) {
                deleteIndex = index;
            }
        });
        inputNumberNow[deleteIndex] = '-';
        this.setState({ inputNumberNow: inputNumberNow });
    };

    handleRandom = () => {
        let inputNumberNow = this.state.inputNumberNow;
        let inputNumberNew = [];
        let books = this.state.books;
        const handleAddNumber = this.handleAddNumber
        inputNumberNow = inputNumberNow.map(function () {
            inputNumberNew.push("-");
            let randomNum = Math.floor(Math.random() * 10);
            handleAddNumber(randomNum)
            return randomNum;
        });
    };

    handleRandomAssist = (type) => {
        let { books, typeLotteryNow, inputNumber } = this.state;
        this.state.typeLotteryActive.forEach((typeLotteryActiveItem, typeId) => {
            if (typeLotteryActiveItem.isActive) {
                const activeTypeObject = this.state.typeLotteryDetails.find(elm => elm.type == typeId)
                if (type == 4) {
                    for (let i = 0; i < 10; i++) {
                        let book = {
                            number: [i, i, i],
                            price: "",
                            typeLottery: typeId
                        };
                        books.push(book)
                    }
                    //toast
                    toast.success("เพิ่มเลขตองแล้ว", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else if (type == 1) {
                    for (let i = 0; i < 10; i++) {
                        for (let j = 0; j < 5; j++) {
                            let book = {
                                number: [i, j * 2 + 1],
                                price: "",
                                typeLottery: typeId
                            };
                            books.push(book)
                        }
                    }
                    //toast
                    toast.success("เพิ่มเลขคี่แล้ว", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else if (type == 2) {
                    for (let i = 0; i < 10; i++) {
                        for (let j = 0; j < 5; j++) {
                            let book = {
                                number: [i, j * 2],
                                price: "",
                                typeLottery: typeId
                            };
                            books.push(book)
                        }
                    }
                    //toast
                    toast.success("เพิ่มเลขคู่แล้ว", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else if (type == 3) {
                    for (let i = 0; i < 10; i++) {
                        let book = {
                            number: [i, i],
                            price: "",
                            typeLottery: typeId
                        };
                        books.push(book)
                    }
                    //toast
                    toast.success("เพิ่มเลขเบิ้ลแล้ว", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else if (type == 5) {
                    for (let i = 5; i < 10; i++) {
                        for (let j = 0; j < 10; j++) {
                            let book = {
                                number: [i, j],
                                price: "",
                                typeLottery: typeId
                            };
                            books.push(book)
                        }
                    }
                    //toast
                    toast.success("เพิ่มเลขสูงแล้ว", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else if (type == 6) {
                    for (let i = 0; i < 5; i++) {
                        for (let j = 0; j < 10; j++) {
                            let book = {
                                number: [i, j],
                                price: "",
                                typeLottery: typeId
                            };
                            books.push(book)
                        }
                    }
                    //toast
                    toast.success("เพิ่มเลขต่ำแล้ว", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else if (type == 7) {
                    let newstate = (this.state.mode_active === 1) ? 0 : 1
                    this.setState({ mode_active: newstate })

                    if (newstate == 1) {
                        this.setState({ inputNumber: 1, inputNumberNow: ["-"] })
                    } else {
                        this.setState({ inputNumber: 2, inputNumberNow: ["-", "-"] })
                    }
                } else if (type == 8) {
                    let newstate = (this.state.mode_active === 2) ? 0 : 2
                    this.setState({ mode_active: newstate })
                    if (this.state.inputNumber == 3) {
                        this.setState({ inputNumber: 3, inputNumberNow: ["-", "-", "-"] })
                    } else {
                        this.setState({ inputNumber: 2, inputNumberNow: ["-", "-"] })
                    }
                } else if (type == 9) {
                    let newstate = (this.state.mode_active === 3) ? 0 : 3
                    this.setState({ mode_active: newstate })
                    if (newstate == 3) {
                        this.setState({ inputNumber: 1, inputNumberNow: ["-"] })
                    } else {
                        this.setState({ inputNumber: 2, inputNumberNow: ["-", "-"] })
                    }
                } else if (type == 10) {
                    let newstate = (this.state.mode_active === 4) ? 0 : 4
                    this.setState({ mode_active: newstate })
                    if (newstate == 4) {
                        this.setState({ inputNumber: 1, inputNumberNow: ["-"] })
                    } else {
                        this.setState({ inputNumber: 2, inputNumberNow: ["-", "-"] })
                    }
                } else if (type == 11) {
                    let x1 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                    let x2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
                    for (let i in x1) {
                        let book = {
                            number: [x1[i], x2[i]],
                            price: "",
                            typeLottery: typeId
                        };
                        books.push(book)
                    }
                    //toast
                    toast.success("เพิ่มเลขพี่น้อง", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                else if (type == 12) {
                    let x2 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                    let x1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
                    for (let i in x1) {
                        let book = {
                            number: [x1[i], x2[i]],
                            price: "",
                            typeLottery: typeId
                        };
                        books.push(book)
                    }
                    //toast
                    toast.success("เพิ่มเลขพี่น้อง", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                this.setState(oldState => oldState)
            }
        })
    };


    handleAddNumber = (numberLottery) => {
        let inputNumberNow = this.state.inputNumberNow;
        let books = this.state.books;
        let oneTime = 1;
        let lengthNow = 0;
        let setTimeoutMinute = 0;
        inputNumberNow.map(function (item, index) {
            if (isNaN(parseInt(item)) && oneTime == 1) {
                lengthNow = index + 1;
                inputNumberNow[index] = numberLottery;
                oneTime++;
            }
        });

        if (inputNumberNow.length == lengthNow) {
            setTimeoutMinute = 500;
            let inputNumberNowStr = "";
            let inputNumberNew = inputNumberNow.map(function (item, index) {
                inputNumberNow[index] = "-";
                inputNumberNowStr += item
                return item;
            });
            this.setState({ inputNumberNow: inputNumberNew });

            this.state.typeLotteryActive.forEach((typeLotteryActiveItem, typeId) => {
                if (typeLotteryActiveItem.isActive) {
                    const activeTypeObject = this.state.typeLotteryDetails.find(elm => elm.type == typeId)
                    if (this.state.mode_active == 1) { //19 ประตู
                        for (let i = 0; i < 10; i++) {
                            let inputNumberItem = inputNumberNew.map((data, i) => data)
                            inputNumberItem.push(i);
                            books.push({
                                typeLottery: typeId,
                                number: inputNumberItem,
                                price: ''
                            });
                        }
                        for (let i = 0; i < 10; i++) {
                            let inputNumberItem = inputNumberNew.map((data, i) => data)
                            inputNumberItem.push(i)
                            inputNumberItem = inputNumberItem.reverse().map((data, i) => data)
                            if (i != inputNumberNew[0]) {
                                books.push({
                                    typeLottery: typeId,
                                    number: inputNumberItem,
                                    price: ''
                                });
                            }
                        }

                        //toast
                        toast.success("แทง " + inputNumberNew.map((data, i) => data) + " 19 ประตูแล้ว", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    } else if (this.state.mode_active == 2) { //กลับเลข
                        let inputNumbertem = inputNumberNew.map((data, i) => data)
                        this.removeDuplicateStrings(this.permute(inputNumbertem.join(""))).forEach(v => {
                            books.push({
                                typeLottery: typeId,
                                number: v.split(""),
                                price: ''
                            });
                        })

                        //toast
                        toast.success("แทง " + inputNumberNowStr + " กลับเลขแล้ว", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    } else if (this.state.mode_active == 3) { //รูดหน้า
                        let inputNumbertem = inputNumberNew.map((data, i) => data)
                        for (let x = 0; x <= 9; x++) {
                            books.push({
                                typeLottery: typeId,
                                number: [...inputNumbertem, x],
                                price: ''
                            });
                        }

                        //toast
                        toast.success("แทง " + inputNumberNowStr + " รูดหน้า", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    } else if (this.state.mode_active == 4) { //รูดหลัง
                        let inputNumbertem = inputNumberNew.map((data, i) => data)
                        for (let x = 0; x <= 9; x++) {
                            books.push({
                                typeLottery: typeId,
                                number: [x, ...inputNumbertem],
                                price: ''
                            });
                        }

                        //toast
                        toast.success("แทง " + inputNumberNowStr + " รูดหลัง", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    } else {
                        books.push({
                            typeLottery: typeId,
                            number: inputNumberNew,
                            price: ''
                        });

                        //Toast
                        toast.success("แทง " + inputNumberNowStr + " " + activeTypeObject.name, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
        }
        let _this = this;
        setTimeout(function () {
            _this.setState({ inputNumberNow: inputNumberNow, books: books })
        }, setTimeoutMinute);

    };

    handleChangePrice = (value, index) => {
        let typeLotteryDetails = this.state.typeLotteryDetails;

        let price = value.toString().replace(/,/g, '');
        if (!isNaN(price)) {
            price = price * 1;
            let books = this.state.books;
            let typeLotteryDetailsIndex = typeLotteryDetails.findIndex(elm => elm.type == books[index].typeLottery)
            if (price <= typeLotteryDetails[typeLotteryDetailsIndex].maxPrice) {
                let priceReplace = parseInt(Number(price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "")));
                books[index].price = priceReplace;
                this.setState({ books: books });
            }
        }
    };

    handleRemoveOrder = (index) => {
        let books = this.state.books;
        books.splice(index, 1);
        this.setState({ books: books });
    };

    getInputLottery = () => {
        let { inputNumber } = this.state;
        let input = new Array(inputNumber).fill("");
        let inputNumberNow = this.state.inputNumberNow;
        return (<Grid columns={1} centered>
            <Grid.Column textAlign={"center"} style={{ padding: '0px 0px 10px 0px' }}>
                {input.map(function (item, index) {
                    return (<Button key={index} className="input-number-now" content={inputNumberNow[index]} basic circular style={{ marginLeft: 7, marginRight: 7 }} />);
                })}
            </Grid.Column>
        </Grid>);
    };

    closeConfirm = () => {
        this.setState({ openConfirm: false });
    };

    showConfirm = () => {
        this.setState({ openConfirm: true });
    };

    clearBooks = () => {
        this.setState({ books: [] });
    };

    getAssistRandomBox = () => {
        let { assistRandomActive } = this.state;
        let { handleRandomAssist } = this;
        return assistRandomActive.map(function (item, index) {
            if (item.active || item.type == 7 || item.type == 8 || item.type == 9 || item.type == 10) { return null }
            return (<Grid.Column key={index}>
                <Button disabled={item.active} content={item.name}
                    onClick={() => {
                        handleRandomAssist(item.type)
                    }} basic={item.active}
                    style={{ fontWeight: 200 }}
                    color={item.color} fluid />
            </Grid.Column>);
        });
    };

    getAssistRandomBox_2 = () => {
        let { assistRandomActive, mode_active } = this.state;
        let { handleRandomAssist, changinputNumber } = this;

        return (<Grid.Row>{assistRandomActive.map(function (item, index) {
            if (item.type == 7 || item.type == 8 || item.type == 9 || item.type == 10) {
                return (<Grid.Column key={index}>
                    <Button disabled={item.active}
                        content={item.name}
                        onClick={() => {
                            handleRandomAssist(item.type)
                        }}
                        basic={(mode_active == item.mode_active) ? false : true}
                        color={item.color}
                        fluid />
                </Grid.Column>);
            }
        })}</Grid.Row>);
    };

    getAssistantAllow(numberLength) {
        return ((numberLength == 2) ? [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12] : (numberLength == 3) ? [4, 8] : [])
    }

    permute = (str) => {
        const _this = this
        var ret = [];
        if (str.length == 1) return [str];
        if (str.length == 2) return [str, str[1] + str[0]];
        str.split('').forEach(function (chr, idx, arr) {
            var sub = [].concat(arr);
            sub.splice(idx, 1);

            _this.permute(sub.join('')).forEach(function (perm) {
                ret.push(chr + perm);
            });
        });
        return ret;
    }

    removeDuplicateStrings = (inputArray) => {
        return [...new Set(inputArray)]
    }

    getOrderPaymentList = () => {
        const _this = this;
        let { books, typeLotteryDetails } = this.state;
        if (books.length == 0) {
            return (<Header as='h5' style={{ fontSize: '0.9rem' }} color='orange'>ไม่มีรายการ</Header>);
        }
        let booksSort = books.sort((a, b) => (a.typeLottery > b.typeLottery) ? 1 : ((b.typeLottery > a.typeLottery) ? -1 : 0));
        let firstTypeLotteryDetails = true;
        let groupTypeLottery = [];
        let result = booksSort.filter(item => item.number[item.number.length - 1] != '-')

        return result.map(function (book, index) {
            if (groupTypeLottery.indexOf(book.typeLottery) == -1) {
                firstTypeLotteryDetails = true;
                groupTypeLottery.push(book.typeLottery);
            } else {
                firstTypeLotteryDetails = false;
            }

            let detail = typeLotteryDetails.find((typeLotteryDetail) => {
                return typeLotteryDetail.type == book.typeLottery;
            });

            return (
                <Grid.Row style={{ padding: 0 }} key={index}>
                    <Grid.Column width={16}>
                        {firstTypeLotteryDetails ? (<Divider></Divider>) : ''}
                        {firstTypeLotteryDetails ? (<Header as='h4' style={{ margin: 0, marginTop: 5, fontWeight: 400 }} color='green'> {detail.name}</Header>) : ""}
                        <Icon name='delete' size='small' color='red' style={{ position: "absolute", right: 1, padding: 5, cursor: 'pointer' }} onClick={() => _this.handleRemoveOrder(index)} />
                        <h5 style={{ margin: 3, marginTop: 5, color: 'var(--color-list-huay-play)' }}>{(book.number).join("")}</h5>
                    </Grid.Column>
                </Grid.Row>
            );
        });
    }

    getLinkRules = () => {

        // Get linke rules lottery by name lottery
        switch (this.state.huayDetailName.split(" ")[0]) {
            case "หวยรัฐบาลไทย":
                return "https://www.mylotto111.com/detail/91"
            case "หวยหุ้นไทย":
                return "https://www.mylotto111.com/detail/102"
            case "หวยลาว":
                return "https://www.mylotto111.com/detail/95"
            case "หวยมาเลย์":
                return "https://www.mylotto111.com/detail/100"
            case "หวยฮานอย":
                return "https://www.mylotto111.com/detail/101"
            default:
                return "https://www.mylotto111.com/how-to-play"
        }

    }


    getFavoriteOrder = () => {
        BookService.favoriteOrder().then((res) => {
            this.setState({ favoriteBill: res.data, modalPullBill: true })
        })
    }

    addFavoriteOrder = async (data) => {
        let { books, typeLotteryDetails } = this.state
        let _book = []
        await data.map((item,) => {
            let status = typeLotteryDetails.filter((type) => type.type == item.typeId)
            if (status.length > 0) {
                _book.push({
                    number: Array.from(item.number.toString()).map(Number),
                    price: item.price,
                    typeLottery: Number(item.typeId)
                });
            }
        })
        await _book.map((item) => {
            books.push(item)
        })
        this.setState({ modalPullBill: false })
        toast.success("เลือกเลขชุดสำเร็จ", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    async initData() {
        this.setState({ oldOrderLoading: true })
        await BookService.getBookAll(this.state.ShowLimitOldOrder).then(async res => {
            if (res.status) {
                this.setState({ oldBill: res.data, modalPullOldBill: true, oldOrderLoading: false })
            }
        })
    }

    getOldOrder = async (count) => {
        await BookService.getBookDesc().then(res => {
            if (res.status) {
                this.setState({
                    numberOfOrder: res.data.numberOfOrder,
                    bookDescLoading: false
                })
            }
        })
        this.initData()

        // BookService.getOldOrder(count, "huay", this.state.huayDetail._id).then((res) => {
        //     this.setState({ oldBill: res.data, modalPullOldBill: true, oldOrderLoading: false })
        // })
    }

    // handleIncrease = async () => {
    //     await this.setState({ oldOrderLoading: true })
    //     await this.setState({ numberOfOldOrder: this.state.numberOfOldOrder + 5 })
    //     await this.getOldOrder(this.state.numberOfOldOrder)
    // }

    handleIncrease() {
        this.setState({ oldOrderLoading: true })
        this.setState((prevState) => ({ ShowLimitOldOrder: prevState.ShowLimitOldOrder + 5 }), () => {
            this.initData().then(() => this.setState({ oldOrderLoading: false }))
        })
    }

    addOldOrder = async (data) => {
        let { books, typeLotteryDetails } = this.state
        let _book = []
        await data.map((item,) => {
            let status = typeLotteryDetails.filter((type) => type.type == item.lotteryType.typeId)
            if (status.length > 0) {
                _book.push({
                    number: Array.from(item.number.toString()).map(Number),
                    price: "",
                    typeLottery: Number(item.lotteryType.typeId)
                });
            }
        })
        await _book.map((item) => {
            books.push(item)
        })
        this.onCloseOldOrder()
        toast.success("ดึงโพยสำเร็จ", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    onCloseOldOrder = () => {
        this.setState({ modalPullOldBill: false })
    }

    pane1 = () => (<>
        <Grid className='lotteryPlayUI' columns='2'>
            <div style={{ background: 'var(--background-head-menu-item-active)', color: 'var(--color-head-menu-item-active)', border: '2px solid var(--border-color-head-menu-item-active)', width: '100%', padding: '8px 5px', margin: '5px 0 -8px', borderRadius: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.2em' }}><Icon name='clone' color='white' size='small' /><span style={{ fontWeight: 500, marginLeft: 5 }}>  {this.state.huayDetailName}</span></div>

            <Grid.Column computer='13' tablet='13' mobile='12' style={{ padding: 2, paddingTop: '15px' }}>

                <Segment
                    className="customPadding"
                    loading={this.state.onLoadingtypeLottery}
                    style={{
                        background: 'var(--background-num-pad)',
                        borderRadius: 15
                    }}
                >

                    <div style={{ textAlign: 'right', margin: -14, marginBottom: 20, cursor: 'pointer' }}>

                        <Modal style={{ maxWidth: 'unset' }} size='small' closeIcon trigger={<Button basic color="red" size='mini' style={{ padding: 5, marginRight: 7 }}>เลขอั้น / เลขเต็ม</Button>}>
                            <Modal.Content>
                                <HuayLimitDetailContent roundId={this.props.match.params.roundId} />
                            </Modal.Content>
                        </Modal>
                        {/* <Modal style={{ maxWidth: 'unset' }} size='big' closeIcon trigger={<Button basic color="blue" size='mini' style={{ padding: "5px 10px", marginRight: 7 }}>วิธีการเล่น</Button>}>
                            <Modal.Content>
                                <video width="100%" controls>
                                    <source src={require('../../../assets/video/HuayPlayBasic.mp4')} type="video/mp4" />
                                    Your browser does not support HTML video.
                                </video>
                            </Modal.Content>
                        </Modal>
                        <Button basic color="yellow" size='mini' style={{ padding: 5, }} as='a' href={this.getLinkRules()} target="_blank" >กติกา</Button> */}
                    </div>
                    <div style={{ display: 'flex', margin: '0 -1rem' }}>

                        <div style={{ flex: 1, padding: '0 2px' }}>
                            <Button onClick={this.getFavoriteOrder} fluid color='green' style={{ background: 'var(--background-select-favorite-number)', color: '#FFF', padding: '0.9em 0', margin: '-1rem 0 1.3rem', borderRadius: 15 }}>
                                เลือกเลขชุด
                            </Button>
                        </div>

                        <div style={{ flex: 1, padding: '0 2px' }}>
                            <Button onClick={() => this.getOldOrder(this.state.ShowLimitOldOrder)} fluid color='green' style={{ background: 'var(--background-pull-number)', color: '#FFF', padding: '0.9em 0', margin: '-1rem 0 1.3rem', borderRadius: 15 }}>
                                ดึงโพย
                            </Button>
                        </div>

                    </div>


                    <Grid columns={2}>
                        <Grid.Row>
                            {this.state.typeLotteryDetails.map((item, key) => (
                                <Grid.Column key={key}>
                                    <List divided selection className={(this.state.typeLotteryActive[item.type].isActive ? "list-custom red active " : "list-custom red ") + (this.state.typeLotteryActive[item.type].isAllow ? 'allow' : '')}>
                                        <List.Item onClick={() => {
                                            this.handleChangeInput(item.length, item.type, this.getAssistantAllow(item.length))
                                        }}>
                                            {item.name}<br />
                                            {/* <span style={{ fontSize: '0.85rem', fontWeight: 400 }}>x{item.price}</span> */}
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                            ))}
                        </Grid.Row>
                        <hr style={{ margin: 5 }} />
                        {/* <Grid.Row columns={1}><Grid.Column  style={{margin:0,padding:0,color:'#888',fontWeight:300}}><center>-- ตัวช่วย --</center></Grid.Column></Grid.Row> */}
                        {this.getAssistRandomBox_2()}
                    </Grid>
                    <div style={{
                        marginTop: 45,
                        height: 2,
                        background: 'linear-gradient(to right, transparent 80%, rgb(204, 204, 204) 20%)',
                        backgroundSize: 20,
                        marginInlineStart: 50,
                        marginInlineEnd: 50
                    }} />
                    <div style={{ paddingTop: 35, paddingBottom: 35 }}>
                        {this.getInputLottery()}
                    </div>
                    <Grid columns={3} divided>
                        <Grid.Row>
                            <Grid.Column>
                                <Button content='1' onClick={() => {
                                    this.handleAddNumber(1)
                                }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                            </Grid.Column>
                            <Grid.Column>
                                <Button content='2' onClick={() => {
                                    this.handleAddNumber(2)
                                }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                            </Grid.Column>
                            <Grid.Column>
                                <Button content='3' onClick={() => {
                                    this.handleAddNumber(3)
                                }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button content='4' onClick={() => {
                                    this.handleAddNumber(4)
                                }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                            </Grid.Column>
                            <Grid.Column>
                                <Button content='5' onClick={() => {
                                    this.handleAddNumber(5)
                                }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                            </Grid.Column>
                            <Grid.Column>
                                <Button content='6' onClick={() => {
                                    this.handleAddNumber(6)
                                }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button content='7' onClick={() => {
                                    this.handleAddNumber(7)
                                }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                            </Grid.Column>
                            <Grid.Column>
                                <Button content='8' onClick={() => {
                                    this.handleAddNumber(8)
                                }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                            </Grid.Column>
                            <Grid.Column>
                                <Button content='9' onClick={() => {
                                    this.handleAddNumber(9)
                                }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button onClick={this.handleRandom} content='สุ่ม' fluid style={{ backgroundColor: '#FFFFFF', border: '3px solid var(--color-num-pad)' }} />
                            </Grid.Column>
                            <Grid.Column>
                                <Button onClick={() => {
                                    this.handleAddNumber(0)
                                }} content='0' fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                            </Grid.Column>
                            <Grid.Column>
                                <Button onClick={this.handleDeleteNumber} content='ลบ' fluid style={{ backgroundColor: '#FFFFFF', border: '3px solid var(--color-num-pad)' }} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid columns={3} >
                        <Grid.Row>
                            {this.getAssistRandomBox()}
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Grid.Column>
            <Grid.Column computer='3' tablet='3' mobile='4' textAlign='center' style={{ padding: 1, paddingTop: '15px', maxHeight: 863 }}>
                <Segment style={{
                    padding: '10px 3px 3px 3px',
                    overflowY: 'auto',
                    maxHeight: 'inherit',
                    height: '100%',
                    background: 'var(--background-num-pad)',
                    borderRadius: 15
                }}>
                    <p style={{ color: 'var(--color-list-huay-play-head)' }}>เลขแทง</p>
                    <Button color='red' size='mini' fluid onClick={() => this.setState({ books: [] })}>ล้าง</Button>
                    {this.getOrderPaymentList()}
                </Segment>
            </Grid.Column>
        </Grid>
        <Segment style={{ backgroundColor: 'transparent', boxShadow: 'unset', border: 0, padding: 10, paddingTop: 0, marginTop: -10 }}>
            <Grid style={{ marginTop: -3 }}>
                <Grid.Column className="shot-number-card" computer='16' tablet='16' mobile='16' style={{ padding: 2, paddingTop: '1rem' }}>
                    <Segment style={{ border: 0, borderRadius: 'unset', background: 'transparent' }}>
                        <Button onClick={this.handleOpenOrderPayment} fluid icon='arrow left' style={{ background: 'var(--color-num-pad)', color: '#FFF' }}>
                            <Icon name='shopping cart' /> ใส่ราคา
                        </Button>
                    </Segment>
                </Grid.Column>
            </Grid>
        </Segment>


        {/* Modal Pull favorite order */}
        <Modal
            onClose={() => {
                this.setState({ modalPullBill: false })
            }}
            open={this.state.modalPullBill}
            closeIcon
            centered={false}
        >
            <Modal.Header>เลือกเลขชุด</Modal.Header>
            <div style={{ textAlign: 'center', padding: '0.8em 0.8em', fontSize: '0.9em' }}>
                <Button
                    as={Link}
                    to='/favoriteBill'
                    fluid
                    color='green'
                    style={{
                        background: '#198754',
                        color: '#FFF',
                        padding: '0.7em 0',
                        margin: '0 0 0.6rem',
                        borderRadius: 8,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <Icon name="plus" style={{ marginRight: 5 }} />
                    เพิ่มเลขชุด
                </Button>
                <Table unstackable style={{ border: 0, margin: 0 }}>
                    <Table.Row style={{ background: '#212529', color: '#FFF', textAlign: 'center' }}>
                        <Table.Cell style={{ width: '25%' }}>ชื่อ</Table.Cell>
                        <Table.Cell style={{ width: '40%' }}>
                            เลข
                        </Table.Cell>
                        <Table.Cell style={{ width: '25%' }}>
                            วันที่สร้าง
                        </Table.Cell>
                        <Table.Cell style={{ width: '10%' }}>
                            #
                        </Table.Cell>
                    </Table.Row>
                </Table>
                {
                    this.state.favoriteBill.length > 0 ?
                        this.state.favoriteBill?.map((bill, index) => {
                            return (
                                < Table unstackable style={{ border: 0, margin: 0, borderRadius: 0 }}>
                                    <Table.Row style={{ textAlign: 'center' }}>
                                        <Table.Cell style={{ width: '25%', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px', borderColor: 'Gainsboro' }}>{bill.name}</Table.Cell>
                                        <Table.Cell className="text-number-bill-favorite" style={{ width: '40%', wordWrap: 'break-word', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro' }}>
                                            {
                                                bill.items?.map((item, index) => {
                                                    return `${item.number}${(index != bill.items.length - 1) ? ', ' : ''}`
                                                })
                                            }
                                        </Table.Cell>
                                        <Table.Cell style={{ width: '25%', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro' }}>
                                            {`${new Date(bill.updatedAt).toLocaleDateString('th-TH')},`} <br /> {`${new Date(bill.updatedAt).getHours()}:${new Date(bill.updatedAt).getMinutes()}:${new Date(bill.updatedAt).getSeconds()}`}
                                        </Table.Cell>
                                        <Table.Cell style={{ width: '15%', padding: 3, border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro' }}>
                                            <Button onClick={() => this.addFavoriteOrder(bill.items)} size="mini" style={{ background: "red", padding: 5, borderRadius: 5, margin: 0 }}>
                                                <Icon name="cloud download" style={{ color: "#FFF", fontSize: 14, margin: 0 }} />
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table>
                            )
                        })
                        :
                        <Table unstackable style={{ border: 0, margin: 0, borderRadius: 0 }}>
                            <Table.Row >
                                <Table.Cell style={{ width: '100%', textAlign: 'center', padding: '10px 0' }}>
                                    ไม่มีเลขชุด
                                </Table.Cell>
                            </Table.Row>
                        </Table>
                }

            </div>
        </Modal>

        {/* Modal pull order */}
        <Modal
            onClose={this.onCloseOldOrder}
            open={this.state.modalPullOldBill}
            closeIcon
            centered={false}
        >
            <Modal.Header>รายการแทง</Modal.Header>
            <div style={{ textAlign: 'center', padding: '0.8em 0.8em', fontSize: 12 }}>
                <Table unstackable style={{ border: 0, margin: 0 }}>
                    <Table.Row style={{ background: '#212529', color: '#FFF', textAlign: 'center' }}>
                        <Table.Cell style={{ width: '10%' }}>#</Table.Cell>
                        <Table.Cell style={{ width: '20%' }}>งวด</Table.Cell>
                        <Table.Cell style={{ width: '40%' }}>
                            เลข
                        </Table.Cell>
                        <Table.Cell style={{ width: '20%' }}>
                            วันที่ซื้อ
                        </Table.Cell>
                        <Table.Cell style={{ width: '10%' }}>
                            #
                        </Table.Cell>
                    </Table.Row>
                </Table>
                {
                    this.state.oldBill.length > 0 ?
                        this.state.oldBill?.map((bill, index) => {
                            return (
                                < Table unstackable style={{ border: 0, margin: 0, borderRadius: 0 }}>
                                    <Table.Row style={{ textAlign: 'center' }}>
                                        <Table.Cell style={{ width: '10%', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px', borderColor: 'Gainsboro', padding: '0.7em' }}>{index + 1}</Table.Cell>
                                        <Table.Cell style={{ width: '20%', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro', padding: '0.5em' }}>
                                            {`${new Date(bill.huayDetail.roundDetail.announceTime).toLocaleDateString('th-TH')},`} <br /> {`${new Date(bill.huayDetail.roundDetail.announceTime).getHours()}:${new Date(bill.huayDetail.roundDetail.announceTime).getMinutes()}:${new Date(bill.huayDetail.roundDetail.announceTime).getSeconds()}`}
                                        </Table.Cell>
                                        <Table.Cell className="text-number-bill-favorite" style={{ width: '40%', wordWrap: 'break-word', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro' }}>
                                            {
                                                bill.items?.map((item, index) => {
                                                    return `${item.number}${(index != bill.items.length - 1) ? ', ' : ''}`
                                                })
                                            }
                                        </Table.Cell>
                                        <Table.Cell style={{ width: '20%', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro', padding: '0.5em' }}>
                                            {`${new Date(bill.time).toLocaleDateString('th-TH')},`} <br /> {`${new Date(bill.time).getHours()}:${new Date(bill.time).getMinutes()}:${new Date(bill.time).getSeconds()}`}
                                        </Table.Cell>
                                        <Table.Cell style={{ width: '15%', padding: 3, border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro' }}>
                                            <Button onClick={() => this.addOldOrder(bill.items)} size="mini" style={{ background: "red", padding: 5, borderRadius: 5, margin: 0 }}>
                                                <Icon name="cloud download" style={{ color: "#FFF", fontSize: 14, margin: 0 }} />
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table>
                            )
                        })
                        :
                        <Table unstackable style={{ border: 0, margin: 0, borderRadius: 0 }}>
                            <Table.Row >
                                <Table.Cell style={{ width: '100%', textAlign: 'center', padding: '10px 0' }}>
                                    ไม่มีรายการแทง
                                </Table.Cell>
                            </Table.Row>
                        </Table>
                }

                {
                    this.state.numberOfOrder > this.state.oldBill.length && (
                        <center><Button positive size={"small"} style={{ marginTop: 5 }} onClick={() => this.handleIncrease()} disabled={this.setState.oldOrderLoading}>โหลดเพิ่มเติม {this.state.oldOrderLoading && <Icon loading name='spinner' />}</Button></center>
                    )
                }

            </div>
        </Modal>


    </>)

    handleSetHistoryLoading = (status) => {
        this.setState({ historyLoading: status })
    }

    panes = [
        {
            menuItem: 'แทงหวย',
            render: () => <Tab.Pane key='1' attached={false} loading={this.state.onLoading} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0 }}>{this.pane1()}</Tab.Pane>,
        },
        {
            menuItem: 'กติกา',
            render: () => <Tab.Pane key='2' attached={false} loading={this.state.onLoading} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0, margin: '0.5em -0.5em' }}> <Segment >
                <Card centered>
                    <div className='content-rules-huay' dangerouslySetInnerHTML={{ __html: this.state.huayDetail?.rules }} />
                </Card>
            </Segment></Tab.Pane>,
        },
        {
            menuItem: 'ดูผลหวย',
            render: () =>
                <Tab.Pane key='3' attached={false} loading={this.state.onLoading} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0, margin: '1em -0.8em' }}>
                    <div className='lotteryHistory'>
                        <HuayHistory huayId={this.state.huayDetail._id} isloading={this.handleSetHistoryLoading} titleRound={this.state.huayDetailName} showRoundTime={false} />
                    </div>
                </Tab.Pane >,
        }
    ]

    deleteDuplicateBooks = () => {
        let { books } = this.state;
        var booksUnique = [];
        books.filter((item) => {
            var i = booksUnique.findIndex(value => (value.number.toString().replaceAll(',', '') === item.number.toString().replaceAll(',', '')) && value.typeLottery == item.typeLottery);
            if (i <= -1) {
                booksUnique.push(item);
            }
            return null;
        });
        this.setState({ books: booksUnique })
    }

    render() {
        return (
            <Responsive onUpdate={this.resizeBox}>
                <ToastContainer autoClose={1000} transition={Slide} />
                <Container>
                    {this.state.openOrderPayment &&
                        <OrderPayment books={this.state.books} openOrderPayment={this.state.openOrderPayment}
                            handleCloseOrderPayment={this.handleCloseOrderPayment}
                            handleRemoveOrder={this.handleRemoveOrder}
                            showConfirm={this.showConfirm}
                            clearBooks={this.clearBooks}
                            handleChangePrice={this.handleChangePrice}
                            handleCommitOrder={this.commitOrder}
                            typeLotteryDetails={this.state.typeLotteryDetails}
                            roundId={this.props.match.params.roundId}
                            orderType="HUAY"
                            huayName={this.state.huayDetailName}
                            deleteDuplicateBooks={this.deleteDuplicateBooks}
                            onlyUnique
                        />}
                    <Modal size="mini" open={this.state.openConfirm} onClose={this.closeConfirm}>
                        <Modal.Header>ส่งโพยสำเร็จ</Modal.Header>
                        <Modal.Content>
                            <p>คุณต้องการแทงหวยต่อหรือไม่ ?</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button as={Link} to={"/history"} negative>ไปที่โพย</Button>
                            <Button onClick={this.closeConfirm} positive content='แทงหวยต่อ' />
                        </Modal.Actions>
                    </Modal>
                    <div className='lotteryUIContainer'>
                        <Tab menu={{ pointing: false }} panes={this.panes} />
                    </div>


                </Container>
            </Responsive>
        );
    }
}


class QuickPlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeLottery: JSON.parse(JSON.stringify(props.parent.state.typeLotteryDetails)),
            numberNow: '',
            msg: null
        }
        this.inputRef = [];
    }

    inputNumberNowRef = createRef();

    getTypeAllow() {
        return this.state.typeLottery.filter(item => item.length == this.state.numberNow.length)
    }

    resetFild() {
        this.setState({ typeLottery: JSON.parse(JSON.stringify(this.props.parent.state.typeLotteryDetails)), numberNow: '', msg: null })
    }

    setPrice(typeId, price) {
        price = Number(price.toString().replace(/[^0-9\.]+/g, ''))
        const indexType = this.state.typeLottery.findIndex(elm => elm.type == typeId)
        if (indexType > -1) {
            let tempTypeLottery = this.state.typeLottery
            if (price <= tempTypeLottery[indexType].maxPrice) {
                tempTypeLottery[indexType].playPrice = price
            } else {
                tempTypeLottery[indexType].playPrice = tempTypeLottery[indexType].maxPrice
            }
            this.setState({ typeLottery: tempTypeLottery })
        }
    }

    inputNumber(number) {
        number = number.toString().replace(/[^0-9\.]+/g, '')
        let maxLengthType = Math.max(...this.state.typeLottery.map(item => item.length))
        if (number.length <= maxLengthType) {
            this.setState({ numberNow: number, typeLottery: JSON.parse(JSON.stringify(this.props.parent.state.typeLotteryDetails)), msg: null })
        }
    }

    assistReversAllNumber() {
        const numbershotlistTemp = this.state.numbershotlist
        this.state.numbershotlist.forEach(item => {
            numbershotlistTemp.push(item.split("").reverse().join(""))
        });
        this.setState({ numbershotlist: numbershotlistTemp })
    }

    commitToBooks() {
        if (this.state.numberNow == '' || this.state.numberNow == null) {
            this.setState({ msg: null })
            return
        }
        const booksTemp = []
        let flag = true
        let contTypeShot = 0
        this.getTypeAllow().forEach(typeActiveItem => {
            if (typeActiveItem.playPrice && typeActiveItem.playPrice > 0) {
                contTypeShot++
                if (typeActiveItem.playPrice >= typeActiveItem.minPrice && typeActiveItem.playPrice <= typeActiveItem.maxPrice) {
                    booksTemp.push({
                        number: this.state.numberNow.split(""),
                        price: typeActiveItem.playPrice,
                        typeLottery: typeActiveItem.type
                    })
                } else {
                    this.setState({ msg: typeActiveItem.name + ' ราคาน้อยกว่าขั่นต่ำ' })
                    flag = false
                    return
                }
            }
        });
        this.props.parent.setState(oldState => oldState)
        // if(contTypeShot <=0){
        //     this.setState({msg: 'ยังไม่ไส่ราคา'})
        //     return
        // }
        if (flag) {
            this.props.parent.state.books = [...this.props.parent.state.books, ...booksTemp]
            this.resetFild()
            this.inputNumberNowRef.current.focus()
            if (booksTemp.length > 0) {
                toast.success("เพิ่มรายการ แทงสำเร็จ", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    goNextInput() {
        if (!!this.state.numberNow) {
            this.inputRef[0].focus()
        }
    }

    goNextInputForm(key) {
        if (key < this.getTypeAllow().length - 1) {
            this.inputRef[key + 1].focus()
        } else {
            this.commitToBooks()
        }
    }


    render = () => (<>
        <Grid className='lotteryPlayUI' columns='2'>
            <Grid.Column computer='13' tablet='13' mobile='12' style={{ padding: 2, paddingTop: '0', minHeight: 420 }}>
                <Segment className="customPadding" loading={this.state.onLoadingtypeLottery} style={{ height: '100%' }}>
                    <div style={{ textAlign: 'right', margin: -14, marginBottom: 20, cursor: 'pointer' }}>
                        <Modal style={{ maxWidth: 'unset' }} size='small' closeIcon trigger={<Button basic color="red" size='mini' style={{ padding: 5, marginRight: 7 }}>เลขอั้น / เลขเต็ม</Button>}>
                            <Modal.Content>
                                <HuayLimitDetailContent roundId={this.props.parent.props.match.params.roundId} />
                            </Modal.Content>
                        </Modal>
                        {/* <Modal style={{ maxWidth: 'unset' }} size='big' closeIcon trigger={<Button basic color="blue" size='mini' style={{ padding: "5px 10px", marginRight: 7 }}>วิธีการเล่น</Button>}>
                            <Modal.Content>
                                <video width="100%" controls>
                                    <source src={require('../../../assets/video/HuayFastPlay.mp4')} type="video/mp4" />
                                    Your browser does not support HTML video.
                                </video>
                            </Modal.Content>
                        </Modal>
                        <Button basic color="yellow" size='mini' style={{ padding: 5, }} as='a' href="https://www.mylotto111.com/detail/91" target="_blank" >กติกา</Button> */}
                    </div>
                    <Grid columns={3} centered>
                        <h4 style={{ marginBottom: 5 }}>ไส่เลข</h4>
                        <Grid.Row centered>
                            <Grid.Column computer='10' tablet='10' mobile='16'>
                                <Input fluid className='customInput-textCenter' value={this.state.numberNow} ref={this.inputNumberNowRef} onChange={(event) => this.inputNumber(event.target.value)} placeholder='ไส่เลข' onKeyDown={(e) => e.key === 'Enter' && this.goNextInput()} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row centered>
                            {this.getTypeAllow().map((item, key) => (
                                <Grid.Column key={key} computer='4' tablet='8' mobile='8'>
                                    <p style={{ textAlign: 'center' }}>{item.name}</p>
                                    <Input ref={inputRef => this.inputRef[key] = inputRef} fluid className='customInput-textCenter' type='text' value={this.state.typeLottery.find(elm => elm.type == item.type).playPrice || ''} onChange={(event) => this.setPrice(item.type, event.target.value)} placeholder='ไส่ราคา' onKeyDown={(e) => e.key === 'Enter' && this.goNextInputForm(key)} />
                                </Grid.Column>
                            ))}
                        </Grid.Row>
                    </Grid>
                    <div>
                        <div style={{
                            marginTop: 45,
                            height: 2,
                            background: 'linear-gradient(to right, transparent 80%, rgb(204, 204, 204) 20%)',
                            backgroundSize: 20,
                            marginBottom: 20
                        }} />
                        <div>
                            <center style={{ color: 'red' }}>{this.state.msg}</center>
                            <Button fluid size='small' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 5, padding: 5, color: '#FFF', background: 'var(--color-num-pad)' }} onClick={() => this.commitToBooks()} >
                                <svg width="15" height="25" viewBox="0 0 25 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.1842 0.847824C23.6849 0.616699 23.0966 0.706637 22.6882 1.07638L20.5333 2.99332C20.4747 3.04574 20.3862 3.04543 20.3279 2.9927L17.396 0.358038C16.8657 -0.119346 16.0629 -0.119346 15.5326 0.358038L12.6033 2.99028C12.5444 3.04316 12.4553 3.04316 12.3964 2.99028L9.46675 0.358038C8.93647 -0.119268 8.13368 -0.119268 7.60341 0.358038L4.67105 2.9927C4.61236 3.04543 4.52369 3.04566 4.46469 2.99324L2.30787 1.07638C1.73567 0.565138 0.859275 0.616699 0.350427 1.19159C0.122245 1.44939 -0.00259871 1.78309 4.10215e-05 2.12802V30.872C-0.00508317 31.6425 0.612459 32.2713 1.37938 32.2764C1.72293 32.2788 2.05507 32.153 2.31175 31.9236L4.46663 30.0067C4.52524 29.9543 4.61368 29.9546 4.67198 30.0073L7.60395 32.642C8.13423 33.1193 8.93702 33.1193 9.46729 32.642L12.3969 30.0097C12.4559 29.9568 12.5449 29.9568 12.6038 30.0097L15.5335 32.6419C16.0638 33.119 16.8665 33.119 17.3968 32.6419L20.3293 30.0072C20.388 29.9545 20.4767 29.9543 20.5357 30.0067L22.6925 31.9235C23.265 32.4346 24.1413 32.3826 24.6499 31.8075C24.8777 31.5499 25.0024 31.2166 24.9999 30.872V2.12802C25.0072 1.57583 24.6864 1.07232 24.1842 0.847824ZM5.1403 11.1957H12.8721C13.2151 11.1957 13.4932 11.4751 13.4932 11.8198C13.4932 12.1644 13.2151 12.4438 12.8721 12.4438H5.1403C4.79729 12.4438 4.51919 12.1644 4.51919 11.8198C4.51919 11.4751 4.79729 11.1957 5.1403 11.1957ZM19.8596 21.8043H5.1403C4.79729 21.8043 4.51919 21.5249 4.51919 21.1802C4.51919 20.8356 4.79729 20.5562 5.1403 20.5562H19.8596C20.2026 20.5562 20.4807 20.8356 20.4807 21.1802C20.4807 21.5249 20.2026 21.8043 19.8596 21.8043ZM19.8596 17.124H5.1403C4.79729 17.124 4.51919 16.8446 4.51919 16.5C4.51919 16.1554 4.79729 15.876 5.1403 15.876H19.8596C20.2026 15.876 20.4807 16.1554 20.4807 16.5C20.4807 16.8446 20.2026 17.124 19.8596 17.124Z" fill="white" /></svg>
                                <span style={{ marginLeft: 5 }}>เพิ่มรายการแทง</span>
                            </Button>
                        </div>
                    </div>
                </Segment>
            </Grid.Column>
            <Grid.Column computer='3' tablet='3' mobile='4' textAlign='center' style={{ padding: 1, paddingTop: '1rem', maxHeight: 420 }}>
                <Segment style={{ padding: '10px 3px 3px 3px', overflowY: 'auto', maxHeight: 'inherit', height: '100%' }}>
                    <p>เลขแทง</p>
                    <Button color='red' size='mini' fluid onClick={() => this.props.parent.clearBooks()}>ล้าง</Button>
                    {this.props.parent.getOrderPaymentList()}
                </Segment>
            </Grid.Column>
        </Grid>
    </>)
}

const HuayLimitDetailContent = ({ roundId }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [outOfLimit, setOutOfLimit] = useState([])
    const [specialMultiply, setSpecialMultiply] = useState([])

    useEffect(() => {
        HuayService.getNumberOutOfLimit(roundId).then(res => {
            if (res.status) {
                setOutOfLimit(res.data.outOfLimit)
                setSpecialMultiply(res.data.specialMultiply)
                setIsLoading(false)
            }
        })
    }, []);

    const outOfLimitGroupByLotteryName = outOfLimit.reduce((r, object) => {
        var temp = r.find(o => o.group === object._id.lotteryName);
        if (!temp) r.push(temp = { group: object._id.lotteryName, children: [] });
        temp.children.push(object);
        return r;
    }, []);

    return (
        <Segment loading={isLoading} style={{ boxShadow: 'unset', border: 0, padding: 0, margin: 0 }}>
            <div style={{ marginTop: 10 }}>
                <div style={{ background: 'linear-gradient(151.56deg, #F1C72D -8%, #D72F6B 242.47%', textAlign: 'center', borderRadius: 10, marginTop: 30 }}>
                    <Header style={{ paddingTop: 5, color: '#FFFFFF', fontWeight: 400, paddingBottom: 5, marginBottom: 10 }} as="h3"> เลขอั้น</Header>
                </div>
            </div>
            <Grid verticalAlign='middle' style={{ margin: '-10px 10px', }}>
                {specialMultiply.length == 0 && (
                    <Grid.Column verticalAlign='middle' textAlign='center' width='16' style={{ borderRadius: 10 }}>
                        ไม่มีรายการ
                    </Grid.Column>
                )}
                {specialMultiply.map((item, key) => (
                    <Grid.Column key={key} verticalAlign='middle' textAlign='center' computer='4' tablet='4' mobile='8' style={{ borderRadius: 10 }}>
                        <Segment inverted color='grey'>
                            <Header color='yellow' content={item._id.number} size='large' style={{ marginBottom: 5 }} />
                            <span>{item._id.lotteryName}</span><br />
                            <span>X {item.multiply}</span>
                        </Segment>
                    </Grid.Column>
                ))}
            </Grid>
            <div style={{ margin: '-10px 10px', marginBottom: 10 }}>
                <div style={{ background: 'linear-gradient(151.56deg, #E93D26 -8%, #800000 242.47%)', textAlign: 'center', borderRadius: 10, marginTop: 30 }}>
                    <Header style={{ paddingTop: 5, color: '#FFFFFF', fontWeight: 400, paddingBottom: 5, marginBottom: 10 }} as="h3"> เลขเต็ม</Header>
                </div>
            </div>
            <Grid verticalAlign='middle' style={{ margin: 10, }}>
                {outOfLimit.length == 0 && (
                    <Grid.Column verticalAlign='middle' textAlign='center' width='16' style={{ borderRadius: 10 }}>
                        ไม่มีรายการ
                    </Grid.Column>
                )}
                {outOfLimitGroupByLotteryName.map((groupItem, key) => (
                    <Card fluid style={{ marginTop: 0 }}>
                        <Card.Content>
                            <Card.Meta textAlign='center' style={{ fontSize: 16 }}>{groupItem.group}</Card.Meta>
                            <Card.Description>
                                <Grid verticalAlign='middle'>
                                    {groupItem.children.map((item, key) => (
                                        <Grid.Column key={key} verticalAlign='middle' textAlign='center' computer='2' tablet='2' mobile='5' style={{ borderRadius: 10 }}>
                                            <Segment inverted color='grey' size='mini'>
                                                <Header content={item._id.number} size='medium' style={{ color: '#ff7a7a' }} />
                                                {/* <span>{item._id.lotteryName}</span> */}
                                            </Segment>
                                        </Grid.Column>
                                    ))}
                                </Grid>
                            </Card.Description>
                        </Card.Content>
                    </Card>
                ))}
                {/* {outOfLimit.map((item, key) => (
                    <Grid.Column key={key} verticalAlign='middle' textAlign='center' computer='4' tablet='4' mobile='8' style={{ borderRadius: 10 }}>
                        <Segment inverted color='grey'>
                            <Header content={item._id.number} size='large' style={{ marginBottom: 5, color: '#ff7a7a' }} />
                            <span>{item._id.lotteryName}</span>
                        </Segment>
                    </Grid.Column>
                ))} */}
            </Grid>
        </Segment>
    )
}
export default LotteryPlay;