import React, { useState } from 'react'
import { Button, Form, Image, Divider } from 'semantic-ui-react'
import { useHistory, Link } from 'react-router-dom';
import Authen from '../../service/Authen'
import WalletService from '../../service/WalletService';
import UserService from '../../service/UserService';
import Config from '../../config/config'

const Login = (props) => {

    const [tel, setTel] = useState("");
    const [password, setPassword] = useState("");
    const [loadingPage, setLoadingPage] = useState(false);
    const history = useHistory();
    const [msgValidate, setMsgValidate] = useState("");
    const [showMsgValidate, setShowMsgValidate] = useState(false);
    const [onSuccess, setOnSuccess] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoadingPage(true);
        setTimeout(function () {
            let login = true;
            if (login) {
                Authen.login(tel, password).then(res => {
                    if (res.status) {
                        WalletService.fetchWalletBalance().then((res) => {
                            history.push("/home");
                        })
                    } else {
                        setMsgValidate(res.msg)
                        setShowMsgValidate(true)
                        setLoadingPage(false);
                    }
                })
            } else {
                alert('เข้าสู่ระบบไม่สำเร็จ')
                setLoadingPage(false);
            }
        }, 1000);
    };

    const validateForm = () => {
        // return tel.length === 10 && password.length > 0;
        return password.length > 0;
    };

    return (
        <div className="login-page">

            <div className="login-box">
                <Image src={require('../../assets/images/head_logo.png')} size='medium' centered style={{ 'padding': '20px' }} />
                <Form className="login-form" onSubmit={handleSubmit} loading={loadingPage}>
                    <center><p style={{ marginBottom: '5px', color: "red", display: showMsgValidate ? "block" : "none" }}>* {msgValidate}</p></center>
                    <Form.Field>
                        <label>ชื่อผู้ใช้งาน</label>
                        <input className="login-input" placeholder='ชื่อผู้ใช้งาน' onChange={e => setTel(e.target.value.trim())} value={tel} />
                    </Form.Field>
                    <Form.Field>
                        <label>รหัสผ่าน</label>
                        <input className="login-input" placeholder='รหัสผ่าน' type={isShowPassword ? 'text' : 'password'} onChange={e => setPassword(e.target.value)} value={password} />
                    </Form.Field>
                    <Form.Field className='dasktop-hidden'>
                        <label style={{ display: 'flex', alignItems: 'center' }}>
                            <input className="login-input" placeholder='รหัสผ่าน' type='checkbox' onClick={e => setIsShowPassword(!isShowPassword)} style={{ marginRight: 5 }} />
                            แสดงรหัสผ่าน
                        </label>
                    </Form.Field>

                    <div style={{ width: '100%', display: 'flex' }} >
                        <p style={{ marginInlineEnd: '3px' }}>คลิกที่นี่หากท่าน</p>
                        <a href='/forgetPassword' style={{ "text-decoration": "underline" }}>ลืมรหัสผ่าน</a>
                    </div>

                    <Button type='submit' disabled={!validateForm()} style={{ background: 'var(--background-login)' }}>เข้าสู่ระบบ</Button>

                    <Divider horizontal style={{ color: '#C0C0C0', fontWeight: 300 }}>หากยังไม่มีบัญชี</Divider>

                    <a href='/register'><Button type='button' style={{ width: '100%', color: '#FFF', background: 'var(--background-register)' }}>
                        สมัครสมาชิก
                    </Button></a>
                </Form>
            </div>
        </div>
    );
}

export default Login;
