import React, {Component} from 'react'
import {Grid,Message,Icon, Segment, Divider, Header,Container} from 'semantic-ui-react';
import UserService from '../../../service/UserService'

class Income extends Component {
    constructor(props) {
        super(props);
        this.state = {
            childrenList: [],
            sumAFByUser: [],
            totalPrice:{
                topup:0,
                play:0,
                af:0
            }
        }
    }

    async componentDidMount(){
        this.props.handelChangeLoadingPane(true)
        await UserService.getChildren().then(res => {
            localStorage.setItem('children',JSON.stringify(res.data))
            this.initData()
            this.props.handelChangeLoadingPane(false)
        })
    }

    initData(){
        const childrens = JSON.parse(localStorage.getItem('children'))
        // cal sumTopupPrice for each
        let totalPrice={
            topup:0,
            play:0,
            af:0
        }

        let newchildrens=childrens.map((childrenItem,key)=>{
            let sumTopup=0
            let sumPlay=0
            let creditHistory = this.filterTransection(childrenItem.wallet.creditHistory,30)
            creditHistory.forEach(transItem => {
                if(transItem.type == 1){
                    sumTopup += Number(Number(transItem.actionValue).toFixed(2))
                }else if(transItem.type == 3){
                    sumPlay += Number(Number(transItem.actionValue).toFixed(2))
                }
            });
            childrenItem.wallet.creditHistory.forEach(transItem => {
                if(transItem.type == 1){
                    totalPrice.topup += Number(Number(transItem.actionValue).toFixed(2))
                }else if(transItem.type == 3){
                    totalPrice.play += Number(Number(transItem.actionValue).toFixed(2))
                }
            });

            childrenItem.sumTopup=sumTopup
            childrenItem.sumPlay=sumPlay
            return childrenItem
        })

        // set childrens list to state
        this.setState({childrenList: newchildrens})

        let myTrans = JSON.parse(localStorage.getItem('WalletBalance'))
        myTrans = myTrans.creditHistory
        let sumAFByUser=Array(0)
        this.filterTransection(myTrans,30).forEach(transItem=>{
            if(transItem.type == 7){
                if(isNaN(Number(sumAFByUser[transItem.refId]))){
                    sumAFByUser[transItem.refId] = 0
                }
                sumAFByUser[transItem.refId] = Number(sumAFByUser[transItem.refId])+Number(transItem.actionValue)
            }
        })

        myTrans.forEach(transItem=>{
            if(transItem.type == 7){
                totalPrice.af += Number(transItem.actionValue)
            }
        })

        sumAFByUser.sort((a,b)=> b-a)
        let newSumAFByUser=[]
        for (var key in sumAFByUser) {
            newSumAFByUser.push(sumAFByUser[key]);
        }
        this.setState({sumAFByUser: newSumAFByUser, totalPrice: totalPrice})
    }

    filterTransection(transList=[], minusValue){
        if(transList.length == 0){return []}
        var nowDate = new Date();
        nowDate.setDate(nowDate.getDate() - parseInt(minusValue))
        return transList.filter(item=>new Date(item.createAt) >= nowDate)
    }

    getChildrenListSortByTopup(){
        return this.state.childrenList.sort((a,b)=> Number(b.sumTopup) - Number(a.sumTopup))
    }

    getChildrenListSortByPlay(){
        return this.state.childrenList.sort((a,b)=> Number(b.sumPlay) - Number(a.sumPlay))
    }
    
    render() {
        return (
            <Container className="profileCustom">
                <Header>ยอดรวมย้อนหลัง 30 วัน</Header>
                <Grid centered>
                    <Grid.Row>
                        <Grid.Column mobile={5} tablet={5} computer={5}>
                            <Segment>
                                <Header as='h5'><Icon name='user' className="tablat_hidden" />สมาชิกใหม่</Header>
                                <Divider/>
                                {(this.getChildrenListSortByTopup().length == 0) ? (<center><p>ยังไม่มีสมาชิก</p></center>) :
                                this.getChildrenListSortByTopup().map((item,key) =>(
                                    <div>
                                        <span style={{'font-weight':'bold'}}><span className="tablat_hidden">อันดับที่</span> #{key+1}</span>
                                        <span className="float-right">{Number(item.sumTopup).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}฿</span>
                                    </div>
                                ))
                                }
                            </Segment>
                        </Grid.Column>
                        <Grid.Column mobile={5} tablet={5} computer={5}>
                            <Segment>
                                <Header as='h5'><Icon name='money bill alternate outline'  className="tablat_hidden" />ยอดแทง</Header>
                                <Divider/>
                                {(this.getChildrenListSortByPlay().length == 0) ? (<center><p>ยังไม่มีสมาชิก</p></center>) :
                                this.getChildrenListSortByPlay().map((item,key) =>(
                                    <div>
                                        <span style={{'font-weight':'bold'}}><span className="tablat_hidden">อันดับที่</span> #{key+1}</span>
                                        <span className="float-right">{Number(item.sumPlay).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}฿</span>
                                    </div>
                                ))
                                }
                            </Segment>
                        </Grid.Column>
                        <Grid.Column mobile={5} tablet={5} computer={5}>
                            <Segment>
                                <Header as='h5'><Icon name='users' className="tablat_hidden"/>ยอดแนะนำ</Header>
                                <Divider/>
                                {(this.getChildrenListSortByPlay().length == 0) ? (<center><p>ยังไม่มีสมาชิก</p></center>) :
                                this.state.sumAFByUser.map((item,key) =>(
                                    <div>
                                        <span style={{'font-weight':'bold'}}><span className="tablat_hidden">อันดับที่</span> #{key+1}</span>
                                        <span className="float-right">{item}฿</span>
                                    </div>
                                ))}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
        
                </Grid>
                <Header>ยอดรวมทั้งหมด</Header>
                <Grid centered>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={5} computer={5}>
                            <Segment>
                                <span style={{'font-weight':'bold'}}>สมาชิกทั้งหมด</span>
                                <span className="float-right">{this.state.totalPrice.topup.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท</span>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={5} computer={5}>
                            <Segment>
                                <span style={{'font-weight':'bold'}}>ยอดแทงทั้งหมด</span>
                                <span className="float-right">{this.state.totalPrice.play.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท</span>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={5} computer={5}>
                            <Segment>
                                <span style={{'font-weight':'bold'}}>ยอดแนะนำ</span>
                                <span className="float-right">{this.state.totalPrice.af.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท</span>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
    
}

export default Income