import React, { useEffect, useState } from 'react'
import { Tab, Container, Segment, Table, Button, Dropdown } from "semantic-ui-react";
import Book from '../book/Book';
import UserService from '../../../service/UserService';

const History = () => {
    const [historyTransactionLoading, setHistoryTransactionLoading] = useState(false)
    const [statementCredit, setStatementCredit] = useState([])
    const [limit, setLimit] = useState(10)
    const [isLoadingHistoryPlay, setIsLoadingHistoryPlay] = useState(false)
    const [historyHuay, setHistoryHuay] = useState([])
    const [historyGame, setHistoryGame] = useState([])
    const [historyGameLimit, setHistoryGameLimit] = useState(10)
    const [historyHuayLimit, setHistoryHuayLimit] = useState(10)
    const [historySelect, setHistorySelect] = useState('หวย')

    const getHistoryTransaction = (limit = 10) => {
        setHistoryTransactionLoading(true)
        UserService.getHistoryWallet(limit).then((res) => {
            if (res.status) {
                const handleData = [];
                res.data.wallet.creditHistory.map((item, key) => {
                    //prepair topic
                    let topicText = null;
                    switch (item.type) {
                        case 1:
                            topicText = 'ฝาก';
                            break;
                        case 2:
                            topicText = 'ถอน';
                            break;
                        case 3:
                            topicText = 'แทงหวย';
                            break;
                        case 4:
                            topicText = 'ถูกรางวัล';
                            break;
                        case 5:
                            topicText = 'โอน AF เป็นเครดิต';
                            break;
                        case 6:
                            topicText = 'ถอน AF';
                            break;
                        case 7:
                            topicText = 'ได้ AF';
                            break;
                        case 8:
                            topicText = 'เล่นเกม';
                            break;
                        case 9:
                            topicText = 'ชนะเกม';
                            break;
                        case 10:
                            topicText = 'เสมอเกม';
                            break;
                        case 11:
                            topicText = 'เกมหมดเวลา';
                            break;
                        case 12:
                            topicText = 'คืนเงิน';
                            break;
                        case 13:
                            topicText = 'ดึงเงินคืน';
                            break;
                        case 14:
                            topicText = 'ยกเลิกเติมเงิน';
                            break;
                        case 15:
                            topicText = 'ยกเลิกถอนเงิน';
                            break;
                        default:
                            topicText = 'ไม่มีหัวข้อรายการ'
                    }

                    if (item.type != 6) {
                        handleData.push({
                            topic: topicText,
                            dateTime: item.createdAt,
                            money: item.oldValue,
                            moneyAction: ((item.isIncome) ? '+' : '-') + item.actionValue,
                            moneySum: item.newValue,
                            color: (item.isIncome) ? 'green' : 'red',
                            status: (item.isIncome) ? 'positive' : 'negative',
                        })
                    }
                });
                setStatementCredit(handleData)
                setHistoryTransactionLoading(false)
                setLimit(limit)
            }
        })
    }

    const getListHistoryHuay = (limit = 10) => {
        UserService.getHistoryHuay(limit).then((res) => {
            if (res.status) {
                const handleData = [];
                res.data.wallet.creditHistory.map((item, key) => {
                    //prepair topic
                    let topicText = null;
                    switch (item.type) {
                        case 1:
                            topicText = 'ฝาก';
                            break;
                        case 2:
                            topicText = 'ถอน';
                            break;
                        case 3:
                            topicText = 'แทงหวย';
                            break;
                        case 4:
                            topicText = 'ถูกรางวัล';
                            break;
                        case 5:
                            topicText = 'โอน AF เป็นเครดิต';
                            break;
                        case 6:
                            topicText = 'ถอน AF';
                            break;
                        case 7:
                            topicText = 'ได้ AF';
                            break;
                        case 8:
                            topicText = 'เล่นเกม';
                            break;
                        case 9:
                            topicText = 'ชนะเกม';
                            break;
                        case 10:
                            topicText = 'เสมอเกม';
                            break;
                        case 11:
                            topicText = 'เกมหมดเวลา';
                            break;
                        case 12:
                            topicText = 'คืนเงิน';
                            break;
                        case 13:
                            topicText = 'ดึงเงินคืน';
                            break;
                        case 14:
                            topicText = 'ยกเลิกเติมเงิน';
                            break;
                        case 15:
                            topicText = 'ยกเลิกถอนเงิน';
                            break;
                        default:
                            topicText = 'ไม่มีหัวข้อรายการ'
                    }

                    if (item.type != 6) {
                        handleData.push({
                            topic: topicText,
                            dateTime: item.createdAt,
                            money: item.oldValue,
                            moneyAction: ((item.isIncome) ? '+' : '-') + item.actionValue,
                            moneySum: item.newValue,
                            color: (item.isIncome) ? 'green' : 'red',
                            status: (item.isIncome) ? 'positive' : 'negative',
                        })
                    }
                });
                setHistoryHuayLimit(limit)
                setHistoryHuay(handleData)
            }
        })
    }

    const getListHistoryGame = (limit = 10) => {
        UserService.getHistoryGame(limit).then((res) => {
            if (res.status) {
                const handleData = [];
                res.data.wallet.creditHistory.map((item, key) => {
                    //prepair topic
                    let topicText = null;
                    switch (item.type) {
                        case 1:
                            topicText = 'ฝาก';
                            break;
                        case 2:
                            topicText = 'ถอน';
                            break;
                        case 3:
                            topicText = 'แทงหวย';
                            break;
                        case 4:
                            topicText = 'ถูกรางวัล';
                            break;
                        case 5:
                            topicText = 'โอน AF เป็นเครดิต';
                            break;
                        case 6:
                            topicText = 'ถอน AF';
                            break;
                        case 7:
                            topicText = 'ได้ AF';
                            break;
                        case 8:
                            topicText = 'เล่นเกม';
                            break;
                        case 9:
                            topicText = 'ชนะเกม';
                            break;
                        case 10:
                            topicText = 'เสมอเกม';
                            break;
                        case 11:
                            topicText = 'เกมหมดเวลา';
                            break;
                        case 12:
                            topicText = 'คืนเงิน';
                            break;
                        case 13:
                            topicText = 'ดึงเงินคืน';
                            break;
                        default:
                            topicText = 'ไม่มีหัวข้อรายการ'
                    }

                    if (item.type != 6) {
                        handleData.push({
                            topic: topicText,
                            dateTime: item.createdAt,
                            money: item.oldValue,
                            moneyAction: ((item.isIncome) ? '+' : '-') + item.actionValue,
                            moneySum: item.newValue,
                            color: (item.isIncome) ? 'green' : 'red',
                            status: (item.isIncome) ? 'positive' : 'negative',
                            detail: item.desc,
                        })
                    }
                });
                setHistoryGameLimit(limit)
                setHistoryGame(handleData)
            }
        })
    }

    const depositWithdrawHistory = () => {
        return (
            <div style={{
                padding: 3,
                background: 'var(--background-huay-list)',
                borderRadius: 8,
                marginTop: 8
            }}>
                <Segment loading={historyTransactionLoading} style={{ marginTop: 3, border: 0 }}>
                    <p style={{ textAlign: 'center', fontWeight: 300, fontSize: 18, marginBottom: 10 }} >รายการฝาก - ถอน</p>
                    <Table style={{ border: 0, margin: 0, marginBottom: 3 }}>
                        <Table.Body>
                            {
                                statementCredit.map(function (item, key) {
                                    return (
                                        <Table.Row positive={item.myNumberShot} key={key}>
                                            <Table.Cell style={{ width: '50%', paddingLeft: 50, textAlign: 'left', color: '#636363' }}>
                                                <p style={{ color: '#000000', fontWeight: 400, fontSize: 16, marginBottom: 10 }}>{item.topic}</p>
                                                <p style={{ color: '#888', fontWeight: 300, fontSize: 13 }}>{new Date(item.dateTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }) + " " + new Date(item.dateTime).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}</p>
                                            </Table.Cell>
                                            <Table.Cell style={{ width: '50%', paddingRight: 50, textAlign: 'right', color: '#636363' }}>
                                                <p style={{ color: item.color, fontWeight: 400, fontSize: 14 }} >{item.moneyAction}</p>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>
                    {statementCredit.length >= limit &&
                        <center style={{ marginTop: 10 }}>
                            <Button positive onClick={() => getHistoryTransaction(limit + 5)}  > โหลดเพิ่มเติม</Button>
                        </center>
                    }
                </Segment>
            </div>
        )
    }

    const historyPlay = () => {
        return (
            <Container className="profileCustom">
                <div style={{
                    padding: 3,
                    background: 'var(--background-huay-list)',
                    borderRadius: 8
                }}>
                    <Dropdown
                        options={
                            [
                                {
                                    key: 'หวย',
                                    text: 'หวย',
                                    value: 'หวย',
                                },
                                {
                                    key: 'เกม',
                                    text: 'เกม',
                                    value: 'เกม',
                                }
                            ]
                        }
                        defaultValue={historySelect}
                        onChange={(event, data) => {
                            setHistorySelect(data.value)
                        }}
                        className={"profileDropdown"}
                    />
                </div>
                <div style={{
                    padding: 3,
                    background: 'var(--background-huay-list)',
                    borderRadius: 8,
                    marginTop: 8
                }}>
                    <Segment loading={isLoadingHistoryPlay} style={{ marginTop: 0 }}>
                        <Table style={{ border: 0, margin: 0, marginBottom: 20 }}>
                            <Table.Body>
                                {
                                    historySelect === 'หวย' &&
                                    historyHuay.map((item, index) => {
                                        return (
                                            <Table.Row positive={item.myNumberShot} key={index}>
                                                <Table.Cell style={{ width: '50%', paddingLeft: 50, textAlign: 'left', color: '#636363' }}>
                                                    <p style={{ color: '#000000', fontWeight: 400, fontSize: 16, marginBottom: 10 }}>{item.topic}</p>
                                                    <p style={{ color: '#888', fontWeight: 300, fontSize: 13 }}>{new Date(item.dateTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }) + " " + new Date(item.dateTime).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}</p>
                                                </Table.Cell>
                                                <Table.Cell style={{ width: '50%', paddingRight: 50, textAlign: 'right', color: '#636363' }}>
                                                    <p style={{ color: item.color, fontWeight: 400, fontSize: 14 }} >{item.moneyAction}</p>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })

                                }
                                {
                                    historySelect === 'เกม' &&
                                    historyGame.map((item, index) => {
                                        return (
                                            <Table.Row positive={item.myNumberShot} key={index}>
                                                <Table.Cell style={{ width: '50%', paddingLeft: 50, textAlign: 'left', color: '#636363' }}>
                                                    <p style={{ color: '#000000', fontWeight: 400, fontSize: 16, marginBottom: 5 }}>{item.topic}</p>
                                                    <p style={{ color: '#000000', fontWeight: 400, fontSize: 13, marginBottom: 5 }}>{item.detail}</p>
                                                    <p style={{ color: '#888', fontWeight: 300, fontSize: 13 }}>{new Date(item.dateTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }) + " " + new Date(item.dateTime).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}</p>
                                                </Table.Cell>
                                                <Table.Cell style={{ width: '50%', paddingRight: 50, textAlign: 'right', color: '#636363' }}>
                                                    <p style={{ color: item.color, fontWeight: 400, fontSize: 14 }} >{item.moneyAction}</p>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table>
                        {
                            historySelect === 'หวย' &&
                            historyHuay.length >= historyHuayLimit &&
                            <center style={{ marginTop: 10 }}>
                                <Button positive onClick={() => getListHistoryHuay(historyHuayLimit + 5)}  > โหลดเพิ่มเติม</Button>
                            </center>
                        }
                        {
                            historySelect === 'เกม' &&
                            historyGame.length >= historyGameLimit &&
                            <center style={{ marginTop: 10 }}>
                                <Button positive onClick={() => getListHistoryGame(historyGameLimit + 5)}  > โหลดเพิ่มเติม</Button>
                            </center>
                        }
                    </Segment>
                </div>

            </Container>
        )
    }

    const handleChangeTab = async (e, data) => {
        if (data.activeIndex == 1) {
            getHistoryTransaction()
        } else if (data.activeIndex == 2) {
            await setIsLoadingHistoryPlay(true)
            await getListHistoryHuay()
            await getListHistoryGame()
            await setIsLoadingHistoryPlay(false)
        }
    }

    const panes = [
        {
            menuItem: { key: 'book', content: <div style={{ display: 'flex', alignItems: 'center' }}>โพย</div> },
            render: () => <Tab.Pane attached={false} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: '0px' }}><Book /></Tab.Pane>,
        },
        {
            menuItem: { key: 'Wallet', content: <div style={{ display: 'flex', alignItems: 'center' }}>ฝากถอน</div> },
            render: () => <Tab.Pane attached={false} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0 }}>{depositWithdrawHistory()}</Tab.Pane>,
        },
        {
            menuItem: { key: 'play', content: <div style={{ display: 'flex', alignItems: 'center' }}>การเล่น</div> },
            render: () => <Tab.Pane attached={false} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0 }}>{historyPlay()}</Tab.Pane>,
        },
    ]

    return (
        <Container className='historyHeader'>
            <Tab menu={{ pointing: true }} panes={panes} defaultActiveIndex={'0'} onTabChange={handleChangeTab} />
        </Container>
    )
}

export default History