import React, {Component} from 'react'
import {Button, Card, Container, Grid, Header, Icon, Label, Menu, Responsive, Segment, Tab} from "semantic-ui-react";
import {Link} from "react-router-dom";
import YiiGiiService from '../../../service/YiiGiiService'

const asyncFunc = (event) => {
    return new Promise(resolve => {
        event();
        resolve();
    });
};

class YikiHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            box: 3,
            roundList:[],
            showLimit: 5
        };
    }

    async componentDidMount() {
        this.resizeBox();
        await asyncFunc(() => {
            
        });
        this.props.isloading(true)
        YiiGiiService.getYiiGiiRoundToday(this.props.yigiId).then(res=>{
            if(res.status){
                let tempRoundList=[]
                res.data.round.forEach(roundItem => {
                   if(roundItem.result && roundItem.result != null && roundItem.result != ""){
                        tempRoundList.push({
                            roundId: roundItem._id,
                            title: res.data.yiigii.name,
                            roundName: roundItem.name,
                            result: [
                                {
                                    name: "สามตัวบน",
                                    number: roundItem.result.threeTop
                                },
                                {
                                    name: "สองตัวบน",
                                    number: roundItem.result.twoTop
                                },
                                {
                                    name: "สองตัวล่าง",
                                    number: roundItem.result.twoBot
                                },
                            ]
                        })
                   }
                });
                this.setState({roundList: tempRoundList.reverse()})
            }
            this.props.isloading(false)
        })
    }

    resizeBox = () => {
        let box = 1;
        let innerWidth = window.innerWidth;
        if (innerWidth > 720)
            box = 3;

        this.setState((prevState) => ({box: box}))
    };

    getHuayList(){
        let result=[]
        for(var i=0;i<this.state.roundList.length;i++){
            if(i>=this.state.showLimit){break}else{
                result.push(this.state.roundList[i])
            }
        }
        return result
    }

    handleIncreaseShowLimit(){
        this.props.isloading(true)
        setTimeout(()=>{
            this.setState((prevState)=>({showLimit: prevState.showLimit+5}))
            this.props.isloading(false)
        },1)
    }

    render() {
        return (
            <Responsive onUpdate={this.resizeBox}>
                {this.getHuayList().map((roundItem,key)=>(
                    <Card.Group itemsPerRow={1} key={key} style={{borderRadius: 10}}>
                        <Card as={Link} to={`/lotteryDetail/${roundItem.roundId}`}>
                            <Card.Content extra className="bg-white text-black">
                                <div style={{width:22,height:44,background:'rgb(243, 243, 243)', position:'absolute', left: -1, top: 60, borderRadius: '0px 44px 44px 0px'}}></div>
                                <div style={{width:22,height:44,background:'rgb(243, 243, 243)', position:'absolute', right: -1, top: 60, borderRadius: '44px 0px 0px 44px'}}></div>
                                <div style={{textAlign: 'center', marginBottom: 20,marginTop:5}}>
                                    <span style={{fontSize: '1.2em',fontWeight:400,borderBottom:'2.5px solid #CCC', paddingLeft:15, paddingRight:15}}>รอบที่ {roundItem.roundName}</span>
                                </div>
                                <Grid columns={3} centered>
                                    <Grid.Row style={{paddingLeft:20,paddingRight:20}}>
                                        {roundItem.result.map((resultIteml,resultKey)=>(
                                            <Grid.Column key={resultKey} style={{textAlign: 'center',paddingLeft:5,paddingRight:5}}>
                                                <Button circular style={{padding: '5px 5px 5px 5px', width: '2.5em',height: '2.5em', marginBottom:10,fontSize:'1.5em'}} color={resultKey==0 ? 'red' : resultKey == 1 ? 'yellow' : 'blue'}>
                                                    {resultIteml.number}
                                                </Button>
                                                <p style={{marginBottom:0}}>{resultIteml.name}</p>
                                                <p style={{color:'#DDD', fontSize: '0.7em'}}>{resultIteml.number.split('').map(num=>'●')}</p>
                                            </Grid.Column>
                                        ))}
                                    </Grid.Row>
                                </Grid>
                            </Card.Content>
                        </Card>
                    </Card.Group>
                ))}
                <br/>
                <center>
                    <Button positive onClick={()=>this.handleIncreaseShowLimit()} disabled={(this.state.showLimit>=this.state.roundList.length)}>โหลดเพิ่มเติม</Button>
                </center>
            </Responsive>
        );
    }
}

export default YikiHistory;
