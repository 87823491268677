import React, { Component } from 'react'
import { Button, Card, Container, Grid, Header, Icon, Image, Label, Table, Segment, Tab, Accordion, Message, Modal } from "semantic-ui-react";
import Config from "../../../config/config";
import img_coin_front from "../../../assets/images/game/coin-front.png"
import img_coin_back from "../../../assets/images/game/coin-back.png"
import Authen from "../../../service/Authen";
import WalletService from "../../../service/WalletService";
import UserService from "../../../service/UserService";
import { ToastContainer, Slide, toast } from 'react-toastify';
import io from 'socket.io-client';
import 'react-toastify/dist/ReactToastify.css';
import AlertGame from '../../layout/AlertGame';

const asyncFunc = (event) => {
    return new Promise(resolve => {
        event();
        resolve();
    });
};

export default class Huakoi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameHot: 2,
            HuakoiPlayLoading: true,
            HuakoiMyRoundLoading: true,
            HuakoiStatLoading: true
        };

        const socket = null
    }

    async componentDidMount() {
        this.resizeGame();

        //socket init
        this.socket = io.connect(Config.APIConfig.gameSocketURL + "huakoi", { query: { token: Authen.getAcessToken() } })
        this.socket.on('make_round_message', data => {
            if (data.status) {
                toast.success("คุณได้เล่นเกม " + data.data.price + " บาท", {
                    position: toast.POSITION.TOP_RIGHT
                });
                WalletService.fetchWalletBalance()
            } else {
                toast.error(data.msg, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })

        this.socket.on('play_round_result', data => {
            if (data.status) {
                if (data.data.winner == UserService.getUser()._id) {
                    toast.success("คุณชนะการเล่นเกม ได้รับรางวัล " + Number(data.data.price) * Number(data.data.multiply) + " บาท", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    WalletService.fetchWalletBalance()
                } else {
                    toast.error("คุณแพ้การเล่นเกม " + data.data.price + " บาท", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            } else {
                toast.error(data.msg, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })
    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    handelHuakoiPlayLoading = (status) => this.setState({ HuakoiPlayLoading: status })
    handelHuakoiMyRoundLoading = (status) => this.setState({ HuakoiMyRoundLoading: status })
    handelHuakoiStatLoading = (status) => this.setState({ HuakoiStatLoading: status })

    resizeGame = () => {
        let gameHot = 2;
        let innerWidth = window.innerWidth;
        if (innerWidth > 1080) {
            gameHot = 2;
        } else if (innerWidth > 620) {
            gameHot = 2;
        } else {
            gameHot = 1;
        }
        this.setState((prevState) => ({ gameHot: gameHot }))
    };

    panes = [
        {
            menuItem: { key: '1', icon: 'play circle', content: 'เล่นเกม' },
            render: () => <Tab.Pane key='1' attached={false} loading={this.state.HuakoiPlayLoading} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0 }}><HuakoiPlay socketControl={this.socket} handelHLoading={this.handelHuakoiPlayLoading} /></Tab.Pane>,
        },
        {
            menuItem: { key: '2', icon: 'puzzle', content: 'เกมของฉัน' },
            render: () => <Tab.Pane key='2' attached={false} loading={this.state.HuakoiMyRoundLoading} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0 }}><HuakoiMyRound socketControl={this.socket} handelHLoading={this.handelHuakoiMyRoundLoading} /></Tab.Pane>,
        }, {
            menuItem: { key: '3', icon: 'chart bar', content: 'ผลเกม' },
            render: () => <Tab.Pane key='2' attached={false} loading={this.state.HuakoiStatLoading} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0 }}><HuakoiStat socketControl={this.socket} handelHLoading={this.handelHuakoiStatLoading} /></Tab.Pane>,
        }
    ]

    render() {
        return (
            <Container className='gameUI'>
                <ToastContainer autoClose={3000} transition={Slide} />
                <Tab menu={{ pointing: false }} panes={this.panes} />
            </Container>
        );
    }
}

class HuakoiPlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameId: '5f87c792cd542127dc51505f',
            myWallet: { credit: 0 },
            gridCol: {
                priceList: 4,
                gameplaylist: 2
            },
            gameData: {
                description: "",
                priceConfig: [],
                isEnabled: true,
                name: "",
                multiply: 1.9,
                playingType: [],
                gameRound: []
            },
            priceActice: null,
            playingTypeActive: null,
            playingCardLoading: [],
            makeRoundLoading: false
        };
    }

    async componentDidMount() {
        this.props.handelHLoading(true)
        await WalletService.fetchWalletBalance()
        this.setState({ myWallet: WalletService.getWalletBalance() })

        //socket processing
        const socket = this.props.socketControl
        socket.emit('game_data')

        socket.on('game_data', data => {
            if (data.status) {
                this.setState({ gameData: data.data })
                data.data.gameRound.filter((item => item.IdPlayer1 == UserService.getUser()._id)).forEach(item => this.joinGame(item._id))

                const playlist = this.state.gameData.gameRound.filter((item => item.IdPlayer1 != UserService.getUser()._id))
                let playingCardLoading = Array(playlist.length).fill(false)
                this.setState({ playingCardLoading: playingCardLoading })
                this.props.handelHLoading(false)
            }
        })
    }

    joinGame(roundId) {
        this.props.socketControl.emit('join_round', roundId)
    }

    makeRound() {
        if (this.state.priceActice == null || this.state.priceActice < 0) {
            toast.error("กรุณาเลือกจำนวนเงิน", {
                position: toast.POSITION.TOP_RIGHT
            });
            return
        }
        if (this.state.playingTypeActive == null) {
            toast.error("กรุณากำหนดผลการปั่นเหรียญ", {
                position: toast.POSITION.TOP_RIGHT
            });
            return
        }
        this.setState({ makeRoundLoading: true })
        this.props.socketControl.emit('makeRound', { gameId: this.state.gameId, player1Shoot: this.state.playingTypeActive, price: this.state.priceActice })
        this.setState({ playingTypeActive: null, makeRoundLoading: false })
    }
    async play(roundId, player2Shoot, price, indexCard) {
        await WalletService.fetchWalletBalance()
        if (roundId == null || player2Shoot == null) {
            toast.error("เกิดข้อผิดพลาด! ลองใหม่อีกครั้ง", {
                position: toast.POSITION.TOP_RIGHT
            });
            return
        }
        if (Number(WalletService.getWalletBalance().credit) < price) {
            toast.error("ยอดเงินของคุณไม่เพียงพอ", {
                position: toast.POSITION.TOP_RIGHT
            });
            return
        }
        let playingCardLoading = this.state.playingCardLoading
        playingCardLoading[indexCard] = true
        this.setState({ playingCardLoading: playingCardLoading })
        this.props.socketControl.emit('play', { gameId: this.state.gameId, roundId: roundId, player2Shoot: player2Shoot })
    }

    render() {
        return (<>
            <AlertGame gameTimeOut={this.state.gameData.gameTimeout} />
            <Segment className='customeSegment'>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <div style={{ textAlign: 'right', margin: "-10px 10px", marginBottom: 20, cursor: 'pointer' }}>
                        <Button as='a' href="https://www.mylotto111.com/detail/93" target='_blank' basic color="yellow" size='mini' style={{ padding: "5px 10px" }} >กติกา</Button>
                    </div>
                    <div style={{ textAlign: 'right', margin: -10, marginBottom: 20, cursor: 'pointer' }}>
                        <Modal style={{ maxWidth: 'unset' }} size='big' closeIcon trigger={<Button basic color="blue" size='mini' style={{ padding: "5px 10px" }}>วิธีการเล่น</Button>}>
                            <Modal.Content>
                                <video width="100%" controls>
                                    <source src={require('../../../assets/video/HuakoiPlay.mp4')} type="video/mp4" />
                                    Your browser does not support HTML video.
                                </video>
                            </Modal.Content>
                        </Modal>
                    </div>
                </div>
                <center style={{ marginTop: -25 }}>
                    <Header as='h4'><Icon name='gamepad' />เปิดเกม</Header>

                    <p>เลือกจำนวนเงินลงเดิมพัน</p>
                    <div style={{
                        margin: '10%',
                        marginTop: 10,
                        marginBottom: 30,
                        height: 1,
                        backgroundImage: 'linear-gradient(to right, transparent 80%, rgb(204, 204, 204) 20%)',
                        backgroundSize: 20,
                    }} />
                </center>
                <Grid style={{ padding: 8 }}>
                    {this.state.gameData.priceConfig.map((item, key) =>
                        <Grid.Column key={key} style={{ padding: 5 }} computer='4' tablet='4' mobile='5'>
                            <Button fluid color={WalletService.getWalletBalance().credit < item ? 'grey' : 'yellow'} size='medium' active={this.state.priceActice == item ? true : false} basic={this.state.priceActice == item ? false : true} disabled={WalletService.getWalletBalance().credit < item ? true : false} onClick={() => this.setState({ priceActice: item })}>{item}</Button>
                        </Grid.Column>)}
                </Grid>
                <Segment style={{ border: 0, boxShadow: '0px 0px 0px' }} loading={this.state.makeRoundLoading}>
                    <div style={{ textAlign: 'center', marginBottom: 20 }}>
                        <p>กำหนดผลการปั่นเหรียญ</p>
                        <div style={{
                            margin: '10%',
                            marginTop: 10,
                            marginBottom: 30,
                            height: 1,
                            backgroundImage: 'linear-gradient(to right, transparent 80%, rgb(204, 204, 204) 20%)',
                            backgroundSize: 20,
                        }} />
                        <div style={{ clear: 'both' }}></div>
                    </div>
                    <Grid columns='1' centered relaxed='false'>
                        <Grid.Column width='16' style={{ padding: 10 }}>
                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                                {this.state.gameData.playingType.map((item, key) => item.isEnabled ? (
                                    <Button inverted circular={false} className='yellow-custom' style={{ padding: 5 }} onClick={() => this.setState({ playingTypeActive: item })} active={this.state.playingTypeActive == item ? true : false}>
                                        <Image src={item.typeId == 1 ? img_coin_front : img_coin_back} size='small' style={{ minWidth: 50 }} />
                                        <Button inverted circular color="" active disabled style={{ padding: '5px 14px' }}>{item.name}</Button>
                                    </Button>
                                ) : '')}
                            </div>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <center>
                    <Button color="yellow" style={{ paddingLeft: 100, paddingRight: 100 }} onClick={() => this.makeRound()}>ยืนยัน</Button>
                </center>
            </Segment>
            <Segment className='customeSegment'>
                <center>
                    <Header as='h4'><Icon name='play circle' />เล่นเกม</Header>
                    <div style={{
                        margin: '10%',
                        marginTop: 10,
                        marginBottom: 30,
                        height: 1,
                        backgroundImage: 'linear-gradient(to right, transparent 80%, rgb(204, 204, 204) 20%)',
                        backgroundSize: 20,
                    }} />
                </center>
                {this.state.gameData.gameRound.filter((item => item.IdPlayer1 != UserService.getUser()._id)).length < 1 ? (
                    <Message info header='ไม่มีรายการเดิมพัน' style={{ marginBottom: 30 }} />
                ) : ''}
                <Grid >
                    {this.state.gameData.gameRound.filter((item => item.IdPlayer1 != UserService.getUser()._id)).map((item, key) => (
                        <Grid.Column key={key} computer='8' tablet='8' mobile='16'>
                            <Segment className='card-game' loading={this.state.playingCardLoading[key]}>
                                <div style={{ height: 45, color: '#FFF', display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ display: 'flex', alignitems: 'center', color: '#FFF' }}><Icon name='user' /> {item.player1Name}</span>
                                    <span as='h5' style={{ display: 'flex', alignitems: 'center', color: '#FFF' }}>{item.price}฿</span>
                                </div>
                                <Grid columns={2} style={{ backgroundColor: '#F5F5F5' }}>
                                    {this.state.gameData.playingType.map((playingTypeItem, index) => playingTypeItem.isEnabled ? (
                                        <Grid.Column className='play-choice' key={index} onClick={() => this.play(item._id, playingTypeItem, item.price, key)}>
                                            <center>
                                                <p style={{ marginBottom: 5, fontSize: 16, fontWeight: 400, color: '#777' }}>{playingTypeItem.name}</p>
                                                <Image src={playingTypeItem.typeId == 1 ? img_coin_front : img_coin_back} size='small' style={{ minWidth: 50 }} />
                                            </center>
                                        </Grid.Column>
                                    ) : '')}
                                </Grid>
                                <div style={{ height: 15, marginTop: 20, color: '#FFF', display: 'flex', justifyContent: 'center' }}>
                                    <span as='h5' style={{ display: 'flex', alignitems: 'center', color: '#FFF' }}><Icon name='clock' /> {new Date(item.createdAt).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })} {new Date(item.createdAt).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}น.</span>
                                </div>
                            </Segment>
                        </Grid.Column>
                    ))}
                </Grid>
            </Segment>
        </>);
    }
}

class HuakoiMyRound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameId: '5f87c792cd542127dc51505f',
            roundList: [],
            gameData: ""
        };
    }

    async componentDidMount() {
        this.props.handelHLoading(true)
        //socket processing
        const socket = this.props.socketControl
        socket.emit('my_round')

        socket.on('my_round', data => {
            if (data.status) {
                let roundFilter = data.data.filter((item => item.IdPlayer1 == UserService.getUser()._id && item.status == 1))
                this.setState({ roundList: roundFilter })
                this.props.handelHLoading(false)
            }
        })
        socket.emit('game_data')
        socket.on('game_data', data => {
            if (data.status) {
                this.setState({ gameData: data.data })
            }
        })
    }

    render() {
        return (
            <>
                <AlertGame gameTimeOut={this.state.gameData.gameTimeout} />
                <Segment className='customeSegment'>
                    <center>
                        <Header as='h4'>เกมของฉัน</Header>
                        <div style={{
                            margin: '10%',
                            marginTop: 10,
                            marginBottom: 30,
                            height: 1,
                            backgroundImage: 'linear-gradient(to right, transparent 80%, rgb(204, 204, 204) 20%)',
                            backgroundSize: 20,
                        }} />
                    </center>
                    {this.state.roundList.length < 1 ? (
                        <Message info content='ไม่มีรายการของฉันที่กำลังเปิดเดิมพันอยู่' style={{ textAlign: 'center' }} />
                    ) : (
                            <Table unstackable style={{ border: 0, fontWeight: 300 }}>
                                <Table.Header>
                                    <Table.Row style={{ fontWeight: 500 }}>
                                        <Table.Cell textAlign='left' style={{ width: '33.33%' }}>ผลที่เลือก</Table.Cell>
                                        <Table.Cell textAlign='center' style={{ width: '33.33%' }}>จำนวน(บาท)</Table.Cell>
                                        <Table.Cell textAlign='right' style={{ width: '33.33%' }}> วันที่สร้าง</Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.roundList.map((item, key) => (
                                        <Table.Row key={key}>
                                            <Table.Cell textAlign='left' style={{ width: '33.33%' }}>
                                                {item.player1Shoot.typeId == 1 ? (
                                                    <Button circular color='yellow' size='medium' style={{ padding: 15, fontSize: 13 }}>{item.player1Shoot.name}</Button>
                                                ) : (<Button circular color='grey' size='medium' style={{ padding: '15px 10px 15px 10px', fontSize: 13 }}>{item.player1Shoot.name}</Button>)}
                                            </Table.Cell>
                                            <Table.Cell textAlign='center' style={{ width: '33.33%' }}>{item.price} ฿</Table.Cell>
                                            <Table.Cell textAlign='right' style={{ width: '33.33%' }}> {new Date(item.createdAt).toLocaleDateString('th-TH', { year: '2-digit', month: 'short', day: 'numeric' })} {new Date(item.createdAt).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}น.</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                </Segment>
            </>
        )
    }
}

class HuakoiStat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameId: '5f87c792cd542127dc51505f',
            roundList: [],
            sumPlayRound: 0,
            sumWin: 0,
            sumLoss: 0,
            winRate: 0,
            ShowLimitRoundListList: 10,
            expandIndex: null
        };
    }

    async componentDidMount() {
        this.props.handelHLoading(true)
        //socket processing
        const socket = this.props.socketControl
        socket.emit('my_round')

        socket.on('my_round', data => {
            if (data.status) {
                let roundFilter = data.data.filter((item => item.status == 2))
                let sumPlayRound = roundFilter.length
                let sumWin = data.data.filter((item => item.winner == UserService.getUser()._id)).length
                let sumLoss = sumPlayRound - sumWin
                let winRate = sumPlayRound > 0 ? (sumWin * 100 / sumPlayRound).toFixed(0) : 50
                this.setState({ roundList: roundFilter.reverse(), sumPlayRound, sumWin, sumLoss, winRate })
                this.props.handelHLoading(false)
            }
        })
    }

    getRoundListWithLimit() {
        let result = []
        for (var i = 0; i < this.state.roundList.length; i++) {
            if (i >= this.state.ShowLimitRoundListList) { break } else {
                result.push(this.state.roundList[i])
            }
        }
        return result
    }

    handleIncreaseShowLimitRoundListList() {
        this.setState((prevState) => ({ ShowLimitRoundListList: prevState.ShowLimitRoundListList + 10 }))
    }

    render() {
        return (<>
            <Grid centered style={{ marginTop: -5, marginBottom: 10 }}>
                <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                    <Segment style={{ display: 'flex', border: 0, boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)', borderRadius: 10 }}>
                        <Container className='aligned' style={{ flex: 3, paddingLeft: 20, marginTop: 5, marghinBottom: 5 }}>
                            <Header style={{ fontSize: '2.5em', fontWeight: 500, color: '#444', margin: 0 }}>{this.state.sumPlayRound}</Header>
                            <span style={{ fontSize: '1em', fontWeight: 300, color: '#AAA' }}>เล่นทั่งหมด</span>
                        </Container>
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                            <svg width="60" height="60" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50.5" cy="50.5" r="50.5" fill="#00659E" fill-opacity="0.1" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M50.5 80C66.7924 80 80 66.7924 80 50.5C80 34.2076 66.7924 21 50.5 21C34.2076 21 21 34.2076 21 50.5C21 66.7924 34.2076 80 50.5 80ZM44.4597 64.4262L65.5806 52.232C66.914 51.4622 66.914 49.5377 65.5806 48.7679L44.4597 36.5738C43.1263 35.804 41.4597 36.7662 41.4597 38.3058L41.4597 62.6942C41.4597 64.2338 43.1263 65.196 44.4597 64.4262Z" fill="#00659E" />
                            </svg>

                        </div>
                    </Segment>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                    <Segment style={{ display: 'flex', border: 0, boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)', borderRadius: 10 }}>
                        <Container className='aligned' style={{ flex: 3, paddingLeft: 20, marginTop: 5, marghinBottom: 5 }}>
                            <Header style={{ fontSize: '2.5em', fontWeight: 500, color: '#444', margin: 0 }}>{this.state.winRate}</Header>
                            <span style={{ fontSize: '1em', fontWeight: 300, color: '#AAA' }}>อัตราการชนะ</span>
                        </Container>
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                            <svg width="60" height="60" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50.5" cy="50.5" r="50.5" fill="#FBBD08" fill-opacity="0.1" />
                                <path d="M68.0328 28.9835C66.737 27.9298 64.8321 28.1265 63.7784 29.4228L32.4748 67.9346C31.4212 69.2308 31.6179 71.1353 32.9141 72.189C33.2964 72.4996 33.7315 72.7016 34.1823 72.7987C35.2597 73.0308 36.4254 72.6635 37.1684 71.7497L68.472 33.2379C69.5257 31.9417 69.3289 30.0372 68.0328 28.9835Z" fill="#FBBD08" />
                                <path d="M46.9399 40.3388C46.9399 34.0142 41.7943 28.8687 35.4697 28.8687C29.1455 28.8687 24 34.0142 24 40.3388C24 46.6632 29.1455 51.8087 35.4699 51.8087C41.7945 51.8087 46.9399 46.6632 46.9399 40.3388ZM35.4699 45.7601C32.4807 45.7601 30.0486 43.328 30.0486 40.3388C30.0486 37.3494 32.4807 34.9172 35.4699 34.9172C38.4593 34.9172 40.8915 37.3492 40.8915 40.3388C40.8913 43.328 38.4593 45.7601 35.4699 45.7601Z" fill="#FBBD08" />
                                <path d="M65.6134 49.3638C59.2888 49.3638 54.1433 54.5093 54.1433 60.8337C54.1433 67.1581 59.2886 72.3036 65.6134 72.3036C71.9378 72.3036 77.0834 67.1583 77.0834 60.8337C77.0834 54.5091 71.938 49.3638 65.6134 49.3638ZM65.6134 66.2548C62.624 66.2548 60.1919 63.8227 60.1919 60.8335C60.1919 57.8443 62.624 55.4122 65.6134 55.4122C68.6027 55.4122 71.0348 57.8443 71.0348 60.8335C71.0348 63.8227 68.6029 66.2548 65.6134 66.2548Z" fill="#FBBD08" />
                            </svg>
                        </div>
                    </Segment>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                    <Segment style={{ display: 'flex', border: 0, boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)', borderRadius: 10 }}>
                        <Container className='aligned' style={{ flex: 3, paddingLeft: 20, marginTop: 5, marghinBottom: 5 }}>
                            <Header style={{ fontSize: '2.5em', fontWeight: 500, color: '#444', margin: 0 }}>{this.state.sumWin}</Header>
                            <span style={{ fontSize: '1em', fontWeight: 300, color: '#AAA' }}>ชนะ</span>
                        </Container>
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                            <svg width="60" height="60" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50.5" cy="50.5" r="50.5" fill="#00B261" fill-opacity="0.1" />
                                <path d="M68.95 32.05H62.8V30H38.2V32.05H32.05C30.82 32.05 30 32.87 30 34.1V39.02C30 43.735 33.485 47.63 38.2 48.245V48.45C38.2 54.395 42.3 59.315 47.835 60.545L46.4 64.85H41.685C40.865 64.85 40.045 65.465 39.84 66.285L38.2 71H62.8L61.16 66.285C60.955 65.465 60.135 64.85 59.315 64.85H54.6L53.165 60.545C58.7 59.315 62.8 54.395 62.8 48.45V48.245C67.515 47.63 71 43.735 71 39.02V34.1C71 32.87 70.18 32.05 68.95 32.05ZM38.2 44.145C35.945 43.53 34.1 41.48 34.1 39.02V36.15H38.2V44.145ZM54.6 50.5L50.5 48.245L46.4 50.5L47.425 46.4L44.35 42.3H48.655L50.5 38.2L52.345 42.3H56.65L53.575 46.4L54.6 50.5ZM66.9 39.02C66.9 41.48 65.055 43.735 62.8 44.145V36.15H66.9V39.02Z" fill="#00B261" />
                            </svg>
                        </div>
                    </Segment>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                    <Segment style={{ display: 'flex', border: 0, boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)', borderRadius: 10 }}>
                        <Container className='aligned' style={{ flex: 3, paddingLeft: 20, marginTop: 5, marghinBottom: 5 }}>
                            <Header style={{ fontSize: '2.5em', fontWeight: 500, color: '#444', margin: 0 }}>{this.state.sumLoss}</Header>
                            <span style={{ fontSize: '1em', fontWeight: 300, color: '#AAA' }}>แพ้</span>
                        </Container>
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                            <svg width="60" height="60" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50.5" cy="50.5" r="50.5" fill="#D70000" fill-opacity="0.1" />
                                <g clip-path="url(#clip0)">
                                    <path d="M50 77C43.0552 77 36.526 74.2955 31.6152 69.3848C26.7045 64.474 24 57.9448 24 51C24 44.0552 26.7045 37.526 31.6152 32.6152C36.526 27.7045 43.0552 25 50 25C56.9448 25 63.474 27.7045 68.3848 32.6152C73.2955 37.526 76 44.0552 76 51C76 57.9448 73.2955 64.474 68.3848 69.3848C63.474 74.2955 56.9448 77 50 77ZM50 29.0625C37.9036 29.0625 28.0625 38.9036 28.0625 51C28.0625 63.0964 37.9036 72.9375 50 72.9375C62.0964 72.9375 71.9375 63.0964 71.9375 51C71.9375 38.9036 62.0964 29.0625 50 29.0625ZM60.1436 60.9798C59.9925 60.7423 56.3684 55.1641 49.8986 55.1641C43.4289 55.1641 39.8049 60.7423 39.6537 60.9798C39.0515 61.9263 39.3305 63.1817 40.2769 63.784C41.2234 64.3863 42.4789 64.1074 43.0811 63.1608C43.1061 63.1215 45.6341 59.2266 49.8986 59.2266C54.1631 59.2266 56.6911 63.1215 56.7162 63.1608C57.3185 64.1073 58.5739 64.3862 59.5204 63.784C60.4669 63.1817 60.7459 61.9263 60.1436 60.9798ZM41.0625 41.7578C42.4648 41.7578 43.6016 42.8946 43.6016 44.2969C43.6016 45.6991 42.4648 46.8359 41.0625 46.8359C39.6602 46.8359 38.5234 45.6991 38.5234 44.2969C38.5234 42.8946 39.6602 41.7578 41.0625 41.7578ZM56.2969 44.2969C56.2969 45.6991 57.4337 46.8359 58.8359 46.8359C60.2382 46.8359 61.375 45.6991 61.375 44.2969C61.375 42.8946 60.2382 41.7578 58.8359 41.7578C57.4337 41.7578 56.2969 42.8946 56.2969 44.2969Z" fill="#D70000" />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="52" height="52" fill="white" transform="translate(24 25)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </Segment>
                </Grid.Column>
            </Grid>

            <Button color='blue' fluid style={{ borderRadius: 2, background: '#1678c2' }}>ประวัติการเล่น</Button>
            <Table unstackable style={{ marginTop: 0, paddingLeft: 10, paddingRight: 10, paddingBottom: 10, border: 0, boxShadow: 'rgba(34, 36, 38, 0.1) 0px 2px 4px 0px' }}>
                {this.getRoundListWithLimit().length <= 0 ? <Message info content='ไม่มีรายประวัติการเล่น' style={{ margin: 15, textAlign: 'center' }} /> : ''}
                <Table.Body>
                    {this.getRoundListWithLimit().map((item, key) => (<>
                        <Table.Row key={key} style={{ color: '#444', cursor: 'pointer' }} onClick={() => this.setState(oldstate => ({ expandIndex: oldstate.expandIndex == key ? -1 : key }))}>
                            <Table.Cell width='3' textAlign='center'>
                                {item.IdPlayer1 == UserService.getUser()._id ? <Header as='h4' color='yellow' style={{ fontWeight: 400, marginLeft: 5 }}><Icon name='dropdown' style={{ color: '#CCC', fontSize: 12 }} size='mini' />เป็นเจ้ามือ</Header> : <Header as='h4' color='blue' style={{ fontWeight: 400, marginLeft: 5 }}><Icon name='dropdown' style={{ color: '#CCC', fontSize: 12 }} size='mini' />เป็นผู้เล่น</Header>}
                            </Table.Cell>
                            <Table.Cell width='3' textAlign='center'> {item.price} ฿ </Table.Cell>
                            <Table.Cell width='3' textAlign='center'> {item.winner == UserService.getUser()._id ? (<Header as='h4' color='green'>ชนะ</Header>) : (<Header as='h4' color='red'>แพ้</Header>)} </Table.Cell>
                        </Table.Row>
                        <Table.Row style={{ display: this.state.expandIndex == key ? 'contents' : 'none' }}>
                            <Table.Cell colSpan='3' style={{ background: '#F8F8F8', padding: '5px', color: '#777', fontSize: 13 }}>
                                <Grid columns='2' style={{ margin: 0 }}>
                                    <Grid.Column textAlign='right' style={{ padding: '2px 15px', alignSelf: 'center' }}>ตัวฉัน {item.IdPlayer1 == UserService.getUser()._id ? '(เจ้ามือ)' : ''}</Grid.Column>
                                    <Grid.Column textAlign='left' style={{ padding: '2px 15px' }}>
                                        {item.IdPlayer1 == UserService.getUser()._id ? (
                                            item.player1Shoot.typeId == 1 ? (
                                                <Button circular color='yellow' size='medium' style={{ padding: 7, fontSize: 10 }}>{item.player1Shoot.name}</Button>
                                            ) : (<Button circular color='grey' size='medium' style={{ padding: '7px 3px', fontSize: 10 }}>{item.player1Shoot.name}</Button>)
                                        ) : (
                                                item.player2Shoot.typeId == 1 ? (
                                                    <Button circular color='yellow' size='medium' style={{ padding: 7, fontSize: 10 }}>{item.player2Shoot.name}</Button>
                                                ) : (<Button circular color='grey' size='medium' style={{ padding: '7px 3px', fontSize: 10 }}>{item.player2Shoot.name}</Button>)
                                            )
                                        }
                                    </Grid.Column>

                                    <Grid.Column textAlign='right' style={{ padding: '2px 15px', alignSelf: 'center' }}>คู่แข่ง {item.IdPlayer1 != UserService.getUser()._id ? '(เจ้ามือ)' : ''}</Grid.Column>
                                    <Grid.Column textAlign='left' style={{ padding: '2px 15px' }}>
                                        {item.IdPlayer1 != UserService.getUser()._id ? (
                                            item.player1Shoot.typeId == 1 ? (
                                                <Button circular color='yellow' size='medium' style={{ padding: 7, fontSize: 10 }}>{item.player1Shoot.name}</Button>
                                            ) : (<Button circular color='grey' size='medium' style={{ padding: '7px 3px', fontSize: 10 }}>{item.player1Shoot.name}</Button>)
                                        ) : (
                                                item.player2Shoot.typeId == 1 ? (
                                                    <Button circular color='yellow' size='medium' style={{ padding: 7, fontSize: 10 }}>{item.player2Shoot.name}</Button>
                                                ) : (<Button circular color='grey' size='medium' style={{ padding: '7px 3px', fontSize: 10 }}>{item.player2Shoot.name}</Button>)
                                            )
                                        }
                                    </Grid.Column>

                                    <Grid.Column textAlign='right' style={{ padding: '2px 15px' }}>เกมจบเมื่อวันที่</Grid.Column>
                                    <Grid.Column textAlign='left' style={{ padding: '2px 15px' }}>{new Date(item.updatedAt).toLocaleDateString('th-TH', { year: '2-digit', month: 'short', day: 'numeric' })} {new Date(item.updatedAt).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}น.</Grid.Column>
                                </Grid>
                            </Table.Cell>
                        </Table.Row>
                    </>))}
                </Table.Body>
            </Table>
            {this.state.ShowLimitRoundListList < this.state.roundList.length && this.state.roundList.length != 0 ? (
                <center style={{ marginTop: 10 }}>
                    {this.state.roundList.length > 0 ? <Button positive onClick={() => this.handleIncreaseShowLimitRoundListList()} disabled={(this.state.ShowLimitRoundListList >= this.state.roundList.length)}>โหลดเพิ่มเติม</Button> : ''}
                </center>
            ) : ''}
        </>)
    }
}