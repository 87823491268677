import React, { useEffect, useState } from 'react';
import { Card, Modal, Dimmer, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import GameService from "../../service/GameService"
import { isMobile } from 'react-device-detect';

export default ({ open, onClose }) => {
    const [loading, setLoading] = useState(false)

    const handleClickSBO = async () => {
        setLoading(true)
        GameService.loginSBO(isMobile).then((res) => {
            if (res?.data?.status == "0000") {
                window.location.href = res?.data?.data?.data?.url
                setTimeout(() => {
                    setLoading(false)
                }, 1500)
            } else {
                setLoading(false)
            }
        })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeIcon='close'
            className="modal-slot-game"
            style={{ maxWidth: 250 }}
        >
            <div className="modal-content-slot-game">
                <Dimmer active={loading}>
                    <Loader content='กำลังโหลด' />
                </Dimmer>

                <Card as={Link} onClick={handleClickSBO}>
                    <img src={require('../../assets/images/home/sbo_sport.png')} style={{ width: '100%' }} />
                </Card>
                {/* <a className="ui card" style={{ cursor: 'not-allowed' }}>
                    <img src={require('../../assets/images/home/sexy_casino_disable.png')} style={{ width: '100%' }} />
                </a> */}
            </div>
        </Modal>
    )
}