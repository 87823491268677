import React, {useEffect, useState } from 'react'
import { Button, Form, Image, Loader } from 'semantic-ui-react'
import { useHistory, Link,useParams } from 'react-router-dom';
import Authen from '../../service/Authen'
import WalletService from '../../service/WalletService';
import UserService from '../../service/UserService';
import apiConfig from '../../config/config'

const LoginAuto=(props)=>{
    document.title = props.title || "พารวย"
    const { loginKey } = useParams();
    const [isLoading,setIsLoading]=useState(true)
    const history = useHistory();

    useEffect(() => {
        setIsLoading(true)
        Authen.verifyLoginKey(loginKey).then(resV=>{
            if(resV.status){
                UserService.fetchMe().then(resU=>{
                    if(resU.status){
                        WalletService.fetchWalletBalance().then((res) => {
                            localStorage.setItem('login', true);
                            history.push("/home")
                        })
                    }else{
                        Authen.login()
                        setIsLoading(true)
                    }
                })
            }
        })
        return(()=>{
        })
    },[]);

    return (
        <div className="login-page">
            <div className="login-box" style={{textAlign:'center'}}>
                <Loader active={isLoading} inline='centered' size='huge' >Loading</Loader>
                {!isLoading && <><h1> ❗ </h1><h2>เข้าสู่ระบบไม่สำเร็จ</h2> กรุณาลองใหม่อีกครั้ง</>}
            </div>
        </div>
    );
}

export default LoginAuto;
