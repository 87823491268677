import React, { Component } from 'react';
import { Card, Container, Header, Icon, Responsive, Tab, Popup, Segment, Flag, Image } from "semantic-ui-react";

import { Link } from "react-router-dom";
import YikiHistory from "./YikiHistory";
import HuayHistory from "./HuayHistory";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import config from '../../../config/config';

class LotteryHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            panes: [],
            historyLoading: true,

        };
    }

    async componentDidMount() {
        await this.initData()
    }

    iframe() {
        return {
            __html: `<iframe src="${config.HISTORY_HUAY}" width="100%" height="100%"></iframe>`
        }
    }

    initData() {
        let panesTemp = []
        panesTemp.push({
            menuItem: { content: <div style={{ textAlign: 'center' }}><p>{'ผลหวย'}</p></div>, key: "default" },
            render: () => <Tab.Pane key={"default"} style={{ border: 0, backgroundColor: 'unset', paddingLeft: 0, paddingRight: 0, marginTop: 20 }}>
                <div style={{ height: "65vh" }}>
                    <div style={{ height: "100%" }} dangerouslySetInnerHTML={this.iframe()} />
                </div>

            </Tab.Pane>,
        })
        this.props.yiigii.forEach((elm, key) => {
            //init History
            if (elm.isHistoryEnabled == 1) {
                panesTemp.push({
                    menuItem: { content: <div style={{ textAlign: 'center' }}><p>{'ผล' + elm.name}</p></div>, key: key },
                    render: () => <Tab.Pane key={elm._id} loading={this.state.historyLoading} style={{ border: 0, backgroundColor: 'unset', paddingLeft: 0, paddingRight: 0, marginTop: 20 }}>
                        <YikiHistory yigiId={elm._id} isloading={this.handleSetHistoryLoading} />
                    </Tab.Pane>,
                })
            }
        });
        // this.props.huay.forEach(elm => {
        //     //init History
        //     if (elm.isHistoryEnabled == 1) {
        //         panesTemp.push({
        //             menuItem: { content: <div style={{ textAlign: 'center' }}><p>{'ผล' + elm.name}</p></div>, key: elm._id },
        //             render: () => <Tab.Pane key={elm._id} loading={this.state.historyLoading} style={{ border: 0, backgroundColor: 'unset', paddingLeft: 0, paddingRight: 0, marginTop: 20 }}>
        //                 <HuayHistory huayId={elm._id} isloading={this.handleSetHistoryLoading} titleRound={elm.name} showRoundTime={false} />
        //             </Tab.Pane>,
        //         })
        //     }
        // });
        this.setState({ panes: panesTemp })
    }

    handleSetHistoryLoading = (status) => {
        this.setState({ historyLoading: status })
    }

    render() {
        return (
            <div className='lotteryHistory'>
                <Tab className='tabCustom' menu={{ pointing: false, secondary: true, color: 'teal', attached: true, tabular: false, borderless: false, }} panes={this.state.panes} />
            </div>
        )
    };
}

export default LotteryHistory
