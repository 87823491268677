import React from 'react';
import { Modal, Button } from "semantic-ui-react";

export default ({ open, onClose, name }) => {

    return (
        <Modal
            open={open}
            closeIcon
            onClose={onClose}
        >
            <Modal.Header>
                แจ้งเตือน
            </Modal.Header>
            <Modal.Content>
                <center style={{ fontSize: 15 }}>
                    เกม {name} อยู่ระหว่างปิดปรับปรุงประจำสัปดาห์
                </center>
            </Modal.Content>
            <Modal.Actions>
                <center>
                    <Button style={{ background: '#4CAE3C', borderRadius: '30px', paddingInlineStart: '50px', paddingInlineEnd: '50px' }} type='button' color='primary' onClick={() => {
                        onClose()
                    }}>
                        ตกลง
                    </Button>
                </center>
            </Modal.Actions>
        </Modal>
    )
}