import React, { useEffect, useState } from 'react';
import { Input, Card, Container, Tab, Header, Icon, Image, Label, Responsive, Segment, Modal, CardContent } from "semantic-ui-react";
import GameService from '../../../service/GameService';
import Authen from '../../../service/Authen';
import Config from '../../../config/config';
import AlertClosed from './AlertClosed';

const list_game = [
    // FH 9
    {
        "GameType": "FH",
        "GameCode": "JILI-FISH-001",
        "GameName": "Royal Fishing"
    },
    {
        "GameType": "FH",
        "GameCode": "JILI-FISH-002",
        "GameName": "Bombing Fishing"
    },
    {
        "GameType": "FH",
        "GameCode": "JILI-FISH-003",
        "GameName": "Jackpot Fishing"
    },
    {
        "GameType": "FH",
        "GameCode": "JILI-FISH-004",
        "GameName": "Dinosaur Tycoon"
    },
    {
        "GameType": "FH",
        "GameCode": "JILI-FISH-005",
        "GameName": "Happy Fishing"
    },
    {
        "GameType": "FH",
        "GameCode": "JILI-FISH-006",
        "GameName": "Dragon Fortune"
    },
    {
        "GameType": "FH",
        "GameCode": "JILI-FISH-007",
        "GameName": "Mega Fishing"
    },
    {
        "GameType": "FH",
        "GameCode": "JILI-FISH-008",
        "GameName": "Boom Legend"
    },
    {
        "GameType": "FH",
        "GameCode": "JILI-FISH-009",
        "GameName": "All-star Fishing"
    },
    // Slot 51
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-001",
        "GameName": "Gem Party"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-002",
        "GameName": "Hot Chilli"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-003",
        "GameName": "Chin Shi Huang"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-004",
        "GameName": "War Of Dragons"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-005",
        "GameName": "Fortune Tree"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-006",
        "GameName": "Lucky Ball"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-007",
        "GameName": "Hyper Burst"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-008",
        "GameName": "Shanghai Beauty"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-009",
        "GameName": "Fa Fa Fa"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-010",
        "GameName": "God Of Martial"
    },
    // {
    //     "GameType": "SLOT",
    //     "GameCode": "JILI-SLOT-011",
    //     "GameName": ""
    // },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-012",
        "GameName": "Hawaii Beauty"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-013",
        "GameName": "SevenSevenSeven"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-014",
        "GameName": "Crazy777"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-015",
        "GameName": "Bubble Beauty"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-016",
        "GameName": "Bao boon chin"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-017",
        "GameName": "Crazy FaFaFa"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-018",
        "GameName": "XiYangYang"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-019",
        "GameName": "FortunePig"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-020",
        "GameName": "Candy Baby"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-021",
        "GameName": "DiamondParty"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-022",
        "GameName": "Fengshen"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-023",
        "GameName": "GoldenBank"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-024",
        "GameName": "Lucky Goldbricks"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-025",
        "GameName": "Dragon Treasure"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-026",
        "GameName": "Charge Buffalo"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-027",
        "GameName": "Super Ace"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-028",
        "GameName": "Jungle King"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-029",
        "GameName": "Money Coming"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-030",
        "GameName": "Golden Queen"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-031",
        "GameName": "Boxing King"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-032",
        "GameName": "Matka India"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-033",
        "GameName": "Dice"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-034",
        "GameName": "DragonTiger"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-035",
        "GameName": "SevenUpDown"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-036",
        "GameName": "Lucky Number"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-037",
        "GameName": "Lucky Coming"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-038",
        "GameName": "Pharaoh Treasure"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-039",
        "GameName": "Secret Treasure"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-040",
        "GameName": "RomaX"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-041",
        "GameName": "Super Rich"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-042",
        "GameName": "Golden Empire"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-043",
        "GameName": "Fortune Gems"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-044",
        "GameName": "Party Night"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-045",
        "GameName": "Crazy Hunter"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-046",
        "GameName": "Magic Lamp"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-047",
        "GameName": "TWINWINS"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-048",
        "GameName": "Agent Ace"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-049",
        "GameName": "Alibaba"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-050",
        "GameName": "Medusa"
    },
    {
        "GameType": "SLOT",
        "GameCode": "JILI-SLOT-051",
        "GameName": "Mega Ace"
    },
    // table 8
    {
        "GameType": "TABLE",
        "GameCode": "JILI-TABLE-001",
        "GameName": "Teen Patti"
    },
    {
        "GameType": "TABLE",
        "GameCode": "JILI-TABLE-002",
        "GameName": "AK47"
    },
    {
        "GameType": "TABLE",
        "GameCode": "JILI-TABLE-003",
        "GameName": "Andar Bahar"
    },
    {
        "GameType": "TABLE",
        "GameCode": "JILI-TABLE-004",
        "GameName": "Rummy"
    },
    {
        "GameType": "TABLE",
        "GameCode": "JILI-TABLE-005",
        "GameName": "NumberKing"
    },
    {
        "GameType": "TABLE",
        "GameCode": "JILI-TABLE-006",
        "GameName": "Poker King"
    },
    {
        "GameType": "TABLE",
        "GameCode": "JILI-TABLE-007",
        "GameName": "Big small"
    },
    {
        "GameType": "TABLE",
        "GameCode": "JILI-TABLE-008",
        "GameName": "iRich Bingo"
    },
]
const JILISlotGame = () => {
    const [loading, setLoading] = useState(false)
    const [listGames, setListGames] = useState(list_game)
    const [listGamesFiltter, setListGamesFiltter] = useState(list_game)
    const [gameAmount, setGameAmount] = useState(3);
    const [searchValue, setSearchValue] = useState('')
    const [alertClose, setAlertClose] = useState(false)

    useEffect(() => {
        resizeGame()
    }, [])

    const resizeGame = () => {
        let gameAmount = 4;
        let innerWidth = window.innerWidth;
        if (innerWidth > 620) {
            gameAmount = 4;
        } else {
            gameAmount = 2;
        }
        setGameAmount(gameAmount)
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value)
        const _listGames = listGames
        let result = _listGames.filter(gameItem => gameItem.GameName.toLowerCase().includes(e.target.value.toLowerCase()));
        setListGamesFiltter(result)
    }

    const handleChangeTab = (e, data) => {
        setSearchValue('')
        let _listGames = listGames
        let result = []
        if (data.activeIndex == 0) {
            setListGamesFiltter([...listGames])
        }
        if (data.activeIndex == 1) {
            result = _listGames.filter(gameItem => gameItem.GameType == 'SLOT');
            setListGamesFiltter([...result])
        }
        if (data.activeIndex == 2) {
            result = _listGames.filter(gameItem => gameItem.GameType == 'TABLE');
            setListGamesFiltter([...result])
        }
        if (data.activeIndex == 3) {
            result = _listGames.filter(gameItem => gameItem.GameType == 'FH');
            setListGamesFiltter([...result])
        }
    }

    const panes = [
        {
            menuItem: {
                content: <center className="tabSlotItem">
                    <Image src={require('../../../assets/images/home/slot_game_ecasino_2.png')} />
                    <div>เกมส์ทั้งหมด</div>
                </center>
            }
        },
        {
            menuItem: {
                content: <center className="tabSlotItem">
                    <Image src={require('../../../assets/images/home/slot_game_slot_2.png')} />
                    <div>สล็อต</div>
                </center>
            }
        },
        {
            menuItem: {
                content: <center className="tabSlotItem">
                    <Image src={require('../../../assets/images/home/slot_game_all_2.png')} />
                    <div>เกมโต๊ะ</div>
                </center>
            }
        },
        {
            menuItem: {
                content: <center className="tabSlotItem">
                    <Image src={require('../../../assets/images/home/slot_game_fish_2.png')} />
                    <div>ยิงปลา</div>
                </center>
            }
        }
    ]

    const handlePlayJILI = (GameCode, GameType) => {
        if (Config.ENABLE_JILI_SLOT == true) {
            GameService.playSlotJILI(GameCode, GameType).then((res) => {
                if (res.data.status == "0000") {
                    window.location.href = res.data.url
                } else {
                    setAlertClose(true)
                }
            }).catch(() => {
                setAlertClose(true)
            })
        }
    }

    return (
        <Container style={{ margin: 0, paddingBottom: 10, marginTop: 35 }}>
            <Segment loading={loading} style={{ boxShadow: 'unset', border: 0, marginBottom: 18, background: 'transparent' }}>

                <div className="contianer-tab-slot-games">
                    <Tab panes={panes} onTabChange={handleChangeTab} className="tabSlotGame tabSlotGame2" />
                </div>

                <div className="search-container">
                    <input placeholder="ค้นหา" onChange={handleSearchChange} value={searchValue} />
                    <Icon name="search" />
                </div>

                <Responsive onUpdate={resizeGame}>
                    <Card.Group itemsPerRow={gameAmount} className="CardGroup-custom home-card" >
                        {
                            listGamesFiltter?.map((item, index) => {
                                return (
                                    <a
                                        className="ui card jili_slot_games_styles"
                                        target="_blank"
                                        key={index}
                                        style={{ borderRadius: 0 }}
                                        onClick={() => { handlePlayJILI(item.GameCode, item.GameType) }}
                                    >
                                        <div
                                            style={{ backgroundImage: `url(${require(`../../../assets/images/home/jili/${item.GameName.replaceAll(' ', '')}.png`)})` }}
                                        >
                                        </div>
                                        <center style={{
                                            marginTop: -7,
                                            marginBottom: -2,
                                            overflow: 'hidden',
                                            height: 30,
                                            backgroundImage: `url(${require('../../../assets/images/home/name_game_slot.png')})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            color: '#fff',
                                        }}
                                            className="name-slot-game"
                                        >
                                            <span style={{ fontSize: '0.70em' }}>
                                                {item.GameName}
                                            </span>
                                        </center>
                                    </a>
                                )

                            })
                        }
                    </Card.Group>
                </Responsive>
            </Segment>

            <AlertClosed
                open={alertClose}
                onClose={() => setAlertClose(false)}
                name={"JILI"}
            />

        </Container >
    )
}

export default JILISlotGame