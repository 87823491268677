import React, { useEffect, useState } from "react";
import {
  Input,
  Card,
  Container,
  Tab,
  Header,
  Icon,
  Image,
  Label,
  Responsive,
  Segment,
  Modal,
  CardContent,
} from "semantic-ui-react";
import GameService from "../../../service/GameService";
import Authen from "../../../service/Authen";
import Config from "../../../config/config";

const SlotGames = () => {
  const [loading, setLoading] = useState(false);
  const [listGames, setListGames] = useState([]);
  const [listGamesFiltter, setListGamesFiltter] = useState([]);
  const [gameAmount, setGameAmount] = useState(3);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setLoading(true);
    GameService.getSlotGames().then((res) => {
      if (res.data.Error == "0") {
        resizeGame();
        setListGames(res.data.ListGames);
        setListGamesFiltter(res.data.ListGames);
        setLoading(false);
      }
    });
  }, []);

  const resizeGame = () => {
    let gameAmount = 4;
    let innerWidth = window.innerWidth;
    if (innerWidth > 620) {
      gameAmount = 4;
    } else {
      gameAmount = 2;
    }
    setGameAmount(gameAmount);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    const _listGames = listGames;
    let result = _listGames.filter((gameItem) =>
      gameItem.GameName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setListGamesFiltter(result);
  };

  const handleChangeTab = (e, data) => {
    setSearchValue("");
    let _listGames = listGames;
    let result = [];
    if (data.activeIndex == 0) {
      setListGamesFiltter([...listGames]);
    }
    if (data.activeIndex == 1) {
      result = _listGames.filter((gameItem) => gameItem.GameType == "Slot");
      setListGamesFiltter([...result]);
    }
    if (data.activeIndex == 2) {
      result = _listGames.filter((gameItem) => gameItem.GameType == "Bingo");
      setListGamesFiltter([...result]);
    }
    if (data.activeIndex == 3) {
      result = _listGames.filter((gameItem) => gameItem.GameType == "Fishing");
      setListGamesFiltter([...result]);
    }
    if (data.activeIndex == 4) {
      result = _listGames.filter((gameItem) => gameItem.GameType == "ECasino");
      setListGamesFiltter([...result]);
    }
  };

  const panes = [
    {
      menuItem: {
        content: (
          <center className="tabSlotItem">
            <Image
              src={require("../../../assets/images/home/slot_game_all_2.png")}
            />
            <div>เกมส์ทั้งหมด</div>
          </center>
        ),
      },
    },
    {
      menuItem: {
        content: (
          <center className="tabSlotItem">
            <Image
              src={require("../../../assets/images/home/slot_game_slot_2.png")}
            />
            <div>สล็อตแมชชีน</div>
          </center>
        ),
      },
    },
    {
      menuItem: {
        content: (
          <center className="tabSlotItem">
            <Image
              src={require("../../../assets/images/home/slot_game_bingo_2.png")}
            />
            <div>บิงโก</div>
          </center>
        ),
      },
    },
    {
      menuItem: {
        content: (
          <center className="tabSlotItem">
            <Image
              src={require("../../../assets/images/home/slot_game_fish_2.png")}
            />
            <div>ยิงปลา</div>
          </center>
        ),
      },
    },
    {
      menuItem: {
        content: (
          <center className="tabSlotItem">
            <Image
              src={require("../../../assets/images/home/slot_game_ecasino_2.png")}
            />
            <div>อีเกมส์</div>
          </center>
        ),
      },
    },
  ];

  return (
    <Container style={{ margin: 0, paddingBottom: 10, marginTop: 35 }}>
      <Segment
        loading={loading}
        style={{
          boxShadow: "unset",
          border: 0,
          marginBottom: 18,
          background: "transparent",
        }}
      >
        <div className="contianer-tab-slot-games">
          <Icon
            name="chevron right"
            style={{
              position: "absolute",
              color: "#FFF",
              right: -19,
              top: 32,
              fontSize: 18,
              Zindex: 0,
            }}
          />
          <Tab
            panes={panes}
            onTabChange={handleChangeTab}
            className="tabSlotGame"
          />
        </div>

        <div className="search-container">
          <input
            placeholder="ค้นหา"
            onChange={handleSearchChange}
            value={searchValue}
          />
          <Icon name="search" />
        </div>

        <Responsive onUpdate={resizeGame}>
          <Card.Group
            itemsPerRow={gameAmount}
            className="CardGroup-custom home-card"
          >
            {listGamesFiltter?.map((item, index) => {
              return (
                <a
                  className="ui card slot_games_styles"
                  target="_blank"
                  key={index}
                  href={`${
                    Config.JOKER.PLAY_URL
                  }/PlayGame?token=${Authen.getAcessToken()}&appid=${
                    Config.JOKER.APP_ID
                  }&gameCode=${
                    item.GameCode
                  }&language=en&mobile=false&redirectUrl=https://${
                    window.location.hostname
                  }/jokerslotgames`}
                >
                  <div
                    style={{ backgroundImage: `url(https:${item?.Image1})` }}
                  ></div>
                  <center
                    style={{
                      marginTop: -7,
                      marginBottom: -2,
                      overflow: "hidden",
                      height: 30,
                      backgroundImage: `url(${require("../../../assets/images/home/name_game_slot.png")})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      color: "#fff",
                    }}
                    className="name-slot-game"
                  >
                    <span style={{ fontSize: "0.70em" }}>{item.GameName}</span>
                  </center>
                </a>
              );
            })}
          </Card.Group>
        </Responsive>
      </Segment>
    </Container>
  );
};

export default SlotGames;
