import React, { useEffect, useState } from 'react';
import { Input, Card, Container, Tab, Header, Icon, Image, Label, Responsive, Segment, Modal, CardContent } from "semantic-ui-react";
import GameService from '../../../service/GameService';
import UserService from '../../../service/UserService';
import Authen from '../../../service/Authen';
import Config from '../../../config/config';
import crypto from 'crypto';

const PGSlotgame = () => {
    const [loading, setLoading] = useState(false)
    const [listGames, setListGames] = useState([])
    const [listGamesFiltter, setListGamesFiltter] = useState([])
    const [gameAmount, setGameAmount] = useState(3);
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        setLoading(true)
        GameService.getSlotPG().then((res) => {
            if (res.status) {
                resizeGame()
                setListGames(res.data)
                setListGamesFiltter(res.data)
                setLoading(false)
            }
        })
    }, [])

    const resizeGame = () => {
        let gameAmount = 4;
        let innerWidth = window.innerWidth;
        if (innerWidth > 620) {
            gameAmount = 4;
        } else {
            gameAmount = 2;
        }
        setGameAmount(gameAmount)
    };

    let date = new Date()
    date.setDate(date.getDate() + 1)
    let PGOperator_player_session = desEncrypt(JSON.stringify({ id: UserService.getUser()._id, exp: date }));

    function desEncrypt(message) {
        let enKey = 'pgpgpgpg';
        let key = enKey.length >= 8 ? enKey.slice(0, 8) : enKey.concat('0'.repeat(8 - enKey.length))
        const keyHex = new Buffer(key)
        const cipher = crypto.createCipheriv('des-cbc', keyHex, keyHex)
        let c = cipher.update(message, 'utf8', 'hex')
        c += cipher.final('hex')
        return c
    }

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value)
        const _listGames = listGames
        let result = _listGames.filter(gameItem => gameItem.gameName.toLowerCase().includes(e.target.value.toLowerCase()));
        setListGamesFiltter(result)
    }

    const renderImage = (gameId) => {
        try {
            return (
                <div
                    style={{ backgroundImage: `url(${require(`../../../assets/images/home/PG/${gameId}.png`)})` }}
                >
                </div>
            )
        } catch (err) {
            return (
                <div
                    style={{ backgroundImage: `url(${require(`../../../assets/images/home/PG/no-image-icon.png`)})` }}
                >
                </div>
            )
        }
    }

    return (
        <Container style={{ margin: 0, paddingBottom: 10, marginTop: 10 }}>
            <Segment loading={loading} style={{ boxShadow: 'unset', border: 0, marginBottom: 18, background: 'transparent' }}>

                <div className="search-container" st>
                    <input placeholder="ค้นหา" onChange={handleSearchChange} value={searchValue} />
                    <Icon name="search" />
                </div>

                <Responsive onUpdate={resizeGame}>
                    <Card.Group itemsPerRow={gameAmount} className="CardGroup-custom home-card" >
                        {
                            listGamesFiltter?.map((item, index) => {
                                return (
                                    <a
                                        className="ui card pg_slot_games_styles"
                                        key={index}
                                        href={`${Config.PG.PLAY_URL}/${item.gameId}/index.html?l=en&btt=1&ot=${Config.PG.OPERATOR_TOKEN}&ops=${PGOperator_player_session}`}
                                    >
                                        {renderImage(item.gameId)}
                                        <center style={{
                                            marginTop: -7,
                                            marginBottom: -2,
                                            overflow: 'hidden',
                                            height: 30,
                                            backgroundImage: `url(${require('../../../assets/images/home/name_game_slot.png')})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            color: '#fff',
                                        }}
                                            className="name-slot-game-pg"
                                        >
                                            <span style={{ fontSize: '0.70em' }}>
                                                {item.gameName}
                                            </span>
                                        </center>
                                    </a>
                                )

                            })
                        }
                    </Card.Group>
                </Responsive>
            </Segment>
        </Container >
    )

}

export default PGSlotgame