import React, {Component} from 'react'
import {Button, Card, Container, Grid, Header, Icon, Label, Menu, Responsive, Segment, Tab} from "semantic-ui-react";
import {Link} from "react-router-dom";
import HuayService from '../../../service/HuayService'

const asyncFunc = (event) => {
    return new Promise(resolve => {
        event();
        resolve();
    });
};

class HuayHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roundList:[],
            showLimit:5
        };
    }

    async componentDidMount() {
        await asyncFunc(() => {
            
        });
        this.props.isloading(true)
        HuayService.getRoundAll(this.props.huayId).then(res=>{
            if(res.status){
                let tempRoundList=[]
                res.data.forEach(roundItem => {
                   if(roundItem.result && roundItem.result != null && roundItem.result != ""){
                        tempRoundList.push({
                            title: this.props.titleRound,
                            roundName: roundItem.name,
                            result: [
                                {
                                    name: "รางวัลที่ 1",
                                    number: ""+roundItem.result.primaryNumber
                                },
                                {
                                    name: "สามตัวหน้า",
                                    number: roundItem.result.threeFrontNumber
                                },
                                {
                                    name: "สามตัวท้าย",
                                    number: roundItem.result.threeBackNumber
                                },
                                {
                                    name: "สองตัวล่าง",
                                    number: roundItem.result.twoNumber
                                },
                            ]
                        })
                   }
                });
                this.setState({roundList: tempRoundList})
            }
            this.props.isloading(false)
        })
    }

    getHuayList(){
        let result=[]
        for(var i=0;i<this.state.roundList.length;i++){
            if(i>=this.state.showLimit){break}else{
                result.push(this.state.roundList[i])
            }
        }
        return result
    }

    handleIncreaseShowLimit(){     
        this.props.isloading(true)
        setTimeout(()=>{
            this.setState((prevState)=>({showLimit: prevState.showLimit+5}))
            this.props.isloading(false)
        },1)
    }

    addZero(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
    }

    render() {
        return (
            <Responsive onUpdate={this.resizeBox}>
                {this.getHuayList().map((roundItem,key)=>(
                    <Card.Group itemsPerRow={1} key={key}>
                        <Card>
                            <Card.Content extra className="bg-white text-black">
                                <div style={{width:22,height:44,background:'rgb(243, 243, 243)', position:'absolute', left: -1, top: '35%', borderRadius: '0px 44px 44px 0px'}}></div>
                                <div style={{width:22,height:44,background:'rgb(243, 243, 243)', position:'absolute', right: -1, top: '35%', borderRadius: '44px 0px 0px 44px'}}></div>
                                <div style={{textAlign: 'center', marginBottom: 25,marginTop:5}}>
                                    <span style={{fontSize: '1.2em',fontWeight:400,borderBottom:'2.5px solid #CCC', paddingLeft:15, paddingRight:15}}>{new Date(roundItem.roundName).toLocaleDateString('th-TH',{year: 'numeric', month: 'long', day: 'numeric'})}</span>
                                    {this.props.showRoundTime && 
                                    <p style={{marginTop:5,color:'#AAA'}}>รอบ {new Date(roundItem.roundName).getHours()}.{this.addZero(new Date(roundItem.roundName).getMinutes())}น.</p>
                                    }
                                </div>
                                <Grid centered>
                                    <Grid.Row style={{paddingLeft:20,paddingRight:20}}>
                                        {roundItem.result.map((resultIteml,resultKey)=>(
                                            !Array.isArray(resultIteml.number) ? (resultIteml.number && (
                                                <Grid.Column computer='4' tablet='4' mobile='8' key={resultKey} style={{textAlign: 'center',paddingLeft:5,paddingRight:5,marginBottom:20}}>
                                                    <Button circular style={{marginBottom:10}} color={resultKey==0 ? 'red' : resultKey == 1 ? 'yellow' : resultKey == 2 ? 'facebook' : 'twitter'}>
                                                        {resultIteml.number}
                                                    </Button>
                                                    <p style={{marginBottom:0}}>{resultIteml.name}</p>
                                                    {/* <p style={{color:'#DDD', fontSize: '0.7em'}}>{resultIteml.number.split('').map(num=>'●')}</p> */}
                                                </Grid.Column>)
                                            ) : (resultIteml.number.length>0 && (
                                                <Grid.Column computer='4' tablet='4' mobile='8' key={resultKey} style={{textAlign: 'center',paddingLeft:5,paddingRight:5,marginBottom:20}}>
                                                    <Button circular style={{marginBottom:10}} color={resultKey==0 ? 'red' : resultKey == 1 ? 'yellow' : resultKey == 2 ? 'facebook' : 'twitter'}>
                                                        {resultIteml.number.join(' | ')}
                                                    </Button>
                                                    <p style={{marginBottom:0}}>{resultIteml.name}</p>
                                                    {/* <p style={{color:'#DDD', fontSize: '0.7em'}}>{resultIteml.number.split('').map(num=>'●')}</p> */}
                                                </Grid.Column>)
                                            )                                            
                                        ))}
                                    </Grid.Row>
                                </Grid>
                            </Card.Content>
                        </Card>
                    </Card.Group>
                ))}
                <br/>
                <center>
                    <Button positive onClick={()=>this.handleIncreaseShowLimit()} disabled={(this.state.showLimit>=this.state.roundList.length)}>โหลดเพิ่มเติม</Button>
                </center>
            </Responsive>
        );
    }
}

export default HuayHistory;
