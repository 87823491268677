import React from 'react';
import Authen from '../../service/Authen';
import UserService from '../../service/UserService';
import Config from "../../config/config";
import { Card, Modal, } from "semantic-ui-react";
import { Link } from "react-router-dom";
import crypto from 'crypto';

export default ({ open, onClose }) => {
    let date = new Date()
    date.setDate(date.getDate() + 1)
    let PGOperator_player_session = desEncrypt(JSON.stringify({ id: UserService.getUser()._id, exp: date }));
    // console.log(PGOperator_player_session)
    return (
        <Modal
            open={open}
            onClose={onClose}
            closeIcon='close'
            className="modal-slot-game"
        >
            <div className="modal-content-slot-game">
                <Card.Group itemsPerRow={2} className="content-card-marker">
                    <Card as={Link} to='/jokerslotgames'>
                        <img src={require('../../assets/images/home/joker.png')} style={{ width: '100%' }} />
                    </Card>
                    {/* <a className="ui card" href={`${Config.PG.PLAY_URL}/web-lobby/tournament/?operator_token=${Config.PG.OPERATOR_TOKEN}&operator_player_session=${PGOperator_player_session}&language=en`}>
                        <img src={require('../../assets/images/home/pg.png')} style={{ width: '100%' }} />
                    </a> */}
                    <Card as={Link} to='/pgslotgames'>
                        <img src={require('../../assets/images/home/pg.png')} style={{ width: '100%' }} />
                    </Card>
                    <Card as={Link} to='/kingmakerslotgames'>
                        <img src={require('../../assets/images/home/kingmaker.png')} style={{ width: '100%' }} />
                    </Card>
                    <Card as={Link} to='/awsslotgames'>
                        <img src={require('../../assets/images/home/aws.png')} style={{ width: '100%' }} />
                    </Card>
                </Card.Group>
            </div>
        </Modal>
    )
}

function desEncrypt(message) {
    let enKey = 'pgpgpgpg';
    let key = enKey.length >= 8 ? enKey.slice(0, 8) : enKey.concat('0'.repeat(8 - enKey.length))
    const keyHex = new Buffer(key)
    const cipher = crypto.createCipheriv('des-cbc', keyHex, keyHex)
    let c = cipher.update(message, 'utf8', 'hex')
    c += cipher.final('hex')
    return c
}