import Config from "../config/config";
import apiClient from './axios/apiClient'

export default class Authen {
    constructor() {
    }

    static async login(username, pass) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'auth/signin',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ "username": username, "password": pass })
        };

        const returnData = await apiClient(config).then(function (response) {
            localStorage.setItem('login', true);
            setAcessToken(response.data.token)
            localStorage.setItem('user', JSON.stringify(response.data.user))
            return { status: true }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });

        return await returnData
    }

    static async verifyLoginKey(LOGIN_KEY) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'auth/gettokenbymapautologin/' + LOGIN_KEY,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            setAcessToken(response.data.accessToken)
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });

        return await returnData
    }

    static async otpSMSReq(username, captcha) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'otp/sendsms',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ "username": username, "captcha": captcha })
        };

        const OTPdata = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });

        return OTPdata;
    }

    static async otpVerify(username, refcode, verifycode) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'otp/verify',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ username, refcode, verifycode })
        };

        const OTPdata = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });

        return OTPdata;
    }

    static async signup(username, password, otpVerifyID, parentId = "", source = "อื่นๆ", agentId = "") {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'auth/signup',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ username, password, otpVerifyID, parentId, source, agentId })
        };

        const returnData = await apiClient(config).then(function (response) {
            localStorage.setItem('login', true);
            setAcessToken(response.data.token)
            localStorage.setItem('user', JSON.stringify(response.data.user))
            return { status: true }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });

        return await returnData
    }

    static async otpSMSForgetpassword(username, captcha) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'otp/forgetpasswordSendSMS',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ "username": username, "captcha": captcha })
        };

        const OTPdata = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });

        return OTPdata;
    }

    static async forgetpassword(username, password, otpVerifyID) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'auth/resetpassword',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ username, newPassword: password, otpVerifyID })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });

        return await returnData
    }

    static async changePassword(newPassword, oldPassword) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'auth/changpassword',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: JSON.stringify({ newPassword, oldPassword })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });

        return await returnData
    }

    static getAcessToken() {
        let acessToken = localStorage.getItem('AcessToken')
        return acessToken
    }

    static async isAccount(userId) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'users/id/' + userId,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const returnData = await apiClient(config).then(function (response) {
            return { status: true, isAccount: (response.data.user && response.data.user != null) ? true : false }
        }).catch(function (error) {
            return { status: false }
        });
        return await returnData
    }
    static async logout() {
        return new Promise(resolve => {
            localStorage.removeItem("children")
            localStorage.removeItem("login")
            localStorage.removeItem("user")
            localStorage.removeItem("AcessToken")
            localStorage.removeItem("WalletBalance")
            resolve('pass')
        })
    }
}

const setAcessToken = (token) => {
    localStorage.setItem('AcessToken', token)
}