import React, {Component} from 'react'
import {Header} from "semantic-ui-react";

class ReportLowHighTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList:[]
        };
        
    }

    componentWillMount(){
        this.componentWillReceiveProps()
    }

    componentWillReceiveProps(){
        const {dataList} = this.props
        const maxNumOfItemTable=[0]
        let countNumOfItemTable=0
        // console.log(dataList)
        this.setState({dataList:dataList})

        this.tempDatalist=[];
        this.getDotTable = this.state.dataList.map((item,key) => {
            let type=(item.socre < 50) ?  'LOW' : 'HIGH'
            let indexOldtype = (key==0) ? key : key-1; 
            let oldtype = (this.state.dataList[indexOldtype].socre <50) ? 'LOW' : 'HIGH';
            if(type == oldtype){
                // console.log("add "+item.N)
                countNumOfItemTable++
                this.tempDatalist.push(item)
                if(key == (parseInt(this.state.dataList.length)-1)){
                    // console.log("render filnal 1")
                    // console.log(this.tempDatalist)
                    return (<><DotRow datalist={this.tempDatalist} /></>)
                }
            }else{
                maxNumOfItemTable.push(countNumOfItemTable)
                countNumOfItemTable=1

                let datalist = this.tempDatalist
                this.tempDatalist=[]
                this.tempDatalist.push(item)
                // console.log("add new "+item.N)
                // console.log(datalist)
                if(key == (parseInt(this.state.dataList.length)-1)){
                    // console.log("render filnal 2")
                    return (<><DotRow datalist={datalist} /><DotRow datalist={this.tempDatalist} /></>)
                }
                // console.log("render")
                return (<DotRow datalist={datalist} key={key} />)
            }
        })
        
        numOfItemTable=Math.max(...maxNumOfItemTable) > 7 ? Math.max(...maxNumOfItemTable) : 7
    }

    render() {
        return (
            <>
                <div className="lead-high-low d-flex justify-space-between" style={{margin: 10,border: '1px solid #e9e9e9'}}>
                   <div className="d-flex flex-row-reverse align-content-start lead-high-low-content" style={{flex:20, height: "140px"}}>
                        {this.state.dataList.length > 0 ? this.getDotTable : (<Header as='h4'>ไม่มีข้อมูลสติถิ</Header>)}
                   </div>
                </div>
            </>
        )
    }
}

let numOfItemTable=7

const DotRow = ({datalist})=>{
    
    const geNumberOfEmtyElm =()=>(numOfItemTable-parseInt(datalist.length));

    var tmp = [];
    
    for (var i = 1; i <= geNumberOfEmtyElm(); i++) {
        tmp.push(i);
    }

    var ElmEmty = tmp.map(function (i,k) {
        // console.log(geNumberOfEmtyElm())
        return (
            <DotItem type="" N="" key={k} />
        )
      })

    return (
        <>
            <div className="d-flex flex-column">
                {datalist.reverse().map((item,key) => {
                  let type=(item.socre < 50) ?  'LOW' : 'HIGH'
                  return <DotItem type={type} N={item.N} key={key} />  
                })}
                {ElmEmty}
            </div>
        </>
    )
}


const DotItem = ({type, N})=>{
    
    return (
        <div className={"dot "+type}>
            <p className="N">{N}</p>
        </div>
    )
}


export default ReportLowHighTable;