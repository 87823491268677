import React, { useState, useEffect } from 'react'
import { Button, Form, Image, Divider, Icon, Modal, Dropdown } from 'semantic-ui-react'
import { Link, useHistory, useParams } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import Authen from '../../service/Authen'
import WalletService from '../../service/WalletService';
import UserService from '../../service/UserService';
import SuccessPage from './SuccessPage';
import config from '../../config/config';
import ConfigValueService from '../../service/ConfigValueService'
import { BankObject } from '../view/profile/Setting';

const Register = (props) => {
    const history = useHistory();
    const [registerState, setRegisterState] = useState(1);
    const [tel, setTel] = useState("");
    const [otpRef, setOtpRef] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [otpVerifyID, setOtpVerifyID] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [submitText, setSubmitText] = useState("ขอ OTP");
    const [loadingPage, setLoadingPage] = useState(false);
    const [msgValidate, setMsgValidate] = useState("");
    const [showMsgValidate, setShowMsgValidate] = useState(false);
    const [visitedCount, setVisitedCount] = useState(0);
    const [onSuccess, setOnSuccess] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState("");
    const [init_af, set_init_af] = useState(0)
    const [bankName, setBankName] = useState("")
    const [bankID, setBankID] = useState("")
    const [statusBank, setStatusBank] = useState("check")
    const [bankOwner, setBankOwner] = useState("")
    const [lockOwnerName, setLockOwnerName] = useState(false)

    const parentId = useParams().parentId
    const agentId = useParams().agentId

    useEffect(() => {
        document.body.style.background = "#1b1b1b";
    }, [])

    useEffect(() => {
        if (!!parentId) {
            localStorage.setItem('parentId', parentId)
        }
        if (!!agentId) {
            localStorage.setItem('agentId', agentId)
        }
    }, [])

    useEffect(() => {
        if (parentId != null) {

            Authen.isAccount(parentId).then(res => {
                if (res.status && res.isAccount) {
                    //is Account
                    if (visitedCount == 0) {
                        UserService.incressVisitedCount(parentId).then(res => { if (res.status) { setVisitedCount(1) } })
                    }
                    return true
                }
                history.push("/register")
            })
        }
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoadingPage(true);
        setTimeout(function () {
            if (registerState === 1) { // OTP Req
                Authen.otpSMSReq(tel, recaptchaValue).then(res => {
                    if (res.status) {
                        setOtpRef(res.data.otp.refcode)
                        setRegisterState(2);
                        setSubmitText("ยืนยัน");
                        setShowMsgValidate(false)
                    } else {
                        setMsgValidate(res.msg)
                        setShowMsgValidate(true)
                    }
                })
                setLoadingPage(false);
            } else if (registerState === 2) {
                Authen.otpVerify(tel, otpRef, otpCode).then(res => {
                    if (res.status) {
                        setOtpVerifyID(res.data.otpVerifyID)
                        setRegisterState(4)
                        setSubmitText("ตรวจสอบข้อมูล");
                        setShowMsgValidate(false)
                    } else {
                        setMsgValidate(res.msg)
                        setShowMsgValidate(true)
                    }
                })
                setLoadingPage(false);
            } else if (registerState === 3) {
                const parent = (parentId) ? parentId : (localStorage.getItem('parentId') || null)
                const source = (parentId) ? "เพื่อนแนะนำ" : "อื่นๆ"
                const agent = (agentId) ? agentId : (localStorage.getItem('agentId') || null)
                Authen.signup(tel, password, otpVerifyID, parent, source, agent).then(res => {
                    if (res.status) {
                        setShowMsgValidate(false)
                        localStorage.removeItem('parentId')
                        localStorage.removeItem('agentId')
                        WalletService.fetchWalletBalance().then((res) => {
                            // Set open onsuccess page confrim login
                            UserService.saveBankAccount(bankName, bankID, bankOwner).then(() => {
                                if (res.status) {
                                    ConfigValueService.INIT_AF().then(async (res_init_af) => {
                                        if (res_init_af?.data?.value > 0) {
                                            await UserService.fetchMe()
                                            set_init_af(res_init_af?.data?.value)
                                            setOnSuccess(true)
                                        } else {
                                            await UserService.fetchMe()
                                            history.push("/home")
                                        }
                                    })
                                } else {
                                    setMsgValidate(res.msg)
                                    setShowMsgValidate(true)
                                }

                            })
                        })
                    } else {
                        setMsgValidate(res.msg)
                        setShowMsgValidate(true)
                        setLoadingPage(false);
                    }
                })
            } else if (registerState === 4) {
                setLoadingPage(true);
                UserService.checkBankRegister(bankName, bankID, otpVerifyID).then(async (res) => {
                    if (res.status) {
                        if (res.data?.status == "0000") {
                            setRegisterState(3)
                            setSubmitText("สมัครสมาชิก");
                            setStatusBank("save")
                            setShowMsgValidate(false)
                            setLockOwnerName(true)
                            setLoadingPage(false)
                            setBankOwner(res.data?.data?.accountName || "")
                        } else if (res.data?.status == "5009" || res.data?.status == "9999") {
                            setShowMsgValidate(true)
                            setMsgValidate(res.data.message)
                            setLoadingPage(false)
                        } else if (res.data?.status == "0401") {
                            setRegisterState(1);
                            setBankID("")
                            setBankName("")
                            setOtpCode("")
                            setSubmitText("ขอ OTP");
                            setLoadingPage(false)
                        } else if (res.data?.status == "5000") {
                            setRegisterState(3)
                            setSubmitText("สมัครสมาชิก");
                            setStatusBank("save")
                            setShowMsgValidate(false)
                            setLockOwnerName(false)
                            setLoadingPage(false)
                        } else {
                            setShowMsgValidate(false)
                            setBankOwner(res.data?.accountName || "")
                            setStatusBank("save")
                            setLockOwnerName(false)
                            setLoadingPage(false)
                        }
                    }
                    else {
                        setMsgValidate(res.msg)
                        setLoadingPage(false)
                    }
                })
            }

        }, 1000);
    };

    const onSubmit = () => {
        history.push("/home")
        setOnSuccess(false)
    }

    const validateForm = () => {
        if (registerState === 1) {
            return tel.length === 10 && !(recaptchaValue == "" || recaptchaValue == null);
        }
        if (registerState === 2) {
            return tel.length === 10 && otpCode != "";
        }
        if (registerState === 3) {
            return tel.length === 10 && password === confirmPassword && password.length >= 6 && password.length <= 20;
        }
        if (registerState === 4) {
            return bankID != "" && bankName != "";
        }
        return tel.length === 10 && password === confirmPassword;
    };

    return (
        <div className="login-page">

            <div className="login-box">

                <Image src={require('../../assets/images/head_logo.png')} size='medium' centered style={{ 'padding': '20px' }} />
                <Form className="login-form" loading={loadingPage}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: 20, position: "relative", marginBottom: 5 }}>
                        <div style={{ position: "absolute", left: 25, bottom: 0, fontSize: 12 }}>ยืนยัน OTP</div>
                        <div style={{ position: "absolute", bottom: 0, fontSize: 12 }}>บัญชีธนาคาร</div>
                        <div style={{ position: "absolute", right: 32, bottom: 0, fontSize: 12 }}>สำเร็จ</div>
                        <div className="step-container"
                            style={registerState === 2 || registerState === 1 ? { background: "rgb(255,217,167)" } : (registerState == 4 || registerState == 3 ? { background: "rgb(100,255,111)" } : {})}>
                            {
                                registerState == 4 || registerState == 3 ?
                                    <Icon name="check" color="#005f16" style={{ marginRight: 3 }} />
                                    :
                                    1
                            }
                        </div>
                        <div>------------</div>
                        <div className="step-container" style={registerState == 4 || registerState == 3 ? { background: "rgb(255,217,167)" } : {}}>2</div>
                        <div>------------</div>
                        <div className="step-container" >3</div>
                    </div>
                    <center><p style={{ marginBottom: '5px', color: "red", display: showMsgValidate ? "block" : "none" }}>* {msgValidate}</p></center>
                    <Form.Field style={{ display: registerState === 2 || registerState === 1 ? 'inherit' : 'none' }}>
                        <label style={registerState === 1 ? { color: '#DB2828' } : {}}>{registerState === 1 ? 'สมัครสมาชิก (กรอกเบอร์โทรศัพท์)' : 'เบอร์โทรศัพท์'}</label>
                        <input className="login-input" placeholder='เบอร์โทรศัพท์' disabled={registerState === 2} onChange={e => setTel(e.target.value)} />
                        <Form.Field style={{ display: registerState === 1 ? 'inherit' : 'none' }}>
                            <center>
                                <ReCAPTCHA style={{ marginTop: 15 }} size='normal'
                                    sitekey={config.RECAPTCHA_SITE_KEY}
                                    onChange={(value) => setRecaptchaValue(value)}
                                />
                            </center>
                        </Form.Field>
                        {/* <Modal style={{ maxWidth: 'unset' }} size='big' closeIcon trigger={<div style={{cursor: 'pointer'}}><Icon name='info' circular size='small' inverted color='blue' />วิธีสมัครสมาชิก</div>}>
                            <Modal.Content><br/>
                                <video width="100%" controls>
                                    <source src={require('../../assets/video/Register.mp4')} type="video/mp4" />
                                    Your browser does not support HTML video.
                                </video>
                            </Modal.Content>
                        </Modal> */}
                    </Form.Field>
                    <Form.Field style={{ display: registerState === 2 ? 'inherit' : 'none' }}>
                        <label>OTP (หมายเลขอ้างอิง : {otpRef})</label>
                        <input className="login-input" placeholder='หมายเลข OTP จาก SMS' type='text' onChange={e => setOtpCode(e.target.value)} />
                        <center><p style={{ color: "orange", marginTop: 5 }}>OTP จะหมดเวลาใน 5นาที</p></center>
                    </Form.Field>
                    <Form.Field style={{ display: registerState === 4 || registerState === 3 ? 'inherit' : 'none' }}>
                        <Form.Field required>
                            <label >ชื่อธนาคาร</label>
                            <Dropdown
                                placeholder='Select Bank'
                                fluid
                                selection
                                options={BankObject.bankAccountList}
                                onChange={(e, data) => setBankName(data.value)}
                                value={bankName}
                                className="select-bank"
                                disabled={statusBank == "save"}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>เลขธนาคาร</label>
                            <input
                                placeholder=''
                                type="number"
                                onChange={(event) => {
                                    const pattern = /^[0-9]*$/
                                    if (pattern.test(event.target.value)) {
                                        setBankID(event.target.value)
                                    }
                                }}
                                value={bankID}
                                disabled={statusBank == "save"}
                            />
                        </Form.Field>

                        {
                            statusBank == "save" &&
                            <Form.Field required>
                                <label>ชื่อเจ้าของธนาคาร</label>
                                <input placeholder='' onChange={(event) => setBankOwner(event.target.value)} value={bankOwner} disabled={lockOwnerName} />
                            </Form.Field>
                        }

                    </Form.Field>
                    <Form.Field style={{ display: registerState === 3 ? 'inherit' : 'none' }}>
                        <label>ตั้งรหัสผ่าน</label>
                        <input className="login-input" placeholder='ตัวอักษรตัวเลขหรืออักขระ 6ตัวขึ้นไป' type='password' onChange={e => setPassword(e.target.value)} />
                    </Form.Field>
                    <Form.Field style={{ display: registerState === 3 ? 'inherit' : 'none' }}>
                        <label>ยืนยันรหัสผ่าน</label>
                        <input className="login-input" placeholder='กรอกรหัสผ่านอีกครั้ง' type='password' onChange={e => setConfirmPassword(e.target.value)} />
                    </Form.Field>

                    <Button type='submit' color='green' disabled={!validateForm()} onClick={(event) => handleSubmit(event)}>{submitText}</Button>
                    {/* <Button type='button' color='primary' disabled={!validateForm()} onClick={handleSubmit}>{submitText}</Button> */}
                    <br />
                    <Divider horizontal style={{ color: '#C0C0C0', fontWeight: 300, marginBottom: 0 }}>
                        {/* หากมีบัญชีแล้ว */}
                    </Divider>
                    <Button onClick={() => { history.push('/') }} style={{ background: '#999' }} type='button' fluid>
                        มีไอดีแล้ว
                    </Button>

                    <Modal
                        open={onSuccess}
                        closeIcon
                        onClose={() => onSubmit()}
                    >
                        <Modal.Header>
                            ยินดีด้วย
                        </Modal.Header>
                        <Modal.Content>
                            <center style={{ fontSize: 15 }}>
                                คุณได้ AF ฟรี {init_af} บาท
                            </center>
                        </Modal.Content>
                        <Modal.Actions>
                            <center>
                                <Button style={{ background: '#4CAE3C', borderRadius: '30px', paddingInlineStart: '50px', paddingInlineEnd: '50px' }} type='button' color='primary' onClick={() => {
                                    onSubmit()
                                }}>
                                    ตกลง
                                </Button>
                            </center>
                        </Modal.Actions>
                    </Modal>

                </Form>
            </div>
        </div>
    );
}

export default Register;