import React, { useEffect, useState } from 'react';
import { Input, Card, Container, Tab, Header, Icon, Image, Label, Responsive, Segment, Modal, CardContent } from "semantic-ui-react";
import GameService from '../../../service/GameService';
import Authen from '../../../service/Authen';
import Config from '../../../config/config';
import AlertClosed from './AlertClosed';

const KingMakerSlotGame = () => {
    const [loading, setLoading] = useState(false)
    const [listGames, setListGames] = useState([])
    const [listGamesFiltter, setListGamesFiltter] = useState([])
    const [gameAmount, setGameAmount] = useState(3);
    const [searchValue, setSearchValue] = useState('')
    const [alertClose, setAlertClose] = useState(false)

    useEffect(() => {
        setLoading(true)
        GameService.getSlotKingmaker().then((res) => {
            if (res.status) {
                resizeGame()
                setListGames(res.data)
                setListGamesFiltter(res.data)
                setLoading(false)
            }
        })
    }, [])

    const resizeGame = () => {
        let gameAmount = 4;
        let innerWidth = window.innerWidth;
        if (innerWidth > 620) {
            gameAmount = 4;
        } else {
            gameAmount = 2;
        }
        setGameAmount(gameAmount)
    };

    const handlePlayKingmaker = (gameCode) => {
        if (Config.ENABLE_KINGMAKER_SLOT == true) {
            GameService.playSlotKingmaker(gameCode).then((res) => {
                if (res.data.status == "0000") {
                    window.location.href = res.data.url
                } else {
                    setAlertClose(true)
                }
            }).catch(() => {
                setAlertClose(true)
            })
        }
    }
    return (
        <Container style={{ margin: 0, paddingBottom: 10, marginTop: 35 }}>
            <Segment loading={loading} style={{ boxShadow: 'unset', border: 0, marginBottom: 18, background: 'transparent' }}>

                <Responsive onUpdate={resizeGame}>
                    <Card.Group itemsPerRow={gameAmount} className="CardGroup-custom home-card" >
                        {
                            listGamesFiltter?.map((item, index) => {
                                return (
                                    <a
                                        className="ui card kingmarker_slot_games_styles"
                                        key={index}
                                        onClick={() => { handlePlayKingmaker(item.gameCode) }}
                                    >
                                        <div
                                            style={{ backgroundImage: `url(${require(`../../../assets/images/home/kingmakergame/${item.gameName.replaceAll(' ', '')}.jpg`)})` }}
                                        >
                                        </div>
                                        <center style={{
                                            marginTop: -7,
                                            marginBottom: -2,
                                            overflow: 'hidden',
                                            height: 30,
                                            backgroundImage: `url(${require('../../../assets/images/home/name_game_slot.png')})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            color: '#fff',
                                        }}
                                            className="name-slot-game"
                                        >
                                            <span style={{ fontSize: '0.70em' }}>
                                                {item.gameName}
                                            </span>
                                        </center>
                                    </a>
                                )

                            })
                        }
                    </Card.Group>
                </Responsive>
            </Segment>

            <AlertClosed
                open={alertClose}
                onClose={() => setAlertClose(false)}
                name={"King Maker"}
            />
        </Container >
    )

}

export default KingMakerSlotGame