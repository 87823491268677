import React, { Component } from 'react'
import { Button, Card, Container, Grid, Header, Icon, Image, Label, Responsive, Segment, Modal, CardContent, Dimmer, Loader } from "semantic-ui-react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import WalletMoney from "../wallet/WalletMoney";
import WalletHeader from "../wallet/WalletHeader";
import { Link, useHistory } from "react-router-dom";
import CardLottery from '../lottery/CardLottery';
import YiiGiiService from '../../../service/YiiGiiService';
import HuayService from '../../../service/HuayService';
import GameService from "../../../service/GameService"
import PromotionSlideService from '../../../service/PromotionSlideService';
import WalletService from '../../../service/WalletService';
import UserService from '../../../service/UserService';
import TopUpCreditByAccount from "../../view/wallet/TopUpCreditByAccount";
import WithdrawCredit from "../../view/wallet/WithdrawCredit";
import WithdrawAF from "../../view/wallet/WithdrawAF";
import { CreditIcon, AFIcon } from '../../../assets/images/svg/svg-icon'
import Config from "../../../config/config";
import ModalSlotGames from '../../layout/ModalSlotGames';
import ModalCasino from '../../layout/ModalCasino';
import ModalSport from '../../layout/ModalSport';
import AlertClosed from './AlertClosed';

const asyncFunc = (event) => {
    return new Promise(resolve => {
        event();
        resolve();
    });
};

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promotionSlide: [],
            gameHot: 2,
            contentVisible: false,
            YiiGii: [],
            huay: [],
            onLoading: false,
            wallet: WalletService.getWalletBalance(),
            User: UserService.getUser(),
            openTopUpCreditByAccount: false,
            openWithdrawCredit: false,
            openWithdrawAF: false,
            loadingCreadit: false,
            gameHuakoi: { isEnabled: false },
            gameHilo: { isEnabled: false },
            gamePaoyingchub: { isEnabled: false },
            open: false,
            isOpenModel: false,
            isOpenModelAnnounce: false,
            announce: '',
            isOpenModelCasino: false,
            isOpenModelSport: false,
            bonusEnable: false,
            alertClose: false,
            loading_lotto_new: false,
            siamEnable: false
        };
    }

    async componentDidMount() {
        await asyncFunc(() => {
        });
        this.resizeGame();
        this.initData();
        this.syncWallet()
    }

    async initData() {
        // load PromotionSlide data
        // PromotionSlideService.getPromotionSlide().then(res => {
        //     if (res.status) {
        //         let promotion = res.data.filter((item) => item.isActive == '1' && item.isPopup == '0')
        //         this.setState({ promotionSlide: promotion })
        //         let announce = res.data.filter((item) => item.isActive == '1' && item.isPopup == '1')
        //         if (announce.length > 0) {
        //             this.setState({ announce: announce[0], isOpenModelAnnounce: true })
        //         }
        //     }
        // })
        await this.setState({ onLoading: true })

        await UserService.getHomeConfig().then((res) => {
            if (res.status) {
                let promotion = res.data.promotionSlide.filter((item) => item.isActive == '1' && item.isPopup == '0')
                this.setState({ promotionSlide: promotion })
                let announce = res.data.promotionSlide.filter((item) => item.isActive == '1' && item.isPopup == '1')
                if (announce.length > 0) {
                    this.setState({ announce: announce[0], isOpenModelAnnounce: true })
                }
                this.setState({ bonusEnable: res.data.dailyRewardGame.enable })
                this.setState({ siamEnable: res.data.enableSiamLot })
            }
        })

        //load huay
        // await YiiGiiService.getYiiGii().then((res) => {
        //     if (res.status) {
        //         this.setState({ YiiGii: res.data })
        //     }
        // })
        // await HuayService.getHuayPlaylist().then(res => {
        //     if (res.status) {
        //         this.setState({ huay: res.data })
        //     }
        // })
        // await GameService.getGame().then(res => {
        //     if (res.status) {
        //         this.setState({
        //             gameHuakoi: res.data.huakoi,
        //             gameHilo: res.data.hilo,
        //             gamePaoyingchub: res.data.paoyingchub,
        //             loading: false
        //         })
        //     }
        // })
        this.setState({ onLoading: false })
    }

    syncWallet = () => setInterval(() => {
        this.setState({ wallet: WalletService.getWalletBalance() });
    }, 3000);

    handleOpenTopUpCreditByAccount = () => {
        this.setState({ openTopUpCreditByAccount: true });
    };

    handleCloseTopUpCreditByAccount = () => {
        this.setState({ openTopUpCreditByAccount: false });
    };

    handleOpenWithdrawCredit = () => {
        this.setState({ loadingCreadit: true })
        WalletService.fetchWalletBalance().then(res => {
            this.setState({ loadingCreadit: false, openWithdrawCredit: true });
        })
    };

    handleCloseWithdrawCredit = () => {
        this.setState({ openWithdrawCredit: false });
    };

    closeWithdrawAF = () => this.setState({ openWithdrawAF: false })


    getSuggestedYiiGiiList = () => this.state.YiiGii.filter(value => value.isSuggested == 1)
    getSuggestedHuayList = () => this.state.huay.filter(value => value.isSuggested == 1)

    resizeGame = () => {
        let gameHot = 2;
        let innerWidth = window.innerWidth;
        if (innerWidth > 1080) {
            gameHot = 2;
        } else if (innerWidth > 620) {
            gameHot = 2;
        } else {
            gameHot = 1;
        }
        this.setState((prevState) => ({ gameHot: gameHot }))
    };

    handleOpenContent = (PromotionIndex) => {
        let newState = this.state.promotionSlide
        newState[PromotionIndex].contentVisible = true
        this.setState({ promotionSlide: newState })
        // this.setState({contentVisible: true});
    };

    handleCloseContent = (PromotionIndex) => {
        let newState = this.state.promotionSlide
        newState[PromotionIndex].contentVisible = false
        this.setState({ promotionSlide: newState })
        // this.setState({contentVisible: false});
    };

    getDurationToTime = (duration) => {
        var timer = duration, minutes, seconds;
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        if (--timer < 0) {
            timer = duration;
        }

        return [minutes, seconds]
    }

    toTwoDecimal = (num) => {
        let num_new = (parseInt(Number(Number((Number(num) * 10).toFixed(3)) * 10).toFixed(3)) / 100.00)
        let _num = num_new.toString().split('.')
        if (_num.length > 1) {
            return (_num[0] + '.' + (_num[1].length < 2 ? `${_num[1].substring(0, 2)}0` : _num[1].substring(0, 2))).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
            return (_num[0] + '.00').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }


    render() {
        return (
            <>
                <Card className="promote-slide">
                    <marquee width="100%" direction="left" height="100%" behavior="scroll" scrollamount="3">
                        {
                            this.state.promotionSlide ?
                                this.state.promotionSlide.map((item, key) => {
                                    return <a href={item.url} key={key} target='_blank' style={{ color: '#FFF' }}> || {item.description} || </a>
                                })
                                :
                                '--- ยินดีต้อนรับ ---'
                        }
                    </marquee>
                </Card>
                <Container style={{ marginTop: 0, paddingBottom: 10 }}>
                    {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <a target="_blank" href={"https://www.mylotto111.com/how-to-play"} style={{ color: "#049650" }} >กติกาการเล่น</a>
                    <p style={{ color: "#000", padding: "0px 15px", margin: 0, fontWeight: 500 }}>|</p>
                    <a target="_blank" href={Config.LineURL} style={{ color: "#049650" }} >ติดต่อเรา</a>
                </div> */}

                    <Dimmer active={this.state.loading_lotto_new}>
                        <Loader content='กำลังโหลด' />
                    </Dimmer>

                    <Card className="card-wallet">
                        <Card.Content className="card-wallet-content">
                            <div>
                                <div style={{ fontSize: '1.2rem', fontWeight: 400 }}>ID</div>
                                <div style={{ fontSize: '1.2rem', fontWeight: 400, marginTop: -2 }}>{this.state.User.username}</div>
                                <div style={{ fontSize: '2.8rem', padding: '8px 0', fontWeight: 500 }}>{this.toTwoDecimal(this.state.wallet.credit)} บาท</div>
                                <div style={{ fontSize: '1.2rem', fontWeight: 400 }}>AF {this.toTwoDecimal(this.state.wallet.af)}</div>
                            </div>

                        </Card.Content>
                        {/* <Card.Content style={{ display: 'flex', alignItems: 'center', padding: '0.6em 1em 0em 1em' }}>
                            <Card.Header className="container-box-user-icon" style={{ width: '60%' }}>
                                <div style={{ alignItems: 'center', display: 'flex' }}>
                                    <CreditIcon className="box-user-icon" width="70" height="60" />
                                    <div >
                                        <span className="box-user" style={{ fontWeight: 400, fontSize: '1.5rem', color: '#F6CF3D', marginLeft: 10 }}>ชื่อผู้ใช้งาน</span><br />
                                        <span className="box-user" style={{ fontWeight: 300, fontSize: '1rem', color: '#F6CF3D', marginLeft: 10 }}>{this.state.User.username}</span>
                                    </div>
                                </div>
                            </Card.Header>


                        </Card.Content>
                        <Card.Content className="container-box-wallet-home" style={{ borderTop: '0px', display: 'flex', alignItems: 'flex-end', padding: '0.6em 1em' }}>
                            <Card.Header style={{ width: '70%' }}>
                                <p className="box-wallet-amount" style={{ color: '#FFFFFF', fontSize: '3rem', fontWeight: 500, marginBottom: '0px' }}>฿ {this.toTwoDecimal(this.state.wallet.credit)}</p>
                                <p className="box-wallet-amount-name" style={{ color: '#F6CF3D', fontSize: '1.5rem', fontWeight: 400 }}>ยอดเงินคงเหลือ</p>
                            </Card.Header>
                            <Card.Header className="container-box-af-icon-home" style={{ width: '30%', textAlign: 'right', marginBottom: 5 }}>
                                <AFIcon width='20' height='20' className="box-af-icon-home" />
                                <span className="box-af-home" style={{ color: '#FBDA61', fontSize: '1.3rem', fontWeight: 300 }}>  {this.toTwoDecimal(this.state.wallet.af)}</span>
                                <p className="box-af" style={{ color: '#FBDA61', fontSize: '0.8rem', fontWeight: 300, marginTop: -5, paddingTop: 5 }}>AF คงเหลือ</p>
                            </Card.Header>
                        </Card.Content> */}
                    </Card >

                    <Segment loading={this.state.onLoading} style={{ boxShadow: 'unset', border: 0, marginTop: 35, marginBottom: 18, background: 'transparent' }} >
                        {!this.state.onLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Card className="card-wallet-come-out" style={{ paddingTop: 5, paddingBottom: 5, borderRadius: 40, justifyContent: 'center', marginTop: -33, marginBottom: 15, width: 450, background: 'transparent' }}>
                                    <Card.Header style={{ display: 'flex', justifyContent: 'center', paddingLeft: 5 }}>
                                        <Button
                                            color='green'
                                            style={{
                                                paddingRight: 25,
                                                paddingLeft: 25,
                                                borderRadius: 13,
                                                background: 'var(--background-wallet-come)',
                                                color: 'var(--color-wallet-come)',
                                                border: '2px solid var(--border-wallet-come)',
                                                width: '40%',
                                                marginRight: '0.3em',
                                            }}
                                            onClick={() => {
                                                this.handleOpenTopUpCreditByAccount()
                                            }}>
                                            <Icon name='plus square outline' /> เติมเงิน
                                        </Button>
                                        <Button
                                            color='red'
                                            style={{
                                                paddingRight: 25,
                                                paddingLeft: 25,
                                                borderRadius: 13,
                                                background: 'var(--background-wallet-out)',
                                                color: 'var(--color-wallet-out)',
                                                border: '2px solid var(--border-wallet-out)',
                                                width: '40%',
                                                marginLeft: '0.3em',
                                            }}
                                            onClick={() => {
                                                this.handleOpenWithdrawCredit()
                                            }}>
                                            <Icon name='minus square outline' />ถอนเงิน
                                        </Button>
                                    </Card.Header>
                                </Card>
                                {this.state.openTopUpCreditByAccount &&
                                    <TopUpCreditByAccount openTopUpCreditByAccount={this.state.openTopUpCreditByAccount}
                                        handleCloseTopUpCreditByAccount={this.handleCloseTopUpCreditByAccount} />
                                }
                                {this.state.openWithdrawCredit &&
                                    <WithdrawCredit openWithdrawCredit={this.state.openWithdrawCredit}
                                        handleCloseWithdrawCredit={this.handleCloseWithdrawCredit} />
                                }
                                <WithdrawAF openWithdrawAF={this.state.openWithdrawAF} closeWithdrawAF={this.closeWithdrawAF} />
                            </div>) : ''}

                        <Responsive onUpdate={this.resizeGame}>
                            <Card.Group itemsPerRow={2} className="CardGroup-custom home-card">

                                {
                                    this.state.bonusEnable &&
                                    <Card className={!this.state.siamEnable ? "bonus_bg_2" : "bonus_bg"} as={Link} to='/bonus' />
                                }

                                {
                                    this.state.siamEnable &&
                                    <Card className={!this.state.bonusEnable ? "lotto_new_2_bg" : "lotto_new_bg"}
                                        as={Link} to='/lottery'
                                    />
                                }

                                <AlertClosed
                                    open={this.state.alertClose}
                                    onClose={() => this.setState({ alertClose: true })}
                                    name={"LOTTOSOD NEW"}
                                />

                                <Card
                                    className={"huay_group_bg"}
                                    onClick={() => {
                                        if (this.state.siamEnable) {
                                            this.setState({ loading_lotto_new: true })
                                            GameService.playSiam(`https://${window.location.hostname}`).then((res) => {
                                                if (res?.data?.status?.code == "200") {
                                                    window.location.href = res?.data?.data?.url
                                                    setTimeout(() => {
                                                        this.setState({ loading_lotto_new: false })
                                                    }, 1500)
                                                }
                                                else {
                                                    this.setState({ loading_lotto_new: false })
                                                    this.setState({ alertClose: true })
                                                }
                                            }).catch(() => {
                                                this.setState({ loading_lotto_new: false })
                                                this.setState({ alertClose: true })
                                            })
                                        }
                                    }}
                                    as={this.state.siamEnable ? undefined : Link}
                                    to={this.state.siamEnable ? undefined : "/lottery"}
                                >
                                </Card>
                                <Card className="slot_bg" as={Link} to='/slot'>

                                </Card>
                                <Card className="casino_bg" as={Link} to='/casino'>

                                </Card>
                                <Card className="sport_bg" as={Link} to='/sport'>

                                </Card>

                            </Card.Group>
                        </Responsive>

                    </Segment>

                    <Segment style={{
                        boxShadow: 'unset',
                        marginBottom: 10,
                        border: 0,
                        background: 'rgba(18, 18, 18, 1)',
                        padding: '2em 1em 1em',
                        marginTop: 10,
                        borderRadius: 40,
                        width: 'calc(100% + 2em)',
                        marginLeft: '-1em'
                    }} >
                        <Card.Group itemsPerRow={3} style={{}}>
                            <div className="card-bottom-container">
                                <Card className="card-bottom" as={Link} to='/history' style={{ background: 'rgba(38, 155, 0, 1)' }}>
                                    <Card.Content >
                                        <img src={require('../../../assets/images/home/history_all.png')} className={'card-bottom-icon-history-all'} />
                                    </Card.Content>
                                </Card>
                                <p>รายการ</p>
                            </div>
                            <div className="card-bottom-container">
                                <Card className="card-bottom" as={Link} to='/profile' style={{ background: 'rgba(0, 168, 221, 1)' }}>
                                    <Card.Content >
                                        <img src={require('../../../assets/images/home/me.png')} className={'card-bottom-icon'} />
                                    </Card.Content>
                                </Card>
                                <p>ตัวฉัน</p>
                            </div>
                            <div className="card-bottom-container">
                                <Card className="card-bottom" as={Link} to='/profile/1' style={{ background: 'rgba(30, 79, 156, 1)' }}>
                                    <Card.Content >
                                        <img src={require('../../../assets/images/home/invite_icon_white.png')} className={'card-bottom-icon-2'} />
                                    </Card.Content>
                                </Card>
                                <p>แนะนำเพื่อน</p>
                            </div>
                            <div className="card-bottom-container">
                                <Card className="card-bottom" as={Link} to='/favoriteBill' style={{ background: 'rgba(255, 168, 0, 1)' }}>
                                    <Card.Content >
                                        <Icon name='list ol' className={'card-bottom-sigle-icon-rule'} />
                                    </Card.Content>
                                </Card>
                                <p>สร้างเลขชุด</p>
                            </div>
                            <div className="card-bottom-container">
                                <a className="ui card card-bottom" target={'_blank'} href={Config.LineURL} style={{ background: 'rgba(234, 2, 41, 1)' }}>
                                    <Card.Content style={{ padding: '0em' }}>
                                        <img src={require('../../../assets/images/home/service_icon_line.png')} className={'card-bottom-icon-service'} />
                                    </Card.Content>
                                    <div style={{ color: '#FFF', position: 'absolute', bottom: 5, fontSize: 11 }}>{Config.LineID}</div>
                                </a>
                                <p>ติดต่อแอดมิน</p>
                            </div>
                            <div className="card-bottom-container">
                                <Card className="card-bottom" as={Link} to='/huayhistory' style={{ background: 'rgba(18, 0, 62, 1)' }}>
                                    <Card.Content >
                                        <Icon name='question circle outline' className={'card-bottom-sigle-icon-rule'} />
                                    </Card.Content>
                                </Card>
                                <p>ดูผลหวย</p>
                            </div>
                        </Card.Group>

                    </Segment>

                    {/* <Segment style={{
                    boxShadow: 'unset',
                    marginBottom: 10,
                    border: 0,
                    background: 'transparent',
                    marginTop: 10
                }} >
                    <Card.Group itemsPerRow={2} style={{}}>
                        <Card className="card-bottom" as={Link} to='/history' style={{ background: 'rgba(38, 155, 0, 1)' }}>
                            <Card.Content >
                                <img src={require('../../../assets/images/home/history_all.png')} className={'card-bottom-icon-history-all'} />
                                <p>ประวัติการทำรายการ</p>
                            </Card.Content>
                        </Card>
                        <Card className="card-bottom" as={Link} to='/profile' style={{ background: 'rgba(0, 168, 221, 1)' }}>
                            <Card.Content >
                                <img src={require('../../../assets/images/home/me.png')} className={'card-bottom-icon'} />
                                <p>ตัวฉัน</p>
                            </Card.Content>
                        </Card>
                        <Card className="card-bottom" as={Link} to='/favoriteBill' style={{ background: 'rgba(255, 168, 0, 1)' }}>
                            <Card.Content >
                                <Icon name='list ol' className={'card-bottom-sigle-icon-rule'} />
                                <p>สร้างเลขชุด</p>
                            </Card.Content>
                        </Card>
                        <Card className="card-bottom" as={Link} to='/profile/1' style={{ background: 'rgba(30, 79, 156, 1)' }}>
                            <Card.Content >
                                <img src={require('../../../assets/images/home/invite_icon_white.png')} className={'card-bottom-icon-2'} />
                                <p>แนะนำเพื่อน</p>
                            </Card.Content>
                        </Card>
                        <a className="ui card card-bottom" target={'_blank'} href={Config.LineURL} style={{ background: 'rgba(234, 2, 41, 1)' }}>
                            <Card.Content >
                                <img src={require('../../../assets/images/home/service_icon_white.png')} className={'card-bottom-icon'} />
                                <p>ติดต่อแอดมิน</p>
                            </Card.Content>
                        </a>
                        <a className="ui card card-bottom" target={'_blank'} href={"https://paruay.com/lotto/rule/"} style={{ background: 'rgba(18, 0, 62, 1)' }}>
                            <Card.Content >

                                <Icon name='question circle outline' className={'card-bottom-sigle-icon-rule'} />
                                <p>กฎกติกา</p>
                            </Card.Content>
                        </a>
                    </Card.Group>

                </Segment> */}

                    {/* 
                <Segment loading={this.state.onLoading} style={{ boxShadow: 'unset', border: 0, marginTop: 20 }} >
                    <Responsive onUpdate={this.resizeGame}>
                        <h3 style={{ marginLeft: '0.5em' }}><Icon color="black" name="gamepad" /> เกมเดิมพัน </h3>
                        <Card.Group className="game-card CardGroup-custom" itemsPerRow={this.state.gameHot}>
                            <Card className="container-game-card" as={this.state.gameHuakoi.isEnabled ? Link : null} to='/game/huakoi' style={{ height: 220 }}>
                                <Card.Content className="hy_bg">
                                    <Card.Header>
                                        {this.state.gameHuakoi.isEnabled ? (
                                            <>
                                                <Label className="float-left" color='blue' circular style={{ opacity: 0.82 }}><Icon name='user' />{this.state.gameHuakoi.countRoundPlaying}</Label>
                                                <Label size="big" className="float-right" color='green' style={{ borderRadius: 15, paddingLeft: 18, paddingRight: 18, background: '#00E117' }}>เปิด</Label>
                                            </>
                                        ) : (
                                            <>
                                                <Label className="float-right" color='red' style={{ borderRadius: 15, paddingLeft: 18, paddingRight: 18 }}>ปิด</Label>
                                            </>
                                        )}
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content extra className="bg-white text-black">
                                    <center style={{ fontSize: 18 }}>หัวก้อย</center>
                                </Card.Content>
                            </Card>
                            <Card className="" as={this.state.gameHilo.isEnabled ? Link : null} to='/game/hilo' style={{ height: 220 }}>
                                <Card.Content className="hailow-bg">
                                    <Card.Header>
                                        {this.state.gameHilo.isEnabled ? (
                                            <>
                                                <Label className="float-left" color='blue' circular style={{ opacity: 0.82 }}><Icon name='user' />{this.state.gameHilo.countRoundPlaying}</Label>
                                                <Label className="float-right" color='green' style={{ borderRadius: 15, paddingLeft: 18, paddingRight: 18, backgroundColor: '#00E117' }}>เปิด</Label>
                                            </>
                                        ) : (
                                            <>
                                                <Label className="float-right" color='red' style={{ borderRadius: 15, paddingLeft: 18, paddingRight: 18 }}>ปิด</Label>
                                            </>
                                        )}

                                    </Card.Header>
                                </Card.Content>
                                <Card.Content extra className="bg-white text-black">
                                    <center style={{ fontSize: 18 }}>ไฮโล</center>
                                </Card.Content>
                            </Card>
                            <Card className="container-game-card" as={this.state.gamePaoyingchub.isEnabled ? Link : null} to='/game/paoyingchub' style={{ height: 220 }}>
                                <Card.Content className="yeengshop-bg">
                                    <Card.Header>
                                        {this.state.gamePaoyingchub.isEnabled ? (
                                            <>
                                                <Label className="float-left" color='blue' circular style={{ opacity: 0.82 }}><Icon name='user' />{this.state.gamePaoyingchub.countRoundPlaying}</Label>
                                                <Label className="float-right" color='green' style={{ borderRadius: 15, paddingLeft: 18, paddingRight: 18, backgroundColor: '#00E117' }} >เปิด</Label>
                                            </>
                                        ) : (
                                            <>
                                                <Label className="float-right" color='red' style={{ borderRadius: 15, paddingLeft: 18, paddingRight: 18 }}>ปิด</Label>
                                            </>
                                        )}
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content extra className="bg-white text-black">
                                    <center style={{ fontSize: 18 }}>เป่ายิงฉุบ</center>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                    </Responsive>
                </Segment> */}

                    <ModalSlotGames
                        open={this.state.isOpenModel}
                        onClose={() => this.setState({ isOpenModel: false })}
                    />

                    {/* Modal select casino */}
                    <ModalCasino
                        open={this.state.isOpenModelCasino}
                        onClose={() => this.setState({ isOpenModelCasino: false })}
                    />

                    {/* Modal select casino */}
                    <ModalSport
                        open={this.state.isOpenModelSport}
                        onClose={() => this.setState({ isOpenModelSport: false })}
                    />

                    <Modal
                        className="modal-announce"
                        open={this.state.isOpenModelAnnounce}
                        onClose={() => this.setState({ isOpenModelAnnounce: false })}
                    >
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                            <img src={require('../../../assets/images/home/announce_1.png')} style={{ height: 'auto', width: '100%' }} />
                            <center className="logo-announce">
                                <img src={require('../../../assets/images/head_logo.png')} style={{ height: 50, width: 110 }} />
                            </center>
                            <div className="content-announce">
                                {/* <center style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}> */}
                                <center style={{ overflowY: 'auto', height: '100%', width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                    <div style={{}}>
                                        <div style={{ fontSize: '1.2em', fontWeight: 500, color: 'rgba(231, 0, 0, 1)' }}>{this.state.announce?.name}</div>
                                        {
                                            this.state.announce?.imagePath &&
                                            <a href={this.state.announce?.url || '#'}>
                                                <img src={this.state.announce?.imagePath} style={{ height: 150, width: 150 }} />
                                            </a>
                                        }
                                        <div style={{ fontSize: '1.4em', fontWeight: 500, color: '#FFF' }} dangerouslySetInnerHTML={{ __html: this.state.announce?.description }}></div>
                                    </div>
                                    <center style={{ marginBottom: 5 }}>
                                        <Button
                                            size="mini"
                                            style={{ background: '#E70000', borderRadius: 5, padding: '5px 25px', color: '#FFFF', fontSize: '18px' }}
                                            onClick={() => this.setState({ isOpenModelAnnounce: false })}
                                        >
                                            ปิด
                                        </Button>
                                    </center>
                                </center>
                            </div>
                        </div>
                    </Modal>
                </Container >
            </>
        );
    }
}

export default Home;
