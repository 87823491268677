import React from 'react'
import { Image } from "semantic-ui-react";

const CreditIcon = ({ width = 130, height = 91, style = {}, className = '' }) => (
    <div className='iconSVG' style={style}>
        <Image className={className} width={width} height={height} src={require('../wallet_icon.png')} />
        {/* <svg className={className} width={width} height={height} viewBox="0 0 47 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2631 40H41.8331C44.2892 40 46.2803 38.009 46.2803 35.5529V17.193C46.2803 14.7369 44.2892 12.7458 41.8331 12.7458H14.2631C11.807 12.7458 9.81595 14.7369 9.81595 17.193V35.5529C9.81595 38.009 11.807 40 14.2631 40Z" fill="url(#paint0_linear)" />
            <path d="M14.0109 9.86019C14.0109 8.39645 13.1185 7.14106 11.8479 6.60865C11.0325 6.26689 10.4868 5.48749 10.4868 4.60336V3.36992C10.4868 1.50873 11.9956 0 13.8567 0H36.3709C38.2321 0 39.7408 1.50873 39.7408 3.36992V4.60336C39.7408 5.48758 39.1952 6.26689 38.3797 6.60865C37.1092 7.14106 36.2168 8.39645 36.2168 9.86019C36.2168 11.3239 37.1092 12.5793 38.3797 13.1117C39.1952 13.4535 39.7408 14.2329 39.7408 15.117V16.3505C39.7408 18.2116 38.2321 19.7204 36.3709 19.7204H13.8567C11.9956 19.7204 10.4868 18.2116 10.4868 16.3505V15.117C10.4868 14.2328 11.0325 13.4535 11.8479 13.1117C13.1185 12.5793 14.0109 11.324 14.0109 9.86019Z" fill="url(#paint1_linear)" />
            <path d="M22.1086 10.9205H28.1184C28.7039 10.9205 29.1786 10.4458 29.1786 9.86027C29.1786 9.27475 28.7039 8.80005 28.1184 8.80005H22.1086C21.5231 8.80005 21.0484 9.27475 21.0484 9.86027C21.0484 10.4458 21.5231 10.9205 22.1086 10.9205Z" fill="#E06E1C" />
            <path d="M8.06789 11.8695C9.10294 10.8345 9.35951 9.3158 8.8377 8.04086C8.50276 7.22256 8.66797 6.2857 9.29322 5.66045L10.1654 4.78831C11.4814 3.47226 13.6151 3.47226 14.9312 4.78831L30.8511 20.7082C32.1671 22.0243 32.1671 24.158 30.8511 25.474L29.979 26.3463C29.3537 26.9715 28.4168 27.1367 27.5985 26.8018C26.3237 26.28 24.8049 26.5366 23.7699 27.5716C22.7348 28.6067 22.4782 30.1253 23.0001 31.4003C23.335 32.2186 23.1698 33.1555 22.5445 33.7807L21.6724 34.6528C20.3563 35.9689 18.2226 35.9689 16.9066 34.6528L0.98666 18.7329C-0.329391 17.4169 -0.329391 15.2832 0.98666 13.9671L1.85889 13.095C2.48415 12.4697 3.42101 12.3045 4.23931 12.6394C5.51415 13.1612 7.03284 12.9046 8.06789 11.8695Z" fill="url(#paint2_linear)" />
            <path d="M19.2644 7.16555C19.8045 6.62548 20.68 6.62548 21.2202 7.16555L32.807 18.7523C33.1079 19.0532 33.3707 19.3779 33.5959 19.7203H29.8635L19.2645 9.12132C18.7243 8.58126 18.7243 7.70561 19.2644 7.16555Z" fill="#E06E1C" />
            <path d="M34.4782 33.8643H39.1814C39.7669 33.8643 40.2416 33.3896 40.2416 32.8041C40.2416 32.2186 39.7669 31.7439 39.1814 31.7439H34.4782C33.8927 31.7439 33.418 32.2186 33.418 32.8041C33.4179 33.3896 33.8926 33.8643 34.4782 33.8643Z" fill="#C2C8CF" />
            <path d="M18.0436 22.9055C18.315 22.9055 18.5863 22.802 18.7934 22.595C19.2074 22.1809 19.2074 21.5097 18.7934 21.0955L14.5438 16.8459C14.1298 16.4319 13.4585 16.4319 13.0444 16.8459C12.6304 17.26 12.6304 17.9312 13.0444 18.3453L17.2939 22.595C17.501 22.8019 17.7723 22.9055 18.0436 22.9055Z" fill="#EFC534" />
            <path d="M28.8045 28.0673C28.7413 27.5128 29.0162 26.9727 29.5048 26.703C29.6759 26.6086 29.8356 26.4896 29.9791 26.3462L30.8512 25.474C32.1673 24.1579 32.1673 22.0242 30.8512 20.7082L29.8636 19.7205H36.3711C38.2323 19.7205 39.741 18.2118 39.741 16.3506V15.1171C39.741 14.2316 39.1928 13.4536 38.3765 13.1104C38.1431 13.0123 37.9228 12.8895 37.7182 12.7458H41.8332C41.8619 12.7628 41.8941 12.782 41.9227 12.799C42.2986 13.4983 42.5066 14.2902 42.5066 15.1171V16.3506C42.5066 19.7392 39.7595 22.4863 36.3709 22.4863H34.5714C34.7449 24.2525 34.1567 26.0798 32.8068 27.4297L31.9346 28.3019C31.6005 28.6361 31.2289 28.9125 30.8325 29.1306C29.9762 29.6016 28.9154 29.0384 28.8045 28.0673Z" fill="#424E5E" />
            <path d="M9.09201 23.8059C9.32802 23.8059 9.56385 23.7159 9.74391 23.5359C10.1039 23.1759 10.1039 22.5921 9.74391 22.2321L7.55636 20.0446C7.19634 19.6847 6.61258 19.6846 6.25257 20.0447C5.89255 20.4047 5.89255 20.9885 6.25257 21.3485L8.44012 23.536C8.62008 23.7159 8.85609 23.8059 9.09201 23.8059Z" fill="#FFFDE3" />
            <path d="M4.23056 18.9444C4.46648 18.9444 4.7025 18.8544 4.88246 18.6744C5.24247 18.3144 5.24247 17.7306 4.88246 17.3705L4.57029 17.0583C4.21028 16.6983 3.62652 16.6983 3.26651 17.0583C2.9065 17.4184 2.9065 18.0021 3.26651 18.3622L3.57867 18.6744C3.75863 18.8544 3.99464 18.9444 4.23056 18.9444Z" fill="#FFFDE3" />
            <defs>
                <linearGradient id="paint0_linear" x1="61.7253" y1="26.3729" x2="0.517118" y2="26.3729" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#4E5660" />
                    <stop offset="1" stop-color="#64768D" />
                </linearGradient>
                <linearGradient id="paint1_linear" x1="25.1138" y1="21.5404" x2="25.1138" y2="-3.91561" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EA7D32" />
                    <stop offset="1" stop-color="#F0B160" />
                </linearGradient>
                <linearGradient id="paint2_linear" x1="15.9189" y1="32.4584" x2="15.9189" y2="2.86056" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F4D952" />
                    <stop offset="1" stop-color="#F9EDAF" />
                </linearGradient>
            </defs>
        </svg> */}
    </div>
)

const AFIcon = ({ width = 130, height = 91, style = {}, className = '' }) => (
    <div className='iconSVG' style={style} >
        <svg className={className} width={width} height={height} viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M56.2725 21.0255V28.251C56.2725 30.2215 54.6752 31.8189 52.7046 31.8189H9.97609C8.00552 31.8189 6.4082 30.2215 6.4082 28.251V21.0255C6.4082 19.0564 8.00552 17.4591 9.97609 17.4591H52.7045C54.6752 17.4591 56.2725 19.0564 56.2725 21.0255Z" fill="#DD9F3A" />
            <path d="M49.8643 35.3866V42.612C49.8643 44.5826 48.267 46.1799 46.2964 46.1799H3.56788C1.59732 46.1799 0 44.5826 0 42.612V35.3866C0 33.4175 1.59732 31.8202 3.56788 31.8202H46.2963C48.267 31.8202 49.8643 33.4175 49.8643 35.3866Z" fill="#DD9F3A" />
            <path d="M52.2686 49.747V56.9725C52.2686 58.9431 50.6713 60.5404 48.7007 60.5404H5.97218C4.00162 60.5404 2.4043 58.9431 2.4043 56.9725V49.747C2.4043 47.778 4.00162 46.1807 5.97218 46.1807H48.7006C50.6713 46.1807 52.2686 47.778 52.2686 49.747Z" fill="#DD9F3A" />
            <path d="M49.8643 64.1081V71.3336C49.8643 73.3042 48.267 74.9015 46.2964 74.9015H3.56788C1.59732 74.9015 0 73.3042 0 71.3336V64.1081C0 62.1391 1.59732 60.5417 3.56788 60.5417H46.2963C48.267 60.5417 49.8643 62.1391 49.8643 64.1081Z" fill="#DD9F3A" />
            <path d="M49.8648 64.1082V69.0908C49.8648 70.9332 48.4678 72.4504 46.675 72.6395C46.5501 72.6523 46.425 72.6587 46.2969 72.6587H12.3496C10.3805 72.6587 8.7832 71.0613 8.7832 69.0908V64.1082C8.7832 62.1392 10.3805 60.5419 12.3496 60.5419H46.2969C48.2674 60.5419 49.8648 62.1392 49.8648 64.1082Z" fill="#F4B74A" />
            <path d="M49.8648 64.1082V69.0908C49.8648 70.9332 48.4678 72.4504 46.675 72.6395C46.5501 72.6523 46.425 72.6587 46.2969 72.6587H12.3496C10.3805 72.6587 8.7832 71.0613 8.7832 69.0908V64.1082C8.7832 62.1392 10.3805 60.5419 12.3496 60.5419H46.2969C48.2674 60.5419 49.8648 62.1392 49.8648 64.1082Z" fill="#F4B74A" />
            <path d="M49.8648 49.747V54.7296C49.8648 56.572 48.4678 58.0892 46.675 58.2783C46.5501 58.2911 46.425 58.2975 46.2969 58.2975H12.3496C10.3805 58.2975 8.7832 56.7001 8.7832 54.7296V49.747C8.7832 47.778 10.3805 46.1807 12.3496 46.1807H46.2969C48.2676 46.1805 49.8648 47.7778 49.8648 49.747Z" fill="#F4B74A" />
            <path d="M49.8648 35.3873V40.3698C49.8648 42.2123 48.4678 43.7295 46.675 43.9185C46.5501 43.9313 46.425 43.9377 46.2969 43.9377H12.3496C10.3805 43.9377 8.7832 42.3404 8.7832 40.3698V35.3873C8.7832 33.4182 10.3805 31.8209 12.3496 31.8209H46.2969C48.2676 31.8209 49.8648 33.4182 49.8648 35.3873Z" fill="#F4B74A" />
            <path d="M56.273 21.0258V26.0084C56.273 27.8508 54.876 29.368 53.0832 29.5571C52.9583 29.5699 52.8332 29.5763 52.7051 29.5763H18.7578C16.7887 29.5763 15.1914 27.9789 15.1914 26.0084V21.0258C15.1914 19.0568 16.7887 17.4595 18.7578 17.4595H52.7051C54.6758 17.4595 56.273 19.0568 56.273 21.0258Z" fill="#F4B74A" />
            <path d="M49.8654 64.1084V71.3339C49.8654 73.1763 48.4684 74.6935 46.6756 74.8825C46.3504 74.8953 46.0251 74.9017 45.6999 74.9017C42.2522 74.9017 38.8044 74.192 35.5937 72.7694C33.4388 71.8162 31.3945 70.5409 29.5265 68.9468C29.0266 68.5207 28.5428 68.072 28.0702 67.601C25.9603 65.491 24.3084 63.0974 23.1133 60.542H46.2975C48.2681 60.542 49.8654 62.1393 49.8654 64.1084Z" fill="#DD9F3A" />
            <path d="M49.8638 49.747V56.9725C49.8638 58.9431 48.2665 60.5404 46.2959 60.5404H25.5117C23.4017 56.0304 22.716 51.0174 23.4561 46.1807H46.2959C48.2665 46.1805 49.8638 47.778 49.8638 49.747Z" fill="#DD9F3A" />
            <path d="M49.8643 6.66463V13.8901C49.8643 15.8607 48.267 17.458 46.2964 17.458H3.56788C1.59732 17.4582 0 15.8608 0 13.8903V6.66463C0 4.69559 1.59732 3.09827 3.56788 3.09827H46.2963C48.267 3.09842 49.8643 4.69574 49.8643 6.66463Z" fill="#DD9F3A" />
            <path d="M49.8648 6.66438V11.6469C49.8648 13.4894 48.4678 15.0066 46.675 15.1956C46.5501 15.2084 46.425 15.2148 46.2969 15.2148H12.3496C10.3805 15.2148 8.7832 13.6175 8.7832 11.6469V6.66438C8.7832 4.69534 10.3805 3.09802 12.3496 3.09802H46.2969C48.2674 3.09817 49.8648 4.6955 49.8648 6.66438Z" fill="#F4B74A" />
            <path d="M43.9982 3.09802H38.7656V15.2148H43.9982V3.09802Z" fill="#F9C662" />
            <path d="M34.9209 3.09802H32.3047V15.2148H34.9209V3.09802Z" fill="#F9C662" />
            <path d="M50.3015 17.46H45.0684V31.8214H50.3015V17.46Z" fill="#F9C662" />
            <path d="M41.2221 17.46H38.6055V31.8214H41.2221V17.46Z" fill="#F9C662" />
            <path d="M56.2734 25.9171V28.2515C56.2734 30.2221 54.6761 31.8194 52.7056 31.8194H32.6133C39.1324 25.6734 48.1523 23.7062 56.2734 25.9171Z" fill="#DD9F3A" />
            <path d="M49.8646 35.3874V42.6129C49.8646 44.5835 48.2673 46.1808 46.2967 46.1808H21.0537C21.8275 41.1149 24.1667 36.2428 28.0678 32.3418C28.2455 32.164 28.425 31.9909 28.6061 31.821H46.2967C48.2673 31.821 49.8646 33.4184 49.8646 35.3874Z" fill="#DD9F3A" />
            <path d="M70.6975 67.6002C63.1979 75.0984 52.1114 76.8221 42.9624 72.769C40.8077 71.8155 38.7623 70.5415 36.8952 68.947C36.3952 68.5212 35.9101 68.0714 35.4377 67.6002C25.7017 57.863 25.7017 42.0776 35.4377 32.3404C45.1749 22.6045 60.9603 22.6045 70.6975 32.3404C71.1699 32.8128 71.6184 33.2979 72.0444 33.7979C73.6388 35.6636 74.9128 37.7102 75.8664 39.8651C79.9195 49.0129 78.1957 60.1007 70.6975 67.6002Z" fill="#F4B74A" />
            <path d="M75.8665 39.8652L42.9627 72.7691C40.808 71.8156 38.7626 70.5415 36.8955 68.9471L72.0446 33.798C73.6389 35.6637 74.913 37.7105 75.8665 39.8652Z" fill="#F9C662" />
            <path d="M70.6978 49.9703C70.6978 59.7069 62.8045 67.6 53.0681 67.6C47.572 67.6 42.6641 65.0859 39.4303 61.1437C36.9349 58.1023 35.4385 54.211 35.4385 49.9703C35.4385 40.2338 43.3317 32.3407 53.0681 32.3407C57.3097 32.3407 61.2011 33.8393 64.2424 36.3348C68.1835 39.5673 70.6978 44.4755 70.6978 49.9703Z" fill="#FFD488" />
            <path d="M64.2425 36.3347L39.4326 61.1446L39.4303 61.1436C38.702 60.2562 38.0588 59.2956 37.514 58.2755C36.8929 57.1162 36.399 55.8807 36.0486 54.5843C35.6508 53.1134 35.4385 51.566 35.4385 49.9702C35.4385 40.2336 43.3317 32.3406 53.0681 32.3406C54.6651 32.3406 56.2123 32.5526 57.6821 32.9507C58.9786 33.3011 60.2141 33.7962 61.3734 34.4173C62.3937 34.9623 63.354 35.6065 64.2425 36.3347Z" fill="#F9C662" />
            <path d="M61.3736 34.4173L37.5142 58.2757C36.8931 57.1163 36.3992 55.8808 36.0488 54.5844L57.6825 32.9507C58.9786 33.3011 60.2143 33.7962 61.3736 34.4173Z" fill="#FFD488" />
            <path d="M54.2702 48.8647V42.2475C56.0221 42.6558 57.3041 43.9318 57.3041 45.4396C57.3041 46.1032 57.8419 46.6411 58.5056 46.6411C59.1694 46.6411 59.7072 46.1032 59.7072 45.4396C59.7072 42.633 57.3588 40.2928 54.27 39.803V39.0963C54.27 38.4327 53.7323 37.8948 53.0685 37.8948C52.4047 37.8948 51.867 38.4327 51.867 39.0963V39.803C48.7782 40.293 46.4297 42.6331 46.4297 45.4396C46.4297 48.2461 48.7782 50.5864 51.867 51.0761V57.6933C50.115 57.2851 48.8329 56.009 48.8329 54.5013C48.8329 53.8377 48.2951 53.2997 47.6314 53.2997C46.9676 53.2997 46.4298 53.8377 46.4298 54.5013C46.4298 57.3079 48.7784 59.6481 51.8671 60.1378V60.8446C51.8671 61.5082 52.4049 62.0461 53.0687 62.0461C53.7324 62.0461 54.2702 61.5082 54.2702 60.8446V60.1378C57.3589 59.6481 59.7073 57.3078 59.7073 54.5013C59.7073 51.6948 57.3589 49.3547 54.2702 48.8647ZM48.8331 45.4394C48.8331 43.9317 50.115 42.6557 51.8671 42.2474V48.6315C50.1152 48.2234 48.8331 46.9472 48.8331 45.4394ZM54.2702 57.6933V51.3092C56.0221 51.7175 57.3041 52.9935 57.3041 54.5013C57.3041 56.009 56.0221 57.2851 54.2702 57.6933Z" fill="#E0A33F" />
            <path d="M53.0686 68.8017C47.4062 68.8017 42.0969 66.2883 38.5017 61.9057C35.7519 58.5543 34.2373 54.3155 34.2373 49.9704C34.2373 39.5868 42.6851 31.139 53.0686 31.139C57.4126 31.139 61.6517 32.6544 65.0052 35.4057C69.3869 38.9998 71.8998 44.3084 71.8998 49.9702C71.8997 60.3539 63.4522 68.8017 53.0686 68.8017ZM53.0686 33.5423C44.0101 33.5423 36.6405 40.9119 36.6405 49.9704C36.6405 53.761 37.9613 57.4585 40.3597 60.3814C43.4964 64.2053 48.1286 66.3985 53.0686 66.3985C62.127 66.3985 69.4966 59.0289 69.4966 49.9704C69.4966 45.0311 67.3039 40.3997 63.4809 37.2639C60.5562 34.8638 56.8582 33.5423 53.0686 33.5423Z" fill="#E0A33F" />
        </svg>
    </div>
)

const puzzleIcon = ({ width = 20, height = 25, style = {}, className = '' }) => (
    <div className='iconSVG' style={style} >
        <svg className={className} width={width} height={height} viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.4749 20.9125L32.5221 16.8656L28.4749 12.8184L29.127 12.1663C30.2552 11.0381 30.2552 9.2025 29.1272 8.07428C28.5806 7.52791 27.8539 7.22694 27.081 7.22694C26.3083 7.22694 25.5816 7.52791 25.0352 8.07428L24.3831 8.72635L20.336 4.6792L19.1417 5.87367V7.7614C21.2543 8.26484 22.8301 10.1682 22.8301 12.4326C22.8301 14.6972 21.2543 16.6006 19.1417 17.1038V22.9573H15.6382L14.9398 23.6557L20.336 29.0517L24.3831 25.0045L24.9451 25.5668C26.0733 26.695 27.909 26.695 29.0372 25.5668C30.1654 24.4386 30.1654 22.6029 29.0372 21.4747L28.4749 20.9125Z" fill="#D3D3D3" />
            <path d="M17.234 21.0496V15.3261H18.0287C19.6244 15.3261 20.9223 14.0282 20.9223 12.4325C20.9223 10.8371 19.6244 9.53913 18.0287 9.53913H17.234V3.81565H11.5105V2.89362C11.5105 1.29794 10.2125 0 8.61685 0C7.02142 0 5.72348 1.29794 5.72348 2.89362V3.81565H0V9.53913H0.79499C2.19491 9.53913 3.36556 10.5383 3.63154 11.8611C3.74692 12.0224 3.81565 12.2194 3.81565 12.4325C3.81565 12.6456 3.74692 12.8427 3.63154 13.0042C3.36556 14.3267 2.19491 15.3261 0.79499 15.3261H0V21.0496H5.72348V20.1273C5.72348 18.5319 7.02142 17.234 8.61685 17.234C10.2125 17.234 11.5105 18.5319 11.5105 20.1273V21.0496H17.234Z" fill="#D3D3D3" />
        </svg>
    </div>
)

const ProfileDetailIcon = ({ width = 22, height = 22, style = {}, className = '', color = "#FFF" }) => (
    <div className='iconSVG' style={style} >
        <svg className={className} width={width} height={height} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4.11111V32.8889C0 33.9792 0.433134 35.0249 1.20412 35.7959C1.9751 36.5669 3.02078 37 4.11111 37H32.8889C35.15 37 37 35.15 37 32.8889V4.11111C37 1.85 35.15 0 32.8889 0H4.11111C3.02078 0 1.9751 0.433134 1.20412 1.20412C0.433134 1.9751 0 3.02078 0 4.11111ZM24.6667 12.3333C24.6667 15.7456 21.9122 18.5 18.5 18.5C15.0878 18.5 12.3333 15.7456 12.3333 12.3333C12.3333 8.92111 15.0878 6.16667 18.5 6.16667C21.9122 6.16667 24.6667 8.92111 24.6667 12.3333ZM6.16667 28.7778C6.16667 24.6667 14.3889 22.4056 18.5 22.4056C22.6111 22.4056 30.8333 24.6667 30.8333 28.7778V30.8333H6.16667V28.7778Z" fill={color} />
        </svg>
    </div>
)

const HistoryIcon = ({ width = 22, height = 22, style = {}, className = '', color = "#FFF" }) => (
    <div className='iconSVG' style={style} >
        <svg className={className} width={width} height={height} viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.8333 0L33 8.9982V34.2162C32.9998 34.4507 32.9525 34.6828 32.8609 34.8994C32.7692 35.1159 32.6351 35.3126 32.466 35.4783C32.297 35.6439 32.0964 35.7752 31.8756 35.8648C31.6549 35.9543 31.4183 36.0002 31.1795 36H1.8205C1.33903 35.9967 0.878199 35.8076 0.537566 35.4735C0.196933 35.1394 0.00384019 34.6871 0 34.2144V1.7856C0 0.7992 0.815833 0 1.8205 0H23.8333ZM18.3333 12.6H14.6667V23.4H23.8333V19.8H18.3333V12.6Z" fill={color} />
        </svg>
    </div>

)

export { CreditIcon, AFIcon, puzzleIcon, ProfileDetailIcon, HistoryIcon }