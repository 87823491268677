import React, { Component } from 'react'
import { Card, Container, Header, Icon, Responsive, Tab, Popup, Segment, Flag, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import LotteryHistory from "./LotteryHistory";
import YiiGiiService from '../../../service/YiiGiiService';
import HuayService from '../../../service/HuayService';
import CardLottery from './CardLottery';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

const asyncFunc = (event) => {
    return new Promise(resolve => {
        event();
        resolve();
    });
};

class Lottery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            YiiGii: [],
            huay: [],
            huayTypeList: [],
            onLoading: false,
            historyLoading: false,
            suggestCardPreview: 3,
        };
    }

    async componentDidMount() {
        await asyncFunc(() => {
        });
        this.resizeGame();
        this.initData();
    }

    async initData() {
        //load huay
        this.setState({ onLoading: true })
        await YiiGiiService.getYiiGii().then((res) => {
            if (res.status) {
                this.setState({ YiiGii: res.data })
            }
        })
        await HuayService.getHuayPlaylist().then(res => {
            if (res.status) {
                let tempHuayTypeList = res.data.reduce((disData, elm) => {
                    if (disData.findIndex((item) => item.type == elm.type) < 0) {
                        disData.push({ type: elm.type, typeDescription: elm.typeDescription })
                    }
                    return disData
                }, Array())
                this.setState({ huay: res.data, huayTypeList: tempHuayTypeList })
            }
        })
        this.setState({ onLoading: false })
    }

    getSuggestedYiiGiiList = () => this.state.YiiGii.filter(value => value.isSuggested == 1)
    getSuggestedHuayList = () => this.state.huay.filter(value => value.isSuggested == 1)

    getDurationToTime = (duration) => {
        var timer = duration, minutes, seconds;
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        if (--timer < 0) {
            timer = duration;
        }

        return [minutes, seconds]
    }

    resizeGame = () => {
        let gameHot = 2;
        let suggestCardPreview = 3
        let innerWidth = window.innerWidth;
        if (innerWidth > 1380) {
            gameHot = 3;
            suggestCardPreview = 3
        } else if (innerWidth > 660) {
            gameHot = 2;
            suggestCardPreview = 3
        } else {
            gameHot = 2;
            suggestCardPreview = 1
        }
        this.setState((prevState) => ({ gameHot: gameHot, suggestCardPreview: suggestCardPreview }))
    };

    handleSetHistoryLoading = (status) => {
        this.setState({ historyLoading: status })
    }

    getHuayList(type = null) {
        if (type == null) return this.state.huay
        return this.state.huay.filter((elm, key) => elm.type == type)
    }

    getHuaySuggestedComponent() {
        return !this.state.onLoading ? (
            <Swiper
                spaceBetween={5}
                slidesPerView={this.state.suggestCardPreview}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                {this.getSuggestedYiiGiiList().map((res, key) => (
                    <SwiperSlide className='ui cards CardGroup-custom' style={{ marginTop: 'unset' }} key={key}>
                        <Popup key={key} content={"ปิดรอบทุก " + res.duration / 60 + "นาที " + 86400 / res.duration + " รอบ"} inverted style={{ opacity: 0.8 }} position='bottom center' trigger={
                            <Card as={(res.isEnabled) ? Link : null} to={"/yiki/" + res._id} className={res.backgroundColorCode}>
                                {/* <Card.Content className="bg-white">
                                </Card.Content> */}
                                <Card.Content className="">
                                    <Card.Header style={{ marginTop: 'auto' }}>
                                        <Icon name='braille' size='large' color='teal' />
                                        <span className="text-white-on-color" style={{ float: 'right' }}>{res.name}</span>
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content extra className="bg-white text-black" style={{ paddingTop: 8, paddingBottom: 8 }}>
                                    {(res.isEnabled) ? (<span><Icon name='clock outline' color='green' /> ปิดรอบทุก {res.duration / 60} นาที</span>) :
                                        (<span><Icon name='dont' color='red' />รอบนี้ปิด</span>)
                                    }
                                </Card.Content>
                            </Card>
                        } />
                    </SwiperSlide>
                ))}
                {this.getSuggestedHuayList().map((res, key) => (
                    <SwiperSlide className='ui cards CardGroup-custom' style={{ marginTop: 'unset' }} key={key}>
                        <Popup key={key} content={() => (res.roundActive.length) ? <div><div>เปิดรับแทง {new Date(res.roundActive[0].startTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</div><div>ปิดรับแทง {new Date(res.roundActive[0].endTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</div><div>ประกาศผล {new Date(res.roundActive[0].announceTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</div></div> : 'ปิด'} inverted style={{ opacity: 0.8 }} position='bottom center' trigger={
                            <Card as={(res.isEnabled && res.roundActive.length) ? Link : null} to={(res.roundActive.length) ? "/lotteryPlay/huay/" + res.roundActive[0]._id : ''} className={(res.isEnabled && res.roundActive.length) ? res.backgroundColorCode : 'card-none'}>
                                {/* <Card.Content className="bg-white">
                                </Card.Content> */}
                                <Card.Content className="">
                                    <Card.Header style={{ marginTop: 'auto' }}>
                                        <Icon name='braille' size='large' color='teal' />
                                        <span className="text-white-on-color" style={{ float: 'right' }}>{res.name}</span>
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content extra className="bg-white text-black" style={{ paddingTop: 8, paddingBottom: 8 }}>
                                    {(res.isEnabled && res.roundActive.length) ? (<span><Icon name='clock outline' color='green' /> ปิดรอบในอีก {(res.roundActive.length) ? (((new Date(res.roundActive[0].endTime) - new Date()) > 86400000) ? Math.abs((new Date(res.roundActive[0].endTime) - new Date()) / 86400000).toFixed(0) + ' วัน' : this.getDurationToTime((new Date(res.roundActive[0].endTime) - new Date()) / 1000)[0] + 'นาที') : ''}</span>) : (<span><Icon name='dont' color='red' />ปิดรอบ</span>)}
                                </Card.Content>
                            </Card>
                        } />
                    </SwiperSlide>
                ))}
                <SwiperSlide></SwiperSlide>
            </Swiper>
        ) : ''
    }

    sortHuayByEndTime = (arr = []) => {
        const assignValue = val => {
            if (val == undefined) {
                return Infinity;
            }
            else {
                return (new Date(val.endTime) - new Date());
            };
        };
        const sorter = (a, b) => {
            return assignValue(a.roundActive[0]) - assignValue(b.roundActive[0]);
        };
        return arr.sort(sorter);
    }

    panes = [
        {
            menuItem: { key: 'แทงหวย', content: 'แทงหวย' },
            render: () =>
                <Tab.Pane attached={false} loading={this.state.onLoading} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0 }}>
                    {/* <Segment loading={this.state.onLoading} style={{boxShadow:'unset',border: 0}} >
                        <Responsive onUpdate={this.resizeGame}>
                            <h3 style={{fontWeight:500,marginBottom:0}}><Icon name='clone' color='grey' size='small' /> หวยแนะนำ <Icon color="yellow" name="star" size='small' /></h3>    
                            {this.getHuaySuggestedComponent()}
                        </Responsive>
                    </Segment> */}

                    <Segment loading={this.state.onLoading} style={{ boxShadow: 'unset', border: 0, marginBottom: 20, background: 'var(--background-huay-list)', paddingTop: 5, borderRadius: 15 }} >
                        <Responsive onUpdate={this.resizeGame}>
                            <div className="game-name-container">
                                <div><Icon name='clone' color='white' size='small' /> หวยยี่กี่ </div>
                            </div>
                            <Card.Group itemsPerRow={this.state.YiiGii.length == 1 ? 1 : this.state.gameHot} className="CardGroup-custom lottery-card">
                                {this.state.YiiGii.map((res, key) => (
                                    <CardLottery
                                        key={key}
                                        popupContent={"ปิดรอบทุก " + res.duration / 60 + "นาที " + 86400 / res.duration + " รอบ"}
                                        isEnabled={res.isEnabled}
                                        linkTo={"/yiki/" + res._id}
                                        backgroundColorCode={res.backgroundColorCode}
                                        backgroundImage={res.bgPath}
                                        name={res.name}
                                        description={`ปิดรอบทุก ${res.duration / 60} นาที`}
                                        img={res.logoPath}
                                        isHot={false}
                                    />
                                ))}
                            </Card.Group>
                        </Responsive>
                    </Segment>

                    {this.state.huayTypeList.map((huayTypeListElm, huayTypeListKey) => {
                        const _length_huay_enable = this.getHuayList(huayTypeListElm.type).filter((item) => item.isEnabled === 1).length
                        if (_length_huay_enable > 0) {
                            return <Segment loading={this.state.onLoading} style={{ boxShadow: 'unset', border: 0, marginBottom: 20, background: 'var(--background-huay-list)', paddingTop: 5, borderRadius: 15 }} >
                                <Responsive onUpdate={this.resizeGame}>
                                    <div className="game-name-container">
                                        <div><Icon name='clone' color='white' size='small' /> {huayTypeListElm.typeDescription} </div>
                                    </div>
                                    <Card.Group itemsPerRow={this.getHuayList(huayTypeListElm.type).length == 1 ? 1 : this.state.gameHot} className="CardGroup-custom  lottery-card">
                                        {this.sortHuayByEndTime(this.getHuayList(huayTypeListElm.type)).map((res, key) => (
                                            res.isEnabled === 1 ?
                                                <CardLottery
                                                    key={key}
                                                    popupContent={(res.roundActive.length) ? <div><div>เปิดรับแทง {new Date(res.roundActive[0].startTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</div><div>ปิดรับแทง {new Date(res.roundActive[0].endTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</div><div>ประกาศผล {new Date(res.roundActive[0].announceTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</div></div> : 'ปิดรอบ'}
                                                    isEnabled={res.isEnabled && res.roundActive.length}
                                                    linkTo={(res.roundActive.length) ? "/lotteryPlay/huay/" + res.roundActive[0]._id : ''}
                                                    backgroundColorCode={res.backgroundColorCode}
                                                    backgroundImage={res.bgPath}
                                                    name={res.name}
                                                    description={
                                                        (res.isEnabled && res.roundActive.length) ?
                                                            (<span>ปิดรอบในอีก {(res.roundActive.length) ?
                                                                (((new Date(res.roundActive[0].endTime) - new Date()) > 86400000) ?
                                                                    Math.abs((new Date(res.roundActive[0].endTime) - new Date()) / 86400000).toFixed(0) + ' วัน' :
                                                                    (this.getDurationToTime((new Date(res.roundActive[0].endTime) - new Date()) / 1000)[0] >= 60 ?
                                                                        (this.getDurationToTime((new Date(res.roundActive[0].endTime) - new Date()) / 1000)[0] / 60).toFixed(0) + ' ชั่วโมง'
                                                                        :
                                                                        this.getDurationToTime((new Date(res.roundActive[0].endTime) - new Date()) / 1000)[0] + ' นาที')) :
                                                                ''}</span>) :
                                                            (<span>ปิดรอบ</span>)
                                                    }
                                                    img={res.logoPath}
                                                    isHot={false}
                                                />
                                                :
                                                null
                                        ))}
                                    </Card.Group>
                                </Responsive>
                            </Segment>
                        } else {
                            return null
                        }

                    })}

                </Tab.Pane>
        },
        {
            menuItem: { key: 'ดูผลหวย', content: 'ดูผลหวย' },
            render: () => <Tab.Pane attached={false} loading={this.state.onLoading} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0 }}><LotteryHistory huay={this.state.huay} yiigii={this.state.YiiGii} /></Tab.Pane>
        }
    ];

    render() {
        return (
            <Container className='lotteryUI'>
                <Tab menu={{ pointing: false }} panes={this.panes} />
            </Container>
        );
    }
}

export default Lottery;
