export const globalReducer = (state = {innerWidth: window.innerWidth}, action) => {
    switch (action.type) {
        case'addInnerWidth':
            state.innerWidth = action.payload;
            return {...state};
        default:
            return state;
    }

};
