import Login from '../components/layout/Login.js';
import LoginAuto from '../components/layout/LoginAuto';
import Home from '../components/view/home/Home';
import Profile from '../components/view/profile/Profile';
import Book from '../components/view/book/Book';
import Lottery from '../components/view/lottery/Lottery';
import Yiki from '../components/view/lottery/Yiki';
import Wallet from '../components/view/wallet/Wallet';
import LotteryPlay from "../components/view/lottery/LotteryPlay";
import LotteryPlayYiigii from "../components/view/lottery/LotteryPlayYiigii";
import LotteryDetail from "../components/view/lottery/LotteryDetail";
import { NumberShotPage } from "../components/view/lottery/NumberShot";
import Game from "../components/view/game/Game";
import Huakoi from "../components/view/game/Huakoi";
import Hilo from "../components/view/game/Hilo";
import Paoyingchub from "../components/view/game/Paoyingchub";
import FavoirteBill from '../components/view/lottery/FavoriteBill';
import CreateFavoriteBill from '../components/view/lottery/CreateFavoriteBill';
import SlotGames from '../components/view/home/SlotGames';
import AWSSlotgame from '../components/view/home/AWSSlotgame';
import KingMakerSlotGame from '../components/view/home/KingMakerSlotGame';
import PGSlotgame from '../components/view/home/PGSlotgame.js';
import History from '../components/view/home/History.js';
import Casino from '../components/view/home/Casino.js';
import Slot from '../components/view/home/Slot.js';
import RuleHuay from '../components/view/home/RuleHuay.js';
import Sport from '../components/view/home/Sport.js';
import HuayHistory from '../components/view/home/HuayHistory.js';
import JILISlotgame from '../components/view/home/JILISlotgame';
import PPSlotgame from '../components/view/home/PPSlotgame';
import SpadeSlotgame from '../components/view/home/SpadeSlotgame.js';
import FCSlotgame from '../components/view/home/FCSlotgame';
import VenusCasino from '../components/view/home/VenusCasino';
import Bonus from '../components/view/home/Bonus';
import PGAMBSlotgame from '../components/view/home/PGAMBSlotgame.js';
import KingMakerAMBSlotGame from '../components/view/home/KingMakerAMBSlotGame.js';
import PPAMBSlotgame from '../components/view/home/PPAMBSlotgame.js';
import JokerAMBSlotgame from '../components/view/home/JokerAMBSlotgame.js';
import JiliAMBSlotgame from '../components/view/home/JiliAMBSlotgame.js';
import SpadeAMBSlotgame from '../components/view/home/SpadeAMBSlotgame.js';
import AskMeAMBSlotgame from '../components/view/home/AskMeAMBSlotgame.js';
import SlotXoAMBSlotgame from '../components/view/home/SlotXoAMBSlotgame.js';

let Routes = {
    login: {
        path: "/",
        nameEn: "Login",
        nameTh: "เข้าสู่ระบบ",
        icon: '',
        component: Login,
        layout: "/user"
    },
    home: {
        path: "/home",
        nameEn: "Home",
        nameTh: "หน้าแรก",
        icon: '',
        component: Home,
        layout: "/user"
    },
    autoLogin: {
        path: "/a/:loginKey",
        nameEn: "Login",
        nameTh: "เข้าสู่ระบบ",
        icon: '',
        component: LoginAuto,
        layout: "/user"
    },
    privateRoute: [
        {
            path: "/home",
            nameEn: "Home",
            nameTh: "หน้าแรก",
            icon: 'home',
            component: Home,
            layout: "/user",
            menuActive: "/home"
        },
        {
            path: "/game",
            nameEn: "Game",
            nameTh: "เกม",
            icon: 'game',
            component: Game,
            layout: "/user",
            menuActive: "/game"
        },
        {
            path: "/game/huakoi",
            nameEn: "Game huakoi",
            nameTh: "เกม หัวก้อย",
            icon: 'game',
            component: Huakoi,
            layout: "/user",
            menuActive: "/game",
            onBack: true,
        },
        {
            path: "/game/hilo",
            nameEn: "Game hilo",
            nameTh: "เกม ไฮโล",
            icon: 'game',
            component: Hilo,
            layout: "/user",
            menuActive: "/game",
            onBack: true,
        },
        {
            path: "/game/paoyingchub",
            nameEn: "Game Paoyingchub",
            nameTh: "เกม เป่ายิงฉุบ",
            icon: 'game',
            component: Paoyingchub,
            layout: "/user",
            menuActive: "/game",
            onBack: true,
        },
        {
            path: "/lottery",
            nameEn: "Lottery",
            nameTh: "แทงหวย",
            icon: 'diamond',
            component: Lottery,
            layout: "/user",
            menuActive: "/lottery"
        },
        {
            path: "/yiki/:yiigiiID",
            nameEn: "Yiki",
            nameTh: "ยี่กี่",
            icon: 'diamond',
            component: Yiki,
            layout: "/user",
            onBack: true,
            menuActive: "/lottery"
        },
        {
            path: "/book",
            nameEn: "Book",
            nameTh: "โพย",
            icon: 'list alternate',
            component: Book,
            layout: "/user",
            menuActive: "/lottery"
        },
        {
            path: "/profile",
            nameEn: "Profile",
            nameTh: "ตัวฉัน",
            icon: 'user',
            component: Profile,
            layout: "/user",
            menuActive: "/home"
        },
        {
            path: "/lotteryPlay/huay/:roundId",
            nameEn: "lotteryPlay",
            nameTh: "แทงหวย",
            icon: 'diamond',
            component: LotteryPlay,
            layout: "/user",
            onBack: true,
            menuActive: "/lottery"
        },
        {
            path: "/lotteryPlay/yiigii/:roundId",
            nameEn: "lotteryPlay",
            nameTh: "แทงหวย",
            icon: 'diamond',
            component: LotteryPlayYiigii,
            layout: "/user",
            onBack: true,
            menuActive: "/lottery"
        },
        {
            path: "/yiigiinumberShot/:roundId",
            nameEn: "Lottery",
            nameTh: "ยิงเลข",
            icon: 'diamond',
            component: NumberShotPage,
            layout: "/user",
            onBack: true,
            menuActive: "/lottery"
        },
        {
            path: "/lotteryDetail/:roundId",
            nameEn: "Lottery",
            nameTh: "หวย",
            icon: 'diamond',
            component: LotteryDetail,
            layout: "/user",
            onBack: true,
            menuActive: "/lottery"
        },
        {
            path: "/profile/:id",
            nameEn: "Profile",
            nameTh: "ตัวฉัน",
            icon: 'user',
            component: Profile,
            layout: "/user",
            menuActive: "/profile"
        },
        {
            path: "/profile/af",
            nameEn: "Profile",
            nameTh: "ตัวฉัน",
            icon: 'user',
            component: Profile,
            layout: "/user",
            menuActive: "/profile"
        },
        {
            path: "/favoriteBill",
            nameEn: "FavoriteBill",
            nameTh: "เลขชุด",
            icon: 'user',
            component: FavoirteBill,
            layout: "/user",
            onBack: true,
            menuActive: "/home"
        },
        {
            path: "/createFavoriteBill",
            nameEn: "createFavoriteBill",
            nameTh: "สร้างเลขชุด",
            icon: 'user',
            component: CreateFavoriteBill,
            layout: "/user",
            onBack: true,
            menuActive: "/home"
        },
        {
            path: "/jokerslotgames",
            nameEn: "jokerslotgames",
            nameTh: "เกมส์ สล็อต",
            icon: 'user',
            component: SlotGames,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/jokerambslotgames",
            nameEn: "jokerambslotgames",
            nameTh: "เกมส์ สล็อต",
            icon: 'user',
            component: JokerAMBSlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/kingmakerslotgames",
            nameEn: "kingmakerslotgames",
            nameTh: "คิง มาเกอร์",
            icon: 'user',
            component: KingMakerSlotGame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/awsslotgames",
            nameEn: "awsslotgames",
            nameTh: "AWS",
            icon: 'user',
            component: AWSSlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/jilislotgames",
            nameEn: "JILI",
            nameTh: "JILI",
            icon: 'user',
            component: JILISlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/jiliambslotgames",
            nameEn: "JILI",
            nameTh: "JILI",
            icon: 'user',
            component: JiliAMBSlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/pgslotgames",
            nameEn: "pgslotgames",
            nameTh: "PG",
            icon: 'user',
            component: PGSlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/pgambslotgames",
            nameEn: "pgambslotgames",
            nameTh: "PG",
            icon: 'user',
            component: PGAMBSlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/kingmakerambslotgames",
            nameEn: "kingmakerambslotgames",
            nameTh: "Kingmaker",
            icon: 'user',
            component: KingMakerAMBSlotGame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/ppslotgames",
            nameEn: "Pragmaticplay",
            nameTh: "Pragmaticplay",
            icon: 'user',
            component: PPSlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/ppambslotgames",
            nameEn: "Pragmaticplay",
            nameTh: "Pragmaticplay",
            icon: 'user',
            component: PPAMBSlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/spadeslotgames",
            nameEn: "SPADE",
            nameTh: "SPADE",
            icon: 'user',
            component: SpadeSlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/spadeambslotgames",
            nameEn: "SPADE",
            nameTh: "SPADE",
            icon: 'user',
            component: SpadeAMBSlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/slotxoslotgames",
            nameEn: "SLOT XO",
            nameTh: "SLOT XO",
            icon: 'user',
            component: SlotXoAMBSlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/askmeslotgames",
            nameEn: "ASK ME SLOT",
            nameTh: "ASK ME SLOT",
            icon: 'user',
            component: AskMeAMBSlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/fcslotgames",
            nameEn: "FA CHAI",
            nameTh: "FA CHAI",
            icon: 'user',
            component: FCSlotgame,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/venuscasino",
            nameEn: "VENUS",
            nameTh: "VENUS",
            icon: 'user',
            component: VenusCasino,
            layout: "/user",
            onBack: true,
            menuActive: "/casino"
        },
        {
            path: "/history",
            nameEn: "history",
            nameTh: "ประวัติการทำรายการ",
            icon: 'user',
            component: History,
            layout: "/user",
            onBack: true,
            menuActive: "/home"
        },
        {
            path: "/casino",
            nameEn: "casino",
            nameTh: "คาสิโน",
            icon: 'user',
            component: Casino,
            layout: "/user",
            onBack: true,
            menuActive: "/casino"
        },
        {
            path: "/slot",
            nameEn: "slot",
            nameTh: "สล็อต",
            icon: 'user',
            component: Slot,
            layout: "/user",
            onBack: true,
            menuActive: "/slot"
        },
        {
            path: "/sport",
            nameEn: "sport",
            nameTh: "กีฬา",
            icon: 'user',
            component: Sport,
            layout: "/user",
            onBack: true,
            menuActive: "/sport"
        },
        {
            path: "/huayhistory",
            nameEn: "huayhistory",
            nameTh: "ดูผลหวย",
            icon: 'user',
            component: HuayHistory,
            layout: "/user",
            onBack: true,
            menuActive: "/home"
        },
        {
            path: "/bonus",
            nameEn: "bonus",
            nameTh: "โบนัสรายวัน",
            icon: 'user',
            component: Bonus,
            layout: "/user",
            onBack: true,
            menuActive: "/home"

        }
    ],
    menu: [
        {
            path: "/lottery",
            nameEn: "Lottery",
            nameTh: "หวย",
            icon: 'diamond',
            component: Lottery,
            layout: "/user"
        },
        {
            path: "/sport",
            nameEn: "Sport",
            nameTh: "กีฬา",
            icon: 'sport',
            component: Home,
            layout: "/user"
        },
        {
            path: "/home",
            nameEn: "Home",
            nameTh: "หน้าแรก",
            icon: 'home',
            component: Home,
            layout: "/user"
        },
        {

            path: "/casino",
            nameEn: "Casino",
            nameTh: "คาสิโน",
            icon: 'casino',
            component: Casino,
            layout: "/user"
        },
        {
            path: "/slot",
            nameEn: "Slot",
            nameTh: "สล็อต",
            icon: 'slot',
            component: Slot,
            layout: "/user"
        },

    ]
};

export default Routes;