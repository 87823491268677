import React, { useEffect, useState } from 'react';
import { Input, Card, Container, Tab, Header, Icon, Image, Label, Responsive, Segment, Modal, CardContent } from "semantic-ui-react";
import GameService from '../../../service/GameService';
import Authen from '../../../service/Authen';
import Config from '../../../config/config';
import AlertClosed from './AlertClosed';

const list_game = [
    {
        "GameType": "LIVE",
        "GameCode": "VN-LIVE-001",
        "GameName": "Baccarat Classic"
    },
    {
        "GameType": "LIVE",
        "GameCode": "VN-LIVE-002",
        "GameName": "Baccarat Speed"
    },
    {
        "GameType": "LIVE",
        "GameCode": "VN-LIVE-003",
        "GameName": "Baccarat Insurance"
    },
    {
        "GameType": "LIVE",
        "GameCode": "VN-LIVE-006",
        "GameName": "DragonTiger"
    },
    {
        "GameType": "LIVE",
        "GameCode": "VN-LIVE-007",
        "GameName": "SicBo"
    },
    {
        "GameType": "LIVE",
        "GameCode": "VN-LIVE-008",
        "GameName": "FishPrawnCrab"
    },
    {
        "GameType": "LIVE",
        "GameCode": "VN-LIVE-009",
        "GameName": "Roulette"
    },
]
const VenusCasino = () => {
    const [loading, setLoading] = useState(false)
    const [listGames, setListGames] = useState(list_game)
    const [listGamesFiltter, setListGamesFiltter] = useState(list_game)
    const [gameAmount, setGameAmount] = useState(3);
    const [searchValue, setSearchValue] = useState('')
    const [alertClose, setAlertClose] = useState(false)

    useEffect(() => {
        resizeGame()
    }, [])

    const resizeGame = () => {
        let gameAmount = 4;
        let innerWidth = window.innerWidth;
        if (innerWidth > 620) {
            gameAmount = 4;
        } else {
            gameAmount = 2;
        }
        setGameAmount(gameAmount)
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value)
        const _listGames = listGames
        let result = _listGames.filter(gameItem => gameItem.GameName.toLowerCase().includes(e.target.value.toLowerCase()));
        setListGamesFiltter(result)
    }

    const handlePlayVenus = (GameCode, GameType) => {
        if (Config.ENABLE_VENUS_CASINO == true) {
            GameService.playCasinoVenus(GameCode, GameType).then((res) => {
                if (res.data.status == "0000") {
                    window.location.href = res.data.url
                } else {
                    setAlertClose(true)
                }
            }).catch(() => {
                setAlertClose(true)
            })
        }
    }

    return (
        <Container style={{ margin: 0, paddingBottom: 10, marginTop: 35 }}>
            <Segment loading={loading} style={{ boxShadow: 'unset', border: 0, marginBottom: 18, background: 'transparent' }}>

                <div className="search-container">
                    <input placeholder="ค้นหา" onChange={handleSearchChange} value={searchValue} />
                    <Icon name="search" />
                </div>

                <Responsive onUpdate={resizeGame}>
                    <Card.Group itemsPerRow={gameAmount} className="CardGroup-custom home-card" >
                        {
                            listGamesFiltter?.map((item, index) => {
                                return (
                                    <a
                                        className="ui card jili_slot_games_styles"
                                        target="_blank"
                                        key={index}
                                        style={{ borderRadius: 0 }}
                                        onClick={() => { handlePlayVenus(item.GameCode, item.GameType) }}
                                    >
                                        <div
                                            style={{ backgroundImage: `url(${require(`../../../assets/images/home/venus/${item.GameName.replaceAll(' ', '').replaceAll("'", '')}.jpg`)})` }}
                                        >
                                        </div>
                                        <center style={{
                                            marginTop: -7,
                                            marginBottom: -2,
                                            overflow: 'hidden',
                                            height: 30,
                                            backgroundImage: `url(${require('../../../assets/images/home/name_game_slot.png')})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            color: '#fff',
                                        }}
                                            className="name-slot-game"
                                        >
                                            <span style={{ fontSize: '0.70em' }}>
                                                {item.GameName}
                                            </span>
                                        </center>
                                    </a>
                                )

                            })
                        }
                    </Card.Group>
                </Responsive>
            </Segment>

            <AlertClosed
                open={alertClose}
                onClose={() => setAlertClose(false)}
                name={"VENUS"}
            />

        </Container >
    )
}

export default VenusCasino