import React, { useEffect, useState } from 'react';
import Authen from '../../service/Authen';
import Config from "../../config/config";
import { Card, Modal, } from "semantic-ui-react";
import { Link } from "react-router-dom";
import GameService from "../../service/GameService"

export default ({ open, onClose }) => {

    const handleClickSACasino = async () => {
        GameService.loginSA().then((res) => {
            if (res.status) {
                window.location.href = `${Config.SA.PLAY_URL}?username=${res.data.displayName}&token=${res.data.tokenSA}&lobby=${Config.SA.LOBBY_CODE}&lang=en_US&returnurl=https://${window.location.hostname}&mobile=true&options=defaulttable=601,hidelogo=1`
            }
        })
    }

    const handleClickSexyGame = async () => {
        GameService.loginSexyGame().then((res) => {
            if (res.data.status == "0000") {
                window.location.href = res.data.url
            }
        })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeIcon='close'
            className="modal-slot-game"
        >
            <div className="modal-content-slot-game">
                <Card as={Link} onClick={handleClickSACasino}>
                    <img src={require('../../assets/images/home/sa_casino.png')} style={{ width: '100%' }} />
                </Card>
                <a className="ui card" onClick={handleClickSexyGame}>
                    <img src={require('../../assets/images/home/sexy_casino.png')} style={{ width: '100%' }} />
                </a>
            </div>
        </Modal>
    )
}