const dotenv = require('dotenv');

export default {
    APIConfig: {
        apiDomain: process.env.REACT_APP_API_URL || 'https://api-uat.lotto-platform.net/api',
    },
    LineURL: process.env.REACT_APP_LINE_URL || '/',
    LineID: process.env.REACT_APP_LINE_ID || '',
    LineOaURL: process.env.REACT_APP_LINE_OA_URL || '/',
    RuleURL: process.env.REACT_APP_RULE_URL || '/',
    enableAF: process.env.REACT_APP_ENABLE_AF || true,
    enableEditBankAccount: process.env.REACT_APP_ENABLE_EDIT_BANK_ACCOUNT || true,
    maintenancePageURL: process.env.REACT_APP_MAINTENANCE_PAGE_URL || '/maintenance',
    JOKER: {
        PLAY_URL: process.env.REACT_APP_JOKER_PLAY_URL || 'https://www.gwc688.net',
        APP_ID: process.env.REACT_APP_JOKER_APP_ID || 'TGEU'
    },
    PG: {
        PLAY_URL: process.env.REACT_APP_PG_PLAY_URL || 'https://public.pg-redirect.net',
        OPERATOR_TOKEN: process.env.REACT_APP_PG_OPERATOR_TOKEN || '7fd0c01094bfb52327743c124835ad90'
    },
    SA: {
        PLAY_URL: process.env.REACT_APP_SA_PLAY_URL || 'https://www.sai.slgaming.net/app.aspx',
        LOBBY_CODE: process.env.REACT_APP_SA_LOBBY_CODE || 'A4971',
    },
    RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY || "6LfAUjMdAAAAAENgsm8cvuZzzHImvjGDkF0Yh3pX",
    ENABLE_JOKER_SLOT: process.env.REACT_APP_ENABLE_JOKER_SLOT || true,
    ENABLE_PG_SLOT: process.env.REACT_APP_ENABLE_PG_SLOT || true,
    ENABLE_AE_SLOT: process.env.REACT_APP_ENABLE_AE_SLOT || true,
    ENABLE_KINGMAKER_SLOT: process.env.REACT_APP_ENABLE_KINGMAKER_SLOT || true,
    ENABLE_SA_GAME: process.env.REACT_APP_ENABLE_SA_GAME || true,
    ENABLE_SEXY_GAME: process.env.REACT_APP_ENABLE_SEXY_GAME || true,
    ENABLE_SBO_SPORT: process.env.REACT_APP_ENABLE_SBO_SPORT || true,
    ENABLE_AFB_SPORT: process.env.REACT_APP_ENABLE_AFB_SPORT || false,
    ENABLE_JILI_SLOT: process.env.REACT_APP_ENABLE_JILI_SLOT || true,
    ENABLE_PP_SLOT: process.env.REACT_APP_ENABLE_PP_SLOT || true,
    ENABLE_SPADE_SLOT: process.env.REACT_APP_ENABLE_SPADE_SLOT || true,
    ENABLE_FC_SLOT: process.env.REACT_APP_ENABLE_FC_SLOT || true,
    ENABLE_VENUS_CASINO: process.env.REACT_APP_ENABLE_VENUS_CASINO || true,
    ENABLE_ALLBET_CASINO: process.env.REACT_APP_ENABLE_ALLBET_CASINO || true,
    AF_TYPE: process.env.REACT_APP_AF_TYPE || '1',
    ENABLE_BONUS: process.env.REACT_APP_ENABLE_BONUS || false,
    ENABLE_PG_AMB_SLOT: process.env.REACT_APP_ENABLE_PG_AMB_SLOT || false,
    ENABLE_KINGMAKER_AMB_SLOT: process.env.REACT_APP_ENABLE_KINGMAKER_AMB_SLOT || false,
    ENABLE_SEXY_AMB_SLOT: process.env.REACT_APP_ENABLE_SEXY_AMB_SLOT || false,
    ENABLE_ALL_BET_AMB_SLOT: process.env.REACT_APP_ENABLE_ALL_BET_AMB_SLOT || false,
    ENABLE_SA_AMB_CASINO: process.env.REACT_APP_ENABLE_SA_AMB_CASINO || false,
    ENABLE_PP_AMB_SLOT: process.env.REACT_APP_ENABLE_PP_AMB_SLOT || false,
    ENABLE_JOKER_AMB_SLOT: process.env.REACT_APP_ENABLE_JOKER_AMB_SLOT || false,
    ENABLE_JILI_AMB_SLOT: process.env.REACT_APP_ENABLE_JILI_AMB_SLOT || false,
    ENABLE_SPADE_AMB_SLOT: process.env.REACT_APP_ENABLE_SPADE_AMB_SLOT || false,
    HISTORY_HUAY: process.env.REACT_APP_HISTORY_HUAY || "https://lottosod365.com/result-lotto/",
    ENABLE_PRETTY_AMB: process.env.REACT_APP_ENABLE_PRETTY_AMB || false,
    ENABLE_SLOT_XO_AMB: process.env.REACT_APP_ENABLE_SLOT_XO_AMB || false,
    ENABLE_ASK_ME_SLOT_AMB: process.env.REACT_APP_ENABLE_ASK_ME_SLOT_AMB || false,
}
