import React, { useState } from 'react'
import { Button, Form, Image, Divider, Header } from 'semantic-ui-react'
import { Link, useHistory } from 'react-router-dom';
import Authen from '../../service/Authen'
import WalletService from '../../service/WalletService';
import SuccessPage from './SuccessPage';
import config from '../../config/config';
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPassword = (props) => {

    const [resetState, setResetState] = useState(1);
    const [tel, setTel] = useState("");
    const [otp, setOtp] = useState("");

    const [otpRef, setOtpRef] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [otpVerifyID, setOtpVerifyID] = useState("");

    const history = useHistory();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [submitText, setSubmitText] = useState("ขอ OTP");
    const [loadingPage, setLoadingPage] = useState(false);

    const [msgValidate, setMsgValidate] = useState("");
    const [showMsgValidate, setShowMsgValidate] = useState(false);
    const [onSuccess, setOnSuccess] = useState(false);
    const [recaptchaValue,setRecaptchaValue]=useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoadingPage(true);
        setTimeout(function () {
            if (resetState === 1) {
                Authen.otpSMSForgetpassword(tel,recaptchaValue).then(res => {
                    if (res.status) {
                        setOtpRef(res.data.otp.refcode)
                        setResetState(2);
                        setSubmitText("ยืนยัน");
                        setShowMsgValidate(false)
                    } else {
                        setMsgValidate(res.msg)
                        setShowMsgValidate(true)
                    }
                })
                setLoadingPage(false);
            } else if (resetState === 2) {
                Authen.otpVerify(tel, otpRef, otpCode).then(res => {
                    if (res.status) {
                        setOtpVerifyID(res.data.otpVerifyID)
                        setResetState(3);
                        setSubmitText("รีเซตรหัสผ่าน");
                        setShowMsgValidate(false)
                    } else {
                        setMsgValidate(res.msg)
                        setShowMsgValidate(true)
                    }
                })
                setLoadingPage(false);
            } else if (resetState === 3) {
                Authen.forgetpassword(tel, password, otpVerifyID).then(res => {
                    if (res.status) {
                        //login
                        Authen.login(tel, password).then(res => {
                            if (res.status) {
                                WalletService.fetchWalletBalance().then((res) => {
                                    // Set open onsuccess page confrim login
                                    setOnSuccess(true)
                                    history.push("/home")
                                })
                            } else {
                                setMsgValidate(res.msg)
                                setShowMsgValidate(true)
                            }
                        })
                    } else {
                        setMsgValidate(res.msg)
                        setShowMsgValidate(true)
                    }
                })
            }

        }, 1000);
    };

    const validateForm = () => {
        if (resetState === 3) {
            return tel.length === 10 && password === confirmPassword && password.length >= 6;
        }
        if (resetState === 2) {
            return tel.length === 10;
        }
        if (resetState === 1) {
            return tel.length === 10 && !(recaptchaValue=="" || recaptchaValue==null);
        }
        return tel.length === 10 && password === confirmPassword;
    };

    return (
        <div className="login-page">

            <div className="login-box">

                <Image src={require('../../assets/images/head_logo.png')} size='medium' centered style={{ 'padding': '20px' }} />
                <Form className="login-form" loading={loadingPage}>
                    <center><p style={{ marginBottom: '5px', color: "red", display: showMsgValidate ? "block" : "none" }}>* {msgValidate}</p></center>
                    <Form.Field style={{ display: resetState === 2 || resetState === 1 ? 'inherit' : 'none' }}>
                        <label>เบอร์โทรศัพท์</label>
                        <input className="login-input" placeholder='เบอร์โทรศัพท์' disabled={resetState === 2} onChange={e => setTel(e.target.value)} />
                        <Form.Field style={{ display: resetState === 1 ? 'inherit' : 'none' }}>
                            <center>
                                <ReCAPTCHA style={{marginTop:15}} size='normal'
                                    sitekey={config.RECAPTCHA_SITE_KEY}
                                    onChange={(value)=>setRecaptchaValue(value)}
                                />
                            </center>
                        </Form.Field>
                    </Form.Field>
                    <Form.Field style={{ display: resetState === 2 ? 'inherit' : 'none' }}>
                        <label>OTP (หมายเลขอ้างอิง : {otpRef})</label>
                        <input className="login-input" placeholder='หมายเลข OTP จาก SMS' type='text' onChange={e => setOtpCode(e.target.value)} />
                    </Form.Field>
                    <Form.Field style={{ display: resetState === 3 ? 'inherit' : 'none' }}>
                        <label>รหัสผ่านใหม่</label>
                        <input className="login-input" placeholder='รหัสผ่านใหม่' type='password' onChange={e => setPassword(e.target.value)} />
                    </Form.Field>
                    <Form.Field style={{ display: resetState === 3 ? 'inherit' : 'none' }}>
                        <label>ยืนยันรหัสผ่าน</label>
                        <input className="login-input" placeholder='กรอกรหัสผ่านอีกครั้ง' type='password' onChange={e => setConfirmPassword(e.target.value)} />
                    </Form.Field>
                    <Button type='submit' color='green' style={{ marginBottom: '1rem' }} disabled={!validateForm()} onClick={(event) => handleSubmit(event)}>{submitText}</Button>
                    {/* <Button style={{ marginBottom: '1rem' }} type='button' color='teal' disabled={!validateForm()} onClick={handleSubmit}>{submitText}</Button> */}
                    <br />
                    {/* <Divider horizontal>หรือ</Divider> */}

                    <Button onClick={() => history.push('/')} style={{ background: '#999' }} type='button' fluid>
                        ยกเลิก
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default ForgotPassword;