import React, { useEffect, useState } from 'react';
import { Input, Card, Container, Tab, Header, Icon, Image, Label, Responsive, Segment, Modal, CardContent } from "semantic-ui-react";
import GameService from '../../../service/GameService';
import Authen from '../../../service/Authen';
import Config from '../../../config/config';
import AlertClosed from './AlertClosed';

const list_game = [
    {
        "GameType": "FH",
        "GameCode": "SG-FISH-001",
        "GameName": "Fishing God"
    },
    {
        "GameType": "FH",
        "GameCode": "SG-FISH-002",
        "GameName": "Fishing War"
    },
    {
        "GameType": "FH",
        "GameCode": "SG-FISH-003",
        "GameName": "Alien Hunter"
    },
    {
        "GameType": "FH",
        "GameCode": "SG-FISH-004",
        "GameName": "Zombie Party"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-002",
        "GameName": "GoldenFist"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-003",
        "GameName": "FaFaFa2"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-004",
        "GameName": "GangsterAxe"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-005",
        "GameName": "PrincessWang"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-006",
        "GameName": "WowProsperity"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-008",
        "GameName": "FirstLove"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-009",
        "GameName": "GoldenMonkey"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-010",
        "GameName": "PanFairy"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-011",
        "GameName": "ShangHai008"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-012",
        "GameName": "JungleKing"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-013",
        "GameName": "FistofGold"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-014",
        "GameName": "TigerWarrior"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-015",
        "GameName": "SeaEmperor"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-016",
        "GameName": "ZEUS"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-017",
        "GameName": "HoYeahMonkey"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-018",
        "GameName": "GoldenChicken"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-019",
        "GameName": "DragonGoldSA"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-020",
        "GameName": "EmperorGateSA"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-021",
        "GameName": "BigProsperitySA"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-022",
        "GameName": "GreatStarsSA"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-023",
        "GameName": "IcelandSA"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-029",
        "GameName": "WongChoySA"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-030",
        "GameName": "5FortuneSA"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-031",
        "GameName": "BabyCaiShen"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-032",
        "GameName": "GoldenLotusSE"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-033",
        "GameName": "Mermaid"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-034",
        "GameName": "KingPharaoh"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-035",
        "GameName": "WongPo"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-036",
        "GameName": "Alibaba"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-037",
        "GameName": "CaiShen888"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-038",
        "GameName": "DoubleFortunes"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-039",
        "GameName": "5FortuneDragons"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-040",
        "GameName": "GodsKitchen"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-041",
        "GameName": "LuckyKoi"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-043",
        "GameName": "DrunkenJungle"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-044",
        "GameName": "HoneyHunter"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-045",
        "GameName": "Fafafa"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-046",
        "GameName": "LuckyFengShui"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-047",
        "GameName": "PirateKing"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-048",
        "GameName": "GolfChampions"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-049",
        "GameName": "HighwayFortune"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-050",
        "GameName": "FestiveLion"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-052",
        "GameName": "MasterChef"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-053",
        "GameName": "CaiYuanGuangJin"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-054",
        "GameName": "DaddysVacation"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-055",
        "GameName": "DaFuXiaoFu"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-058",
        "GameName": "GoldenWhale"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-059",
        "GameName": "LuckyCaiShen"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-060",
        "GameName": "LuckyMeow"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-063",
        "GameName": "SpinStone"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-064",
        "GameName": "SantaGifts"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-068",
        "GameName": "HeroesGame"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-069",
        "GameName": "888"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-070",
        "GameName": "DancingFever"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-071",
        "GameName": "MoneyMouse"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-072",
        "GameName": "SweetBakery"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-073",
        "GameName": "ThreeLuckyStars"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-074",
        "GameName": "Mega7"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-075",
        "GameName": "JokersTreasure"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-076",
        "GameName": "DoubleFlame"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-077",
        "GameName": "BookofMyth"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-078",
        "GameName": "CrazyBomber"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-079",
        "GameName": "FierySevens"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-080",
        "GameName": "MagicalLamp"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-081",
        "GameName": "TriplePanda"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-082",
        "GameName": "GoldPanther"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-083",
        "GameName": "MrChuTycoon"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-084",
        "GameName": "BrothersKingdom"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-085",
        "GameName": "ProsperityGods"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-086",
        "GameName": "Mayan Gems"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-087",
        "GameName": "Rise Of Werewolves"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-088",
        "GameName": "MoodieFoodie"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-089",
        "GameName": "Hugon Quest"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-090",
        "GameName": "Kungfu Dragon"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-091",
        "GameName": "Magic Kitty"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-092",
        "GameName": "Sugar Bonanza"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-093",
        "GameName": "Love Idol"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-094",
        "GameName": "Ruby Hood"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-095",
        "GameName": "Poker Ways"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-096",
        "GameName": "Rich Caishen"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-097",
        "GameName": "Muay Thai Fighter"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-098",
        "GameName": "Royal Katt"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-099",
        "GameName": "Wild Wet Win"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-100",
        "GameName": "Gold Rush Cowboy"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-101",
        "GameName": "Candy Candy"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-102",
        "GameName": "Roma"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-103",
        "GameName": "Sexy Vegas"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-104",
        "GameName": "Tiger Dance"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-105",
        "GameName": "Royale House"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-106",
        "GameName": "Fiery Sevens Exclusive"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-107",
        "GameName": "Lucky Koi Exclusive"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-108",
        "GameName": "Joker's Treasure Exclusive"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-109",
        "GameName": "Caishen"
    },
    {
        "GameType": "SLOT",
        "GameCode": "SG-SLOT-110",
        "GameName": "Space Conquest"
    },
]
const SpadeSlotGame = () => {
    const [loading, setLoading] = useState(false)
    const [listGames, setListGames] = useState(list_game)
    const [listGamesFiltter, setListGamesFiltter] = useState(list_game)
    const [gameAmount, setGameAmount] = useState(3);
    const [searchValue, setSearchValue] = useState('')
    const [alertClose, setAlertClose] = useState(false)

    useEffect(() => {
        resizeGame()
    }, [])

    const resizeGame = () => {
        let gameAmount = 4;
        let innerWidth = window.innerWidth;
        if (innerWidth > 620) {
            gameAmount = 4;
        } else {
            gameAmount = 2;
        }
        setGameAmount(gameAmount)
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value)
        const _listGames = listGames
        let result = _listGames.filter(gameItem => gameItem.GameName.toLowerCase().includes(e.target.value.toLowerCase()));
        setListGamesFiltter(result)
    }

    const handleChangeTab = (e, data) => {
        setSearchValue('')
        let _listGames = listGames
        let result = []
        if (data.activeIndex == 0) {
            setListGamesFiltter([...listGames])
        }
        if (data.activeIndex == 1) {
            result = _listGames.filter(gameItem => gameItem.GameType == 'SLOT');
            setListGamesFiltter([...result])
        }
        if (data.activeIndex == 2) {
            result = _listGames.filter(gameItem => gameItem.GameType == 'FH');
            setListGamesFiltter([...result])
        }
    }

    const panes = [
        {
            menuItem: {
                content: <center className="tabSlotItem">
                    <Image src={require('../../../assets/images/home/slot_game_ecasino_2.png')} />
                    <div>เกมส์ทั้งหมด</div>
                </center>
            }
        },
        {
            menuItem: {
                content: <center className="tabSlotItem">
                    <Image src={require('../../../assets/images/home/slot_game_slot_2.png')} />
                    <div>สล็อต</div>
                </center>
            }
        },
        {
            menuItem: {
                content: <center className="tabSlotItem">
                    <Image src={require('../../../assets/images/home/slot_game_fish_2.png')} />
                    <div>ยิงปลา</div>
                </center>
            }
        }
    ]

    const handlePlaySpade = (GameCode, GameType) => {
        if (Config.ENABLE_SPADE_SLOT == true) {
            GameService.playSlotSPADE(GameCode, GameType).then((res) => {
                if (res.data.status == "0000") {
                    window.location.href = res.data.url
                } else {
                    setAlertClose(true)
                }
            }).catch(() => {
                setAlertClose(true)
            })
        }
    }

    return (
        <Container style={{ margin: 0, paddingBottom: 10, marginTop: 35 }}>
            <Segment loading={loading} style={{ boxShadow: 'unset', border: 0, marginBottom: 18, background: 'transparent' }}>

                <div className="contianer-tab-slot-games">
                    <Tab panes={panes} onTabChange={handleChangeTab} className="tabSlotGame tabSlotGame2" />
                </div>

                <div className="search-container">
                    <input placeholder="ค้นหา" onChange={handleSearchChange} value={searchValue} />
                    <Icon name="search" />
                </div>

                <Responsive onUpdate={resizeGame}>
                    <Card.Group itemsPerRow={gameAmount} className="CardGroup-custom home-card" >
                        {
                            listGamesFiltter?.map((item, index) => {
                                return (
                                    <a
                                        className="ui card jili_slot_games_styles"
                                        target="_blank"
                                        key={index}
                                        style={{ borderRadius: 0 }}
                                        onClick={() => { handlePlaySpade(item.GameCode, item.GameType) }}
                                    >
                                        <div
                                            style={{ backgroundImage: `url(${require(`../../../assets/images/home/spade/${item.GameName.replaceAll(' ', '').replaceAll("'", '')}.png`)})` }}
                                        >
                                        </div>
                                        <center style={{
                                            marginTop: -7,
                                            marginBottom: -2,
                                            overflow: 'hidden',
                                            height: 30,
                                            backgroundImage: `url(${require('../../../assets/images/home/name_game_slot.png')})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            color: '#fff',
                                        }}
                                            className="name-slot-game"
                                        >
                                            <span style={{ fontSize: '0.70em' }}>
                                                {item.GameName}
                                            </span>
                                        </center>
                                    </a>
                                )

                            })
                        }
                    </Card.Group>
                </Responsive>
            </Segment>

            <AlertClosed
                open={alertClose}
                onClose={() => setAlertClose(false)}
                name={"SPADE"}
            />

        </Container >
    )
}

export default SpadeSlotGame