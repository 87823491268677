import React, {Component} from 'react'
import {
    Button, Divider,
    Header,
    Icon,
    Image,
    Input, Loader,
    Message,
    Modal,
    Progress,
    Step
} from "semantic-ui-react";

class TopUpCredit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            show: 'mini',
            close: true,
            money: 0,
            next: true,
            time: {},
            seconds: 900,
            countDownPercent: 100,
            step: 1,
            loader: false
        };
        this.timer = 0;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({time: timeLeftVar});
    }

    handleChangeMoney = (event) => {
        this.setState({next: true})
        let value = event.target.value.replace(/,/g, '');
        if (!isNaN(value)) {
            value = value * 1;
            if ((value.toString()).length < 7) {
                if (value >= 20) {
                    this.setState({next: false})
                }
                let valueReplace = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.setState({money: valueReplace})
            } else {
                this.setState({next: false})
            }
        }
    };

    handleClosePopup = () => {
        let timeLeftVar = this.secondsToTime(900);
        this.setState({
            money: 0,
            next: true,
            countDownPercent: 100,
            step: 1,
            time: timeLeftVar
        });
        this.props.handleCloseTopUpCredit();
    };

    startTimer = () => {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    };

    secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        return {
            "h": hours,
            "m": minutes,
            "s": seconds.toString().length == 1 ? '0' + seconds : seconds
        };
    };

    countDown = () => {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
            countDownPercent: parseInt((seconds * 100 / 900).toFixed(0))
        });
        if (seconds == 0) {
            clearInterval(this.timer);
        }
    };

    render() {
        return (
            <Modal centered={false} open={this.props.openTopUpCredit} onClose={this.handleClosePopup}>
                <Loader active={this.state.loader} className='workaround' size='large'/>
                <Modal.Header>เติมเครดิต</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Step.Group fluid ordered>
                            <Step completed={this.state.step > 0}>
                                <Step.Content>
                                    <Step.Title>ระบุจำนวนเงิน</Step.Title>
                                </Step.Content>
                            </Step>

                            <Step completed={this.state.step > 1}>
                                <Step.Content>
                                    <Step.Title>โอนเงิน</Step.Title>
                                </Step.Content>
                            </Step>

                            <Step completed={this.state.step > 2}>
                                <Step.Content>
                                    <Step.Title>รอยืนยันการโอน</Step.Title>
                                </Step.Content>
                            </Step>
                        </Step.Group>
                        <br/>
                        <br/>
                    </Modal.Description>
                    <Modal.Description className={this.state.step != 1 ? "d-none" : ""}>
                        <Header color={"grey"}><Icon
                            name="money bill alternate outline"/>จำนวนเงินที่ต้องการโอน</Header>
                        <Message color="teal">
                            <p>ระบุยอดเงินที่ต้องการเป็นจำนวนเต็ม ระบบจะทำการสุ่มทศนิยมให้
                                (อย่าเพิ่งโอนเงินจริงในขั้นตอนนี้)</p>
                        </Message>

                        <Header as='p' color="red" size={"tiny"}>โอนขั้นต่ำ "ครั้งละ 20 บาท"</Header>
                        <Input placeholder='0 บาท' icon='bitcoin' fluid value={this.state.money} onChange={(event) => {
                            this.handleChangeMoney(event)
                        }}/>

                        <Header color={"grey"}><Icon name="university"/>ธนาคาร</Header>
                        <Message color="teal">
                            <p>ระบบจะเลือกธนาคาร และเลขที่บัญชีให้อัตโนมัติ</p>
                        </Message>
                        <Message color="yellow">
                            <p>* ระบบธนาคารจะปิดปรับปรุงประจำวัน จึงขอปิดระบบฝากและถอน ตั้งแต่เวลา
                                23:00 ถึง 1:00 ขออภัยในความไม่สะดวก</p>
                        </Message>
                        <Button disabled={this.state.next} onClick={() => {
                            this.setState({seconds: 900, loader: true}, () => {
                                setTimeout(() => {
                                    this.setState({step: 2, loader: false});
                                }, 700)
                            });
                            this.startTimer();
                        }} fluid>ถัดไป</Button>
                    </Modal.Description>
                    <Modal.Description className={this.state.step != 2 ? "d-none" : ""}>
                        <Header color={"grey"} className="text-center"><Icon name="university"/>กรุณาโอนเงินใน 15
                            นาที</Header>
                        <Header as="h2" textAlign={"center"} color={"green"}
                                size={"large"}>{this.state.time.m}:{this.state.time.s}</Header>
                        <Message size={"big"}>
                            <Message.Header>ธนาคารกรุงศรีอยุธยา</Message.Header>
                            <Message.Content>
                                <Divider/>
                                <Image style={{'background-color': 'rgb(254, 196, 59)'}} floated='left'
                                       src='https://play.huaydee.com/img/bay.78f13504.svg' size='mini'/>
                                <p>
                                    <div>ชื่อบัญชี: มานพ รัตนพล</div>
                                    <div>เลขบัญชี: 04311997753</div>
                                </p>
                            </Message.Content>
                        </Message>

                        <Message size={"big"}>
                            <Message.Header>จำนวนเงินที่ต้องการโอน</Message.Header>
                            <Message.Content>
                                <Divider/>
                                <Header as='h1' color='green' textAlign={"center"}>
                                    {this.state.money}.00
                                </Header>
                            </Message.Content>
                        </Message>

                        <Message color="red">
                            <p>โอนเงินจำนวน {this.state.money}.00 ด้วยบัญชีที่ท่านผูกกับ Huay Easy เท่านั้น</p>
                        </Message>
                        <Message color="red">
                            <p>ห้ามจำบัญชีเดิมเพื่อทำการโอนเงิน ระบบจะเลือกบัญชี Huay Easy ให้ใหม่ทุกครั้ง</p>
                        </Message>
                        <Message color="yellow">
                            <p>* ระบบธนาคารจะปิดปรับปรุงประจำวัน จึงขอปิดระบบฝากและถอน ตั้งแต่เวลา 23:00 ถึง 1:00
                                ขออภัยในความไม่สะดวก</p>
                        </Message>

                        <Button color={"green"} onClick={() => {
                            this.setState({seconds: 300, loader: true}, () => {
                                setTimeout(() => {
                                    this.setState({step: 3, loader: false});
                                }, 700)
                            });
                        }} fluid>เมื่อโอนแล้วคลิกที่นี่</Button>

                    </Modal.Description>
                    <Modal.Description className={this.state.step != 3 ? "d-none" : ""}>
                        <Header as="h1" color={"green"}
                                className="text-center">{this.state.time.m}:{this.state.time.s}</Header>
                        <Header as="h5" className="text-center">รอยืนยันจากระบบตรวจสอบอัตโนมัติ</Header>
                        <Button color={"green"} fluid onClick={this.handleClosePopup}>เสร็จสิ้น</Button>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={this.handleClosePopup}>ยกเลิก</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default TopUpCredit;
