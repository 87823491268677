import React, { useEffect, useState } from 'react';
import { Input, Card, Container, Tab, Header, Icon, Image, Label, Responsive, Segment, Modal, CardContent } from "semantic-ui-react";
import GameService from '../../../service/GameService';
import Authen from '../../../service/Authen';
import Config from '../../../config/config';
import AlertClosed from './AlertClosed';

const list_game = [
    {
        "GameType": "EGAME",
        "GameCode": "FC-EGAME-001",
        "GameName": "MONEY TREE DOZER"
    },
    {
        "GameType": "EGAME",
        "GameCode": "FC-EGAME-002",
        "GameName": "FA CHAI DOZER"
    },
    {
        "GameType": "EGAME",
        "GameCode": "FC-EGAME-003",
        "GameName": "Circus Dozer"
    },
    {
        "GameType": "EGAME",
        "GameCode": "FC-EGAME-004",
        "GameName": "LIGHTNING BOMB"
    },
    {
        "GameType": "FH",
        "GameCode": "FC-FISH-001",
        "GameName": "Monkey King Fishing"
    },
    {
        "GameType": "FH",
        "GameCode": "FC-FISH-002",
        "GameName": "Bao Chuan Fishing"
    },
    // {
    //     "GameType": "FH",
    //     "GameCode": "FC-FISH-003",
    //     "GameName": "UFA FISHING"
    // },
    {
        "GameType": "FH",
        "GameCode": "FC-FISH-004",
        "GameName": "FIERCE FISHING"
    },
    {
        "GameType": "FH",
        "GameCode": "FC-FISH-005",
        "GameName": "STAR HUNTER"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-001",
        "GameName": "GOLDEN PANTHER"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-002",
        "GameName": "THREE LITTLE PIGS"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-003",
        "GameName": "HOT POT PARTY"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-004",
        "GameName": "NIGHT MARKET"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-005",
        "GameName": "PONG PONG HU"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-006",
        "GameName": "PANDA DRAGON BOAT"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-007",
        "GameName": "CHINESE NEW YEAR"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-008",
        "GameName": "FORTUNE KOI"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-009",
        "GameName": "HAPPY DUO BAO"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-010",
        "GameName": "DA LE MEN"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-011",
        "GameName": "ANIMAL RACING"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-012",
        "GameName": "LUXURY GOLDEN PANTHER"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-013",
        "GameName": "Magic Beans"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-014",
        "GameName": "COWBOYS"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-015",
        "GameName": "WAR OF THE UNIVERSE"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-016",
        "GameName": "TREASURE CRUISE"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-017",
        "GameName": "GOLD RUSH"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-018",
        "GameName": "RICH MAN"
    },
    {
        "GameType": "SLOT",
        "GameCode": "FC-SLOT-019",
        "GameName": "GLORY OF ROME"
    },
]
const FCSlotGame = () => {
    const [loading, setLoading] = useState(false)
    const [listGames, setListGames] = useState(list_game)
    const [listGamesFiltter, setListGamesFiltter] = useState(list_game)
    const [gameAmount, setGameAmount] = useState(3);
    const [searchValue, setSearchValue] = useState('')
    const [alertClose, setAlertClose] = useState(false)

    useEffect(() => {
        resizeGame()
    }, [])

    const resizeGame = () => {
        let gameAmount = 4;
        let innerWidth = window.innerWidth;
        if (innerWidth > 620) {
            gameAmount = 4;
        } else {
            gameAmount = 2;
        }
        setGameAmount(gameAmount)
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value)
        const _listGames = listGames
        let result = _listGames.filter(gameItem => gameItem.GameName.toLowerCase().includes(e.target.value.toLowerCase()));
        setListGamesFiltter(result)
    }

    const handleChangeTab = (e, data) => {
        setSearchValue('')
        let _listGames = listGames
        let result = []
        if (data.activeIndex == 0) {
            setListGamesFiltter([...listGames])
        }
        if (data.activeIndex == 1) {
            result = _listGames.filter(gameItem => gameItem.GameType == 'SLOT');
            setListGamesFiltter([...result])
        }
        if (data.activeIndex == 2) {
            result = _listGames.filter(gameItem => gameItem.GameType == 'FH');
            setListGamesFiltter([...result])
        }
        if (data.activeIndex == 3) {
            result = _listGames.filter(gameItem => gameItem.GameType == 'EGAME');
            setListGamesFiltter([...result])
        }
    }

    const panes = [
        {
            menuItem: {
                content: <center className="tabSlotItem">
                    <Image src={require('../../../assets/images/home/slot_game_ecasino_2.png')} />
                    <div>เกมส์ทั้งหมด</div>
                </center>
            }
        },
        {
            menuItem: {
                content: <center className="tabSlotItem">
                    <Image src={require('../../../assets/images/home/slot_game_slot_2.png')} />
                    <div>สล็อต</div>
                </center>
            }
        },
        {
            menuItem: {
                content: <center className="tabSlotItem">
                    <Image src={require('../../../assets/images/home/slot_game_fish_2.png')} />
                    <div>ยิงปลา</div>
                </center>
            }
        }
        ,
        {
            menuItem: {
                content: <center className="tabSlotItem">
                    <Image src={require('../../../assets/images/home/slot_game_all_2.png')} />
                    <div>อีเกมส์</div>
                </center>
            }
        }
    ]

    const handlePlayFC = (GameCode, GameType) => {
        if (Config.ENABLE_FC_SLOT == true) {
            GameService.playSlotFC(GameCode, GameType).then((res) => {
                if (res.data.status == "0000") {
                    window.location.href = res.data.url
                } else {
                    setAlertClose(true)
                }
            }).catch(() => {
                setAlertClose(true)
            })
        }
    }

    return (
        <Container style={{ margin: 0, paddingBottom: 10, marginTop: 35 }}>
            <Segment loading={loading} style={{ boxShadow: 'unset', border: 0, marginBottom: 18, background: 'transparent' }}>

                <div className="contianer-tab-slot-games">
                    <Tab panes={panes} onTabChange={handleChangeTab} className="tabSlotGame tabSlotGame2" />
                </div>

                <div className="search-container">
                    <input placeholder="ค้นหา" onChange={handleSearchChange} value={searchValue} />
                    <Icon name="search" />
                </div>

                <Responsive onUpdate={resizeGame}>
                    <Card.Group itemsPerRow={gameAmount} className="CardGroup-custom home-card" >
                        {
                            listGamesFiltter?.map((item, index) => {
                                return (
                                    <a
                                        className="ui card jili_slot_games_styles"
                                        target="_blank"
                                        key={index}
                                        style={{ borderRadius: 0 }}
                                        onClick={() => { handlePlayFC(item.GameCode, item.GameType) }}
                                    >
                                        <div
                                            style={{ backgroundImage: `url(${require(`../../../assets/images/home/fc/${item.GameName.replaceAll(' ', '').replaceAll("'", '')}.png`)})` }}
                                        >
                                        </div>
                                        <center style={{
                                            marginTop: -7,
                                            marginBottom: -2,
                                            overflow: 'hidden',
                                            height: 30,
                                            backgroundImage: `url(${require('../../../assets/images/home/name_game_slot.png')})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            color: '#fff',
                                        }}
                                            className="name-slot-game"
                                        >
                                            <span style={{ fontSize: '0.70em' }}>
                                                {item.GameName}
                                            </span>
                                        </center>
                                    </a>
                                )

                            })
                        }
                    </Card.Group>
                </Responsive>
            </Segment>

            <AlertClosed
                open={alertClose}
                onClose={() => setAlertClose(false)}
                name={"FA CHAI"}
            />

        </Container >
    )
}

export default FCSlotGame