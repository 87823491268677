import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Segment,
  Dimmer,
  Message,
  Modal,
} from "semantic-ui-react";
import { BankObject } from "../profile/Setting";
import UserService from "../../../service/UserService";
import WalletService from "../../../service/WalletService";
import Countdown from "react-countdown";
import config from "../../../config/config";
class TopUpCreditByAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      show: "mini",
      close: true,
      money: 0,
      next: true,
      time: {},
      seconds: 900,
      countDownPercent: 100,
      step: 1,
      loader: false,
      adminBank: {},
      ENABLE_DEPOSIT_CREDIT: false,
      deposit_message: "",
      minTopUp: 0,
      maxTopUp: 0,
      stepTopUp: 1,
      dateTimeOut: "",
      BANK_SYSTEM_DEPOSIT_AUTO_SYSTEM: "",
      qrcodeDeposit: "",
      moneyDeposit: "",
      DEPOSIT_ALLOW_TIME: true,
      VIZPAY_DEPOST_WAITING: {
        qrcode: "",
        timeout: "",
      },
    };
    this.timer = 0;
  }

  componentDidMount() {
    // if (UserService.getBankAccount()) {
    this.setState({ loader: true });
    UserService.getBankConfig().then((res) => {
      if (res.status === "0000" && res.data.BANK_SYSTEM_ENABLE_DEPOSIT_CREDIT) {
        this.setState({
          ENABLE_DEPOSIT_CREDIT: res.data?.BANK_SYSTEM_ENABLE_DEPOSIT_CREDIT,
        });

        if (res.data.BANK_SYSTEM_DEPOSIT_AUTO_SYSTEM == "BANK_API") {
          UserService.getBankTransection().then((res) => {
            if (res.status === "0000") {
              this.setState({ adminBank: res.data, loader: false });
            } else {
              this.setState({ deposit_message: res.message, loader: false });
            }
          });
        } else {
          this.setState({
            BANK_SYSTEM_DEPOSIT_AUTO_SYSTEM:
              res.data?.BANK_SYSTEM_DEPOSIT_AUTO_SYSTEM,
            ENABLE_DEPOSIT_CREDIT: res.data?.BANK_SYSTEM_ENABLE_DEPOSIT_CREDIT,
            loader: false,
            maxTopUp: res.data?.BANK_SYSTEM_MAXIMUM_DEPOSIT,
            minTopUp: res.data?.BANK_SYSTEM_MINIMUN_DEPOSIT,
            DEPOSIT_ALLOW_TIME: res.data?.DEPOSIT_ALLOW_TIME,
            VIZPAY_DEPOST_WAITING: res.data?.VIZPAY_DEPOST_WAITING,
          });
        }
      } else {
        this.setState({
          ENABLE_DEPOSIT_CREDIT: res.data?.BANK_SYSTEM_ENABLE_DEPOSIT_CREDIT,
          loader: false,
        });
      }
    });
  }

  handleChangeMoney = (event) => {
    this.setState({ next: true });
    let value = event.target.value.replace(/,/g, "");
    if (!isNaN(value)) {
      value = value * 1;
      if (value.toString().length < 7) {
        if (value >= (this.state.minTopUp || 20)) {
          this.setState({ next: false });
        }
        let valueReplace = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // ตั้ง max ฝากด้วย this.state.maxTopUp
        if (Number(value) > this.state.maxTopUp) {
          this.setState({
            money: this.state.maxTopUp
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          });
        } else {
          this.setState({ money: valueReplace });
        }
      } else {
        this.setState({ next: false });
      }
    }
  };

  handleClosePopup = () => {
    let timeLeftVar = this.secondsToTime(900);
    this.setState({
      money: 0,
      next: true,
      countDownPercent: 100,
      step: 1,
      time: timeLeftVar,
    });
    this.props.handleCloseTopUpCreditByAccount();
  };

  startTimer = () => {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    return {
      h: hours,
      m: minutes,
      s: seconds.toString().length == 1 ? "0" + seconds : seconds,
    };
  };

  countDown = () => {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
      countDownPercent: parseInt(((seconds * 100) / 900).toFixed(0)),
    });
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  };

  copyToClipboard = (str) => {
    let el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  toTwoDecimal = (num) => {
    let num_new =
      parseInt(Number(Number((Number(num) * 10).toFixed(3)) * 10).toFixed(3)) /
      100.0;
    let _num = num_new.toString().split(".");
    if (_num.length > 1) {
      return (
        _num[0] +
        "." +
        (_num[1].length < 2
          ? `${_num[1].substring(0, 2)}0`
          : _num[1].substring(0, 2))
      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return (_num[0] + ".00").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  downloadImage = (imageUrl) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = new Date().toISOString()?.split("T")[0] + ".png";
        document.body.appendChild(a);

        a.click();

        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading image:", error));
  };

  flowVispayDeposit = (amount) => {
    this.setState({
      loader: true,
    });
    try {
      UserService.vizpayDeposit(amount).then((res) => {
        if (res.data?.status == "0000") {
          this.setState({
            step: 2,
            loader: false,
            dateTimeOut: res.data?.data?.timeout,
            qrcodeDeposit: res.data?.data?.qrcode,
            moneyDeposit: res.data?.data?.amount,
          });
        } else {
          this.setState({
            deposit_message: res.data?.message,
            loader: false,
          });
        }
      });
    } catch (error) {
      this.setState({
        loader: false,
      });
    }
  };

  render() {
    return (
      <Modal
        centered={false}
        open={this.props.openTopUpCreditByAccount}
        onClose={this.handleClosePopup}
      >
        {UserService.getBankAccount() ? (
          <>
            {this.state.BANK_SYSTEM_DEPOSIT_AUTO_SYSTEM == "VIZPAY" ? (
              !!this.state.VIZPAY_DEPOST_WAITING ? (
                <>
                  <Modal.Header
                    style={{
                      textAlign: "center",
                    }}
                  >
                    จ่ายเงินผ่าน QR CODE นี้
                    <div
                      style={{
                        color: "red",
                        fontWeight: "400",
                        fontSize: "1rem",
                        marginTop: 10,
                      }}
                    >
                      * QR CODE ปัจจุบันจะถูกยกเลิก หาก *
                    </div>
                    <div
                      style={{
                        color: "red",
                        fontWeight: "400",
                        fontSize: "1rem",
                        marginBottom: 10,
                      }}
                    >
                      หมดเวลา ,กดปิด ,กดสร้าง QR CODE ใหม่
                    </div>
                  </Modal.Header>

                  <Modal.Content
                    as={Segment}
                    style={{ margin: 0, border: 0 }}
                    loading={this.state.loader}
                  >
                    <Modal.Description>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: 400,
                          }}
                        >
                          <div
                            style={{
                              color: "#F56D14",
                              textAlign: "center",
                              fontSize: "1.5rem",
                              fontWeight: "500",
                            }}
                          >
                            ต้องโอนจาก {UserService.getBankAccount().bankName}
                          </div>

                          <div
                            style={{
                              color: "#F56D14",
                              textAlign: "center",
                              fontSize: "1.5rem",
                              fontWeight: "500",
                            }}
                          >
                            เลขบัญชี {UserService.getBankAccount().bankNumber}{" "}
                            เท่านั้น
                          </div>

                          {/* <div

                                                    style={{
                                                        color: "#F56D14",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                        fontWeight: "500"
                                                    }}
                                                >
                                                    ยอด {this.state.moneyDeposit.toString()} บาท เท่านั้น
                                                </div> */}

                          <div
                            style={{
                              padding: "20px 0px 10px",
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={this.state.VIZPAY_DEPOST_WAITING.qrcode}
                              style={{
                                width: 250,
                                height: 250,
                              }}
                            />
                          </div>

                          <center
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontWeight: 400,
                            }}
                          >
                            ** คำเตือน : QR Code
                            สามารถจ่ายได้เพียงครั้งเดียวเท่านั้น **
                          </center>
                          <center
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontWeight: 400,
                              marginBottom: 5,
                            }}
                          >
                            ** โปรดอย่าสแกนจ่ายซ้ำ **
                          </center>

                          <Countdown
                            date={this.state.VIZPAY_DEPOST_WAITING.timeout}
                            renderer={({
                              hours,
                              minutes,
                              seconds,
                              completed,
                            }) => {
                              if (completed) {
                                // Render a completed state
                                return (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginBottom: 10,
                                    }}
                                  >
                                    หมดเวลา
                                  </div>
                                );
                              } else {
                                // Render a countdown
                                return (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginBottom: 10,
                                    }}
                                  >
                                    หมดเวลาในอีก{" "}
                                    {hours.toString()?.length == 1
                                      ? "0" + hours
                                      : hours}
                                    :
                                    {minutes.toString()?.length == 1
                                      ? "0" + minutes
                                      : minutes}
                                    :
                                    {seconds.toString()?.length == 1
                                      ? "0" + seconds
                                      : seconds}{" "}
                                    นาที
                                  </div>
                                );
                              }
                            }}
                          />

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                borderRadius: 15,
                                background: "#FFF8DB",
                                color: "#A88531",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                border: "1px solid #A88531",
                                padding: "10px 0px",
                                cursor: "pointer",
                                width: 300,
                              }}
                              onClick={() => {
                                this.downloadImage(this.state.qrcodeDeposit);
                              }}
                            >
                              <Icon
                                name={"download"}
                                style={{
                                  marginRight: 10,
                                  color: "#A88531",
                                }}
                              />
                              ดาวน์โหลด
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button negative onClick={this.handleClosePopup}>
                      ปิด
                    </Button>
                  </Modal.Actions>
                </>
              ) : this.state.step == 1 ? (
                <>
                  <Modal.Header
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    เติมเครดิต
                    <Icon
                      name="times"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 5,
                      }}
                      onClick={this.handleClosePopup}
                    />
                  </Modal.Header>

                  <Modal.Content
                    as={Segment}
                    style={{ margin: 0, border: 0 }}
                    loading={this.state.loader}
                  >
                    {!!this.state.DEPOSIT_ALLOW_TIME ? (
                      // <Modal.Description>
                      //     <Message size={"big"}>
                      //         <Message.Header><Icon name="university" />เครดิตของท่าน</Message.Header>
                      //         <Message.Content>
                      //             <Divider />
                      //             <Header as={"h1"} className="text-center">{this.toTwoDecimal(WalletService.getWalletBalance().credit)} ฿</Header>
                      //         </Message.Content>
                      //     </Message>
                      //     <Header>จำนวนเงินที่ต้องการฝาก</Header>
                      //     <Input
                      //         placeholder='0 บาท'
                      //         icon='bitcoin'
                      //         fluid
                      //         value={this.state.money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      //         onChange={(event) => {
                      //             this.handleChangeMoney(event)
                      //         }}
                      //     />

                      //     <Message color="yellow" >
                      //         <p style={{ fontSize: 13 }}>* ฝากขั้นต่ำ "ครั้งละ {this.state.minTopUp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท" ไม่เกิน "ครั้งละ {this.state.maxTopUp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท"</p>
                      //         <p style={{ fontSize: 13 }}>* ช่วงเวลาทำการของระบบฝาก (Deposit) คือ 02:01 - 22:54 น. [ปิดฝากช่วงเวลา 22:55 - 02:00 น.]</p>

                      //     </Message>

                      //     {
                      //         this.state.deposit_message &&
                      //         <div
                      //             style={{
                      //                 color: "#C03A37",
                      //                 textAlign: "center",
                      //                 marginBottom: 10
                      //             }}
                      //         >
                      //             ** {this.state.deposit_message} **
                      //         </div>
                      //     }

                      //     <Button color="green"
                      //         disabled={this.state.next}
                      //         onClick={() => {

                      //             this.flowVispayDeposit(this.state.money.replaceAll(",", ""))
                      //         }}
                      //         fluid>สร้าง QR CODE จ่ายเงิน</Button>
                      // </Modal.Description>
                      <Message color="yellow">
                        <center>
                          <Message.Header style={{ fontSize: 18 }}>
                            {"ปิดฝากชั่วคราว หากต้องการฝากกรุณาติดต่อที่"}
                            <br />
                            <a target={"_blank"} href={config.LineURL}>
                              {`${config.LineID}`}
                            </a>
                          </Message.Header>
                        </center>
                      </Message>
                    ) : (
                      <Message color="yellow">
                        <center>
                          <Message.Header>
                            {"ปิดฝากช่วงเวลา 22:55 - 02:00 น."}
                          </Message.Header>
                        </center>
                      </Message>
                    )}
                  </Modal.Content>

                  <Modal.Actions>
                    <Button negative onClick={this.handleClosePopup}>
                      ยกเลิก
                    </Button>
                  </Modal.Actions>
                </>
              ) : (
                this.state.step == 2 && (
                  <>
                    <Modal.Header
                      style={{
                        textAlign: "center",
                      }}
                    >
                      จ่ายเงินผ่าน QR CODE นี้
                      <div
                        style={{
                          color: "red",
                          fontWeight: "400",
                          fontSize: "1rem",
                          marginTop: 10,
                        }}
                      >
                        * QR CODE ปัจจุบันจะถูกยกเลิก หาก *
                      </div>
                      <div
                        style={{
                          color: "red",
                          fontWeight: "400",
                          fontSize: "1rem",
                          marginBottom: 10,
                        }}
                      >
                        หมดเวลา ,กดปิด ,กดสร้าง QR CODE ใหม่
                      </div>
                    </Modal.Header>

                    <Modal.Content
                      as={Segment}
                      style={{ margin: 0, border: 0 }}
                      loading={this.state.loader}
                    >
                      <Modal.Description>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: 400,
                            }}
                          >
                            <div
                              style={{
                                color: "#F56D14",
                                textAlign: "center",
                                fontSize: "1.5rem",
                                fontWeight: "500",
                              }}
                            >
                              ต้องโอนจาก {UserService.getBankAccount().bankName}
                            </div>

                            <div
                              style={{
                                color: "#F56D14",
                                textAlign: "center",
                                fontSize: "1.5rem",
                                fontWeight: "500",
                              }}
                            >
                              เลขบัญชี {UserService.getBankAccount().bankNumber}{" "}
                              เท่านั้น
                            </div>

                            {/* <div

                                                    style={{
                                                        color: "#F56D14",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                        fontWeight: "500"
                                                    }}
                                                >
                                                    ยอด {this.state.moneyDeposit.toString()} บาท เท่านั้น
                                                </div> */}

                            <div
                              style={{
                                padding: "20px 0px 10px",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={
                                  !!this.state.VIZPAY_DEPOST_WAITING
                                    ? this.state.VIZPAY_DEPOST_WAITING.qrcode
                                    : this.state.qrcodeDeposit
                                }
                                style={{
                                  width: 250,
                                  height: 250,
                                }}
                              />
                            </div>

                            <center
                              style={{
                                color: "red",
                                fontSize: 13,
                                fontWeight: 400,
                              }}
                            >
                              ** คำเตือน : QR Code
                              สามารถจ่ายได้เพียงครั้งเดียวเท่านั้น **
                            </center>
                            <center
                              style={{
                                color: "red",
                                fontSize: 13,
                                fontWeight: 400,
                                marginBottom: 5,
                              }}
                            >
                              ** โปรดอย่าสแกนจ่ายซ้ำ **
                            </center>

                            <Countdown
                              date={this.state.dateTimeOut}
                              renderer={({
                                hours,
                                minutes,
                                seconds,
                                completed,
                              }) => {
                                if (completed) {
                                  // Render a completed state
                                  return (
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginBottom: 10,
                                      }}
                                    >
                                      หมดเวลา
                                    </div>
                                  );
                                } else {
                                  // Render a countdown
                                  return (
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginBottom: 10,
                                      }}
                                    >
                                      หมดเวลาในอีก{" "}
                                      {hours.toString()?.length == 1
                                        ? "0" + hours
                                        : hours}
                                      :
                                      {minutes.toString()?.length == 1
                                        ? "0" + minutes
                                        : minutes}
                                      :
                                      {seconds.toString()?.length == 1
                                        ? "0" + seconds
                                        : seconds}{" "}
                                      นาที
                                    </div>
                                  );
                                }
                              }}
                            />

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {/* <div
                                                                            style={{
                                                                                borderRadius: 15,
                                                                                background: "#31A998",
                                                                                color: "#FFF",
                                                                                textAlign: "center",
                                                                                padding: "10px 0px",
                                                                                cursor: "pointer",
                                                                                width: 300
                                                                            }}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    step: 1,
                                                                                })
                                                                            }}
                                                                        >
                                                                            สร้าง QR CODE ใหม่
                                                                        </div> */}

                              <div
                                style={{
                                  borderRadius: 15,
                                  background: "#FFF8DB",
                                  color: "#A88531",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  border: "1px solid #A88531",
                                  padding: "10px 0px",
                                  // marginTop: 10,
                                  cursor: "pointer",
                                  width: 300,
                                }}
                                onClick={() => {
                                  this.downloadImage(this.state.qrcodeDeposit);
                                }}
                              >
                                <Icon
                                  name={"download"}
                                  style={{
                                    marginRight: 10,
                                    color: "#A88531",
                                  }}
                                />
                                ดาวน์โหลด
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button negative onClick={this.handleClosePopup}>
                        ปิด
                      </Button>
                    </Modal.Actions>
                  </>
                )
              )
            ) : (
              <>
                <Modal.Header style={{ textAlign: "center" }}>
                  โอนเงินเข้าบัญชีนี้
                  <p style={{ color: "red", fontSize: 13, fontWeight: 400 }}>
                    <br />* ต้องโอนจากบัญชีที่ลงทะเบียนกับระบบเท่านั้น *
                  </p>
                  {UserService.getBankAccount() && (
                    <p style={{ color: "red", fontSize: 14, fontWeight: 400 }}>
                      * จาก {UserService.getBankAccount().bankName} เลขบัญชี{" "}
                      {UserService.getBankAccount().bankNumber} *
                    </p>
                  )}
                </Modal.Header>

                <Modal.Content
                  as={Segment}
                  loading={this.state.loader}
                  style={{ margin: 0, border: 0 }}
                >
                  {!this.state.loader &&
                    (UserService.getBankAccount() ? (
                      !!this.state.adminBank.bankNumber &&
                      this.state.ENABLE_DEPOSIT_CREDIT ? (
                        <Modal.Description>
                          <Header
                            color={"grey"}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Icon name="university" />
                            ธนาคาร :{" "}
                          </Header>
                          <Header
                            as="h1"
                            color="orange"
                            textAlign={"center"}
                            className={"margin-top-0"}
                          >
                            {
                              BankObject.getBankData(
                                this.state.adminBank.bankName
                              ).text.split("(")[0]
                            }
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Image
                                rounded
                                src={
                                  BankObject.getBankData(
                                    this.state.adminBank.bankName
                                  ).image.src
                                }
                                size="tiny"
                                style={{
                                  marginRight: 10,
                                  borderRadius: 15,
                                  width: 30,
                                  height: 30,
                                }}
                              />
                              <div style={{ fontSizee: "1.7rem" }}>
                                {
                                  BankObject.getBankData(
                                    this.state.adminBank.bankName
                                  ).key
                                }
                              </div>
                            </div>
                          </Header>
                          <Divider />
                          <Header color={"grey"}>
                            <Icon name="user" />
                            บัญชี :
                          </Header>
                          <Header
                            as="h1"
                            color="orange"
                            textAlign={"center"}
                            className={"margin-top-0"}
                          >
                            {this.state.adminBank.accountName}
                          </Header>
                          <Divider />
                          <Header color={"grey"}>
                            <Icon name="address book" />
                            ธนาคาร :
                          </Header>
                          <Header
                            as="h1"
                            color="orange"
                            textAlign={"center"}
                            className={"margin-top-0"}
                          >
                            {this.state.adminBank.bankNumber}
                          </Header>
                          <br />
                          <Grid columns={1} textAlign={"center"}>
                            <Button
                              icon="copy"
                              color={"teal"}
                              size={"mini"}
                              content={"คัดลอกเลขบัญชี"}
                              onClick={() => {
                                this.copyToClipboard(
                                  this.state.adminBank.bankNumber
                                );
                              }}
                            />
                          </Grid>
                        </Modal.Description>
                      ) : (
                        <Message color="yellow">
                          <center>
                            <Message.Header>
                              {this.state.deposit_message
                                ? this.state.deposit_message
                                : "ระบบปิดการเติมเงินชั่วคราว"}
                            </Message.Header>
                          </center>
                        </Message>
                        // <center><h3 style={{ color: 'red' }}>ระบบปิดการเติมเงินชั่วคราว</h3></center>
                      )
                    ) : (
                      <>
                        <center>
                          <p style={{ color: "blue", marginBottom: 20 }}>
                            กรุณาเพิ่มบัญชีธนาคารของคุณก่อนเติมเงิน
                          </p>
                        </center>
                        <BankObject.SettingBankAccount
                          handelSaved={() => this.componentDidMount()}
                        />
                      </>
                    ))}
                </Modal.Content>
                <Modal.Actions>
                  <Button negative onClick={this.handleClosePopup}>
                    ปิด
                  </Button>
                </Modal.Actions>
              </>
            )}
          </>
        ) : (
          <>
            <Modal.Header>เติมเครดิต</Modal.Header>
            <Modal.Content
              as={Segment}
              style={{ margin: 0, border: 0 }}
              loading={this.state.loader}
            >
              <center>
                <p style={{ color: "blue", marginBottom: 20 }}>
                  กรุณาเพิ่มบัญชีธนาคารของคุณก่อนฝากเงิน
                </p>
              </center>
              <BankObject.SettingBankAccount
                handelSaved={() => this.componentDidMount()}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={this.handleClosePopup}>
                ยกเลิก
              </Button>
            </Modal.Actions>
          </>
        )}
      </Modal>
    );
  }
}

export default TopUpCreditByAccount;
