import React, { useEffect, useState } from 'react';
import { Grid, Table, Button, Input, Icon, Segment, Message, Header, Container, Image, Dropdown, Modal } from 'semantic-ui-react';
import Config from "../../../config/config";
import { Link, useHistory } from "react-router-dom";
import GameService from "../../../service/GameService";
import AlertClosed from './AlertClosed';
import { isMobile } from 'react-device-detect';
import Loading from '../../layout/Loading';

const Casino = () => {
    const [alertClose, setAlertClose] = useState(false)
    const [gameName, setGameName] = useState("")
    const [loadingGame, setLoadingGame] = useState(false)
    const history = useHistory();

    const handleClickSACasino = async () => {
        setLoadingGame(true)
        if (Config.ENABLE_SA_GAME == true) {
            GameService.loginSA().then((res) => {
                if (res.status) {
                    window.location.href = `${Config.SA.PLAY_URL}?username=${res.data.displayName}&token=${res.data.tokenSA}&lobby=${Config.SA.LOBBY_CODE}&lang=en_US&returnurl=https://${window.location.hostname}&mobile=true&options=defaulttable=601,hidelogo=1`
                    setTimeout(() => {
                        setLoadingGame(false)
                    }, 1500)
                } else {
                    setLoadingGame(false)
                    setGameName("SA")
                    setAlertClose(true)
                }
            }).catch(() => {
                setLoadingGame(false)
                setGameName("SA")
                setAlertClose(true)
            })
        }
    }

    const handleClickSexyGame = async () => {
        setLoadingGame(true)
        if (Config.ENABLE_SEXY_GAME == true) {
            GameService.loginSexyGame().then((res) => {
                if (res.data.status == "0000") {
                    window.location.href = res.data.url
                    setTimeout(() => {
                        setLoadingGame(false)
                    }, 1500)
                } else {
                    setLoadingGame(false)
                    setGameName("Sexy")
                    setAlertClose(true)
                }
            }).catch(() => {
                setLoadingGame(false)
                setGameName("Sexy")
                setAlertClose(true)
            })
        }
    }

    const handleClickAllbet = async () => {
        setLoadingGame(true)
        if (Config.ENABLE_ALLBET_CASINO == true) {
            GameService.loginAllbet().then((res) => {
                if (res.data.resultCode == "OK") {
                    window.location.href = res.data.data.gameLoginUrl
                    setTimeout(() => {
                        setLoadingGame(false)
                    }, 1500)
                } else {
                    setLoadingGame(false)
                    setGameName("Allbet")
                    setAlertClose(true)
                }
            }).catch(() => {
                setLoadingGame(false)
                setGameName("Allbet")
                setAlertClose(true)
            })
        }
    }

    const handleClickSexyAmbGame = async () => {
        setLoadingGame(true)
        if (Config.ENABLE_SEXY_AMB_SLOT == "true") {
            GameService.loginSexyAmbGame(isMobile).then((res) => {
                if (res.data.status == "0000") {
                    if (res?.data?.data?.data?.url) {
                        window.location.href = res?.data?.data?.data?.url
                        setTimeout(() => {
                            setLoadingGame(false)
                        }, 1500)
                    } else {
                        setLoadingGame(false)
                        setGameName("Sexy")
                        setAlertClose(true)
                    }
                } else {
                    setLoadingGame(false)
                    setGameName("Sexy")
                    setAlertClose(true)
                }
            }).catch(() => {
                setLoadingGame(false)
                setGameName("Sexy")
                setAlertClose(true)
            })
        }
    }

    const handleClickAllBetAmbGame = async () => {
        setLoadingGame(true)
        if (Config.ENABLE_ALL_BET_AMB_SLOT == "true") {
            GameService.getAllBetAmbGame().then((res) => {
                if (res.data.status == "0000") {
                    const result = res?.data?.data?.filter((item) => item.name == "Baccarat")
                    GameService.loginAllBetAmbGame(result[0]?.code, isMobile).then((res) => {
                        if (res.data.status == "0000") {
                            if (res?.data?.data?.data?.url) {
                                window.location.href = res?.data?.data?.data?.url
                                setTimeout(() => {
                                    setLoadingGame(false)
                                }, 1500)
                            } else {
                                setLoadingGame(false)
                                setGameName("All Bet")
                                setAlertClose(true)
                            }
                        } else {
                            setLoadingGame(false)
                            setGameName("All Bet")
                            setAlertClose(true)
                        }
                    })
                } else {
                    setLoadingGame(false)
                    setGameName("All Bet")
                    setAlertClose(true)
                }
            }).catch(() => {
                setLoadingGame(false)
                setGameName("All Bet")
                setAlertClose(true)
            })
        }
    }

    const handleClickSAAMBCasino = async () => {
        setLoadingGame(true)
        if (Config.ENABLE_SA_AMB_CASINO == "true") {
            GameService.loginSAAmbGame(isMobile).then((res) => {
                if (res.data.status == "0000") {
                    if (res?.data?.data?.data?.url) {
                        window.location.href = res?.data?.data?.data?.url
                        setTimeout(() => {
                            setLoadingGame(false)
                        }, 1500)
                    } else {
                        setLoadingGame(false)
                        setGameName("Sexy")
                        setAlertClose(true)
                    }
                } else {
                    setLoadingGame(false)
                    setGameName("Sexy")
                    setAlertClose(true)
                }
            }).catch(() => {
                setLoadingGame(false)
                setGameName("Sexy")
                setAlertClose(true)
            })
        }
    }

    const handleClickPrettyAmbGame = async () => {
        setLoadingGame(true)
        if (Config.ENABLE_PRETTY_AMB == "true") {
            GameService.getPrettyAmbGame().then((res) => {
                if (res.data.status == "0000") {
                    const result = res?.data?.data?.filter((item) => item.name == "Baccarat")
                    GameService.loginPrettyAmbGame(result[0]?.code, isMobile).then((res) => {
                        if (res.data.status == "0000") {
                            if (res?.data?.data?.data?.url) {
                                window.location.href = res?.data?.data?.data?.url
                                setTimeout(() => {
                                    setLoadingGame(false)
                                }, 1500)
                            } else {
                                setLoadingGame(false)
                                setGameName("Pretty")
                                setAlertClose(true)
                            }
                        } else {
                            setLoadingGame(false)
                            setGameName("Pretty")
                            setAlertClose(true)
                        }
                    })
                } else {
                    setLoadingGame(false)
                    setGameName("Pretty")
                    setAlertClose(true)
                }
            }).catch(() => {
                setLoadingGame(false)
                setGameName("Pretty")
                setAlertClose(true)
            })
        }
    }

    return (
        <Container>

            <Loading loading={loadingGame} />

            <Grid>
                {
                    Config.ENABLE_SA_GAME == true &&
                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment
                            style={{
                                boxShadow: 'unset',
                                border: 0,
                                background: 'var(--background-huay-list)',
                                borderRadius: 20,
                                padding: '10px 5px',
                                cursor: 'pointer'
                            }}
                            onClick={handleClickSACasino}
                        >
                            <img
                                src={Config.ENABLE_SA_GAME == true ? require('../../../assets/images/home/sa_casino.jpeg') : require('../../../assets/images/home/sa_casino_disable.jpeg')}
                                style={{
                                    width: '100%',
                                    maxHeight: 130,
                                    borderRadius: 20,
                                    padding: '0px 5px 5px'
                                }}
                            />
                            <div className="game-name-container" style={{ marginBottom: 0 }}>
                                <div>SA Game</div>
                            </div>
                        </Segment>
                    </Grid.Column>
                }
                {
                    Config.ENABLE_SA_AMB_CASINO == "true" &&
                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment
                            style={{
                                boxShadow: 'unset',
                                border: 0,
                                background: 'var(--background-huay-list)',
                                borderRadius: 20,
                                padding: '10px 5px',
                                cursor: 'pointer'
                            }}
                            onClick={handleClickSAAMBCasino}
                        >
                            <img
                                src={require('../../../assets/images/home/sa_casino.jpeg')}
                                style={{
                                    width: '100%',
                                    maxHeight: 130,
                                    borderRadius: 20,
                                    padding: '0px 5px 5px'
                                }}
                            />
                            <div className="game-name-container" style={{ marginBottom: 0 }}>
                                <div>SA Game</div>
                            </div>
                        </Segment>
                    </Grid.Column>
                }
                {
                    Config.ENABLE_SEXY_GAME == true &&
                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment
                            style={{
                                boxShadow: 'unset',
                                border: 0,
                                background: 'var(--background-huay-list)',
                                borderRadius: 20,
                                padding: '10px 5px',
                                cursor: 'pointer'
                            }}
                            onClick={handleClickSexyGame}
                        >
                            <img
                                src={Config.ENABLE_SEXY_GAME == true ? require('../../../assets/images/home/sexy_casino.jpeg') : require('../../../assets/images/home/sexy_casino_disable.jpeg')}
                                style={{
                                    width: '100%',
                                    maxHeight: 130,
                                    borderRadius: 20,
                                    padding: '0px 5px 5px'
                                }}
                            />
                            <div className="game-name-container" style={{ marginBottom: 0 }}>
                                <div>Sexy Game</div>
                            </div>
                        </Segment>
                    </Grid.Column>
                }
                {
                    Config.ENABLE_SEXY_AMB_SLOT == "true" &&
                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment
                            style={{
                                boxShadow: 'unset',
                                border: 0,
                                background: 'var(--background-huay-list)',
                                borderRadius: 20,
                                padding: '10px 5px',
                                cursor: 'pointer'
                            }}
                            onClick={handleClickSexyAmbGame}
                        >
                            <img
                                src={require('../../../assets/images/home/sexy_casino.jpeg')}
                                style={{
                                    width: '100%',
                                    maxHeight: 130,
                                    borderRadius: 20,
                                    padding: '0px 5px 5px'
                                }}
                            />
                            <div className="game-name-container" style={{ marginBottom: 0 }}>
                                <div>Sexy Game</div>
                            </div>
                        </Segment>
                    </Grid.Column>
                }
                {Config.ENABLE_VENUS_CASINO == true &&
                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment
                            style={{
                                boxShadow: 'unset',
                                border: 0,
                                background: 'var(--background-huay-list)',
                                borderRadius: 20,
                                padding: '10px 5px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                if (Config.ENABLE_VENUS_CASINO == true) {
                                    history.push('/venuscasino')
                                }
                            }}
                        >
                            <img
                                src={Config.ENABLE_VENUS_CASINO == true ? require('../../../assets/images/home/venus.jpeg') : require('../../../assets/images/home/venus_disable.jpeg')}
                                style={{
                                    width: '100%',
                                    maxHeight: 130,
                                    borderRadius: 20,
                                    padding: '0px 5px 5px'
                                }}
                            />
                            <div className="game-name-container" style={{ marginBottom: 0 }}>
                                <div>Venus</div>
                            </div>
                        </Segment>
                    </Grid.Column>
                }
                {
                    (Config.ENABLE_ALLBET_CASINO == true || Config.ENABLE_ALLBET_CASINO == "true") &&
                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment
                            style={{
                                boxShadow: 'unset',
                                border: 0,
                                background: 'var(--background-huay-list)',
                                borderRadius: 20,
                                padding: '10px 5px',
                                cursor: 'pointer'
                            }}
                            onClick={handleClickAllbet}
                        >
                            <img
                                src={(Config.ENABLE_ALLBET_CASINO == true || Config.ENABLE_ALLBET_CASINO == "true") ? require('../../../assets/images/home/allbet.jpeg') : require('../../../assets/images/home/allbet_disable.jpeg')}
                                style={{
                                    width: '100%',
                                    maxHeight: 130,
                                    borderRadius: 20,
                                    padding: '0px 5px 5px'
                                }}
                            />
                            <div className="game-name-container" style={{ marginBottom: 0 }}>
                                <div>Allbet</div>
                            </div>
                        </Segment>
                    </Grid.Column>
                }
                {
                    Config.ENABLE_ALL_BET_AMB_SLOT == "true" &&
                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment
                            style={{
                                boxShadow: 'unset',
                                border: 0,
                                background: 'var(--background-huay-list)',
                                borderRadius: 20,
                                padding: '10px 5px',
                                cursor: 'pointer'
                            }}
                            onClick={handleClickAllBetAmbGame}
                        >
                            <img
                                src={require('../../../assets/images/home/allbet.jpeg')}
                                style={{
                                    width: '100%',
                                    maxHeight: 130,
                                    borderRadius: 20,
                                    padding: '0px 5px 5px'
                                }}
                            />
                            <div className="game-name-container" style={{ marginBottom: 0 }}>
                                <div>Allbet</div>
                            </div>
                        </Segment>
                    </Grid.Column>
                }
                {
                    Config.ENABLE_PRETTY_AMB == "true" &&
                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment
                            style={{
                                boxShadow: 'unset',
                                border: 0,
                                background: 'var(--background-huay-list)',
                                borderRadius: 20,
                                padding: '10px 5px',
                                cursor: 'pointer'
                            }}
                            onClick={handleClickPrettyAmbGame}
                        >
                            <img
                                src={require('../../../assets/images/home/pretty.jpg')}
                                style={{
                                    width: '100%',
                                    maxHeight: 130,
                                    borderRadius: 20,
                                    padding: '0px 5px 5px'
                                }}
                            />
                            <div className="game-name-container" style={{ marginBottom: 0 }}>
                                <div>Pretty</div>
                            </div>
                        </Segment>
                    </Grid.Column>
                }
            </Grid>

            <AlertClosed
                open={alertClose}
                onClose={() => setAlertClose(false)}
                name={gameName}
            />

        </Container >
    )
}

export default Casino