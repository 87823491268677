import React, { useState } from 'react';
import { Grid, Dimmer, Loader, Container, Segment } from "semantic-ui-react";
import Config from "../../../config/config";
import GameService from "../../../service/GameService";
import { isMobile } from 'react-device-detect';
import AlertClosed from './AlertClosed';

const Sport = () => {
    const [loading, setLoading] = useState(false)
    const [alertClose, setAlertClose] = useState(false)

    const handleClickSBO = async () => {
        if (Config.ENABLE_SBO_SPORT == true || Config.ENABLE_SBO_SPORT == "true") {
            setLoading(true)
            GameService.loginSBO(isMobile).then((res) => {
                if (res?.data?.status == "0000") {
                    window.location.href = res?.data?.data?.data?.url
                    setTimeout(() => {
                        setLoading(false)
                    }, 1500)
                } else {
                    setLoading(false)
                }
            })
        }
    }

    const handleClickAFB = async () => {
        if (Config.ENABLE_AFB_SPORT == "true" || Config.ENABLE_AFB_SPORT == true) {
            setLoading(true)
            GameService.loginAFB('d').then((res) => {
                if (res.data?.status === "0000") {
                    window.location.href = `${res.data?.loginLink}`
                    setTimeout(() => {
                        setLoading(false)
                    }, 1500)
                } else {
                    setLoading(false)
                    setAlertClose(true)
                }
            }).catch(() => {
                setLoading(false)
                setAlertClose(true)
            })
        }
    }

    return (
        <Container>
            <Grid>
                <Dimmer active={loading}>
                    <Loader content='กำลังโหลด' />
                </Dimmer>
                <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>

                    <Segment
                        style={{
                            boxShadow: 'unset',
                            border: 0,
                            background: 'var(--background-huay-list)',
                            borderRadius: 20,
                            padding: '10px 5px',
                            cursor: 'pointer'
                        }}
                        onClick={handleClickSBO}
                    >
                        <img
                            src={(Config.ENABLE_SBO_SPORT == true || Config.ENABLE_SBO_SPORT == "true") ? require('../../../assets/images/home/sbobet.jpeg') : require('../../../assets/images/home/sbobet_disable.jpeg')}
                            style={{
                                width: '100%',
                                maxHeight: 130,
                                borderRadius: 20,
                                padding: '0px 5px 5px'
                            }}
                        />
                        <div className="game-name-container" style={{ marginBottom: 0 }}>
                            <div>Sbobet</div>
                        </div>
                    </Segment>
                </Grid.Column>

                {
                    (Config.ENABLE_AFB_SPORT == true || Config.ENABLE_AFB_SPORT == 'true') &&
                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment
                            style={{
                                boxShadow: 'unset',
                                border: 0,
                                background: 'var(--background-huay-list)',
                                borderRadius: 20,
                                padding: '10px 5px',
                                cursor: 'pointer'
                            }}
                            onClick={handleClickAFB}
                        >
                            <img
                                src={(Config.ENABLE_AFB_SPORT == true || Config.ENABLE_AFB_SPORT == 'true') ? require('../../../assets/images/home/afb.jpeg') : require('../../../assets/images/home/afb_disable.jpg')}
                                style={{
                                    width: '100%',
                                    maxHeight: 130,
                                    borderRadius: 20,
                                    padding: '0px 5px 5px'
                                }}
                            />
                            <div className="game-name-container" style={{ marginBottom: 0 }}>
                                <div>Sports</div>
                            </div>
                        </Segment>
                    </Grid.Column>
                }

                <AlertClosed
                    open={alertClose}
                    onClose={() => setAlertClose(false)}
                    name={"Sports"}
                />

            </Grid>

        </Container>
    )
}

export default Sport