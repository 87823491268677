import Config from "../config/config";
import Authen from './Authen'
import apiClient from './axios/apiClient'
import qs from 'qs'
const axios = require('axios');

export default class UserService {
    constructor() {
    }

    static getUser() {
        return JSON.parse(localStorage.getItem('user')) || Authen.logout();
    }

    static getBankAccount() {
        const myuser = JSON.parse(localStorage.getItem('user'));
        return myuser.accounts
    }

    static async saveBankAccount(bankName, bankNumber, accountName) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'users/bank',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: JSON.stringify({ bankNumber, accountName, bankName })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data.account }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });

        return await returnData
    }

    static async fetchMe() {
        if (Authen.getAcessToken() == null) { return { status: true, msg: "not fond token." } }
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'users/me',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            localStorage.setItem('user', JSON.stringify(response.data.user))
            return { status: true }
        }).catch(function (error) {
            if (error.response.status == 401) { Authen.logout().then(res => window.location.reload()) }
            return { status: false, msg: error.response.data.errors.message }
        });

        return await returnData
    }

    static async incressVisitedCount(userId, numberAction = 1) {
        var config = {
            method: 'patch',
            url: Config.APIConfig.apiDomain + 'users/visitedCount',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ userId, numberAction })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });

        return await returnData
    }

    static async getChildren() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'users/children',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });

        return await returnData
    }

    static async getBankTransection() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'banksystem/getBankOfdeposit',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return response.data
        })
        return returnData
    }

    static async getBankConfig() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'banksystem/getConfig',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return response.data
        })
        return returnData
    }

    static async setNameUser(name) {
        var config = {
            method: 'patch',
            url: Config.APIConfig.apiDomain + 'users/name',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: JSON.stringify({ name: name })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async getInviteLink() {
        var today = new Date()
        let month = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1).toString() : today.getMonth() + 1
        var date = today.getFullYear() + '-' + month + '-' + today.getDate();

        var data = qs.stringify({
            'long_url': `https://ms11.af8win.com/?r=${this.getUser().username}&d=${date}`
        });
        var config = {
            method: 'post',
            url: 'https://huay.in/create.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        const returnData = await axios(config).then(function (response) {
            if (response.data.status == "0000") {
                return { status: true, data: response.data }
            } else {
                return { status: false }
            }
        }).catch(function (error) {
            return { status: false, msg: error.response }
        });

        return returnData
    }

    static async getAFInfo() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'users/AFinfo',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async getAFInfoStat() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'users/AFinfoStat',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }


    static async getHistoryHuay(limit = 10) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + `wallet/transaction/huay/${limit}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async getHistoryGame(limit = 10) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + `wallet/transaction/game/${limit}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async getHistoryWallet(limit = 10) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + `wallet/transaction/wallet/${limit}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async getSearchChildren(clientUsername) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'users/getClientAfData',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: JSON.stringify({ "clientUsername": clientUsername })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async getDailyRewardGame() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + `DailyRewardGame/config`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async playDailyRewardGame() {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + `DailyRewardGame/play`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async getHomeConfig() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'homeConfig',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async checkBank(bankName, bankNumber) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'banksystem/bankAccountDetail',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: JSON.stringify({ bankName: bankName, bankNumber: bankNumber })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async checkBankRegister(bankName, bankNumber, otpVerifyID) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'banksystem/bankAccountDetail',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({ bankName: bankName, bankNumber: bankNumber, otpVerifyID: otpVerifyID })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async checkBankCaptCha(bankName, bankNumber, captcha) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'banksystem/bankAccountDetailWithRecaptcha',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: JSON.stringify({ bankName: bankName, bankNumber: bankNumber, captcha: captcha })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async vizpayDeposit(amount) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'banksystem/vizpay/deposit',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: JSON.stringify({ amount: amount })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return returnData
    }

    static async vizpayWithdraw(amount) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'banksystem/vizpay/withdraw',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: JSON.stringify({ amount: amount })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.message }
        });
        return returnData
    }
}