import Config from "../config/config";
import Authen from './Authen'
import apiClient from './axios/apiClient'

export default class PromotionSlideService {
    constructor() {
    }

    static async getGame() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'game/gamedata',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZjE4NjRiZDQxYWQyOTRmMTgxMDU0Y2EiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE1OTU0MzUxNjJ9.J8B5inumutWbEQac8D3aNZ_HH3BlH05-Iq_pi_dkTW4',
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getSlotGames() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'joker/list-games',
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginSA() {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'sa/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginSBO(isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'amb/sbobet/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                "isMobile": isMobile,
                "callbackUrl": "https://" + window.location.hostname
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginSexyGame() {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'sexy/loginAndLaunchGame',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameType': "LIVE",
                'platform': "SEXYBCRT",
                'externalURL': window.location.hostname,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getSlotKingmaker() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'sexy/list-games/KINGMAKER',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async playSlotKingmaker(gameCode) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'sexy/loginAndLaunchGame',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameType': "TABLE",
                'platform': "KINGMAKER",
                'gameCode': gameCode,
                'externalURL': window.location.hostname,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getSlotAWS() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'sexy/list-games/AWS',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async playSlotAWS(GameType, gameCode) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'sexy/loginAndLaunchGame',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameType': GameType,
                'platform': "AWS",
                'gameCode': gameCode,
                'externalURL': window.location.hostname,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getSlotPG() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'pg/gamePlayList',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginAFB(data) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'afb/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'device': data,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async playSlotJILI(gameCode, gameType) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'sexy/loginAndLaunchGame',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameType': gameType,
                'platform': "JILI",
                'gameCode': gameCode,
                'externalURL': `${window.location.hostname}/slot`,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async playSlotPP(gameCode, gameType) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'sexy/loginAndLaunchGame',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameType': gameType,
                'platform': "PP",
                'gameCode': gameCode,
                'externalURL': `${window.location.hostname}/slot`,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async playSlotSPADE(gameCode, gameType) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'sexy/loginAndLaunchGame',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameType': gameType,
                'platform': "SPADE",
                'gameCode': gameCode,
                'externalURL': `${window.location.hostname}/slot`,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async playSlotFC(gameCode, gameType) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'sexy/loginAndLaunchGame',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameType': gameType,
                'platform': "FC",
                'gameCode': gameCode,
                'externalURL': `${window.location.hostname}/slot`,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async playCasinoVenus(gameCode, gameType) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'sexy/loginAndLaunchGame',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameType': gameType,
                'platform': "VENUS",
                'gameCode': gameCode,
                'externalURL': `${window.location.hostname}/casino`,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginAllbet() {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'allbet/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async playSiam(redirect) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'siamlot/login?redirect=' + redirect,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getSlotPGAmb() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + '/amb/pg/productList',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async playSlotPGAmb(gameCode, isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + '/amb/pg/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                "gameCode": gameCode,
                "isMobile": isMobile
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getSlotKingmakerAmb() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + '/amb/kingmaker/productList',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async playKingmakerAmb(gameCode, isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + '/amb/kingmaker/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                "gameCode": gameCode,
                "isMobile": isMobile
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginSexyAmbGame(isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + '/amb/sexy/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameCode': "MX-LIVE-001",
                'isMobile': isMobile,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginAllBetAmbGame(gameCode, isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + '/amb/allbet/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameCode': gameCode,
                'isMobile': isMobile,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getAllBetAmbGame() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + '/amb/allbet/productList',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginSAAmbGame(isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + '/amb/sa/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'isMobile': isMobile,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginPPAmbGame(gameCode, isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + '/amb/pragmatic/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameCode': gameCode,
                'isMobile': isMobile,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getPPAmbGame() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + '/amb/pragmatic/productList',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginJokerAmbGame(gameCode, isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + '/amb/joker/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameCode': gameCode,
                'isMobile': isMobile,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getJokerAmbGame() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + '/amb/joker/productList',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginJiliAmbGame(gameCode, isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + '/amb/jili/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameCode': gameCode,
                'isMobile': isMobile,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getJiliAmbGame() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + '/amb/jili/productList',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginSpadeAmbGame(gameCode, isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + '/amb/spade/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameCode': gameCode,
                'isMobile': isMobile,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getSpadeAmbGame() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + '/amb/spade/productList',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginPrettyAmbGame(gameCode, isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + '/amb/pretty/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameCode': gameCode,
                'isMobile': isMobile,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getPrettyAmbGame() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + '/amb/pretty/productList',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginSlotXoAmbGame(gameCode, isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + '/amb/slotxo/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameCode': gameCode,
                'isMobile': isMobile,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getSlotXoAmbGame() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + '/amb/slotxo/productList',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async loginAskMeAmbGame(gameCode, isMobile) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + '/amb/askmeslot/login',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: {
                'gameCode': gameCode,
                'isMobile': isMobile,
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getAskMeAmbGame() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + '/amb/askmeslot/productList',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

}
