import React, { Component } from 'react'
import {
    Button, Grid, Header,
    Modal, Segment, Table, Container, Icon
} from "semantic-ui-react";
import YiiGiiService from '../../../service/YiiGiiService';
import UserService from '../../../service/UserService';

class NumberShot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sumShotNumber: 0,
            numberShotPerPage: 10,
            numberShotNowPage: 1,
            numberShots: [],
            numberShot: [
                '-',
                '-',
                '-',
                '-',
                '-'
            ],
            disabledButtonNumberShots: false,
            disabledButtonLoad: false,
            isLoadingNumberShots: true,
            msgError: null
        };
    }

    componentWillMount() {
        this.getShotNumberData()
    }

    getShotNumberData() {
        let { roundId } = this.props;
        this.setState({ disabledButtonLoad: true })
        YiiGiiService.getYiiGiiRound(roundId).then((res) => {
            if (res.status) {
                let sumShotNumber = 0;
                let numberShotsList = []
                res.data.numberShots.reverse().forEach(item => {
                    sumShotNumber += parseInt(item.numberShot)
                    let numberShotItem = {
                        user: item.username,
                        numberShot: item.numberShot,
                        time: new Date(new Date(item.createdAt).toLocaleString("en-US", { timeZone: "Asia/Bangkok" })).getHours() + ":" + new Date(new Date(item.createdAt).toLocaleString("en-US", { timeZone: "Asia/Bangkok" })).getMinutes() + " น.",
                        myNumberShot: false,
                    }
                    numberShotsList.push(numberShotItem)
                })
                this.setState({
                    numberShots: numberShotsList,
                    sumShotNumber: sumShotNumber,
                    disabledButtonLoad: false,
                    isLoadingNumberShots: false
                })
            } else {
                this.setState({ msgError: res.msg })
            }
        })
    }

    handleAddNumberShot = () => {
        let { numberShot, sumShotNumber } = this.state;
        let { roundId } = this.props;
        let sumShotNumberNew = parseInt(numberShot.join(""));
        if (!isNaN(sumShotNumberNew)) {
            let numberShotNew = {
                user: UserService.getUser().username,
                numberShot: sumShotNumberNew,
                time: new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Bangkok" })).getHours() + ":" + new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Bangkok" })).getMinutes() + " น.",
                myNumberShot: true,
            };
            YiiGiiService.numberShot(roundId, sumShotNumberNew).then(res => {
                if (res.status) {
                    this.setState(prevState => ({
                        numberShots: [numberShotNew, ...prevState.numberShots],
                        sumShotNumber: sumShotNumberNew + sumShotNumber,
                        numberShot: ['-', '-', '-', '-', '-'],
                        disabledButtonNumberShots: false
                    }));
                } else {
                    this.setState({ msgError: res.msg })
                    this.setState({ disabledButtonNumberShots: false })
                }
            })
        }
    };

    handleRandom = () => {
        let { numberShot, disabledButtonNumberShots } = this.state;
        let numberShotNew = [];
        if (!disabledButtonNumberShots) {
            numberShotNew = numberShot.map(function () {
                return Math.floor(Math.random() * 10);
            });
            this.setState({ numberShot: numberShotNew });
        }

    };

    handleDeleteNumber = () => {
        let { numberShot, disabledButtonNumberShots } = this.state;
        if (!disabledButtonNumberShots) {
            let deleteIndex = 0;
            numberShot.map(function (item, index) {
                if (Number.isInteger(item)) {
                    deleteIndex = index;
                }
            });
            numberShot[deleteIndex] = '-';
            this.setState({ numberShot: numberShot });
        }
    };

    handleAddNumber = (numberLottery) => {
        let { numberShot, disabledButtonNumberShots } = this.state;
        if (!disabledButtonNumberShots) {
            let oneTime = true;
            numberShot.map(function (item, index) {
                if (item == "-" && oneTime) {
                    oneTime = false;
                    numberShot[index] = numberLottery;
                }
            });
            this.setState({ numberShot: numberShot });
        }
    };

    handleLoadNumberShotList = () => {
        this.getShotNumberData()
        let { numberShotNowPage } = this.state;
        let _this = this;
        this.setState({ isLoadingNumberShots: true });
        setTimeout(function () {
            _this.setState({ numberShotNowPage: numberShotNowPage + 1, isLoadingNumberShots: false });
        }, 1000);
    };

    getNumberShotList = () => {
        let { numberShotPerPage, numberShotNowPage, numberShots } = this.state;
        let numberShotSize = numberShotPerPage * numberShotNowPage;
        return numberShots.map(function (item, index) {
            return index < numberShotSize ? (<Table.Row positive={item.myNumberShot} key={index}>
                {/* <Table.Cell>{index + 1}</Table.Cell> */}
                <Table.Cell>{"****" + item.user[item.user.length - 4] + item.user[item.user.length - 3] + item.user[item.user.length - 2] + item.user[item.user.length - 1]}</Table.Cell>
                <Table.Cell>{item.numberShot}</Table.Cell>
                <Table.Cell textAlign='right'>{item.time}</Table.Cell>
            </Table.Row>) : "";
        });
    };

    render() {
        const { numberShot, sumShotNumber } = this.state;
        return (<>
            <div style={{ textAlign: 'center' }}>
                <h4 style={{ margin: 0 }}>ผลรวมยิงเลข</h4>
                <Header as='h2' style={{ margin: 0, fontWeight: 400, letterSpacing: 2, color: 'var(--color-select)' }}>{sumShotNumber}</Header>
                <div style={{ justifyContent: 'space-evenly', display: 'flex', marginTop: 20 }}>
                    {numberShot.map(function (item, key) {
                        return (
                            <Button circular key={key} className="input-number-now" content={item} basic />);
                    })}
                </div>
            </div>

            <div className='customPadding'>
                <Grid columns={3} style={{ marginTop: 20, paddingLeft: 10, paddingRight: 10 }}>
                    <Grid.Row>
                        <Grid.Column style={{ padding: 3 }}>
                            <Button content='1' onClick={() => {
                                this.handleAddNumber(1)
                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                        </Grid.Column >
                        <Grid.Column style={{ padding: 3 }}>
                            <Button content='2' onClick={() => {
                                this.handleAddNumber(2)
                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                        </Grid.Column>
                        <Grid.Column style={{ padding: 3 }}>
                            <Button content='3' onClick={() => {
                                this.handleAddNumber(3)
                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button content='4' onClick={() => {
                                this.handleAddNumber(4)
                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                        </Grid.Column>
                        <Grid.Column>
                            <Button content='5' onClick={() => {
                                this.handleAddNumber(5)
                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                        </Grid.Column>
                        <Grid.Column>
                            <Button content='6' onClick={() => {
                                this.handleAddNumber(6)
                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button content='7' onClick={() => {
                                this.handleAddNumber(7)
                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                        </Grid.Column>
                        <Grid.Column>
                            <Button content='8' onClick={() => {
                                this.handleAddNumber(8)
                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                        </Grid.Column>
                        <Grid.Column>
                            <Button content='9' onClick={() => {
                                this.handleAddNumber(9)
                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button onClick={this.handleRandom} content='สุ่ม' fluid style={{ backgroundColor: '#FFFFFF', border: '3px solid var(--color-num-pad)' }} />
                        </Grid.Column>
                        <Grid.Column>
                            <Button onClick={() => {
                                this.handleAddNumber(0)
                            }} content='0' fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                        </Grid.Column>
                        <Grid.Column>
                            <Button onClick={this.handleDeleteNumber} content='ลบ' style={{ backgroundColor: '#FFFFFF', border: '3px solid var(--color-num-pad)' }}
                                fluid />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>

            <Grid columns={1} style={{ marginTop: 20 }}>
                <Grid.Row>
                    <Grid.Column>
                        {(this.state.msgError != "") ? (<center><p style={{ color: "red" }}>{this.state.msgError}</p></center>) : ""}
                        <Button disabled={this.state.disabledButtonNumberShots} fluid negative
                            onClick={this.handleAddNumberShot}><Icon name='paper plane outline' /> ยิงเลข</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Segment style={{ padding: 0, boxShadow: 'unset', border: 0, marginTop: 40 }} loading={this.state.isLoadingNumberShots}>
                {this.state.numberShots.length > 0 ? (
                    <Table unstackable striped style={{ border: 0, fontWeight: 300 }}>
                        <Table.Body>
                            {this.getNumberShotList()}
                        </Table.Body>
                    </Table>
                ) : (
                    <center><p>ไม่มีรายการแทงเลข</p></center>
                )}

                {this.state.numberShots.length > 10 ? (
                    <Grid columns={1} centered>
                        <Grid.Column textAlign={"right"}>
                            <Button disabled={this.state.disabledButtonLoad || this.state.numberShotPerPage * this.state.numberShotNowPage >= this.state.numberShots.length} positive
                                onClick={this.handleLoadNumberShotList}>โหลดเพิ่ม</Button>
                        </Grid.Column>
                    </Grid>
                ) : ''}
            </Segment>
        </>);
    }
}

class NumberShotPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container>
                <div style={{ backgroundColor: '#FFF', padding: 20, borderRadius: 7 }}>
                    <NumberShot roundId={this.props.match.params.roundId} />
                </div>
            </Container>
        )
    }
}

export { NumberShot, NumberShotPage };