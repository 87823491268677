import React from 'react'
import Header from "./Header";
import MenuBar from "./MenuBar";
import routes from "../../config/routes";
import { Route } from "react-router-dom";

const getAllowBack = (props) => {
    let result = routes.privateRoute.find(function (item) {
        return props.path == item.path;
    });
    return result.onBack ? result.onBack : false;
};

const Main = (props) => {

    return (
        <div className="body" style={{ background: 'var(--background-body)' }}>
            <Header onBack={getAllowBack(props)} title={props.routeData.nameTh} />
            <div className="content-app">
                <Route component={props.routeData.component} />
            </div>
            <MenuBar path={props.path} menuActive={props.menuActive} />
        </div>
    )
};

export default Main