import React, {Component} from 'react'
import {Dropdown, Grid, Icon, Label, Responsive, Button, Segment, Confirm, Modal,Header,Card,Divider,Input} from "semantic-ui-react";
import WalletService from '../../../service/WalletService';

class WithdrawAF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openWithdrawAF: false,
            AFtotal:0,
            AFWithdrawActive: 0,
            AfWithdrawLoading:false,
            AFWithdrawVelue: "",
            next:true,
            openConfirm: false,
            errorMSG:""
        };
    }

    componentDidMount(){
        this.setState({
            AFtotal: Number(WalletService.getWalletBalance().af).toFixed(0),
            AFWithdrawActive: Number(WalletService.getWalletBalance().af).toFixed(0)
        })
    }

    handleChangeMoney = (event) => {
        this.setState({next: true})
        let value = event.target.value.replace(/,/g, '');
        value=Number(value)
        if (!isNaN(value)) {
            if (value <= Number(this.state.AFWithdrawActive)) {
                if (value >= 20) {
                    this.setState({next: false})
                }
                let valueReplace = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
                this.setState({AFWithdrawVelue: valueReplace})
            }
        }
    };

    handleOpenSuccessWithdrawCredit = () => {
        this.setState({openConfirm: true});
    };

    handleCloseSuccessWithdrawCredit = () => {
        this.setState({openConfirm: false,AFWithdrawVelue:""});
    };

    AFWithdraw=()=>{
        let _this = this;
        this.setState({AfWithdrawLoading: true});
        setTimeout(function () {
            WalletService.AFwithdraw(Number(_this.state.AFWithdrawVelue)).then(res=>{
                if(res.status){
                    _this.handleOpenSuccessWithdrawCredit()                   
                    _this.setState({AfWithdrawLoading: false});
                    _this.props.closeWithdrawAF()
                }else{
                    _this.setState({errorMSG: res.msg})
                    _this.setState({AfWithdrawLoading: false});
                }
            })            
        }, 1000);
    }

    render() {
        return (
            <>
            {/* ถอน AF */}
             <Modal open={this.props.openWithdrawAF} onClose={()=>this.props.closeWithdrawAF()} >
                <Header content='โอน AF เป็นเครดิต' style={{fontSize:"1.5rem"}} />
                <Segment style={{padding:20,marginBottom:0,marginTop:0}} loading={this.state.AfWithdrawLoading} >
                    <Grid columns='equal' centered>
                        <Grid.Column textAlign={"center"} style={{padding: 5,}}>
                            <Segment style={{padding: 3,paddingTop:15}}>
                                <div>AF ทั้งหมด <br/>ที่เคยสะสม</div>
                                <h2>{Number(this.state.AFtotal).toFixed(2)} ฿</h2>
                                <Divider inverted />
                                <div style={{color:"#888",fontSize:"0.8rem"}}>ข้อมูลเมื่อเวลา {new Date().toLocaleTimeString('en-US',{ hour12: false }).substring(0,5)} <br/>(สรุปข้อมูลรายชั่วโมง)</div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column textAlign={"center"} style={{padding: 5,}}>
                            <Segment style={{padding: 3,paddingTop:15}}>
                                <div>AF ปัจจุบัน <br/>ที่สามารถโอนได้</div>
                                <h2>{Number(this.state.AFWithdrawActive).toFixed(2)} ฿</h2>
                                <Divider inverted />
                                <div style={{color:"#888",fontSize:"0.8rem"}}>ข้อมูล realtime <br/>.</div>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                    <Grid centered >
                        <Grid.Row columns='1'>
                            <Grid.Column textAlign={"left"} style={{padding: 5}}>
                                <h3>จำนวน AF ที่ต้องการโอน</h3>
                                <Input labelPosition='right' type='text' placeholder='0' fluid >
                                    <input style={{textAlign:"right"}} value={this.state.AFWithdrawVelue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onChange={(event)=>this.handleChangeMoney(event)} />
                                    <Label>บาท</Label>
                                </Input>
                                <p style={{color:'red',textAlign:'right'}}><br/>โอนขั้นต่ำครั้งละ 20AF</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <center style={{color:'red',marginTop:10}}><p>{this.state.errorMSG}</p></center>
                </Segment>
                <Modal.Actions>
                    <Button color='red' onClick={()=>this.props.closeWithdrawAF()}>
                        <Icon name='remove' /> ปิด
                    </Button>
                    <Button color='green' disabled={this.state.next} onClick={()=>this.AFWithdraw()}>
                        <Icon name='checkmark' /> ยืนยัน
                    </Button>
                </Modal.Actions>
            </Modal>

            <Confirm
                open={this.state.openConfirm}
                header="ทำรายการสำเร็จ"
                content={"โยก "+this.state.AFWithdrawVelue+" AF เป็น "+this.state.AFWithdrawVelue+" Credit สำเร็จ"}
                confirmButton="ตกลง"
                cancelButton={false}
                onConfirm={this.handleCloseSuccessWithdrawCredit}
                size={"mini"}
            />
            </>
        );
    }
}

export default WithdrawAF;
