import Config from "../config/config";
import Authen from './Authen'

import apiClient from './axios/apiClient';

export default class WalletService {
    constructor() {
    }

    static async fetchWalletBalance(limit = 0) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'wallet/me/' + limit,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken()
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            let wallet = {
                credit: response.data.wallet.credit.toString(),
                af: response.data.wallet.af.toString(),
                creditHistory: response.data.wallet.creditHistory
            }
            localStorage.setItem('WalletBalance', JSON.stringify(wallet));
            return {
                status: true, data:
                {
                    wallet: wallet
                }
            }
        }).catch(function (error) {
            if (error.response.status == 401) { Authen.logout().then(res => window.location.reload()) }
            return { status: false, msg: error.response.data.errors.message }
        });

        return await returnData
    }

    static getWalletBalance() {
        return JSON.parse(localStorage.getItem('WalletBalance'))
    }

    static async withdraw(money) {
        if (money == null) return { status: false, msg: "money action is null" }
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'banksystem/withdrawRequest',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken()
            },
            data: JSON.stringify({ amount: money })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.message }
        });
        // await this.fetchWalletBalance();
        return await returnData
    }

    static async getHistoryTransaction(limit = 10) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'wallet/me/' + limit,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken()
            }
        };

        const returnData = await apiClient(config).then(function (response) {
            return {
                status: true, data:
                {
                    historyTransaction: response.data.wallet.creditHistory
                }
            }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });

        return await returnData
    }

    static async AFwithdraw(money) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'wallet/af/withdraw',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken()
            },
            data: JSON.stringify({ value: money })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        await this.fetchWalletBalance();
        return await returnData
    }

    static async getConfigBankPlaymentSystem() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'banksystem/config',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken()
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }
}