import React, { Component } from 'react'
import { Card, Container, Header, Icon, Responsive, Tab, Popup, Segment, Flag, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

class CardLottery extends Component {
    constructor(props) {
        super(props);
    }

    render = () => (
        <Popup content={this.props.popupContent} inverted style={{ opacity: 0.8 }} position='bottom center' trigger={
            <Card as={(this.props.isEnabled) ? Link : null} to={this.props.linkTo} className={!this.props.isEnabled ? 'card-none' : ''}
                style={this.props.isEnabled ? (this.props.backgroundImage ? {
                    backgroundImage: `url(${this.props.backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: 20
                } : { background: this.props.backgroundColorCode,borderRadius: 20 }) : {borderRadius: 20}}>
                {/* <Card.Content className="bg-white">
                </Card.Content> */}
                {this.props.isHot ? (<span className='pik'> HOT </span>) : ''}
                <Card.Content className="content-middle" style={{ color: '#FFF' }}>
                    {this.props.isEnabled ? (this.props.img ? <img src={this.props.img} width='40' height='auto' /> : <Icon name='bolt' size='big' color='teal' />) : <Icon name='dont' size='big' color='red' />}
                    <span style={{ marginLeft: 10 }}>
                        <span style={{ fontSize: '1.3em', fontWeight: 400 }} >{this.props.name}</span>
                        <p style={{ fontSize: '0.9em', fontWeight: 200 }}>{this.props.description}</p>
                    </span>
                    <Icon style={{ marginLeft: 'auto' }} name='angle right' />
                </Card.Content>
            </Card>
        } />
    )
}

export default CardLottery;