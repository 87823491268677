import React, { Component } from 'react'
import { Button, Card, Container, Grid, Header, Icon, Image, Label, Responsive, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import imgCoins from "../../../assets/images/coin-flip-43.gif"
import GameService from "../../../service/GameService"

const asyncFunc = (event) => {
    return new Promise(resolve => {
        event();
        resolve();
    });
};

class Game extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameHot: 2,
            loading: false,
            gameHuakoi: { isEnabled: false },
            gameHilo: { isEnabled: false },
            gamePaoyingchub: { isEnabled: false }
        };
    }

    async componentDidMount() {
        await asyncFunc(() => {
        });
        this.resizeGame();
        this.setState({ loading: true })
        // game init
        GameService.getGame().then(res => {
            if (res.status) {
                this.setState({
                    gameHuakoi: res.data.huakoi,
                    gameHilo: res.data.hilo,
                    gamePaoyingchub: res.data.paoyingchub,
                    loading: false
                })
            }
        })
    }

    resizeGame = () => {
        let gameHot = 2;
        let innerWidth = window.innerWidth;
        if (innerWidth > 1080) {
            gameHot = 2;
        } else if (innerWidth > 620) {
            gameHot = 2;
        } else {
            gameHot = 1;
        }
        this.setState((prevState) => ({ gameHot: gameHot }))
    };

    render() {
        return (
            <Container>
                <Responsive className='customeSegment' as={Segment} onUpdate={this.resizeGame} loading={this.state.loading}>
                    <h2 style={{ marginLeft: '0.5em' }}><Icon color="black" name="gamepad" /> เกมเดิมพัน </h2>
                    <Card.Group className="game-card" itemsPerRow={this.state.gameHot}>
                        <Card className="container-game-card" as={this.state.gameHuakoi.isEnabled ? Link : null} to='/game/huakoi' style={{ height: 220 }}>
                            <Card.Content className="hy_bg">
                                <Card.Header>
                                    {this.state.gameHuakoi.isEnabled ? (
                                        <>
                                            <Label className="float-left" color='blue' circular style={{opacity: 0.82}}><Icon name='user' />{this.state.gameHuakoi.countRoundPlaying}</Label>
                                            <Label size="big" className="float-right" color='green' style={{ borderRadius: 15, paddingLeft: 18, paddingRight: 18, background: '#00E117' }}>เปิด</Label>
                                        </>
                                    ) : (
                                            <>
                                                <Label className="float-right" color='red' style={{ borderRadius: 15, paddingLeft: 18, paddingRight: 18 }}>ปิด</Label>
                                            </>
                                        )}
                                </Card.Header>
                            </Card.Content>
                            <Card.Content extra className="bg-white text-black">
                                <center style={{ fontSize: 18 }}>หัวก้อย</center>
                            </Card.Content>
                        </Card>
                        <Card className="" as={this.state.gameHilo.isEnabled ? Link : null} to='/game/hilo' style={{ height: 220 }}>
                            <Card.Content className="hailow-bg">
                                <Card.Header>
                                    {this.state.gameHilo.isEnabled ? (
                                        <>
                                            <Label className="float-left" color='blue' circular style={{opacity: 0.82}}><Icon name='user' />{this.state.gameHilo.countRoundPlaying}</Label>
                                            <Label className="float-right" color='green' style={{ borderRadius: 15, paddingLeft: 18, paddingRight: 18, backgroundColor: '#00E117' }}>เปิด</Label>
                                        </>
                                    ) : (
                                            <>
                                                <Label className="float-right" color='red' style={{ borderRadius: 15, paddingLeft: 18, paddingRight: 18 }}>ปิด</Label>
                                            </>
                                        )}

                                </Card.Header>
                            </Card.Content>
                            <Card.Content extra className="bg-white text-black">
                                <center style={{ fontSize: 18 }}>ไฮโล</center>
                            </Card.Content>
                        </Card>
                        <Card className="container-game-card" as={this.state.gamePaoyingchub.isEnabled ? Link : null} to='/game/paoyingchub' style={{ height: 220 }}>
                            <Card.Content className="yeengshop-bg">
                                <Card.Header>
                                    {this.state.gamePaoyingchub.isEnabled ? (
                                        <>
                                            <Label className="float-left" color='blue' circular style={{opacity: 0.82}}><Icon name='user' />{this.state.gamePaoyingchub.countRoundPlaying}</Label>
                                            <Label className="float-right" color='green' style={{ borderRadius: 15, paddingLeft: 18, paddingRight: 18, backgroundColor: '#00E117' }} >เปิด</Label>
                                        </>
                                    ) : (
                                            <>
                                                <Label className="float-right" color='red' style={{ borderRadius: 15, paddingLeft: 18, paddingRight: 18 }}>ปิด</Label>
                                            </>
                                        )}
                                </Card.Header>
                            </Card.Content>
                            <Card.Content extra className="bg-white text-black">
                                <center style={{ fontSize: 18 }}>เป่ายิงฉุบ</center>
                            </Card.Content>
                        </Card>
                    </Card.Group>
                </Responsive>
            </Container>
        );
    }
}

export default Game;
