import Config from "../../config/config";
const axios = require('axios');

const apiClient = axios.create();
apiClient.interceptors.response.use((response)=>{
    return response;
  }, function (error) {
    console.log(error.message)
    if(error.message==="Network Error"){
        window.location.replace(Config.maintenancePageURL) //Maintenance page
    }else{
        if(error.response.status==503){
            if(error.response.data.errors.message == 'server maintenance'){ window.location.replace(Config.maintenancePageURL);} //Maintenance page
        }
    }
    return Promise.reject(error);
  });

  export default apiClient