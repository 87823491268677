import React, { Component } from 'react'
import {
    Button, Grid, Header,
    Modal, Segment, Table,
    Container, Card, Divider,
    Responsive
} from "semantic-ui-react";
import WalletHeader from "../wallet/WalletHeader";
import YiiGiiService from '../../../service/YiiGiiService';

class LotteryDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sumShotNumber: 0,
            numberShotPerPage: 10,
            numberShotNowPage: 1,
            disabledButtonLoad: false,
            numberShots: [],
            lotteryResult: null,
            roundNumber: 0
        };
    }

    componentDidMount() {
        this.getShotNumberData()
    }
    getShotNumberData() {
        let { roundId } = this.props.match.params
        this.setState({ disabledButtonLoad: true })
        YiiGiiService.getYiiGiiRound(roundId).then((res) => {
            if (res.status) {
                let sumShotNumber = 0;
                let numberShotsList = []
                res.data.numberShots.reverse().forEach(item => {
                    sumShotNumber += parseInt(item.numberShot)
                    let numberShotItem = {
                        user: item.username,
                        numberShot: item.numberShot,
                        time: new Date(new Date(item.createdAt).toLocaleString("en-US", { timeZone: "Asia/Bangkok" })).getHours() + ":" + new Date(new Date(item.createdAt).toLocaleString("en-US", { timeZone: "Asia/Bangkok" })).getMinutes() + " น.",
                        myNumberShot: false,
                    }
                    numberShotsList.push(numberShotItem)
                })
                this.setState({
                    numberShots: numberShotsList,
                    sumShotNumber: sumShotNumber,
                    disabledButtonLoad: false,
                    lotteryResult: res.data.result,
                    roundNumber: res.data.name
                })
            }
        })
    }

    handleLoadNumberShotList = () => {
        let { numberShotNowPage } = this.state;
        let _this = this;
        this.setState({ isLoadingNumberShots: true });
        setTimeout(function () {
            _this.setState({ numberShotNowPage: numberShotNowPage + 1, isLoadingNumberShots: false });
        }, 1000);
    };

    getNumberShotList = () => {
        {
            let { numberShotPerPage, numberShotNowPage, numberShots } = this.state;
            let numberShotSize = numberShotPerPage * numberShotNowPage;
            return numberShots.map(function (item, index) {
                return index < numberShotSize ? (
                    <Table.Row positive={item.myNumberShot} style={{ textAlign: 'center', background: (index % 2 === 0) ? '#F9F9F9' : '#FFFFFF' }}>
                        <Table.Cell className="table-cell-number-shot" style={{ width: '33%', paddingLeft: 50, textAlign: 'left', color: '#636363' }}>{"****" + item.user[item.user.length - 4] + item.user[item.user.length - 3] + item.user[item.user.length - 2] + item.user[item.user.length - 1]}</Table.Cell>
                        <Table.Cell className="table-cell-number-shot" style={{ width: '33%', textAlign: 'center', color: '#636363' }}>{item.numberShot}</Table.Cell>
                        <Table.Cell className="table-cell-number-shot" style={{ width: '33%', paddingRight: 50, textAlign: 'right', color: '#636363' }}>{item.time}</Table.Cell>
                    </Table.Row>
                ) : "";
            });
        };
    }

    resizeBox = () => {
        let box = 1;
        let innerWidth = window.innerWidth;
        if (innerWidth > 720)
            box = 3;

        this.setState((prevState) => ({ box: box }))
    };

    render() {
        return (
            <Container>
                <Segment style={{ borderRadius: 10,padding:'1em 1em' }}>
                    <Card.Content extra className="bg-white text-black">
                        <div style={{ width: 22, height: 44, background: 'rgb(243, 243, 243)', position: 'absolute', left: -1, top: 55, borderRadius: '0px 44px 44px 0px' }}></div>
                        <div style={{ width: 22, height: 44, background: 'rgb(243, 243, 243)', position: 'absolute', right: -1, top: 55, borderRadius: '44px 0px 0px 44px' }}></div>
                        <div style={{ textAlign: 'center', marginBottom: 20, marginTop: 5 }}>
                            <span style={{ fontSize: '1.2em', fontWeight: 400, borderBottom: '2.5px solid #CCC', paddingLeft: 15, paddingRight: 15 }}>รอบที่ {this.state.roundNumber ? this.state.roundNumber : "-"}</span>
                        </div>
                        <Grid columns={3} centered>
                            <Grid.Row style={{ paddingLeft: 20, paddingRight: 20 }}>

                                <Grid.Column style={{ textAlign: 'center', paddingLeft: 5, paddingRight: 5 }}>
                                    <Button circular style={{ padding: '5px 5px 5px 5px', width: '2.5em', height: '2.5em', marginBottom: 10, fontSize: '1.5em' }} color={'red'}>
                                        {this.state.lotteryResult ? this.state.lotteryResult.threeTop : "-"}
                                    </Button>
                                    <p style={{ marginBottom: 0 }}>สามตัวบน</p>
                                    <p style={{ color: '#DDD', fontSize: '0.7em' }}>{'●●●'}</p>
                                </Grid.Column>
                                <Grid.Column style={{ textAlign: 'center', paddingLeft: 5, paddingRight: 5 }}>
                                    <Button circular style={{ padding: '5px 5px 5px 5px', width: '2.5em', height: '2.5em', marginBottom: 10, fontSize: '1.5em' }} color={'yellow'}>
                                        {this.state.lotteryResult ? this.state.lotteryResult.twoTop : "-"}
                                    </Button>
                                    <p style={{ marginBottom: 0 }}>สองตัวบน</p>
                                    <p style={{ color: '#DDD', fontSize: '0.7em' }}>{'●●'}</p>
                                </Grid.Column>
                                <Grid.Column style={{ textAlign: 'center', paddingLeft: 5, paddingRight: 5 }}>
                                    <Button circular style={{ padding: '5px 5px 5px 5px', width: '2.5em', height: '2.5em', marginBottom: 10, fontSize: '1.5em', backgroun: '#00B261' }} color={'blue'}>
                                        {this.state.lotteryResult ? this.state.lotteryResult.twoBot : "-"}
                                    </Button>
                                    <p style={{ marginBottom: 0 }}>สองตัวล่าง</p>
                                    <p style={{ color: '#DDD', fontSize: '0.7em' }}>{'●●'}</p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Content>
                </Segment>

                <Segment style={{ borderRadius: 10, paddingInlineEnd: 0, paddingInlineStart: 0,padding:'1em 1em' }}>
                    <Card.Content extra className="bg-white text-black">
                        <div style={{ width: 22, height: 44, background: 'rgb(243, 243, 243)', position: 'absolute', left: -1, top: 80, borderRadius: '0px 44px 44px 0px' }}></div>
                        <div style={{ width: 22, height: 44, background: 'rgb(243, 243, 243)', position: 'absolute', right: -1, top: 80, borderRadius: '44px 0px 0px 44px' }}></div>
                        <div style={{ textAlign: 'center', marginBottom: 20, marginTop: 5 }}>
                            <span style={{ fontSize: '1.2em', fontWeight: 400, paddingLeft: 15, paddingRight: 15 }}>เลขที่ออก</span>
                            <Header as='h2' style={{ marginTop: 5,color:'var(--color-select)' }}>{this.state.lotteryResult ? this.state.lotteryResult.number : "-"}</Header>
                        </div>
                        <div style={{
                            height: 2,
                            backgroundImage: 'linear-gradient(to right, transparent 80%, rgb(204, 204, 204) 20%)',
                            backgroundSize: 20,
                            marginInlineStart: 50,
                            marginInlineEnd: 50
                        }} />
                        <div style={{ display: 'flex', justifyContent: 'space-bettween', width: '100%', marginTop: 10 }}>

                            <div style={{ textAlign: 'center', marginBottom: 20, marginTop: 5, width: '50%' }}>
                                <span style={{ fontSize: '1.2em', fontWeight: 400, paddingLeft: 15, paddingRight: 15 }}>ผลรวมยิงเลข</span>
                                <Header as='h2' style={{ marginTop: 5,color:'var(--color-select)' }}>{this.state.lotteryResult ? this.state.lotteryResult.totalShotNumber : "-"}</Header>
                            </div>
                            <div style={{ textAlign: 'center', marginBottom: 20, marginTop: 5, width: '50%' }}>
                                <span style={{ fontSize: '1.2em', fontWeight: 400, paddingLeft: 15, paddingRight: 15 }}>เลขลำดับที่ 16</span>
                                <Header as='h2' style={{ marginTop: 5,color:'var(--color-select)' }}>{this.state.lotteryResult ? this.state.lotteryResult.sixTeenShotNumber : "-"}</Header>
                            </div>
                        </div>
                        <Table style={{ border: 0, margin: 0, marginBottom: 20 }}>
                            <Table.Body>
                                {this.getNumberShotList()}
                            </Table.Body>
                        </Table>
                        {
                            !!!(this.state.disabledButtonLoad || this.state.numberShotPerPage * this.state.numberShotNowPage >= this.state.numberShots.length) && this.state.numberShots?.length > 10 &&
                            <Grid columns={1} centered>
                                <Grid.Column textAlign={"center"}>
                                    <Button disabled={this.state.disabledButtonLoad || this.state.numberShotPerPage * this.state.numberShotNowPage >= this.state.numberShots.length} positive
                                        onClick={this.handleLoadNumberShotList}>โหลดเพิ่ม</Button>
                                </Grid.Column>
                            </Grid>
                        }

                    </Card.Content>
                </Segment>

            </Container >
        );
    }
}

export default LotteryDetail;