import Config from "../config/config";
import Authen from './Authen'
import apiClient from './axios/apiClient';

export default class YiiGiiService {
    constructor() {
    }

    static async getYiiGii(yiigiiId = null) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain + 'YiiGii/getyiigii/' + ((yiigiiId != null) ? yiigiiId : ''),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data.yiigii }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getYiiGiiRoundToday(yiigiiID) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'YiiGii/getRound',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: JSON.stringify({
                // "announceTime" : "2020-08-06T17:00:00.000Z"
                "yiiGiiId": yiigiiID
            })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getYiiGiiRoundByDate(yiigiiID, dateObj) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'YiiGii/getRound',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: JSON.stringify({
                "announceTime": new Date(dateObj).toISOString(),
                "yiiGiiId": yiigiiID
            })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async getYiiGiiRound(roundID) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'YiiGii/getRound/' + roundID,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true, data: response.data.round }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }

    static async play(roundID, orderitem, orderName) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain+'YiiGii/play/'+roundID,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
            data: JSON.stringify({
                "orderItem": orderitem,
                "orderName":orderName
            })
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return {status: false ,msg: error.response.data.errors.message}
        });        
        return await returnData
    }

    static async numberShot(roundID, number) {
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain + 'YiiGii/numberShot/',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Authen.getAcessToken(),
            },
            data: JSON.stringify({
                roundId: roundID,
                number: number.toString()
            })
        };

        const returnData = await apiClient(config).then(function (response) {
            return { status: true }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors.message }
        });
        return await returnData
    }
}