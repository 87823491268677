import React, { Component } from 'react'
import { Grid, Icon, Label, Segment, Card, Tab, Container, Button, Table, Input } from "semantic-ui-react";
import TopUpCredit from "./TopUpCredit";
import WithdrawCredit from "./WithdrawCredit";
import WalletService from '../../../service/WalletService';
import TopUpCreditByAccount from "./TopUpCreditByAccount";
import UserService from '../../../service/UserService';
import ConfigValueService from '../../../service/ConfigValueService';
import WithdrawAF from "../../view/wallet/WithdrawAF";
import config from '../../../config/config'
import { CreditIcon, AFIcon } from '../../../assets/images/svg/svg-icon'
import { ToastContainer, Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class WalletMoney extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openTopUpCredit: false,
            openWithdrawCredit: false,
            WalletBalance: WalletService.getWalletBalance(),
            User: UserService.getUser(),
            statementCredit: [],
            historyTransactionLoading: false,
            ShowLimitStatementCreditList: 10,
            minimumAFWitdraw: 1,
            AFtotal: 0,
            AFWithdrawActive: 0,
            AfWithdrawLoading: false,
            AFWithdrawVelue: "",
            next: true,
            openConfirm: false,
            errorMSG: "",
            limit: 10
        };
    }
    componentDidMount() {
        // WalletService.fetchWalletBalance();
        const _this = this;
        setInterval(function () {
            _this.setState({ WalletBalance: WalletService.getWalletBalance() })
        }, 3000);
        this.setState({
            AFtotal: Number(WalletService.getWalletBalance().af).toFixed(0),
            AFWithdrawActive: Number(WalletService.getWalletBalance().af).toFixed(0),
        })
        this.getHistoryTransaction();
        this.initData()

    }

    initData() {
        this.setState({ AfWithdrawLoading: true })
        ConfigValueService.MINIMUM_WITHDRAW_AF().then(res => {
            this.setState({ minimumAFWitdraw: res.data.value || 1, AfWithdrawLoading: false })
        })
    }

    handleChangeMoney = (event) => {
        const minimumAFWitdraw = this.state.minimumAFWitdraw
        this.setState({ next: true })
        let value = event.target.value.replace(/,/g, '');
        value = Number(value)
        if (!isNaN(value)) {
            if (value <= Number(this.state.AFWithdrawActive)) {
                if (value >= minimumAFWitdraw) {
                    this.setState({ next: false })
                }
                // let valueReplace = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
                this.setState({ AFWithdrawVelue: value })
            }
        }
    };

    AFWithdraw = () => {
        let _this = this;
        this.setState({ AfWithdrawLoading: true, errorMSG: "" });
        setTimeout(function () {
            WalletService.AFwithdraw(Number(_this.state.AFWithdrawVelue)).then(res => {
                if (res.status) {
                    _this.setState({ AfWithdrawLoading: false });
                    WalletService.fetchWalletBalance();
                    _this.setState({ WalletBalance: WalletService.getWalletBalance() })
                    _this.setState({ AFWithdrawVelue: '' });
                    toast.success("โอน AF สำเร็จ", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    _this.setState({ errorMSG: res.msg })
                    _this.setState({ AfWithdrawLoading: false });
                    toast.error("โอน AF ไม่สำเร็จ", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
        }, 1000);
    }

    getHistoryTransaction(limit = 10) {
        this.setState({ historyTransactionLoading: true })
        const _this = this;
        UserService.getHistoryWallet(limit).then((res) => {
            if (res.status) {
                const handleData = [];
                res.data.wallet.creditHistory.map((item, key) => {
                    //prepair topic
                    let topicText = null;
                    switch (item.type) {
                        case 1:
                            topicText = 'ฝาก';
                            break;
                        case 2:
                            topicText = 'ถอน';
                            break;
                        case 3:
                            topicText = 'แทงหวย';
                            break;
                        case 4:
                            topicText = 'ถูกรางวัล';
                            break;
                        case 5:
                            topicText = 'โอน AF เป็นเครดิต';
                            break;
                        case 6:
                            topicText = 'ถอน AF';
                            break;
                        case 7:
                            topicText = 'ได้ AF';
                            break;
                        case 8:
                            topicText = 'เล่นเกม';
                            break;
                        case 9:
                            topicText = 'ชนะเกม';
                            break;
                        case 10:
                            topicText = 'เสมอเกม';
                            break;
                        case 11:
                            topicText = 'เกมหมดเวลา';
                            break;
                        case 12:
                            topicText = 'คืนเงิน';
                            break;
                        case 13:
                            topicText = 'ดึงเงินคืน';
                            break;
                        default:
                            topicText = 'ไม่มีหัวข้อรายการ'
                    }

                    if (item.type != 6) {
                        handleData.push({
                            topic: topicText,
                            dateTime: item.createAt,
                            money: item.oldValue,
                            moneyAction: ((item.isIncome) ? '+' : '-') + item.actionValue,
                            moneySum: item.newValue,
                            color: (item.isIncome) ? 'green' : 'red',
                            status: (item.isIncome) ? 'positive' : 'negative',
                        })
                    }
                });

                _this.setState({ statementCredit: handleData, historyTransactionLoading: false, limit: limit });
            }
        })
    }

    handleCollapseCredit = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndexCredit } = this.state;
        const newIndex = activeIndexCredit === index ? -1 : index;
        this.setState({ activeIndexCredit: newIndex })
    };

    handleCollapseLottery = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndexLottery } = this.state;
        const newIndex = activeIndexLottery === index ? -1 : index;
        this.setState({ activeIndexLottery: newIndex })
    };

    getStatementCreditListWithLimit() {
        let result = []
        for (var i = 0; i < this.state.statementCredit.length; i++) {
            if (i >= this.state.ShowLimitStatementCreditList) { break } else {
                result.push(this.state.statementCredit[i])
            }
        }
        return result
    }

    handleIncreaseShowLimitStatementCreditList() {
        this.setState({ historyTransactionLoading: true })
        setTimeout(() => {
            this.setState((prevState) => ({ ShowLimitStatementCreditList: prevState.ShowLimitStatementCreditList + 10 }))
            this.setState({ historyTransactionLoading: false })
        }, 1)
    }

    handleOpenTopUpCreditByAccount = () => {
        this.setState({ openTopUpCreditByAccount: true });
    };

    handleCloseTopUpCreditByAccount = () => {
        this.setState({ openTopUpCreditByAccount: false });
    };

    handleOpenWithdrawCredit = () => {
        this.setState({ loadingCreadit: true })
        WalletService.fetchWalletBalance().then(res => {
            this.setState({ loadingCreadit: false, openWithdrawCredit: true });
        })
    };

    handleCloseWithdrawCredit = () => {
        this.setState({ openWithdrawCredit: false });
    };

    toTwoDecimal = (num) => {
        let num_new = (parseInt(Number(Number((Number(num) * 10).toFixed(3)) * 10).toFixed(3)) / 100.00)
        let _num = num_new.toString().split('.')
        if (_num.length > 1) {
            return (_num[0] + '.' + (_num[1].length < 2 ? `${_num[1].substring(0, 2)}0` : _num[1].substring(0, 2))).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
            return (_num[0] + '.00').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }

    panes = [
        {
            menuItem: { key: 'wallet', icon: <CreditIcon width='33' height='23' className="wallet-profile-header" />, content: 'กระเป๋าเงิน' },
            render: () => <Tab.Pane attached={false} loading={this.state.isLoadingPane} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0, marginTop: '-0.5em' }}>
                <Card className="card-wallet-profile">
                    <Card.Content style={{ display: 'flex', alignItems: 'center' }}>
                        <Card.Header className="container-box-user-icon" style={{ width: '60%' }}>
                            <div style={{ alignItems: 'center', display: 'flex' }}>
                                <CreditIcon width='65' height='55' />
                                <div >
                                    <span className="box-user" style={{ fontWeight: 400, fontSize: '1.5rem', color: '#F6CF3D', marginLeft: 10 }}>ชื่อผู้ใช้งาน</span><br />
                                    <span className="box-user" style={{ fontWeight: 300, fontSize: '1rem', color: '#F6CF3D', marginLeft: 10 }}>{this.state.User.username}</span>
                                </div>
                            </div>
                        </Card.Header>
                    </Card.Content>

                    <Card.Content className="container-box-wallet" style={{ borderTop: '0px', display: 'flex', alignItems: 'flex-end' }}>
                        <Card.Header style={{ width: '70%' }}>
                            <p className="box-wallet-amount" style={{ color: '#FFFFFF', fontSize: '3rem', fontWeight: 500, marginBottom: '0px' }}>฿ {this.toTwoDecimal(this.state.WalletBalance.credit)}</p>
                            <p className="box-wallet-amount-name" style={{ color: '#F6CF3D', fontSize: '1.5rem', fontWeight: 400 }}>ยอดเงินคงเหลือ</p>
                        </Card.Header>
                        <Card.Header className="container-box-af-icon" style={{ width: '30%', textAlign: 'right', marginBottom: 5 }}>
                            <AFIcon width='20' height='20' />
                            <span className="box-af" style={{ color: '#FBDA61', fontSize: '1.3rem', fontWeight: 300 }}>  {this.toTwoDecimal(this.state.WalletBalance.af)}</span>
                            <p className="box-af" style={{ color: '#FBDA61', fontSize: '0.8rem', fontWeight: 300, marginTop: -5, paddingTop: 5 }}>AF คงเหลือ</p>
                        </Card.Header>
                    </Card.Content>
                </Card >
                <Segment loading={this.state.historyTransactionLoading} style={{ marginTop: 25, border: 0 }}>
                    {!this.state.historyTransactionLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Card className="card-wallet-come-out" style={{ paddingTop: 5, paddingBottom: 5, borderRadius: 40, justifyContent: 'center', marginTop: -25, marginBottom: 25, width: 300, background: '#FFFFFF' }}>
                                <Card.Header style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 5 }}>
                                    <Button
                                        color='green'
                                        style={{
                                            paddingRight: 25,
                                            paddingLeft: 25,
                                            borderRadius: 35,
                                            background: 'var(--background-wallet-come)',
                                            width: '50%'
                                        }}
                                        onClick={() => {
                                            this.handleOpenTopUpCreditByAccount()
                                        }}>
                                        <Icon name='plus square outline' /> เติมเงิน
                                    </Button>
                                    <Button
                                        color='red'
                                        style={{
                                            paddingRight: 25,
                                            paddingLeft: 25,
                                            borderRadius: 35,
                                            background: 'var(--background-wallet-out)',
                                            width: '50%'
                                        }}
                                        onClick={() => {
                                            this.handleOpenWithdrawCredit()
                                        }}>
                                        <Icon name='minus square outline' />ถอนเงิน
                                    </Button>
                                </Card.Header>
                            </Card>
                            {this.state.openTopUpCreditByAccount &&
                                <TopUpCreditByAccount openTopUpCreditByAccount={this.state.openTopUpCreditByAccount}
                                    handleCloseTopUpCreditByAccount={this.handleCloseTopUpCreditByAccount} />
                            }
                            {this.state.openWithdrawCredit &&
                                <WithdrawCredit openWithdrawCredit={this.state.openWithdrawCredit}
                                    handleCloseWithdrawCredit={this.handleCloseWithdrawCredit} />
                            }
                        </div>) : ''}
                    <p style={{ textAlign: 'center', fontWeight: 300, fontSize: 18 }} >รายการฝาก - ถอน</p>
                    <Table style={{ border: 0, margin: 0, marginBottom: 20 }}>
                        <Table.Body>
                            {
                                this.state.statementCredit.map(function (item, key) {
                                    return (
                                        <Table.Row positive={item.myNumberShot} key={key}>
                                            <Table.Cell style={{ width: '50%', paddingLeft: 50, textAlign: 'left', color: '#636363' }}>
                                                <p style={{ color: '#000000', fontWeight: 400, fontSize: 16, marginBottom: 10 }}>{item.topic}</p>
                                                <p style={{ color: '#888', fontWeight: 300, fontSize: 13 }}>{new Date(item.dateTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }) + " " + new Date(item.dateTime).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}</p>
                                            </Table.Cell>
                                            <Table.Cell style={{ width: '50%', paddingRight: 50, textAlign: 'right', color: '#636363' }}>
                                                <p style={{ color: item.color, fontWeight: 400, fontSize: 14 }} >{item.moneyAction}</p>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>
                    {this.state.statementCredit.length >= this.state.limit &&
                        <center style={{ marginTop: 10 }}>
                            <Button positive onClick={() => this.getHistoryTransaction(this.state.limit + 5)}  > โหลดเพิ่มเติม</Button>
                        </center>
                    }
                    {/*  {
                         this.state.ShowLimitStatementCreditList <= this.state.statementCredit.length &&
                         <center style={{ marginTop: 10 }}>
                    <Button positive onClick={() => this.handleIncreaseShowLimitStatementCreditList()} disabled={(this.state.ShowLimitStatementCreditList >= this.state.statementCredit.length)}>{(this.state.statementCredit.length > 0) ? 'โหลดเพิ่มเติม' : 'ไม่มีรายการฝากถอน'}</Button>
                         </center>
                     } */}
                </Segment>
            </Tab.Pane>,
        },
        {
            menuItem: { key: 'AF', icon: <AFIcon width='40' height='25' />, content: 'กระเป๋าเงิน AF' },
            render: () => <Tab.Pane attached={false} loading={this.state.isLoadingPane} style={{ boxShadow: 'unset', border: 0, backgroundColor: 'unset', padding: 0, marginTop: '-0.5em' }}>
                <Card className="card-wallet-af">
                    <Card.Content style={{ display: 'flex', alignItems: 'center' }}>
                        <Card.Header className="container-box-user-icon" style={{ width: '60%' }}>
                            <div style={{ alignItems: 'center', display: 'flex' }}>
                                <AFIcon width='70' height='70' />
                                <div>
                                    <span className="box-user" style={{ fontWeight: 400, fontSize: '1.5rem', color: '#F6CF3D', marginLeft: 10 }}>ชื่อผู้ใช้งาน</span><br />
                                    <span className="box-user" style={{ fontWeight: 300, fontSize: '1rem', color: '#F6CF3D', marginLeft: 10 }}>{this.state.User.username}</span>
                                </div>
                            </div>
                        </Card.Header>
                    </Card.Content>

                    <Card.Content className="container-box-wallet" style={{ borderTop: '0px', display: 'flex', alignItems: 'flex-end' }}>
                        <Card.Header style={{ width: '70%' }}>
                            <p className="box-wallet-amount" style={{ color: '#FFFFFF', fontSize: '3rem', fontWeight: 500, marginBottom: '0px' }}>  {(Number(this.state.WalletBalance.af).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                            <p className="box-wallet-amount-name" style={{ color: '#F6CF3D', fontSize: '1.5rem', fontWeight: 400 }}>AF คงเหลือ</p>
                        </Card.Header>
                        <Card.Header className="container-box-af-icon" style={{ width: '30%', textAlign: 'right', marginBottom: 5 }}>
                            <CreditIcon width='20' height='15' />
                            <span className="box-af" style={{ color: '#FBDA61', fontSize: '1.3rem', fontWeight: 300 }}> {(Number(this.state.WalletBalance.credit).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            <p className="box-af" style={{ color: '#FBDA61', fontSize: '0.8rem', fontWeight: 300, marginTop: -5, paddingTop: 5 }}>ยอดเงินคงเหลือ</p>
                        </Card.Header>
                    </Card.Content>
                </Card >
                <Segment style={{ marginTop: 5, border: 0, padding: '30px 40px' }} loading={this.state.AfWithdrawLoading}>
                    <center style={{ width: '100%', textAlign: 'center', }}>
                        <div style={{}}>
                            <p style={{ fontWeight: 400, fontSize: 14 }}>จำนวน AF ที่ต้องการโอน</p>
                            <p style={{ color: 'red' }}>{this.state.errorMSG}</p>
                            <div style={{ display: 'flex', justifyContent: "center" }}>
                                <div style={{ background: '#F8F8F8', padding: 20, borderRadius: 15, display: 'flex', alignItems: 'center', width: '50%' }}>
                                    <div style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                                        {/* <span style={{ color: '#BDBDBD', marginRight: 20 }}>จาก</span> */}
                                        <AFIcon width='25' height='25' />
                                    </div>
                                    <Input placeholder='0' type='text' style={{ maxWidth: '80%' }} >
                                        <input style={{ textAlign: "right" }} value={this.state.AFWithdrawVelue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onChange={(event) => this.handleChangeMoney(event)} />
                                    </Input>
                                </div>
                            </div>
                        </div>

                        {/* <div style={{ width: '10%', display: 'flex', marginBottom: 20, alignItems: 'flex-end', justifyContent: 'center' }}>
                            <svg width="31" height="35" viewBox="0 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.88889 35L0 28L6.88889 21V26.25H24.1111V19.25H27.5556V28C27.5556 28.9665 26.7845 29.75 25.8333 29.75H6.88889V35ZM6.88889 15.75H3.44444V7C3.44444 6.0335 4.21551 5.25 5.16667 5.25H24.1111V0L31 7L24.1111 14V8.75H6.88889V15.75Z" fill="#6F6F6F" />
                            </svg>
                        </div>

                        <div style={{ width: '45%' }}>
                            <p style={{ fontWeight: 400, fontSize: 14 }}>จำนวนเงินที่ได้</p>
                            <div style={{ background: '#F8F8F8', padding: 20, borderRadius: 15, display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '50%', display: 'flex' }}>
                                    <span style={{ color: '#BDBDBD', marginRight: 20 }}>เป็น</span>
                                    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.7048 21.6074H22.5977C23.9244 21.6074 25 20.5319 25 19.2052V9.28744C25 7.96069 23.9244 6.88513 22.5977 6.88513H7.7048C6.37805 6.88513 5.30249 7.96069 5.30249 9.28744V19.2052C5.30249 20.5319 6.37805 21.6074 7.7048 21.6074Z" fill="url(#paint0_linear)" />
                                        <path d="M7.56784 5.32639C7.56784 4.5357 7.08576 3.85756 6.39946 3.56996C5.95896 3.38534 5.66419 2.96433 5.66419 2.48673V1.82044C5.66419 0.815057 6.47919 6.10352e-05 7.48457 6.10352e-05H19.6464C20.6518 6.10352e-05 21.4668 0.815057 21.4668 1.82044V2.48673C21.4668 2.96438 21.172 3.38534 20.7315 3.56996C20.0452 3.85756 19.5631 4.5357 19.5631 5.32639C19.5631 6.11709 20.0452 6.79523 20.7315 7.08283C21.172 7.26744 21.4668 7.68846 21.4668 8.16606V8.83235C21.4668 9.83773 20.6518 10.6527 19.6464 10.6527H7.48457C6.47919 10.6527 5.66419 9.83773 5.66419 8.83235V8.16606C5.66419 7.68841 5.95896 7.26744 6.39946 7.08283C7.08576 6.79523 7.56784 6.11714 7.56784 5.32639Z" fill="url(#paint1_linear)" />
                                        <path d="M11.9426 5.89915H15.189C15.5053 5.89915 15.7617 5.64272 15.7617 5.32644C15.7617 5.01015 15.5053 4.75372 15.189 4.75372H11.9426C11.6263 4.75372 11.3699 5.01015 11.3699 5.32644C11.3699 5.64272 11.6263 5.89915 11.9426 5.89915Z" fill="#E06E1C" />
                                        <path d="M4.35887 6.41183C4.91799 5.85271 5.05659 5.03234 4.77471 4.34364C4.59378 3.9016 4.68303 3.39552 5.02078 3.05777L5.4919 2.58665C6.20281 1.87574 7.35541 1.87574 8.06632 2.58665L16.666 11.1864C17.3769 11.8973 17.3769 13.0499 16.666 13.7608L16.1949 14.232C15.8572 14.5697 15.3511 14.659 14.909 14.478C14.2204 14.1961 13.4 14.3347 12.8409 14.8939C12.2817 15.453 12.1431 16.2734 12.425 16.9621C12.6059 17.4041 12.5167 17.9102 12.1789 18.2479L11.7078 18.719C10.9969 19.43 9.84432 19.43 9.13341 18.719L0.533695 10.1193C-0.177217 9.40841 -0.177217 8.25582 0.533695 7.5449L1.00486 7.07379C1.34261 6.73603 1.84869 6.64679 2.29073 6.82772C2.97938 7.10954 3.79976 6.97095 4.35887 6.41183Z" fill="url(#paint2_linear)" />
                                        <path d="M10.4067 3.87084C10.6985 3.5791 11.1714 3.5791 11.4632 3.87084L17.7222 10.1298C17.8848 10.2924 18.0268 10.4678 18.1484 10.6528H16.1322L10.4068 4.92732C10.115 4.63559 10.115 4.16258 10.4067 3.87084Z" fill="#E06E1C" />
                                        <path d="M18.625 18.2931H21.1656C21.4819 18.2931 21.7383 18.0366 21.7383 17.7204C21.7383 17.4041 21.4819 17.1476 21.1656 17.1476H18.625C18.3087 17.1476 18.0523 17.4041 18.0523 17.7204C18.0522 18.0366 18.3087 18.2931 18.625 18.2931Z" fill="#C2C8CF" />
                                        <path d="M9.74759 12.3733C9.89415 12.3733 10.0407 12.3174 10.1526 12.2055C10.3762 11.9819 10.3762 11.6193 10.1526 11.3956L7.85703 9.09999C7.63338 8.87634 7.27077 8.87634 7.04707 9.09999C6.82341 9.32365 6.82341 9.68625 7.04707 9.90996L9.3426 12.2055C9.45446 12.3173 9.60102 12.3733 9.74759 12.3733Z" fill="#EFC534" />
                                        <path d="M15.5603 15.1616C15.5261 14.8621 15.6746 14.5703 15.9386 14.4246C16.0309 14.3736 16.1172 14.3094 16.1947 14.2319L16.6659 13.7607C17.3768 13.0498 17.3768 11.8972 16.6659 11.1863L16.1323 10.6528H19.6476C20.653 10.6528 21.468 9.8378 21.468 8.83242V8.16613C21.468 7.68779 21.1719 7.26752 20.7309 7.08211C20.6048 7.02912 20.4858 6.96278 20.3753 6.88519H22.5982C22.6137 6.89436 22.631 6.90472 22.6465 6.91388C22.8496 7.29167 22.9619 7.71941 22.9619 8.16613V8.83242C22.9619 10.6629 21.478 12.1468 19.6475 12.1468H18.6754C18.7692 13.1009 18.4514 14.088 17.7222 14.8172L17.2511 15.2883C17.0706 15.4689 16.8699 15.6182 16.6557 15.736C16.1932 15.9904 15.6201 15.6862 15.5603 15.1616Z" fill="#424E5E" />
                                        <path d="M4.91216 12.8596C5.03965 12.8596 5.16704 12.811 5.2643 12.7138C5.45877 12.5193 5.45877 12.2039 5.2643 12.0095L4.08262 10.8278C3.88814 10.6334 3.5728 10.6334 3.37833 10.8279C3.18386 11.0224 3.18386 11.3377 3.37833 11.5322L4.56001 12.7138C4.65722 12.811 4.78471 12.8596 4.91216 12.8596Z" fill="#FFFDE3" />
                                        <path d="M2.28618 10.2335C2.41362 10.2335 2.54111 10.1849 2.63832 10.0877C2.8328 9.8932 2.8328 9.57785 2.63832 9.38333L2.4697 9.2147C2.27522 9.02023 1.95988 9.02023 1.76541 9.2147C1.57094 9.40918 1.57094 9.72452 1.76541 9.91904L1.93404 10.0877C2.03125 10.1849 2.15874 10.2335 2.28618 10.2335Z" fill="#FFFDE3" />
                                        <defs>
                                            <linearGradient id="paint0_linear" x1="33.3432" y1="14.2463" x2="0.279399" y2="14.2463" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#4E5660" />
                                                <stop offset="1" stopColor="#64768D" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear" x1="13.5655" y1="11.6359" x2="13.5655" y2="-2.11509" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#EA7D32" />
                                                <stop offset="1" stopColor="#F0B160" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear" x1="8.59989" y1="17.5336" x2="8.59989" y2="1.54531" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#F4D952" />
                                                <stop offset="1" stopColor="#F9EDAF" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <Input placeholder='0' type='text' style={{ maxWidth: '60%' }} disabled>
                                    <input style={{ textAlign: "right" }} value={this.state.AFWithdrawVelue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} />
                                </Input>
                            </div>
                        </div> */}
                    </center>
                    <center style={{ color: '#FF7A00', fontSize: 16, marginTop: 20, marginBottom: 5 }}>โอนขั้นต่ำครั้งละ {this.state.minimumAFWitdraw}AF</center>
                    <center style={{ color: '#FF0000', marginBottom: 10 }}>
                        * หมายเหตุ ยอด AF ที่ถอนจะเข้าเป็นยอดเงินในกระเป๋าหลัก
                    </center>
                    <center>
                        <Button style={{ background: '#00659E', color: '#FFFFFF', width: '50%' }} onClick={() => this.AFWithdraw()}>ยืนยัน</Button>
                    </center>
                </Segment>
            </Tab.Pane>,
        },
    ]
    render() {
        return (
            <Container className='wallet-money' style={{ marginTop: 0 }}>
                {(config.enableAF == true || config.enableAF == 'true') ? <Tab defaultActiveIndex={this.props.defaultActiveIndex} menu={{ pointing: true }} panes={this.panes} /> : this.panes[0].render()}
                <ToastContainer autoClose={1500} transition={Slide} />
            </Container>
        );
    }
}

export default WalletMoney;