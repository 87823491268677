import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Icon, Segment, Input, Button, Dropdown, Modal } from "semantic-ui-react";
import BookService from '../../../service/BookService';
import ConfigValueService from '../../../service/ConfigValueService';

const CreateFavoriteBill = (props) => {
    const [list_huay, set_list_huay] = useState([
        {
            typeId: '',
            number: '',
            price: 0
        }
    ])
    const [loading, set_loading] = useState(true)
    const [huay_type, set_huay_type] = useState([])
    const [length_number, set_length_number] = useState([])
    const [name, set_name] = useState('')
    const [_id_favorite, set_id_favorite] = useState('')
    const [loading_button, set_loading_button] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false)
    const [openInfo, setOpenInfo] = useState(false)

    useEffect(() => {
        ConfigValueService.LOTTORY_TYPES().then((res) => {
            let _huay_type = []
            let result = new Promise(async (resolve, reject) => {
                try {
                    let _result = await res.data.value[0].map((item, index) => {
                        _huay_type[index] = {
                            text: item.name,
                            value: item.typeId,
                            key: item.typeId,
                            length: item.length
                        }
                        if (index == 0) {
                            set_list_huay([
                                {
                                    typeId: item.typeId,
                                    number: '',
                                    price: 0
                                },
                                {
                                    typeId: item.typeId,
                                    number: '',
                                    price: 0
                                }
                            ])
                            set_length_number([item.length, item.length])
                        }
                    })
                    await set_huay_type([..._huay_type])
                    if (!!props.location.query) {
                        let _list_huay = []
                        let _length_number = []
                        let result = await props.location.query?.data?.items?.map((item, index) => {
                            _list_huay[index] = {
                                typeId: item.typeId,
                                number: item.number,
                                price: item.price || 0
                            }
                            let _length = _huay_type.filter((type) => type.value == item.typeId)
                            _length_number[index] = _length[0].length
                            return true
                        })
                        set_list_huay([..._list_huay])
                        set_length_number([..._length_number])
                        set_id_favorite(props.location.query?.data?._id)
                        set_name(props.location.query?.data?.name)
                        resolve(true)
                    } else {
                        resolve(true)

                    }
                } catch (e) {
                    reject(false)
                }
            })
            if (result) {
                set_loading(false)
            }
        })
    }, [])

    const onChangeName = (event) => {
        set_name(event.target.value)
    }
    const onChangeNumber = (event, index) => {
        let pattern = /[0-9]/
        let _list_huay = list_huay
        if (event.target.value.length == 0) {
            _list_huay[index].number = ''
            set_list_huay([..._list_huay])
        } else if (event.target.value.length <= length_number[index]) {
            if (pattern.test(event.target.value) && !isNaN(event.target.value)) {
                _list_huay[index].number = event.target.value || ''
                set_list_huay([..._list_huay])
            }
        }
    }

    const onChangePrice = (event, index) => {
        let _list_huay = list_huay
        _list_huay[index].price = parseInt(event.target.value) || ''
        set_list_huay([..._list_huay])
    }

    const onChangeType = (value, index) => {
        let result = huay_type.filter((item) => item.value == value)
        let _list_huay = list_huay
        _list_huay[index] = {
            typeId: value,
            number: '',
            price: 0
        }
        let _length_number = length_number
        _length_number[index] = result[0].length
        set_list_huay([..._list_huay])
        set_length_number([..._length_number])
    }

    const deleteItem = (index) => {
        let result_list = list_huay
        result_list.splice(index, 1)
        set_list_huay([...result_list])
    }

    const onSubmit = async () => {
        set_loading_button(true)
        if (name == '') {
            setOpenInfo({ status: true, massage: 'กรุณาระบุชื่อเลขชุด !' })
        } else {
            let result = true
            await list_huay.map((item, index) => {
                if (item.number.length != length_number[index]) {
                    result = false;
                }
            })
            if (result) {
                if (_id_favorite) {
                    BookService.updateFavoriteOrder({ id: _id_favorite, name: name, items: list_huay }).then((res) => {
                        set_loading_button(false)
                        if (res.status) {
                            setOpenSuccess(true)
                        }
                    })
                } else {
                    BookService.addFavoriteOrder({ name: name, items: list_huay }).then((res) => {
                        set_loading_button(false)
                        if (res.status) {
                            setOpenSuccess(true)
                        }
                    })
                }
            } else {
                setOpenInfo({ status: true, massage: 'กรุณากรอกเลขให้ครบตามชนิดเลข !' })
            }
        }
    }

    return (
        <Container className='lotteryUI' style={{ marginTop: 10 }}>
            <Segment style={{
                background: 'var(--background-head-menu-item-active)',
                color: 'var(--color-head-menu-item-active)',
                border: 'var(--border-color-head-menu-item-active)',
                padding: '8px 10px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.4em'
            }}>
                <div>
                    <Icon name={'list ol'} />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', width: '100%' }}>
                    <div>
                        สร้างเลขชุด
                    </div>
                    <Button onClick={() => props.history.goBack()} size="mini" style={{ color: '#FFF', background: 'transparent', margin: 0, fontSize: 20, padding: 5 }} ><Icon style={{ margin: 0 }} name={'close'} /></Button>
                </div>
            </Segment>

            <Segment
                loading={loading}
                className="continer-create-favorite-bill"
                style={{
                    background: 'var(--background-head-menu-item)',
                    color: 'var(--color-head-menu-item)',
                    border: 'var(--border-color-head-menu-item)',
                    padding: 8,
                    borderRadius: 10,
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div >
                        ชื่อเลขชุด
                    </div>
                    <Input className="input-name-favorite-bill" style={{ marginLeft: 8 }} onChange={onChangeName} value={name} />
                </div>

                <div style={{ display: 'flex', width: '100%', padding: '10px 0' }}>
                    <div style={{ width: '40%' }} >
                        รายการเลขชุด
                    </div>
                    <div style={{ width: '40%', textAlign: 'center' }} >
                        เลข
                    </div>
                    {/* <div style={{ width: '25%', textAlign: 'center' }} >
                        ราคา
                    </div> */}
                    <div style={{ width: '20%', textAlign: 'center' }} >
                        ลบ
                    </div>
                </div>

                {
                    (list_huay && huay_type.length > 0) &&
                    list_huay?.map((item, index) => {
                        return (
                            < div style={{ display: 'flex', width: '100%', alignItems: 'center', padding: '0 0 5px 0' }} key={index}>
                                <div style={{ width: '40%', display: 'flex', alignItems: 'center' }} >
                                    <div style={{ paddingRight: index == 0 ? (7) : (((index + 1) % 10 == 0) ? 2 : 5) }} >
                                        {index + 1}
                                    </div>
                                    <Dropdown
                                        scrolling
                                        className="select-huay-type"
                                        options={huay_type}
                                        // defaultValue={item.typeId}
                                        value={item.typeId}
                                        onChange={(event, { value }) => onChangeType(value, index)}
                                    />
                                </div>
                                <div style={{ width: '40%', textAlign: 'center' }} >
                                    <Input className='input-number-favorite-bill' value={item.number} onChange={(event) => onChangeNumber(event, index)} />
                                </div>
                                {/* <div style={{ width: '25%', textAlign: 'center' }} >
                                    <Input className='input-number-favorite-bill' value={item.price} onChange={(event) => onChangePrice(event, index)} />
                                </div> */}
                                <div style={{ width: '20%', textAlign: 'center' }} >
                                    {
                                        index != 0 &&
                                        <Button size="mini" onClick={() => deleteItem(index)} style={{ color: 'red', background: '#FFF', margin: 0, fontSize: 20, padding: 6, borderRadius: 10 }} ><Icon style={{ margin: 0 }} name={'trash'} /></Button>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                {/* add new number */}
                <Button size="mini" onClick={() => {
                    set_list_huay([...list_huay, {
                        typeId: huay_type[0].value,
                        number: '',
                        price: 0
                    }])
                    set_length_number([...length_number, huay_type[0].length])
                }} style={{ margin: '10px 0', color: '#FFF', background: '#FFA800', borderRadius: 10, fontSize: 14, width: '100%' }} >เพิ่มชุดตัวเลข</Button>

                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 0 5px 0' }}>
                    <Button size="mini" onClick={() => props.history.goBack()} style={{ color: '#FFF', background: '#FD0000', margin: 0, borderRadius: 10, fontSize: 14, width: '48%' }} >ยกเลิก</Button>
                    <Button size="mini" loading={loading_button} disabled={loading_button} onClick={onSubmit} style={{ color: '#FFF', background: '#00C013', margin: 0, borderRadius: 10, fontSize: 14, width: '48%' }} >บันทึก</Button>
                </div>

            </Segment>
            <Modal
                onClose={() => {
                    setOpenInfo({ status: false, massage: '' })
                    set_loading_button(false)
                }}
                open={openInfo.status}
                size="mini"
            >
                <Modal.Content style={{ textAlign: 'center' }}>
                    <Icon name="exclamation circle" size="huge" style={{ color: '#f8bb86' }} />
                    <p style={{ marginTop: 10, fontSize: '1.3rem', fontWeight: 500 }}>{openInfo.massage}</p>
                </Modal.Content>
                <Modal.Actions style={{ textAlign: 'center' }}>
                    <Button
                        content="ตกลง"
                        style={{ margin: 0 }}
                        onClick={() => {
                            setOpenInfo({ status: false, massage: '' })
                            set_loading_button(false)
                        }}
                        color="red"
                    />
                </Modal.Actions>
            </Modal>
            <Modal
                onClose={() => {
                    setOpenSuccess(false)
                    props.history.goBack()
                }}
                open={openSuccess}
                size="mini"
            >
                <Modal.Content style={{ textAlign: 'center' }}>
                    <Icon name="check circle" size="huge" style={{ color: 'green' }} />
                    <p style={{ marginTop: 10, fontSize: '1.3rem', fontWeight: 500 }}>เพิ่มเลขชุดสำเร็จ !</p>
                </Modal.Content>
                <Modal.Actions style={{ textAlign: 'center' }}>
                    <Button
                        content="ตกลง"
                        style={{ margin: 0 }}
                        onClick={() => {
                            setOpenSuccess(false)
                            props.history.goBack()
                        }}
                        positive
                    />
                </Modal.Actions>
            </Modal>
        </Container >
    )
}

export default CreateFavoriteBill