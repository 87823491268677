import React, { Component } from 'react'
import {
    Button, Grid, Header, Icon, Input, List, Segment, Divider,
    Loader,
    Modal,
    Table
} from "semantic-ui-react";
import HuayService from '../../../service/HuayService';
import WalletService from '../../../service/WalletService';

class OrderPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loader: false,
            myCredit: 0,
            summaryOrder: 0,
            typeLotteryDetails: [],
            groupTypeLottery: [],
            msgError: "",
            maxPriceAssist: 9999999,
            minPriceAssist: 0,
            priceAssist: 0,
            outOfCredit: [],
            specialMultiply: [],
            orderName: '',
            priceAllByType: {},
            books: this.props.books
        };
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ myCredit: Number(WalletService.getWalletBalance().credit) })
        this.handleSummaryPrice()
        if (this.props.orderType == "HUAY") {
            this.setState({ loader: true })
            HuayService.getNumberOutOfLimit(this.props.roundId).then(res => {
                if (res.status) {
                    this.setState({ specialMultiply: res.data.specialMultiply, loader: false })
                }
            })
        }
    }

    handleCloseOrderPayment = () => {
        this.props.handleCloseOrderPayment();
    }

    componentWillReceiveProps() { this.handleSummaryPrice() }

    handleSendOrderPayment = () => {
        let _this = this;
        let summaryOrder = _this.state.summaryOrder;
        let orderName = _this.state.orderName;
        let myCredit = _this.state.myCredit;
        let isPriceNull = false;
        _this.setState({ outOfCredit: [] })
        this.props.books.forEach((item) => {
            if (item.price == "" || item.price == null || item.price < 1) {
                isPriceNull = true
            }
        });
        if (summaryOrder > myCredit) {
            this.setState({ msgError: "เครดิตไม่เพียงพอ" })
        } else if (isPriceNull) {
            this.setState({ msgError: "กรอกราคายังไม่ครบ!" })
        } else {
            _this.setState({ loader: true });
            let { handleCloseOrderPayment, showConfirm, clearBooks, books, roundId, handleCommitOrder } = this.props;

            // commit order
            let orderItem = []
            books.map((item, key) => {
                let numberShot = ""
                item.number.map((i, k) => {
                    numberShot = numberShot + i
                })
                let tempOrderItem = {
                    number: numberShot,
                    price: Number(item.price),
                    lotteryType: item.typeLottery.toString(),
                }
                orderItem.push(tempOrderItem)
            })

            handleCommitOrder(roundId, orderItem, orderName).then((res) => {
                if (res.status) {
                    _this.setState({ msgError: "" })
                    clearBooks();
                    handleCloseOrderPayment();
                    showConfirm();
                } else {
                    if (res.type == 2) {
                        _this.state.outOfCredit = res.data
                        _this.setState({ msgError: res.msg })
                    } else {
                        _this.setState({ outOfCredit: [] })
                        _this.setState({ msgError: res.msg })
                    }
                    // handleCloseOrderPayment();
                }
                _this.setState({ loader: false });
            })
        }
    };

    handleSummaryPrice = () => {
        const summaryOrder = this.props.books.reduce((sum, book) => {
            return sum + (book.price == "" ? 0 : Number(book.price));
        }, 0);
        this.setState({ summaryOrder: summaryOrder });
    };

    findIndexInOutOfCredit(number, typeLottery) {
        return this.state.outOfCredit.findIndex(item => item.number == number && item.type == typeLottery)
    }

    handleChangePriceAllByType = (value, detail) => {
        let price = parseInt(value);
        if (isNaN(price)) {
            price = 0;
        }

        if (price > detail.maxPrice) {
            price = detail.maxPrice;
        }
        let { priceAllByType } = this.state;
        priceAllByType[`${detail.type}`] = price
        value = price;
        let { books, handleChangePrice } = this.props;
        books.forEach((item, index) => {
            if (item.typeLottery === detail.type) {
                handleChangePrice(value, index)
            }
        })
        this.handleSummaryPrice()
    }

    getOrderPaymentList = () => {
        const _this = this;
        let { handleSummaryPrice, handleChangePriceAllByType } = this;
        let { priceAllByType } = this.state;
        // let {typeLotteryDetails} = this.state;
        let { books, handleChangePrice, handleRemoveOrder, typeLotteryDetails } = this.props;
        if (books.length == 0) {
            return (<Grid.Row>
                <Grid.Column textAlign={"center"}>
                    ยังไม่มีรายการแทงหวย !
                </Grid.Column>
            </Grid.Row>);
        }
        let booksSort = books.sort((a, b) => (a.typeLottery > b.typeLottery) ? 1 : ((b.typeLottery > a.typeLottery) ? -1 : 0));
        let firstTypeLotteryDetails = true;
        let lastTypeLotteryDetails = true;
        let groupTypeLottery = [];
        var results = booksSort.reduce(function (results, org) {
            (results[org.typeLottery] = results[org.typeLottery] || []).push(org);
            return results;
        }, {})
        let numType = 0
        return booksSort.map(function (book, index) {

            if (groupTypeLottery.indexOf(book.typeLottery) == -1) {
                numType = 0
                numType = numType + 1
                firstTypeLotteryDetails = true;
                groupTypeLottery.push(book.typeLottery);
            } else {
                firstTypeLotteryDetails = false;
                numType = numType + 1
            }

            if (results[book.typeLottery].length == numType || results[book.typeLottery].length == 1) {
                lastTypeLotteryDetails = true
                numType = 1
            } else {
                lastTypeLotteryDetails = false
            }

            let detail = typeLotteryDetails.find((typeLotteryDetail) => {
                return typeLotteryDetail.type == book.typeLottery;
            });

            if (_this.state.maxPriceAssist > detail.maxPrice) {
                _this.setState({ maxPriceAssist: detail.maxPrice })
            }

            if (_this.state.minPriceAssist < detail.minPrice) {
                _this.setState({ minPriceAssist: detail.minPrice })
            }

            return (
                <Grid.Row style={{ padding: '0' }} key={index}>
                    <Grid.Column width={16} className="order-payment-list" style={firstTypeLotteryDetails ? { marginTop: 14 } : {}}>
                        {firstTypeLotteryDetails ? (
                            <div style={{
                                marginTop: 10,
                                borderWidth: '3px 3px 0',
                                borderStyle: 'solid',
                                borderColor: 'var(--color-main-lottery-bill)',
                                borderRadius: '10px 10px 0 0',
                            }}>
                                <div style={{ background: 'var(--color-main-lottery-bill)', textAlign: 'center', borderRadius: 10, marginLeft: '1.5rem', marginRight: '1.5rem', marginTop: -14 }}>
                                    <Header style={{ paddingTop: 5, color: '#FFFFFF', fontWeight: 400, paddingBottom: 5, fontSize: 12, marginBottom: 10 }}
                                        as="h4">{`${detail.name} ขั้นต่ำ ${detail.minPrice} สูงสุด ${detail.maxPrice}`} </Header>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
                                    <Button
                                        className="price-all-by-type-button"
                                        onClick={() => {
                                            handleChangePriceAllByType(5, detail);
                                        }}>
                                        5
                                    </Button>
                                    <Button
                                        className="price-all-by-type-button"
                                        onClick={() => {
                                            handleChangePriceAllByType(10, detail);
                                        }}>
                                        10
                                    </Button>
                                    <Button
                                        className="price-all-by-type-button"
                                        onClick={() => {
                                            handleChangePriceAllByType(20, detail);
                                        }}>
                                        20
                                    </Button>
                                    <Button
                                        className="price-all-by-type-button"
                                        onClick={() => {
                                            handleChangePriceAllByType(50, detail);
                                        }}>
                                        50
                                    </Button>
                                    <Button
                                        className="price-all-by-type-button"
                                        style={{ marginRight: 0 }}
                                        onClick={() => {
                                            handleChangePriceAllByType(100, detail);
                                        }}>
                                        100
                                    </Button>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ width: '30%', color: '#484848', textAlign: 'center', fontSize: 11 }}>{detail.name}</p>
                                    <p style={{ width: '30%', color: '#484848', textAlign: 'center', fontSize: 11 }}>ใส่ราคา</p>
                                    <p style={{ width: '30%', color: '#484848', textAlign: 'center', fontSize: 11 }}>เงินที่จะได้</p>
                                </div>

                                {/* <div style={{ display: 'flex' }}>
                                    <p style={{ width: '30%', color: '#484848', textAlign: 'center', fontSize: 11 }}></p>
                                    <p style={{ width: '30%', color: '#484848', textAlign: 'center', fontSize: 14, padding: '0 0.5em 0 0' }}>
                                        <Input size='mini'
                                            style={{ width: '100%' }}
                                            className="price-all-by-type"
                                            onChange={(event) => {
                                                handleChangePriceAllByType(event.target.value, detail);
                                            }}
                                            placeholder='ใส่ราคาทั้งหมด' value={priceAllByType[`${detail.type}`]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        />
                                    </p>
                                    <p style={{ width: '30%', color: '#484848', textAlign: 'center', fontSize: 11 }}></p>
                                </div> */}
                            </div>
                        ) : ""}

                        <Table style={
                            lastTypeLotteryDetails ?
                                {
                                    borderWidth: '0px 3px 3px',
                                    borderStyle: 'solid',
                                    borderColor: 'var(--color-main-lottery-bill)',
                                    borderRadius: '0 0 10px 10px',
                                    borderSpacing: '0 6px',
                                    margin: '-3px 0 0 0',
                                }
                                :
                                {
                                    borderWidth: '0px 3px',
                                    borderStyle: 'solid',
                                    borderColor: 'var(--color-main-lottery-bill)',
                                    borderRadius: 0,
                                    margin: 0,
                                }
                        }
                            unstackable>
                            <Table.Body >
                                <Table.Row style={{ background: (index % 2 === 0) ? '#F9F9F9' : '#FFFFFF' }}>
                                    <Table.Cell style={{ width: '30%', textAlign: 'center', color: '#000000' }}>{(book.number).join("")}</Table.Cell>
                                    <Table.Cell style={{ width: '30%', textAlign: 'center', color: '#000000' }}>
                                        <Input size='mini' className="price-now" onChange={(event) => {
                                            handleChangePrice(event.target.value, index);
                                            handleSummaryPrice();
                                        }}
                                            placeholder='ใส่ราคา' value={book.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            error={(book.price < detail.minPrice) ? true : false} />
                                        <span style={{ color: '#777', fontSize: 12 }}>
                                            X{_this.state.specialMultiply.find(v => v._id.lotteryType == book.typeLottery && v._id.number == book.number.join('')) ?
                                                _this.state.specialMultiply.find(v => v._id.lotteryType == book.typeLottery && v._id.number == book.number.join('')).multiply : detail.price}
                                        </span>
                                        {(_this.state.outOfCredit.length > 0 && _this.findIndexInOutOfCredit(Number((book.number).join("")), book.typeLottery) != -1) && (
                                            <p style={{ color: 'red', fontSize: 12, fontWeight: 300 }}>
                                                {(Number(_this.state.outOfCredit[_this.findIndexInOutOfCredit(Number((book.number).join("")), book.typeLottery)].playCredit) > 0) ? (
                                                    <>เหลือแทงอีก {_this.state.outOfCredit[_this.findIndexInOutOfCredit(Number((book.number).join("")), book.typeLottery)].playCredit}</>) : ('เลขเต็ม')
                                                }
                                            </p>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell style={{ width: '30%', textAlign: 'center', color: '#000000' }}>
                                        {_this.state.specialMultiply.find(v => v._id.lotteryType == book.typeLottery && v._id.number == book.number.join('')) ?
                                            (Number(book.price) * Number(_this.state.specialMultiply.find(v => v._id.lotteryType == book.typeLottery && v._id.number == book.number.join('')).multiply)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                            (Number(book.price) * Number(detail.price)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        }
                                    </Table.Cell>
                                    <Table.Cell style={{ width: '10%', textAlign: 'center', color: '#000000' }}>
                                        <Icon
                                            onClick={() => {
                                                handleRemoveOrder(index); handleSummaryPrice()
                                            }}
                                            color='red' name='trash alternate outline' link>
                                        </Icon>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>


                    </Grid.Column>
                </Grid.Row >
            );
        });
    };

    assistChangePriceAll = (event) => {
        let priceAssist = parseInt(event.target.value);
        if (isNaN(priceAssist)) {
            priceAssist = 0;
        }
        let { maxPriceAssist, minPriceAssist } = this.state;

        if (priceAssist > maxPriceAssist) {
            priceAssist = maxPriceAssist;
        }

        this.setState({ priceAssist: priceAssist });
        event.target.value = priceAssist;
        let { books, handleChangePrice } = this.props;
        books.forEach((item, index) => {
            handleChangePrice(event.target.value, index)
        })
        this.handleSummaryPrice()
    }

    validatePrice = () => {
        let { books, typeLotteryDetails } = this.props;
        let valiateResult = true
        books.forEach(function (book, index) {
            let detail = typeLotteryDetails.find((typeLotteryDetail) => {
                return typeLotteryDetail.type == book.typeLottery;
            });
            valiateResult = (Number(book.price) >= Number(detail.minPrice) && Number(book.price) <= Number(detail.maxPrice)) ? valiateResult : false
        })
        return valiateResult
    }

    submitOrder = () => {
        this.handleSummaryPrice()
        if (!this.validatePrice()) {
            this.setState({ msgError: "กรอกราคาไม่ถูกต้อง" })
        } else {
            this.setState({ msgError: "" })
            this.handleSendOrderPayment()
        }
    }

    render() {
        return (
            <Modal centered={false} open={this.props.openOrderPayment} onClose={this.handleCloseOrderPayment} style={{ paddingBottom: 80, background: "transparent" }}>

                {
                    this.state.loader &&
                    <Loader active={this.state.loader} className='workaround' size='large' />
                }

                <Modal.Content style={{ borderRadius: 20, padding: '1.5rem 1.5rem 0 1.5rem' }}>
                    <center style={{ display: this.props.books.length < 1 ? 'none' : 'block', marginBottom: 5 }}>
                        <span style={{ fontWeight: 500, color: 'var(--color-main-lottery-bill)', fontSize: '1.1em' }}> {this.props.huayName}</span>
                    </center>

                    <div style={{ display: this.props.books.length < 1 ? 'none' : 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                            <span>
                                ชื่อโพย :
                            </span>

                            <Input
                                className="bill-name"
                                placeholder='ชื่อโพย(ไม่จำเป็นต้องกรอก)'
                                onChange={(event) => {
                                    this.setState({ orderName: event.target.value })
                                }}
                                style={{ margin: 5, textAlign: 'center', flex: 1 }}
                            />
                        </div>
                    </div>

                    <Grid>
                        <Grid.Row
                            style={{ display: this.props.books.length < 1 ? 'none' : '', padding: 5, paddingBottom: 0 }}>
                            <Grid.Column style={{ textAlign: 'center', margin: '10px 0 0 0' }}>

                                {/* <span style={{ fontWeight: 400, color: '#000000', marginBlockEnd: 0 }}>ใส่ราคาทั้งหมด</span> */}

                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                                    <Button
                                        className="price-all-button"
                                        onClick={() => {
                                            this.assistChangePriceAll({
                                                target: {
                                                    value: String(5)
                                                },
                                                getTarget() {
                                                    return { value: String(5) }
                                                },
                                            })
                                        }}>
                                        5
                                    </Button>
                                    <Button
                                        className="price-all-button"
                                        onClick={() => {
                                            this.assistChangePriceAll({
                                                target: {
                                                    value: String(10)
                                                },
                                                getTarget() {
                                                    return { value: String(10) }
                                                },
                                            })
                                        }}>
                                        10
                                    </Button>
                                    <Button
                                        className="price-all-button"
                                        onClick={() => {
                                            this.assistChangePriceAll({
                                                target: {
                                                    value: String(20)
                                                },
                                                getTarget() {
                                                    return { value: String(20) }
                                                },
                                            })
                                        }}>
                                        20
                                    </Button>
                                    <Button
                                        className="price-all-button"
                                        onClick={() => {
                                            this.assistChangePriceAll({
                                                target: {
                                                    value: String(50)
                                                },
                                                getTarget() {
                                                    return { value: String(50) }
                                                },
                                            })
                                        }}>
                                        50
                                    </Button>
                                    <Button
                                        className="price-all-button"
                                        style={{ marginRight: 0 }}
                                        onClick={() => {
                                            this.assistChangePriceAll({
                                                target: {
                                                    value: String(100)
                                                },
                                                getTarget() {
                                                    return { value: String(100) }
                                                },
                                            })
                                        }}>
                                        100
                                    </Button>
                                </div>
                                <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon name='minus square' link size="big" style={{ color: 'var(--color-main-lottery-bill)', padding: 0 }} onClick={
                                        () => {
                                            if (this.state.minPriceAssist < this.state.priceAssist) {
                                                this.assistChangePriceAll({
                                                    target: {
                                                        value: String(this.state.priceAssist - 1)
                                                    },
                                                    getTarget() {
                                                        return { value: String(this.state.priceAssist - 1) }
                                                    },
                                                })
                                                this.setState({ priceAssist: this.state.priceAssist - 1 })
                                            }

                                        }

                                    } />
                                    <Input
                                        ref={this.inputRef}
                                        className="price-now-all"
                                        placeholder='ใส่ราคาทั้งหมด'
                                        type={'number'}
                                        max={this.state.maxPriceAssist}
                                        min={this.state.minPriceAssist}
                                        onChange={(event) => {
                                            this.assistChangePriceAll(event)
                                        }}
                                        style={{ margin: 5, textAlign: 'center', flex: 1 }}
                                        value={this.state.priceAssist > 0 ? this.state.priceAssist : ''}
                                    />
                                    <Icon name='plus square' link size="big" style={{ color: 'var(--color-main-lottery-bill)', padding: 0 }} onClick={
                                        () => {
                                            if (this.state.maxPriceAssist > this.state.priceAssist) {
                                                this.assistChangePriceAll({
                                                    target: {
                                                        value: String(this.state.priceAssist + 1),
                                                    },
                                                    getTarget() {
                                                        return { value: String(this.state.priceAssist + 1) }
                                                    },

                                                })
                                                this.setState({ priceAssist: this.state.priceAssist + 1 })
                                            }
                                        }} />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {/* <div style={{ width: 22, height: 44, background: 'rgba(0, 0, 0, 0.85)', position: 'absolute', left: 0, top: 65, borderRadius: '0px 44px 44px 0px' }}></div>
                    <div style={{ width: 22, height: 44, background: 'rgba(0, 0, 0, 0.85)', position: 'absolute', right: 0, top: 65, borderRadius: '44px 0px 0px 44px' }}></div>
                    <div style={{
                        marginTop: 30,
                        height: 2,
                        backgroundImage: 'linear-gradient(to right, transparent 80%, rgb(204, 204, 204) 20%)',
                        backgroundSize: 20,
                        marginInlineEnd: 30,
                        marginInlineStart: 30,
                    }} /> */}

                    {
                        this.props.orderType === 'YIIGII' &&
                        <div style={{ display: this.props.books.length < 1 ? 'none' : 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', color: 'red', marginTop: 15, marginBottom: '-1em' }}>
                            *** หวยยี่กี่ ยืนยันแล้วไม่สามารถยกเลิกโพยได้ ***
                        </div>
                    }

                </Modal.Content>

                <Modal.Content style={{ paddingLeft: '1rem', paddingRight: '1rem', marginTop: '-1em' }}>
                    <Modal.Description>
                        <Grid columns={1}>
                            {!this.state.loader && this.getOrderPaymentList()}
                        </Grid>
                    </Modal.Description>
                    <Modal.Description>

                        <div className="order-br">
                            <br />
                            <br />
                        </div>

                        {(this.state.msgError != "") ? (
                            <center><p style={{ color: "red" }}>{this.state.msgError}</p></center>) : ""}
                        <center>

                            {
                                this.props.books.length > 0 &&
                                <Button
                                    style={{ margin: '10px 0 0', background: 'var(--color-main-lottery-bill)', color: '#FFF', width: 150 }}
                                    onClick={async () => {
                                        await this.props.deleteDuplicateBooks()
                                        this.handleSummaryPrice()
                                    }}>
                                    ลบเลขซ้ำทั้งหมด
                                </Button>
                            }

                        </center>

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions as={Segment} style={{ margin: 0 }}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column mobile={5} tablet={5} computer={5}>
                                <List selection>
                                    <List.Item>
                                        <List.Content>
                                            <div style={{ textAlign: 'center' }}>
                                                <svg width="40px" height="40px" viewBox="0 0 82 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M25.2722 70.8719H74.1205C78.4722 70.8719 82 67.3442 82 62.9925V30.4625C82 26.1108 78.4722 22.583 74.1205 22.583H25.2722C20.9204 22.583 17.3926 26.1108 17.3926 30.4625V62.9925C17.3926 67.3442 20.9204 70.8719 25.2722 70.8719Z" fill="url(#paint0_linear)" />
                                                    <path d="M24.8258 17.4702C24.8258 14.8768 23.2446 12.6525 20.9935 11.7092C19.5487 11.1036 18.5819 9.72272 18.5819 8.15622V5.97081C18.5819 2.67317 21.2551 0 24.5527 0H64.4433C67.7409 0 70.4141 2.67317 70.4141 5.97081V8.15622C70.4141 9.72288 69.4472 11.1036 68.0024 11.7092C65.7513 12.6525 64.1701 14.8768 64.1701 17.4702C64.1701 20.0637 65.7513 22.288 68.0024 23.2313C69.4472 23.8368 70.4141 25.2178 70.4141 26.7843V28.9697C70.4141 32.2673 67.7409 34.9405 64.4433 34.9405H24.5527C21.2551 34.9405 18.5819 32.2673 18.5819 28.9697V26.7843C18.5819 25.2176 19.5487 23.8368 20.9935 23.2313C23.2446 22.288 24.8258 20.0639 24.8258 17.4702Z" fill="url(#paint1_linear)" />
                                                    <path d="M39.1727 19.3488H49.8207C50.8581 19.3488 51.6992 18.5077 51.6992 17.4703C51.6992 16.4329 50.8581 15.5918 49.8207 15.5918H39.1727C38.1352 15.5918 37.2942 16.4329 37.2942 17.4703C37.2942 18.5077 38.1352 19.3488 39.1727 19.3488Z" fill="#E06E1C" />
                                                    <path d="M14.2951 21.0307C16.129 19.1968 16.5836 16.506 15.659 14.2471C15.0656 12.7972 15.3583 11.1373 16.4661 10.0294L18.0114 8.48418C20.3432 6.15241 24.1237 6.15241 26.4554 8.48418L54.6623 36.691C56.9941 39.0228 56.9941 42.8033 54.6623 45.1351L53.117 46.6805C52.0092 47.7883 50.3493 48.081 48.8994 47.4876C46.6407 46.5631 43.9497 47.0177 42.1158 48.8515C40.2819 50.6854 39.8273 53.3763 40.7519 55.6352C41.3453 57.085 41.0526 58.7451 39.9448 59.8528L38.3995 61.398C36.0677 63.7298 32.2872 63.7298 29.9555 61.398L1.7486 33.1912C-0.583176 30.8594 -0.583176 27.0789 1.7486 24.7471L3.29402 23.2019C4.40184 22.0941 6.06177 21.8013 7.51163 22.3948C9.77039 23.3192 12.4612 22.8646 14.2951 21.0307Z" fill="url(#paint2_linear)" />
                                                    <path d="M34.1338 12.6962C35.0907 11.7393 36.642 11.7393 37.599 12.6962L58.1284 33.2256C58.6616 33.7587 59.1273 34.3341 59.5264 34.9407H52.9133L34.134 16.1614C33.1769 15.2045 33.1769 13.6531 34.1338 12.6962Z" fill="#E06E1C" />
                                                    <path d="M61.0892 60.0006H69.4223C70.4597 60.0006 71.3008 59.1596 71.3008 58.1221C71.3008 57.0847 70.4597 56.2437 69.4223 56.2437H61.0893C60.0519 56.2437 59.2108 57.0847 59.2108 58.1221C59.2107 59.1596 60.0517 60.0006 61.0892 60.0006Z" fill="#C2C8CF" />
                                                    <path d="M31.9701 40.5838C32.4508 40.5838 32.9316 40.4006 33.2984 40.0337C34.032 39.3001 34.032 38.1108 33.2984 37.377L25.7691 29.8476C25.0355 29.114 23.8462 29.114 23.1125 29.8476C22.3789 30.5811 22.3789 31.7705 23.1125 32.5042L30.6418 40.0337C31.0086 40.4004 31.4894 40.5838 31.9701 40.5838Z" fill="#EFC534" />
                                                    <path d="M51.0362 49.7294C50.9242 48.7471 51.4113 47.79 52.277 47.3122C52.58 47.145 52.8631 46.9341 53.1173 46.6801L54.6625 45.1346C56.9943 42.8029 56.9943 39.0224 54.6625 36.6906L52.9126 34.9407H64.4426C67.7402 34.9407 70.4134 32.2675 70.4134 28.9699V26.7844C70.4134 25.2155 69.4421 23.837 67.9957 23.2289C67.5823 23.0551 67.1919 22.8375 66.8294 22.583H74.1204C74.1712 22.6131 74.2282 22.647 74.279 22.6771C74.945 23.9162 75.3135 25.3192 75.3135 26.7844V28.9699C75.3135 34.9738 70.4462 39.8411 64.4423 39.8411H61.2539C61.5613 42.9705 60.5192 46.208 58.1274 48.5997L56.582 50.1451C55.9901 50.7373 55.3318 51.227 54.6294 51.6135C53.1122 52.448 51.2326 51.4501 51.0362 49.7294Z" fill="#424E5E" />
                                                    <path d="M16.1097 42.1792C16.5279 42.1792 16.9457 42.0197 17.2648 41.7007C17.9026 41.0628 17.9026 40.0285 17.2648 39.3907L13.3889 35.5149C12.751 34.8772 11.7167 34.8771 11.0788 35.5151C10.4409 36.153 10.4409 37.1873 11.0788 37.8252L14.9547 41.7009C15.2736 42.0197 15.6917 42.1792 16.1097 42.1792Z" fill="#FFFDE3" />
                                                    <path d="M7.49646 33.5658C7.91446 33.5658 8.33263 33.4063 8.65148 33.0873C9.28935 32.4494 9.28935 31.4151 8.65148 30.7771L8.09839 30.224C7.46052 29.5861 6.42622 29.5861 5.78835 30.224C5.15048 30.8619 5.15048 31.8962 5.78835 32.5342L6.34144 33.0873C6.66029 33.4063 7.07846 33.5658 7.49646 33.5658Z" fill="#FFFDE3" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear" x1="109.365" y1="46.7274" x2="0.917014" y2="46.7274" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#4E5660" />
                                                            <stop offset="1" stopColor="#64768D" />
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear" x1="44.498" y1="38.1653" x2="44.498" y2="-6.93766" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#EA7D32" />
                                                            <stop offset="1" stopColor="#F0B160" />
                                                        </linearGradient>
                                                        <linearGradient id="paint2_linear" x1="28.2055" y1="57.5099" x2="28.2055" y2="5.0686" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#F4D952" />
                                                            <stop offset="1" stopColor="#F9EDAF" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <List.Header className="text-center" style={{ fontSize: 13 }}>ยอดเงินที่มี</List.Header>
                                            <List.Description
                                                className="text-center">{Number(this.state.myCredit).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column mobile={6} tablet={6} computer={6}>
                                <List selection>
                                    <List.Item>
                                        <List.Content>
                                            <div style={{ textAlign: 'center' }}>
                                                <svg width="40px" height="40px" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0)">
                                                        <path d="M8.41602 41.3003H25.2126V72.094H8.41602V41.3003Z" fill="#FDBD40" />
                                                        <path d="M33.6104 24.5034H50.4069V72.0937H33.6104V24.5034Z" fill="#E9686A" />
                                                        <path d="M58.8047 10.5063H75.6013V72.0938H58.8047V10.5063Z" fill="#238892" />
                                                        <path d="M29.4112 6.30743L34.0205 10.9167L22.4126 22.5245L19.2031 19.315C18.6565 18.7686 17.7705 18.7686 17.2239 19.315L0.427323 36.1115C-0.128714 36.6485 -0.14411 37.5347 0.392855 38.0907C0.92982 38.6468 1.81601 38.6622 2.37205 38.1252C2.38377 38.114 2.39515 38.1025 2.40652 38.0907L18.2135 22.2838L21.423 25.4933C21.9696 26.0397 22.8556 26.0397 23.4022 25.4933L35.9997 12.8959L40.6089 17.5051L42.0086 4.90771L29.4112 6.30743Z" fill="#E9686A" />
                                                        <path d="M14.014 67.8948C13.241 67.8948 12.6143 67.268 12.6143 66.495V63.6956C12.6143 62.9226 13.241 62.2959 14.014 62.2959C14.787 62.2959 15.4137 62.9226 15.4137 63.6956V66.495C15.4137 67.268 14.7871 67.8948 14.014 67.8948Z" fill="white" />
                                                        <path d="M14.014 59.4964C13.241 59.4964 12.6143 58.8697 12.6143 58.0967V52.4979C12.6143 51.7249 13.241 51.0981 14.014 51.0981C14.787 51.0981 15.4137 51.7249 15.4137 52.4979V58.0967C15.4137 58.8697 14.7871 59.4964 14.014 59.4964Z" fill="white" />
                                                        <path d="M39.2083 67.8948C38.4353 67.8948 37.8086 67.268 37.8086 66.495V63.6956C37.8086 62.9226 38.4353 62.2959 39.2083 62.2959C39.9813 62.2959 40.608 62.9226 40.608 63.6956V66.495C40.608 67.268 39.9815 67.8948 39.2083 67.8948Z" fill="white" />
                                                        <path d="M39.2083 59.4963C38.4353 59.4963 37.8086 58.8696 37.8086 58.0966V44.0994C37.8086 43.3264 38.4353 42.6997 39.2083 42.6997C39.9813 42.6997 40.608 43.3264 40.608 44.0994V58.0966C40.608 58.8696 39.9815 59.4963 39.2083 59.4963Z" fill="white" />
                                                        <path d="M64.4036 67.8948C63.6306 67.8948 63.0039 67.268 63.0039 66.495V63.6956C63.0039 62.9226 63.6306 62.2959 64.4036 62.2959C65.1766 62.2959 65.8033 62.9226 65.8033 63.6956V66.495C65.8033 67.268 65.1768 67.8948 64.4036 67.8948Z" fill="white" />
                                                        <path d="M64.4036 59.4964C63.6306 59.4964 63.0039 58.8696 63.0039 58.0966V30.1024C63.0039 29.3294 63.6306 28.7026 64.4036 28.7026C65.1766 28.7026 65.8033 29.3294 65.8033 30.1024V58.0966C65.8033 58.8696 65.1768 59.4964 64.4036 59.4964Z" fill="white" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0">
                                                            <rect width="84" height="84" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </div>
                                            <List.Header className="text-center" style={{ fontSize: 13 }}>ยอดแทงรวม</List.Header>
                                            <List.Description
                                                className="text-center">{(this.state.summaryOrder).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column mobile={5} tablet={5} computer={5}>
                                <List selection>
                                    <List.Item>
                                        <List.Content>
                                            <div style={{ textAlign: 'center' }}>
                                                <svg width="40px" height="40px" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M41.1229 15.365V20.6451C41.1229 22.0852 39.9556 23.2524 38.5156 23.2524H7.29089C5.85087 23.2524 4.68359 22.0852 4.68359 20.6451V15.365C4.68359 13.9261 5.85087 12.7588 7.29089 12.7588H38.5155C39.9556 12.7588 41.1229 13.9261 41.1229 15.365Z" fill="#DD9F3A" />
                                                    <path d="M36.4393 25.8596V31.1398C36.4393 32.5798 35.272 33.7471 33.832 33.7471H2.6073C1.16727 33.7471 0 32.5798 0 31.1398V25.8596C0 24.4207 1.16727 23.2534 2.6073 23.2534H33.8319C35.272 23.2534 36.4393 24.4207 36.4393 25.8596Z" fill="#DD9F3A" />
                                                    <path d="M38.1952 36.3537V41.6339C38.1952 43.0739 37.0279 44.2412 35.5879 44.2412H4.36316C2.92313 44.2412 1.75586 43.0739 1.75586 41.6339V36.3537C1.75586 34.9148 2.92313 33.7476 4.36316 33.7476H35.5877C37.0279 33.7476 38.1952 34.9148 38.1952 36.3537Z" fill="#DD9F3A" />
                                                    <path d="M36.4393 46.8484V52.1285C36.4393 53.5686 35.272 54.7358 33.832 54.7358H2.6073C1.16727 54.7358 0 53.5686 0 52.1285V46.8484C0 45.4095 1.16727 44.2422 2.6073 44.2422H33.8319C35.272 44.2422 36.4393 45.4095 36.4393 46.8484Z" fill="#DD9F3A" />
                                                    <path d="M36.4391 46.8484V50.4895C36.4391 51.8359 35.4182 52.9446 34.1081 53.0827C34.0168 53.0921 33.9254 53.0968 33.8318 53.0968H9.02416C7.58524 53.0968 6.41797 51.9295 6.41797 50.4895V46.8484C6.41797 45.4095 7.58524 44.2422 9.02416 44.2422H33.8318C35.2718 44.2422 36.4391 45.4095 36.4391 46.8484Z" fill="#F4B74A" />
                                                    <path d="M36.4391 46.8484V50.4895C36.4391 51.8359 35.4182 52.9446 34.1081 53.0827C34.0168 53.0921 33.9254 53.0968 33.8318 53.0968H9.02416C7.58524 53.0968 6.41797 51.9295 6.41797 50.4895V46.8484C6.41797 45.4095 7.58524 44.2422 9.02416 44.2422H33.8318C35.2718 44.2422 36.4391 45.4095 36.4391 46.8484Z" fill="#F4B74A" />
                                                    <path d="M36.4391 36.3533V39.9944C36.4391 41.3408 35.4182 42.4495 34.1081 42.5876C34.0168 42.597 33.9254 42.6017 33.8318 42.6017H9.02415C7.58524 42.6017 6.41797 41.4344 6.41797 39.9944V36.3533C6.41797 34.9143 7.58524 33.7471 9.02415 33.7471H33.8318C35.2719 33.747 36.4391 34.9142 36.4391 36.3533Z" fill="#F4B74A" />
                                                    <path d="M36.4391 25.8596V29.5007C36.4391 30.8471 35.4182 31.9558 34.1081 32.094C34.0168 32.1033 33.9254 32.108 33.8318 32.108H9.02415C7.58524 32.108 6.41797 30.9407 6.41797 29.5007V25.8596C6.41797 24.4207 7.58524 23.2534 9.02415 23.2534H33.8318C35.2719 23.2534 36.4391 24.4207 36.4391 25.8596Z" fill="#F4B74A" />
                                                    <path d="M41.1217 15.365V19.0061C41.1217 20.3525 40.1008 21.4612 38.7907 21.5993C38.6994 21.6087 38.608 21.6134 38.5144 21.6134H13.7068C12.2679 21.6134 11.1006 20.4461 11.1006 19.0061V15.365C11.1006 13.9261 12.2679 12.7588 13.7068 12.7588H38.5144C39.9546 12.7588 41.1217 13.9261 41.1217 15.365Z" fill="#F4B74A" />
                                                    <path d="M36.4393 46.8484V52.1286C36.4393 53.475 35.4184 54.5837 34.1083 54.7218C33.8706 54.7312 33.6329 54.7359 33.3952 54.7359C30.8758 54.7359 28.3562 54.2172 26.01 53.1776C24.4352 52.481 22.9413 51.5491 21.5762 50.3842C21.211 50.0728 20.8574 49.7449 20.512 49.4007C18.9701 47.8588 17.763 46.1096 16.8896 44.2422H33.832C35.272 44.2422 36.4393 45.4095 36.4393 46.8484Z" fill="#DD9F3A" />
                                                    <path d="M36.4392 36.3537V41.6339C36.4392 43.0739 35.2719 44.2412 33.8319 44.2412H18.6434C17.1015 40.9455 16.6004 37.2821 17.1412 33.7476H33.8319C35.2719 33.7474 36.4392 34.9148 36.4392 36.3537Z" fill="#DD9F3A" />
                                                    <path d="M36.4393 4.87035V10.1505C36.4393 11.5905 35.272 12.7578 33.832 12.7578H2.6073C1.16727 12.7579 0 11.5907 0 10.1506V4.87035C0 3.43143 1.16727 2.26416 2.6073 2.26416H33.8319C35.272 2.26427 36.4393 3.43155 36.4393 4.87035Z" fill="#DD9F3A" />
                                                    <path d="M36.4391 4.86986V8.51095C36.4391 9.85735 35.4182 10.9661 34.1081 11.1042C34.0168 11.1136 33.9254 11.1182 33.8318 11.1182H9.02416C7.58524 11.1182 6.41797 9.95098 6.41797 8.51095V4.86986C6.41797 3.43095 7.58524 2.26367 9.02416 2.26367H33.8318C35.2718 2.26378 36.4391 3.43106 36.4391 4.86986Z" fill="#F4B74A" />
                                                    <path d="M32.1529 2.26416H28.3291V11.1187H32.1529V2.26416Z" fill="#F9C662" />
                                                    <path d="M25.5193 2.26416H23.6074V11.1187H25.5193V2.26416Z" fill="#F9C662" />
                                                    <path d="M36.7588 12.7593H32.9346V23.2542H36.7588V12.7593Z" fill="#F9C662" />
                                                    <path d="M30.1241 12.7593H28.2119V23.2542H30.1241V12.7593Z" fill="#F9C662" />
                                                    <path d="M41.1231 18.9396V20.6454C41.1231 22.0855 39.9558 23.2527 38.5158 23.2527H23.833C28.597 18.7614 35.1885 17.3239 41.1231 18.9396Z" fill="#DD9F3A" />
                                                    <path d="M36.4399 25.8601V31.1403C36.4399 32.5803 35.2726 33.7476 33.8326 33.7476H15.3857C15.9512 30.0456 17.6606 26.4852 20.5114 23.6344C20.6413 23.5045 20.7725 23.378 20.9048 23.2539H33.8326C35.2726 23.2539 36.4399 24.4212 36.4399 25.8601Z" fill="#DD9F3A" />
                                                    <path d="M51.6643 49.4002C46.1839 54.8796 38.0822 56.1393 31.3964 53.1774C29.8218 52.4806 28.3271 51.5496 26.9627 50.3844C26.5973 50.0732 26.2428 49.7445 25.8976 49.4002C18.7828 42.2845 18.7828 30.7491 25.8976 23.6334C33.0132 16.5187 44.5487 16.5187 51.6643 23.6334C52.0096 23.9786 52.3373 24.3331 52.6486 24.6985C53.8137 26.0619 54.7448 27.5575 55.4416 29.1322C58.4035 35.8171 57.1438 43.9198 51.6643 49.4002Z" fill="#F4B74A" />
                                                    <path d="M55.4407 29.132L31.3957 53.1772C29.821 52.4804 28.3263 51.5493 26.9619 50.3842L52.6478 24.6982C53.8129 26.0617 54.7439 27.5574 55.4407 29.132Z" fill="#F9C662" />
                                                    <path d="M51.6639 36.517C51.6639 43.6322 45.8957 49.4002 38.7807 49.4002C34.7643 49.4002 31.1777 47.5629 28.8146 44.6821C26.991 42.4596 25.8975 39.6159 25.8975 36.517C25.8975 29.4018 31.6656 23.6338 38.7807 23.6338C41.8803 23.6338 44.724 24.7289 46.9465 26.5526C49.8265 28.9147 51.6639 32.5015 51.6639 36.517Z" fill="#FFD488" />
                                                    <path d="M46.9466 26.5521L28.8163 44.6824L28.8146 44.6816C28.2823 44.0331 27.8123 43.3312 27.4142 42.5858C26.9603 41.7386 26.5994 40.8357 26.3433 39.8883C26.0527 38.8134 25.8975 37.6827 25.8975 36.5165C25.8975 29.4013 31.6656 23.6333 38.7807 23.6333C39.9477 23.6333 41.0784 23.7883 42.1525 24.0792C43.0999 24.3352 44.0027 24.697 44.8499 25.1509C45.5955 25.5491 46.2973 26.0199 46.9466 26.5521Z" fill="#F9C662" />
                                                    <path d="M44.8504 25.1509L27.4146 42.5858C26.9607 41.7386 26.5998 40.8357 26.3438 39.8883L42.153 24.0791C43.1002 24.3352 44.0031 24.697 44.8504 25.1509Z" fill="#FFD488" />
                                                    <path d="M39.6583 35.7089V30.8732C40.9386 31.1716 41.8754 32.1041 41.8754 33.2059C41.8754 33.6908 42.2684 34.0839 42.7535 34.0839C43.2385 34.0839 43.6315 33.6908 43.6315 33.2059C43.6315 31.1549 41.9154 29.4448 39.6582 29.0869V28.5704C39.6582 28.0855 39.2652 27.6924 38.7802 27.6924C38.2951 27.6924 37.9021 28.0855 37.9021 28.5704V29.0869C35.6449 29.4449 33.9287 31.155 33.9287 33.2059C33.9287 35.2568 35.6449 36.967 37.9021 37.3249V42.1606C36.6218 41.8622 35.6849 40.9297 35.6849 39.8279C35.6849 39.343 35.2919 38.9499 34.8069 38.9499C34.3218 38.9499 33.9288 39.343 33.9288 39.8279C33.9288 41.8789 35.6451 43.589 37.9022 43.9469V44.4634C37.9022 44.9483 38.2952 45.3414 38.7803 45.3414C39.2653 45.3414 39.6583 44.9483 39.6583 44.4634V43.9469C41.9155 43.589 43.6316 41.8788 43.6316 39.8279C43.6316 37.777 41.9155 36.0669 39.6583 35.7089ZM35.685 33.2058C35.685 32.104 36.6218 31.1715 37.9022 30.8731V35.5384C36.622 35.2402 35.685 34.3076 35.685 33.2058ZM39.6583 42.1606V37.4953C40.9386 37.7936 41.8754 38.7261 41.8754 39.8279C41.8754 40.9297 40.9386 41.8622 39.6583 42.1606Z" fill="#E0A33F" />
                                                    <path d="M38.7809 50.2781C34.6429 50.2781 30.763 48.4414 28.1358 45.2387C26.1264 42.7896 25.0195 39.692 25.0195 36.5167C25.0195 28.9287 31.1929 22.7554 38.7809 22.7554C41.9553 22.7554 45.0531 23.8628 47.5038 25.8733C50.7058 28.4998 52.5421 32.3791 52.5421 36.5166C52.542 44.1047 46.3689 50.2781 38.7809 50.2781ZM38.7809 24.5116C32.1612 24.5116 26.7757 29.8971 26.7757 36.5167C26.7757 39.2868 27.7409 41.9888 29.4936 44.1248C31.7858 46.9192 35.1709 48.5219 38.7809 48.5219C45.4004 48.5219 50.7859 43.1364 50.7859 36.5167C50.7859 32.9073 49.1836 29.5228 46.3898 27.2312C44.2525 25.4773 41.5502 24.5116 38.7809 24.5116Z" fill="#E0A33F" />
                                                </svg>

                                            </div>
                                            <List.Header className="text-center" style={{ fontSize: 13 }}>ยอดเงินคงเหลือ</List.Header>
                                            <List.Description
                                                className="text-center">{(Number(this.state.myCredit) - Number(this.state.summaryOrder)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {/* <Divider vertical >|</Divider> */}
                </Modal.Actions>
                <Modal.Actions>
                    {this.props.books.length == 0 ? "" : (
                        <Button disabled={this.state.loader} positive onClick={this.submitOrder} style={{ backgroundColor: '#00B261', width: '60%', margin: 0, marginRight: 5 }}>ส่งโพย {this.state.loader && <Icon size='small' loading name='spinner' />}</Button>)}
                    <Button disabled={this.state.loader} negative onClick={this.handleCloseOrderPayment} style={{ backgroundColor: '#D30000', width: '35%', margin: 0 }}>ย้อนกลับ</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default OrderPayment;