import Config from "../config/config";
import Authen from './Authen'
import apiClient from './axios/apiClient'

export default class BookService {
    constructor() {
    }

    static async getBookAll(limit='1') {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'book/mybook/'+limit,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            // console
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async getBookDesc() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'book/desc',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            // console
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async favoriteOrder(){
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'book/favoriteOrder',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            // console
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async addFavoriteOrder(data){
        var config = {
            method: 'post',
            url: Config.APIConfig.apiDomain+'book/favoriteOrder',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
            data:data
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            // console
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async updateFavoriteOrder(data){
        var config = {
            method: 'patch',
            url: Config.APIConfig.apiDomain+'book/favoriteOrder',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
            data:data
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            // console
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async deleteFavoriteOrder(data){
        var config = {
            method: 'delete',
            url: Config.APIConfig.apiDomain+'book/favoriteOrder',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
            data:data
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            // console
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async getOldOrder(limit=10,huayType="",huayId) {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'book/mybook/'+limit+'/'+huayType+'/'+huayId,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            // console
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }
}
