import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom';
import ConfigValueService from '../../service/ConfigValueService'
import { Image } from 'semantic-ui-react'
import Config from "../../config/config";

const Maintenance = (props) => {

    useEffect(() => {
        ConfigValueService.IS_MAINTENANCE_PLAY_SYSTEM().then(res=>{
            if(res.status){
                if(!Boolean(res.data.value)){ window.location.replace("/home");}
            }
        })
    });

    return (
        <div className="maintenance-page ">

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 10
            }}>
                <Image src={require('../../assets/images/head_logo.png')} size='small' centered style={{ 'padding': '20px' }} />
                <center style={{ color: '#424242', fontSize: '3rem' }}>
                    ขออภัย
                    <br />
                    กำลังปรับปรุงระบบ
                </center>
                <center style={{ color: '#424242', fontSize: '1.2rem', margin: '10px 0px' }}>
                    ทางเว็บไซต์กำลังปิดปรับปรุงเพื่อพัฒนาระบบชั่วคราว
                    <br />
                    ขออภัยในความไม่สะดวก
                    </center>
                <Image src={require('../../assets/images/plug.png')} style={{ width: '100%', margin: '20px 0px' }} />
            </div>
        </div>
    )
}
export default Maintenance;