import React, { Component } from 'react'
import {
    Button,
    Container,
    Grid,
    Header,
    Table,
    List,
    Modal,
    Responsive,
    Segment,
    Icon,
    Divider,
    Image
} from "semantic-ui-react";
import OrderPayment from "../wallet/OrderPayment";
import { Link } from "react-router-dom";
import { NumberShot } from "./NumberShot";
import { ToastContainer, Slide, toast } from 'react-toastify';
import YiiGiiService from '../../../service/YiiGiiService';
import 'react-toastify/dist/ReactToastify.css';
import '../../../assets/css/lotteryPlay.css'
import BookService from '../../../service/BookService';
const asyncFunc = (event) => {
    return new Promise(resolve => {
        event();
        resolve();
    });
};

class LotteryPlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assistRandomBox: 3,
            assistRandomActive: [
                {
                    type: 1,
                    name: "เลขคี่",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                },
                {
                    type: 2,
                    name: "เลขคู่",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                },
                {
                    type: 3,
                    name: "เลขเบิ้ล",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                },
                {
                    type: 4,
                    name: "เลขตอง",
                    active: false,
                    color: "grey",
                    typeLottery: [0],
                },
                {
                    type: 5,
                    name: "เลขสูง",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                },
                {
                    type: 6,
                    name: "เลขต่ำ",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                },
                {
                    type: 7,
                    name: "19 ประตู",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                    mode_active: 1
                },
                {
                    type: 8,
                    name: "กลับเลข",
                    active: true,
                    color: "grey",
                    typeLottery: [2, 3],
                    mode_active: 2
                },
                {
                    type: 9,
                    name: "รูดหน้า",
                    active: true,
                    color: "grey",
                    mode_active: 3
                },
                {
                    type: 10,
                    name: "รูดหลัง",
                    active: true,
                    color: "grey",
                    mode_active: 4
                },
                {
                    type: 11,
                    name: "พี่น้อง",
                    active: true,
                    color: "grey",
                },
                {
                    type: 12,
                    name: "น้องพี่",
                    active: true,
                    color: "grey",
                },
            ],
            mode_active: 0, // shooting mode 0 = normal , 1 = 19Goal ,2=revers, 3=รูดหน้า, 3=รูดหลัง
            openConfirm: false,
            openOrderPayment: false,
            openNumberShot: false,
            responsiveButton: true,
            inputNumber: 3,
            inputNumberNow: ["-", "-", "-"],
            typeLotteryNow: 1,
            typeLotteryActive: [
                { isActive: false, isAllow: false },
                { isActive: true, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false },
                { isActive: false, isAllow: false }
            ],
            books: [],
            typeLotteryDetails: [],
            onLoadingtypeLottery: true,
            favoriteBill: [],
            modalPullBill: false,
            huayDetail: {},
            oldBill: [],
            modalPullOldBill: false,
            bookDescLoading: true,
            numberOfOrder: 0,
            oldOrderLoading: false,
            ShowLimitOldOrder: 10
        };
    }

    async componentDidMount() {
        this.resizeBox();
        await asyncFunc(() => {
        });
        await this.getLotteryTpye();
        this.handleChangeInput(this.state.typeLotteryDetails[0].length, this.state.typeLotteryDetails[0].type, this.getAssistantAllow(this.state.typeLotteryDetails[0].length))
    }

    async componentWillMount() {
    }

    async getLotteryTpye() {
        this.setState({ onLoadingtypeLottery: true })
        await YiiGiiService.getYiiGiiRound(this.props.match.params.roundId).then((res) => {
            if (res.status) {
                const typeLotteryNew = []
                res.data.lotteryTypes.sort((a, b) => b.length - a.length).map((item, key) => {
                    typeLotteryNew.push({
                        type: Number(item.typeId),
                        price: Number(item.multiply),
                        name: item.name,
                        maxPrice: Number(item.maxPrice),
                        minPrice: Number(item.minPrice),
                        length: Number(item.length)
                    })
                })
                let sumShotNumber = 0;
                res.data.numberShots.forEach(item => {
                    sumShotNumber += parseInt(item.numberShot)
                })

                this.setState({
                    typeLotteryDetails: typeLotteryNew,
                    sumShotNumber: sumShotNumber,
                    onLoadingtypeLottery: false,
                    huayDetail: res.data.huayDetail
                })
            }
        })
    }

    commitOrder = (roundId, orderItem, orderName) => YiiGiiService.play(roundId, orderItem, orderName)

    resizeBox = () => {
        let responsiveButton = true;
        let assistRandomBox = 3;
        let innerWidth = window.innerWidth;
        if (innerWidth < 580) {
            responsiveButton = false;
            assistRandomBox = 3;
        }
        this.setState({ responsiveButton: responsiveButton, assistRandomBox: assistRandomBox })
    };

    handleOpenOrderPayment = () => {
        let { books } = this.state;
        let result = books.filter(item => item.number[item.number.length - 1] != '-')
        this.setState({ openOrderPayment: true, books: result });
    };

    handleCloseOrderPayment = () => {
        this.setState({ openOrderPayment: false });
    };

    handleOpenNumberShot = () => {
        this.setState({ openNumberShot: true });
    };

    handleCloseNumberShot = () => {
        this.setState({ openNumberShot: false });
    };

    handleChangeInput = (input, type, assistRandom = []) => {
        let { assistRandomActive, mode_active } = this.state;
        assistRandomActive.map(function (item, index) {
            if (assistRandom.indexOf(item.type) > -1) {
                if (item.type == 7 || item.type == 8 || item.type == 9 || item.type == 10) {
                    assistRandomActive[index]['active'] = false;
                    assistRandomActive[index]['color'] = "orange";
                } else {
                    assistRandomActive[index]['active'] = false;
                    assistRandomActive[index]['color'] = "grey";
                }
            } else {
                assistRandomActive[index]['active'] = true;
                assistRandomActive[index]['color'] = "grey";
            }
        });

        let typeLotteryActive = this.state.typeLotteryActive[type].isAllow ? this.state.typeLotteryActive : [
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false },
            { isActive: false, isAllow: false }
        ];

        typeLotteryActive[type].isActive = !typeLotteryActive[type].isActive;
        if (!(typeLotteryActive.find(elm => elm.isActive == true))) {
            typeLotteryActive[type].isActive = true
        }
        let inputNumberNow = new Array(input).fill("-");

        this.setState({
            // assistRandomActive: assistRandomActive,
            inputNumber: input,
            typeLotteryActive: typeLotteryActive,
            typeLotteryNow: type,
            inputNumberNow: inputNumberNow,
            mode_active: 0
        });
        this.updateAllowType(type, typeLotteryActive)
    };

    updateAllowType(activeType, typeLotteryActive) {
        const activeTypeObject = this.state.typeLotteryDetails.find(elm => elm.type == activeType)

        if (activeTypeObject) {
            let oldState = typeLotteryActive
            this.state.typeLotteryDetails.filter(elm => elm.length == activeTypeObject.length).map(elm => {
                oldState[elm.type].isAllow = true
            })
            this.setState({ typeLotteryActive: oldState })
        }
    }

    updateDeleteNumber = () => {

    };

    handleDeleteNumber = () => {
        let inputNumberNow = this.state.inputNumberNow;
        let deleteIndex = 0;
        inputNumberNow.map(function (item, index) {
            if (Number.isInteger(item)) {
                deleteIndex = index;
            }
        });
        inputNumberNow[deleteIndex] = '-';
        this.setState({ inputNumberNow: inputNumberNow });
    };

    handleRandom = () => {
        let inputNumberNow = this.state.inputNumberNow;
        let inputNumberNew = [];
        let books = this.state.books;
        const handleAddNumber = this.handleAddNumber
        inputNumberNow = inputNumberNow.map(function () {
            inputNumberNew.push("-");
            let randomNum = Math.floor(Math.random() * 10);
            handleAddNumber(randomNum)
            return randomNum;
        });
    };

    handleRandomAssist = (type) => {
        let { books, typeLotteryNow, inputNumber } = this.state;
        this.state.typeLotteryActive.forEach((typeLotteryActiveItem, typeId) => {
            if (typeLotteryActiveItem.isActive) {
                const activeTypeObject = this.state.typeLotteryDetails.find(elm => elm.type == typeId)
                if (type == 4) {
                    for (let i = 0; i < 10; i++) {
                        let book = {
                            number: [i, i, i],
                            price: "",
                            typeLottery: typeId
                        };
                        books.push(book)
                    }
                    //toast
                    toast.success("เพิ่มเลขตองแล้ว", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else if (type == 1) {
                    for (let i = 0; i < 10; i++) {
                        for (let j = 0; j < 5; j++) {
                            let book = {
                                number: [i, j * 2 + 1],
                                price: "",
                                typeLottery: typeId
                            };
                            books.push(book)
                        }
                    }
                    //toast
                    toast.success("เพิ่มเลขคี่แล้ว", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else if (type == 2) {
                    for (let i = 0; i < 10; i++) {
                        for (let j = 0; j < 5; j++) {
                            let book = {
                                number: [i, j * 2],
                                price: "",
                                typeLottery: typeId
                            };
                            books.push(book)
                        }
                    }
                    //toast
                    toast.success("เพิ่มเลขคู่แล้ว", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else if (type == 3) {
                    for (let i = 0; i < 10; i++) {
                        let book = {
                            number: [i, i],
                            price: "",
                            typeLottery: typeId
                        };
                        books.push(book)
                    }
                    //toast
                    toast.success("เพิ่มเลขเบิ้ลแล้ว", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else if (type == 5) {
                    for (let i = 5; i < 10; i++) {
                        for (let j = 0; j < 10; j++) {
                            let book = {
                                number: [i, j],
                                price: "",
                                typeLottery: typeId
                            };
                            books.push(book)
                        }
                    }
                    //toast
                    toast.success("เพิ่มเลขสูงแล้ว", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else if (type == 6) {
                    for (let i = 0; i < 5; i++) {
                        for (let j = 0; j < 10; j++) {
                            let book = {
                                number: [i, j],
                                price: "",
                                typeLottery: typeId
                            };
                            books.push(book)
                        }
                    }
                    //toast
                    toast.success("เพิ่มเลขต่ำแล้ว", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else if (type == 7) {
                    let newstate = (this.state.mode_active === 1) ? 0 : 1
                    this.setState({ mode_active: newstate })

                    if (newstate == 1) {
                        this.setState({ inputNumber: 1, inputNumberNow: ["-"] })
                    } else {
                        this.setState({ inputNumber: 2, inputNumberNow: ["-", "-"] })
                    }
                } else if (type == 8) {
                    let newstate = (this.state.mode_active === 2) ? 0 : 2
                    this.setState({ mode_active: newstate })
                    if (this.state.inputNumber == 3) {
                        this.setState({ inputNumber: 3, inputNumberNow: ["-", "-", "-"] })
                    } else {
                        this.setState({ inputNumber: 2, inputNumberNow: ["-", "-"] })
                    }
                } else if (type == 9) {
                    let newstate = (this.state.mode_active === 3) ? 0 : 3
                    this.setState({ mode_active: newstate })
                    if (newstate == 3) {
                        this.setState({ inputNumber: 1, inputNumberNow: ["-"] })
                    } else {
                        this.setState({ inputNumber: 2, inputNumberNow: ["-", "-"] })
                    }
                } else if (type == 10) {
                    let newstate = (this.state.mode_active === 4) ? 0 : 4
                    this.setState({ mode_active: newstate })
                    if (newstate == 4) {
                        this.setState({ inputNumber: 1, inputNumberNow: ["-"] })
                    } else {
                        this.setState({ inputNumber: 2, inputNumberNow: ["-", "-"] })
                    }
                } else if (type == 11) {
                    let x1 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                    let x2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
                    for (let i in x1) {
                        let book = {
                            number: [x1[i], x2[i]],
                            price: "",
                            typeLottery: typeId
                        };
                        books.push(book)
                    }
                    //toast
                    toast.success("เพิ่มเลขพี่น้อง", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                else if (type == 12) {
                    let x2 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                    let x1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
                    for (let i in x1) {
                        let book = {
                            number: [x1[i], x2[i]],
                            price: "",
                            typeLottery: typeId
                        };
                        books.push(book)
                    }
                    //toast
                    toast.success("เพิ่มเลขพี่น้อง", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                this.setState(oldState => oldState)
            }
        })
    };

    handleAddNumber = (numberLottery) => {
        let inputNumberNow = this.state.inputNumberNow;
        let books = this.state.books;
        let oneTime = 1;
        let lengthNow = 0;
        let setTimeoutMinute = 0;
        inputNumberNow.map(function (item, index) {
            if (isNaN(parseInt(item)) && oneTime == 1) {
                lengthNow = index + 1;
                inputNumberNow[index] = numberLottery;
                oneTime++;
            }
        });

        if (inputNumberNow.length == lengthNow) {
            setTimeoutMinute = 500;
            let inputNumberNowStr = "";
            let inputNumberNew = inputNumberNow.map(function (item, index) {
                inputNumberNow[index] = "-";
                inputNumberNowStr += item
                return item;
            });
            this.setState({ inputNumberNow: inputNumberNew });

            this.state.typeLotteryActive.forEach((typeLotteryActiveItem, typeId) => {
                if (typeLotteryActiveItem.isActive) {
                    const activeTypeObject = this.state.typeLotteryDetails.find(elm => elm.type == typeId)
                    if (this.state.mode_active == 1) { //19 ประตู
                        for (let i = 0; i < 10; i++) {
                            let inputNumberItem = inputNumberNew.map((data, i) => data)
                            inputNumberItem.push(i);
                            books.push({
                                typeLottery: typeId,
                                number: inputNumberItem,
                                price: ''
                            });
                        }
                        for (let i = 0; i < 10; i++) {
                            let inputNumberItem = inputNumberNew.map((data, i) => data)
                            inputNumberItem.push(i)
                            inputNumberItem = inputNumberItem.reverse().map((data, i) => data)
                            if (i != inputNumberNew[0]) {
                                books.push({
                                    typeLottery: typeId,
                                    number: inputNumberItem,
                                    price: ''
                                });
                            }
                        }

                        //toast
                        toast.success("แทง " + inputNumberNew.map((data, i) => data) + " 19 ประตูแล้ว", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    } else if (this.state.mode_active == 2) { //กลับเลข
                        let inputNumbertem = inputNumberNew.map((data, i) => data)
                        this.removeDuplicateStrings(this.permute(inputNumbertem.join(""))).forEach(v => {
                            books.push({
                                typeLottery: typeId,
                                number: v.split(""),
                                price: ''
                            });
                        })

                        //toast
                        toast.success("แทง " + inputNumberNowStr + " กลับเลขแล้ว", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    } else if (this.state.mode_active == 3) { //รูดหน้า
                        let inputNumbertem = inputNumberNew.map((data, i) => data)
                        for (let x = 0; x <= 9; x++) {
                            books.push({
                                typeLottery: typeId,
                                number: [...inputNumbertem, x],
                                price: ''
                            });
                        }

                        //toast
                        toast.success("แทง " + inputNumberNowStr + " รูดหน้า", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    } else if (this.state.mode_active == 4) { //รูดหลัง
                        let inputNumbertem = inputNumberNew.map((data, i) => data)
                        for (let x = 0; x <= 9; x++) {
                            books.push({
                                typeLottery: typeId,
                                number: [x, ...inputNumbertem],
                                price: ''
                            });
                        }

                        //toast
                        toast.success("แทง " + inputNumberNowStr + " รูดหลัง", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    } else {
                        books.push({
                            typeLottery: typeId,
                            number: inputNumberNew,
                            price: ''
                        });

                        //Toast
                        toast.success("แทง " + inputNumberNowStr + " " + activeTypeObject.name, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
        }
        let _this = this;
        setTimeout(function () {
            _this.setState({ inputNumberNow: inputNumberNow, books: books })
        }, setTimeoutMinute);

    };

    handleChangePrice = (value, index) => {
        let typeLotteryDetails = this.state.typeLotteryDetails;

        let price = value.toString().replace(/,/g, '');
        if (!isNaN(price)) {
            price = price * 1;
            let books = this.state.books;
            let typeLotteryDetailsIndex = typeLotteryDetails.findIndex(elm => elm.type == books[index].typeLottery)
            if (price <= typeLotteryDetails[typeLotteryDetailsIndex].maxPrice) {
                let priceReplace = parseInt(Number(price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "")));
                books[index].price = priceReplace;
                this.setState({ books: books });
            }
        }
    };

    handleRemoveOrder = (index) => {
        let books = this.state.books;
        books.splice(index, 1);
        this.setState({ books: books });
    };

    getInputLottery = () => {
        let { inputNumber } = this.state;
        let input = new Array(inputNumber).fill("");
        let inputNumberNow = this.state.inputNumberNow;
        return (<Grid columns={1} centered>
            <Grid.Column textAlign={"center"} style={{ padding: '0px 0px 5px 0px' }}>
                {input.map(function (item, index) {
                    return (
                        <Button key={index} className="input-number-now" content={inputNumberNow[index]} basic circular style={{ marginLeft: 7, marginRight: 7 }} />)
                })}
            </Grid.Column>
        </Grid>);
    };

    closeConfirm = () => {
        this.setState({ openConfirm: false });
    };

    showConfirm = () => {
        this.setState({ openConfirm: true });
    };

    clearBooks = () => {
        this.setState({ books: [] });
    };

    getAssistRandomBox = () => {
        let { assistRandomActive } = this.state;
        let { handleRandomAssist } = this;
        return assistRandomActive.map(function (item, index) {
            if (item.active || item.type == 7 || item.type == 8 || item.type == 9 || item.type == 10) { return null }
            return (<Grid.Column key={index}>
                <Button disabled={item.active} content={item.name}
                    onClick={() => {
                        handleRandomAssist(item.type)
                    }} basic={item.active}
                    style={{ fontWeight: 200 }}
                    color={item.color} fluid />
            </Grid.Column>);
        });
    };

    getAssistRandomBox_2 = () => {
        let { assistRandomActive, mode_active } = this.state;
        let { handleRandomAssist, changinputNumber } = this;

        return (<Grid.Row>{assistRandomActive.map(function (item, index) {
            if (item.type == 7 || item.type == 8 || item.type == 9 || item.type == 10) {
                return (<Grid.Column key={index}>
                    <Button disabled={item.active}
                        content={item.name}
                        onClick={() => {
                            handleRandomAssist(item.type)
                        }}
                        basic={(mode_active == item.mode_active) ? false : true}
                        color={item.color}
                        fluid />
                </Grid.Column>);
            }
        })}</Grid.Row>);
    };

    getAssistantAllow(numberLength) {
        return ((numberLength == 2) ? [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12] : (numberLength == 3) ? [4, 8] : [])
    }

    removeDuplicateStrings = (inputArray) => {
        return [...new Set(inputArray)]
    }

    permute = (str) => {
        const _this = this
        var ret = [];
        if (str.length == 1) return [str];
        if (str.length == 2) return [str, str[1] + str[0]];
        str.split('').forEach(function (chr, idx, arr) {
            var sub = [].concat(arr);
            sub.splice(idx, 1);

            _this.permute(sub.join('')).forEach(function (perm) {
                ret.push(chr + perm);
            });
        });
        return ret;
    }

    getTypeLotteryDetails = () => this.state.typeLotteryDetails

    getOrderPaymentList = () => {
        const _this = this;
        let { books, typeLotteryDetails } = this.state;
        if (books.length == 0) {
            return (<Header as='h5' style={{ fontSize: '0.9rem' }} color='orange'>ไม่มีรายการ</Header>);
        }
        let booksSort = books.sort((a, b) => (a.typeLottery > b.typeLottery) ? 1 : ((b.typeLottery > a.typeLottery) ? -1 : 0));
        let firstTypeLotteryDetails = true;
        let groupTypeLottery = [];
        let result = booksSort.filter(item => item.number[item.number.length - 1] != '-')

        return result.map(function (book, index) {
            if (groupTypeLottery.indexOf(book.typeLottery) == -1) {
                firstTypeLotteryDetails = true;
                groupTypeLottery.push(book.typeLottery);
            } else {
                firstTypeLotteryDetails = false;
            }

            let detail = typeLotteryDetails.find((typeLotteryDetail) => {
                return typeLotteryDetail.type == book.typeLottery;
            });

            return (
                <Grid.Row style={{ padding: 0 }} key={index}>
                    <Grid.Column width={16}>
                        {firstTypeLotteryDetails ? (<Divider></Divider>) : ''}
                        {firstTypeLotteryDetails ? (<Header as='h4' style={{ margin: 0, marginTop: 5 }} color='green'> {detail.name}</Header>) : ""}
                        <Icon name='delete' size='small' color='red' style={{ position: "absolute", right: 1, padding: 5, cursor: 'pointer' }} onClick={() => _this.handleRemoveOrder(index)} />
                        <h5 style={{ margin: 3, marginTop: 5, color: 'var(--color-list-huay-play)' }}>{(book.number).join("")}</h5>
                    </Grid.Column>
                </Grid.Row>
            );
        });
    };

    getFavoriteOrder = () => {
        BookService.favoriteOrder().then((res) => {
            this.setState({ favoriteBill: res.data, modalPullBill: true })
        })
    }

    addFavoriteOrder = async (data) => {
        let { books, typeLotteryDetails } = this.state
        let _book = []
        await data.map((item,) => {
            let status = typeLotteryDetails.filter((type) => type.type == item.typeId)
            if (status.length > 0) {
                _book.push({
                    number: Array.from(item.number.toString()).map(Number),
                    price: item.price,
                    typeLottery: Number(item.typeId)
                });
            }
        })
        await _book.map((item) => {
            books.push(item)
        })
        this.setState({ modalPullBill: false })
        toast.success("เลือกเลขชุดสำเร็จ", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    deleteDuplicateBooks = () => {
        let { books } = this.state;
        var booksUnique = [];
        books.filter((item) => {
            var i = booksUnique.findIndex(value => (value.number.toString().replaceAll(',', '') === item.number.toString().replaceAll(',', '')) && value.typeLottery == item.typeLottery);
            if (i <= -1) {
                booksUnique.push(item);
            }
            return null;
        });
        this.setState({ books: booksUnique })
    }

    async initData() {
        this.setState({ oldOrderLoading: true })
        await BookService.getBookAll(this.state.ShowLimitOldOrder).then(async res => {
            if (res.status) {
                this.setState({ oldBill: res.data, modalPullOldBill: true, oldOrderLoading: false })
            }
        })
    }

    getOldOrder = async (count) => {
        await BookService.getBookDesc().then(res => {
            if (res.status) {
                this.setState({
                    numberOfOrder: res.data.numberOfOrder,
                    bookDescLoading: false
                })
            }
        })
        this.initData()

        // BookService.getOldOrder(count, "huay", this.state.huayDetail._id).then((res) => {
        //     this.setState({ oldBill: res.data, modalPullOldBill: true, oldOrderLoading: false })
        // })
    }

    // handleIncrease = async () => {
    //     await this.setState({ oldOrderLoading: true })
    //     await this.setState({ numberOfOldOrder: this.state.numberOfOldOrder + 5 })
    //     await this.getOldOrder(this.state.numberOfOldOrder)
    // }

    handleIncrease() {
        this.setState({ oldOrderLoading: true })
        this.setState((prevState) => ({ ShowLimitOldOrder: prevState.ShowLimitOldOrder + 5 }), () => {
            this.initData().then(() => this.setState({ oldOrderLoading: false }))
        })
    }

    addOldOrder = async (data) => {
        let { books, typeLotteryDetails } = this.state
        let _book = []
        await data.map((item,) => {
            let status = typeLotteryDetails.filter((type) => type.type == item.lotteryType.typeId)
            if (status.length > 0) {
                _book.push({
                    number: Array.from(item.number.toString()).map(Number),
                    price: "",
                    typeLottery: Number(item.lotteryType.typeId)
                });
            }
        })
        await _book.map((item) => {
            books.push(item)
        })
        this.onCloseOldOrder()
        toast.success("ดึงโพยสำเร็จ", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    onCloseOldOrder = () => {
        this.setState({ modalPullOldBill: false, })
    }

    render() {
        return (
            <Responsive onUpdate={this.resizeBox}>
                <ToastContainer autoClose={1000} transition={Slide} />
                <Container>
                    {this.state.openOrderPayment &&
                        <OrderPayment books={this.state.books} openOrderPayment={this.state.openOrderPayment}
                            handleCloseOrderPayment={this.handleCloseOrderPayment}
                            handleRemoveOrder={this.handleRemoveOrder}
                            showConfirm={this.showConfirm}
                            clearBooks={this.clearBooks}
                            handleChangePrice={this.handleChangePrice}
                            handleCommitOrder={this.commitOrder}
                            typeLotteryDetails={this.state.typeLotteryDetails}
                            roundId={this.props.match.params.roundId}
                            orderType="YIIGII"
                            huayName={this.state.huayDetail?.name}
                            deleteDuplicateBooks={this.deleteDuplicateBooks}
                        />
                    }
                    <Modal centered={false} open={this.state.openNumberShot} onClose={this.handleCloseNumberShot} closeIcon='close'>
                        <Modal.Content>
                            <Modal.Description>
                                <NumberShot roundId={this.props.match.params.roundId} />
                            </Modal.Description>
                        </Modal.Content>
                        {/* <Modal.Actions>
                            <Button negative onClick={this.handleCloseNumberShot}>ย้อนกลับ</Button>
                        </Modal.Actions> */}
                    </Modal>
                    <Modal size="mini" open={this.state.openConfirm} onClose={this.closeConfirm}>
                        <Modal.Header>ส่งโพยสำเร็จ</Modal.Header>
                        <Modal.Content>
                            <p>คุณต้องการแทงหวยต่อหรือไม่ ?</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button as={Link} to={"/history"} negative>ไปที่โพย</Button>
                            <Button onClick={this.closeConfirm} positive content='แทงหวยต่อ' />
                        </Modal.Actions>
                    </Modal>
                    <Grid className='lotteryPlayUI' columns='2' style={{ margin: -5, marginTop: 0 }}>
                        <div style={{ background: 'var(--background-head-menu-item-active)', color: 'var(--color-head-menu-item-active)',border:'2px solid var(--border-color-head-menu-item-active)', width: '100%', padding: '8px 5px', margin: '5px 0 -8px', borderRadius: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.2em' }}><Icon name='clone' color='white' size='small' /><span style={{ fontWeight: 500, marginLeft: 5 }}> {this.state.huayDetail?.name}</span></div>
                        <Grid.Column computer='13' tablet='13' mobile='12' style={{ padding: 2, paddingTop: '1rem' }}>
                            <Segment style={{
                                border: 0,
                                marginBottom: -10,
                                marginTop: 3,
                                background: 'var(--background-shoot-number)',
                                borderRadius: 15,
                                color: 'var(--color-shoot-number)',
                                padding: '15px 0'
                            }}>
                                <Header as='h3' textAlign={"center"} style={{ fontWeight: 400, marginBottom: 5 }}>
                                    เลขยิงปัจจุบัน
                                </Header>
                                <Header as='h2' textAlign={"center"} style={{ marginTop: 0 }}>
                                    {this.state.sumShotNumber}
                                </Header>
                            </Segment>

                            <Segment
                                className="customPadding"
                                loading={this.state.onLoadingtypeLottery}
                                style={{
                                    background: 'var(--background-num-pad)',
                                    borderRadius: 15
                                }}
                            >
                                {/* <div style={{ textAlign: 'right', margin: -10, marginBottom: 20, cursor: 'pointer' }}>
                                    <Modal style={{ maxWidth: 'unset' }} size='big' closeIcon trigger={<Button basic color="blue" size='mini' style={{ padding: "5px 10px"}}>วิธีการเล่น</Button>}>
                                        <Modal.Content>
                                            <video width="100%" controls>
                                                <source src={require('../../../assets/video/YikiPlay.mp4')} type="video/mp4" />
                                                Your browser does not support HTML video.
                                            </video>
                                        </Modal.Content>
                                    </Modal>
                                    <Button basic color="yellow" size='mini' style={{ padding: 5, }} as='a' href="https://www.mylotto111.com/detail/89" target="_blank" >กติกา</Button>
                                </div> */}
                                <div style={{ display: 'flex', margin: '0 -1rem' }}>

                                    <div style={{ flex: 1, padding: '0 2px' }}>
                                        <Button onClick={this.getFavoriteOrder} fluid color='green' style={{ background: 'var(--background-select-favorite-number)', color: '#FFF', padding: '0.9em 0', margin: '-1rem 0 1.3rem', borderRadius: 15 }}>
                                            เลือกเลขชุด
                                        </Button>
                                    </div>

                                    <div style={{ flex: 1, padding: '0 2px' }}>
                                        <Button onClick={() => this.getOldOrder(this.state.ShowLimitOldOrder)} fluid color='green' style={{ background: 'var(--background-pull-number)', color: '#FFF', padding: '0.9em 0', margin: '-1rem 0 1.3rem', borderRadius: 15 }}>
                                            ดึงโพย
                                        </Button>
                                    </div>

                                </div>

                                <Grid columns={2}>
                                    <Grid.Row>
                                        {this.state.typeLotteryDetails.map((item, key) => (
                                            <Grid.Column key={key}>
                                                <List divided selection className={(this.state.typeLotteryActive[item.type].isActive ? "list-custom red active " : "list-custom red ") + (this.state.typeLotteryActive[item.type].isAllow ? 'allow' : '')}>
                                                    <List.Item onClick={() => {
                                                        this.handleChangeInput(item.length, item.type, this.getAssistantAllow(item.length))
                                                    }}>
                                                        {/* <Label color='orange' horizontal className={"label-custom "+((key%2 == 0) ? "left" : 'right') } style={{zIndex: 9, padding:'0.2rem 0.0rem',top:-8}} >
                                                        x{item.price}
                                                    </Label> */}
                                                        {item.name}<br />
                                                        {/* <span style={{ fontSize: '0.85rem', fontWeight: 400 }}>x{item.price}</span> */}
                                                    </List.Item>
                                                </List>
                                            </Grid.Column>
                                        ))}
                                    </Grid.Row>
                                    <hr style={{ margin: 5 }} />
                                    {this.getAssistRandomBox_2()}
                                </Grid>
                                {/* </Segment> */}

                                {/* <Segment > */}
                                <div style={{
                                    marginTop: 45,
                                    height: 2,
                                    backgroundImage: 'linear-gradient(to right, transparent 80%, rgb(204, 204, 204) 20%)',
                                    backgroundSize: 20,
                                    marginInlineStart: 50,
                                    marginInlineEnd: 50
                                }} />
                                <div style={{ paddingTop: 35, paddingBottom: 35 }}>
                                    {this.getInputLottery()}
                                </div>
                                {/* </Segment> */}

                                {/* <Segment className="customPadding" style={{backgroundColor:"#DDD"}}> */}
                                <Grid columns={3} divided>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Button content='1' onClick={() => {
                                                this.handleAddNumber(1)
                                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button content='2' onClick={() => {
                                                this.handleAddNumber(2)
                                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button content='3' onClick={() => {
                                                this.handleAddNumber(3)
                                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Button content='4' onClick={() => {
                                                this.handleAddNumber(4)
                                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button content='5' onClick={() => {
                                                this.handleAddNumber(5)
                                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button content='6' onClick={() => {
                                                this.handleAddNumber(6)
                                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Button content='7' onClick={() => {
                                                this.handleAddNumber(7)
                                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button content='8' onClick={() => {
                                                this.handleAddNumber(8)
                                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button content='9' onClick={() => {
                                                this.handleAddNumber(9)
                                            }} fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Button onClick={this.handleRandom} content='สุ่ม' fluid style={{ backgroundColor: '#FFFFFF', border: '3px solid var(--color-num-pad)' }} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button onClick={() => {
                                                this.handleAddNumber(0)
                                            }} content='0' fluid style={{ background: 'var(--color-num-pad)', color: 'white' }} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button onClick={() => {
                                                this.handleDeleteNumber()
                                            }} content='ลบ' fluid style={{ backgroundColor: '#FFFFFF', border: '3px solid var(--color-num-pad)' }} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                {/* </Segment> */}

                                {/* <Segment className="customPadding" style={{backgroundColor:"#DDD"}}> */}
                                <Grid columns={3} >
                                    <Grid.Row>
                                        {this.getAssistRandomBox()}
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column computer='3' tablet='3' mobile='4' textAlign='center' style={{ padding: 1, paddingTop: '1rem', maxHeight: 810, }}>
                            <Segment style={{ padding: '10px 3px 3px 3px', overflowY: 'auto', maxHeight: 'inherit', height: '100%', background: 'var(--background-num-pad)', borderRadius: 15 }}>
                                <p style={{ color: 'var(--color-list-huay-play-head)' }}>เลขแทง</p>
                                <Button color='red' size='mini' fluid onClick={() => this.setState({ books: [] })}>ล้าง</Button>
                                {this.getOrderPaymentList()}
                            </Segment>
                        </Grid.Column>
                    </Grid>
                    <Segment style={{ backgroundColor: 'transparent', boxShadow: 'unset', border: 0, padding: 10, paddingTop: 0, marginTop: -10 }}>
                        <Grid style={{ marginTop: -3 }}>
                            <Grid.Column computer='13' tablet='13' mobile='16' style={{ padding: 2, paddingTop: '1rem' }}>
                                <Segment style={{ border: 0, borderRadius: 'unset', background: 'transparent' }}>
                                    <Button onClick={this.handleOpenOrderPayment} fluid color='green' icon='arrow left' style={{ background: 'var(--color-num-pad)', color: '#FFF' }}>
                                        <Icon name='shopping cart' /> ใส่ราคา
                                    </Button>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column className="shot-number-card" computer='3' tablet='3' mobile='16' textAlign='center' style={{ padding: 1, paddingTop: '1rem' }}>
                                <Segment style={{ border: 0, borderRadius: 'unset', background: 'transparent' }}>
                                    <Button onClick={this.handleOpenNumberShot} fluid color='red'>
                                        ยิงเลข
                                    </Button>
                                </Segment>
                            </Grid.Column>
                        </Grid>
                    </Segment>

                    {/* Modal Pull favorite order */}
                    <Modal
                        onClose={() => {
                            this.setState({ modalPullBill: false })
                        }}
                        open={this.state.modalPullBill}
                        closeIcon
                        centered={false}
                    >
                        <Modal.Header>เลือกเลขชุด</Modal.Header>
                        <div style={{ textAlign: 'center', padding: '0.8em 0.8em', fontSize: '0.9em' }}>
                            <Button
                                as={Link}
                                to='/favoriteBill'
                                fluid
                                color='green'
                                style={{
                                    background: '#198754',
                                    color: '#FFF',
                                    padding: '0.7em 0',
                                    margin: '0 0 0.6rem',
                                    borderRadius: 8,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <Icon name="plus" style={{ marginRight: 5 }} />
                                เพิ่มเลขชุด
                            </Button>
                            <Table unstackable style={{ border: 0, margin: 0 }}>
                                <Table.Row style={{ background: '#212529', color: '#FFF', textAlign: 'center' }}>
                                    <Table.Cell style={{ width: '25%' }}>ชื่อ</Table.Cell>
                                    <Table.Cell style={{ width: '40%' }}>
                                        เลข
                                    </Table.Cell>
                                    <Table.Cell style={{ width: '25%' }}>
                                        วันที่สร้าง
                                    </Table.Cell>
                                    <Table.Cell style={{ width: '10%' }}>
                                        #
                                    </Table.Cell>
                                </Table.Row>
                            </Table>
                            {
                                this.state.favoriteBill.length > 0 ?
                                    this.state.favoriteBill?.map((bill, index) => {
                                        return (
                                            < Table unstackable style={{ border: 0, margin: 0, borderRadius: 0 }}>
                                                <Table.Row style={{ textAlign: 'center' }}>
                                                    <Table.Cell style={{ width: '25%', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px', borderColor: 'Gainsboro' }}>{bill.name}</Table.Cell>
                                                    <Table.Cell className="text-number-bill-favorite" style={{ width: '40%', wordWrap: 'break-word', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro' }}>
                                                        {
                                                            bill.items?.map((item, index) => {
                                                                return `${item.number}${(index != bill.items.length - 1) ? ', ' : ''}`
                                                            })
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell style={{ width: '25%', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro' }}>
                                                        {`${new Date(bill.updatedAt).toLocaleDateString('th-TH')},`} <br /> {`${new Date(bill.updatedAt).getHours()}:${new Date(bill.updatedAt).getMinutes()}:${new Date(bill.updatedAt).getSeconds()}`}
                                                    </Table.Cell>
                                                    <Table.Cell style={{ width: '15%', padding: 3, border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro' }}>
                                                        <Button onClick={() => this.addFavoriteOrder(bill.items)} size="mini" style={{ background: "red", padding: 5, borderRadius: 5, margin: 0 }}>
                                                            <Icon name="cloud download" style={{ color: "#FFF", fontSize: 14, margin: 0 }} />
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table>
                                        )
                                    })
                                    :
                                    <Table unstackable style={{ border: 0, margin: 0, borderRadius: 0 }}>
                                        <Table.Row >
                                            <Table.Cell style={{ width: '100%', textAlign: 'center', padding: '10px 0' }}>
                                                ไม่มีเลขชุด
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table>
                            }

                        </div>
                    </Modal>

                    {/* Modal pull order */}
                    <Modal
                        onClose={this.onCloseOldOrder}
                        open={this.state.modalPullOldBill}
                        closeIcon
                        centered={false}
                    >
                        <Modal.Header>รายการแทง</Modal.Header>
                        <div style={{ textAlign: 'center', padding: '0.8em 0.8em', fontSize: 12 }}>
                            <Table unstackable style={{ border: 0, margin: 0 }}>
                                <Table.Row style={{ background: '#212529', color: '#FFF', textAlign: 'center' }}>
                                    <Table.Cell style={{ width: '10%' }}>#</Table.Cell>
                                    <Table.Cell style={{ width: '20%' }}>งวด</Table.Cell>
                                    <Table.Cell style={{ width: '40%' }}>
                                        เลข
                                    </Table.Cell>
                                    <Table.Cell style={{ width: '20%' }}>
                                        วันที่ซื้อ
                                    </Table.Cell>
                                    <Table.Cell style={{ width: '10%' }}>
                                        #
                                    </Table.Cell>
                                </Table.Row>
                            </Table>
                            {
                                this.state.oldBill.length > 0 ?
                                    this.state.oldBill?.map((bill, index) => {
                                        return (
                                            < Table unstackable style={{ border: 0, margin: 0, borderRadius: 0 }}>
                                                <Table.Row style={{ textAlign: 'center' }}>
                                                    <Table.Cell style={{ width: '10%', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px', borderColor: 'Gainsboro', padding: '0.7em' }}>{index + 1}</Table.Cell>
                                                    <Table.Cell style={{ width: '20%', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro', padding: '0.5em' }}>
                                                        {`${new Date(bill.huayDetail.roundDetail.announceTime).toLocaleDateString('th-TH')},`} <br /> {`${new Date(bill.huayDetail.roundDetail.announceTime).getHours()}:${new Date(bill.huayDetail.roundDetail.announceTime).getMinutes()}:${new Date(bill.huayDetail.roundDetail.announceTime).getSeconds()}`}
                                                    </Table.Cell>
                                                    <Table.Cell className="text-number-bill-favorite" style={{ width: '40%', wordWrap: 'break-word', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro' }}>
                                                        {
                                                            bill.items?.map((item, index) => {
                                                                return `${item.number}${(index != bill.items.length - 1) ? ', ' : ''}`
                                                            })
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell style={{ width: '20%', border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro', padding: '0.5em' }}>
                                                        {`${new Date(bill.time).toLocaleDateString('th-TH')},`} <br /> {`${new Date(bill.time).getHours()}:${new Date(bill.time).getMinutes()}:${new Date(bill.time).getSeconds()}`}
                                                    </Table.Cell>
                                                    <Table.Cell style={{ width: '15%', padding: 3, border: '0.8px solid #545454', borderWidth: '0 0.8px 0.8px 0px', borderColor: 'Gainsboro' }}>
                                                        <Button onClick={() => this.addOldOrder(bill.items)} size="mini" style={{ background: "red", padding: 5, borderRadius: 5, margin: 0 }}>
                                                            <Icon name="cloud download" style={{ color: "#FFF", fontSize: 14, margin: 0 }} />
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table>
                                        )
                                    })
                                    :
                                    <Table unstackable style={{ border: 0, margin: 0, borderRadius: 0 }}>
                                        <Table.Row >
                                            <Table.Cell style={{ width: '100%', textAlign: 'center', padding: '10px 0' }}>
                                                ไม่มีรายการแทง
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table>
                            }

                            {
                                this.state.numberOfOrder > this.state.oldBill.length && (
                                    <center><Button positive size={"small"} style={{ marginTop: 5 }} onClick={() => this.handleIncrease()} disabled={this.setState.oldOrderLoading}>โหลดเพิ่มเติม {this.state.oldOrderLoading && <Icon loading name='spinner' />}</Button></center>
                                )
                            }

                        </div>
                    </Modal>

                </Container>
            </Responsive>
        );
    }
}

export default LotteryPlay;