import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import NotFoundPage from './components/NotFoundPage';
import Login from "./components/layout/Login";
import LoginAuto from "./components/layout/LoginAuto";
import Register from "./components/layout/Register";
import Main from "./components/layout/Main";
import routes from "./config/routes";
import ForgotPassword from "./components/layout/ForgotPassword";
import Maintenance from './components/layout/Maintenance';

function PrivateRoute({children, ...rest}) {
    let isLogin = localStorage.getItem('login');
    let role = isLogin ? localStorage.getItem('user') : null
    return (
        <Route
            {...rest}
            render={({location}) => (isLogin ? children : <Redirect to={{pathname: "/", state: {from: location}}}/>)
            }
        />
    );
}

function PublicRoute({children, ...rest}) {
    let isLogin = localStorage.getItem('login');
    return (
        <Route
            {...rest}
            render={({location}) => (isLogin ? <Redirect to={{pathname: "/home", state: {from: location}}}/> : children)
            }
        />
    );
}

function App() {
    return (
        <Router>
            <Switch>
                <PublicRoute exact path="/"><Login/></PublicRoute>
                <Route exact path="/a/:loginKey"><LoginAuto/></Route>
                <PublicRoute path="/forgetPassword"><ForgotPassword/></PublicRoute>
                <PublicRoute path="/register/invite/:parentId"><Register title='สมัครสมาชิก'/></PublicRoute>
                <PublicRoute path="/register/a/:agentId"><Register title='สมัครสมาชิก'/></PublicRoute>
                <PublicRoute path="/register"><Register title='สมัครสมาชิก'/></PublicRoute>
                {routes.privateRoute.map(function (item,key) {
                    return (<PrivateRoute exact path={item.path} key={key}><Main path={item.path} menuActive={item.menuActive} routeData={item}/></PrivateRoute>);
                })}
                <Route  path="/maintenance" component={Maintenance}/>
                <Route component={NotFoundPage}/>
            </Switch>
        </Router>
    );
}

export default App;
