import React, { Component } from 'react'
import {
    Dropdown,
    Grid,
    Icon,
    Label,
    Responsive,
    Button,
} from "semantic-ui-react";
import TopUpCreditByAccount from "./TopUpCreditByAccount";
import WithdrawCredit from "./WithdrawCredit";
import WithdrawAF from "./WithdrawAF";
import WalletService from '../../../service/WalletService';

class WalletHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            box: true,
            openTopUpCreditByAccount: false,
            openWithdrawCredit: false,
            openWithdrawAF: false,
            loadingCreadit: false,
            wallet: WalletService.getWalletBalance()
        };
    }

    async componentDidMount() {
        this.resizeBox();
        await WalletService.fetchWalletBalance()
        await this.setState({ wallet: WalletService.getWalletBalance() });
        await this.syncWallet()
    }

    componentWillUnmount() {
        clearInterval(this.syncWallet())
    }

    syncWallet = () => setInterval(() => {
        this.setState({ wallet: WalletService.getWalletBalance() });
    }, 3000);

    resizeBox = () => {
        let box = true;
        let innerWidth = window.innerWidth;
        if (innerWidth > 520)
            box = true;
        else
            box = false;

        this.setState((prevState) => ({ box: box }))
    };

    handleOpenTopUpCreditByAccount = () => {
        this.setState({ openTopUpCreditByAccount: true });
    };

    handleCloseTopUpCreditByAccount = () => {
        this.setState({ openTopUpCreditByAccount: false });
    };

    handleOpenWithdrawCredit = () => {
        this.setState({ loadingCreadit: true })
        WalletService.fetchWalletBalance().then(res => {
            this.setState({ loadingCreadit: false, openWithdrawCredit: true });
        })
    };

    handleCloseWithdrawCredit = () => {
        this.setState({ openWithdrawCredit: false });
    };

    closeWithdrawAF = () => this.setState({ openWithdrawAF: false })

    toTwoDecimal = (num) => {
        let num_new = (parseInt(Number(Number((Number(num) * 10).toFixed(3)) * 10).toFixed(3)) / 100.00)
        let _num = num_new.toString().split('.')
        if (_num.length > 1) {
            return (_num[0] + '.' + (_num[1].length < 2 ? `${_num[1].substring(0, 2)}0` : _num[1].substring(0, 2))).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
            return (_num[0] + '.00').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }

    render() {
        return (
            <>
                <Responsive onUpdate={this.resizeBox}>
                    <TopUpCreditByAccount openTopUpCreditByAccount={this.state.openTopUpCreditByAccount}
                        handleCloseTopUpCreditByAccount={this.handleCloseTopUpCreditByAccount} />
                    <WithdrawCredit openWithdrawCredit={this.state.openWithdrawCredit}
                        handleCloseWithdrawCredit={this.handleCloseWithdrawCredit} />
                    <WithdrawAF openWithdrawAF={this.state.openWithdrawAF} closeWithdrawAF={this.closeWithdrawAF} />
                    <Grid columns={3} className={"d-flex margin-bottom-20"}>
                        <Grid.Row>
                            <Grid.Column>
                                <Button.Group color='teal' className="float-left label-af">
                                    <Button style={{ padding: 10 }}
                                        onClick={() => this.setState({ openWithdrawAF: true })}>{this.state.wallet.af}
                                        <Icon style={{ paddingLeft: 10, paddingRight: 10 }}>AF</Icon></Button>
                                </Button.Group>
                            </Grid.Column>
                            <Grid.Column textAlign='center'>
                                <Label className="label-tel" style={{ padding: 6 }}>
                                    <h3>{JSON.parse(localStorage.getItem('user')).username}</h3>
                                </Label>
                            </Grid.Column>
                            <Grid.Column textAlign='right'>
                                <Button.Group color='teal' className="float-right label-credit">
                                    <Button style={{ paddingLeft: 5, paddingRight: 3 }} icon="redo" onClick={() => { this.setState({ loadingCreadit: true }); WalletService.fetchWalletBalance().then(() => this.setState({ loadingCreadit: false })) }}></Button>
                                    <Button loading={this.state.loadingCreadit} style={{ paddingLeft: 5, paddingRight: 2 }}>{this.toTwoDecimal(this.state.wallet.credit)}</Button>
                                    <Dropdown className='button dropdownCustom' style={{ padding: 8, paddingLeft: 1, paddingRight: 8 }}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item text='เติมเครดิต' onClick={() => {
                                                this.handleOpenTopUpCreditByAccount()
                                            }} />
                                            <Dropdown.Item text='ถอนเครดิต' onClick={() => {
                                                this.handleOpenWithdrawCredit()
                                            }} />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Button.Group>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Responsive>
            </>
        );
    }
}

export default WalletHeader;